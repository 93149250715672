import { useContext, useEffect, useState } from "react"
import useConfirm from "../../hooks/useConfirm"
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri"
import { supervisionService } from "../../services/supervisionService"
import { DeblocageContext } from "../../providers/DeblocageContextProvider"
import { DataContext } from "../../providers/DataContextProvider"

const ConfirmModal = () => {

    const [inputValue, setInputValue] = useState("")
    const [codeTournant, setCodeTournant] = useState("")
    const [codeTournantText, setCodeTournantText] = useState("")
    const [showCodeTournant, setShowCodeTournant] = useState(true)
    const {
        title,
        opts,
        isOpen,
        proceed,
        cancel,
    } = useConfirm()
    const [codeSaisi, setCodeSaisi] = useState("")
    const { codeGenere, setCodeGenere } = useContext(DeblocageContext)
    const { selectedIntervention } = useContext(DataContext)

    useEffect(() => {
        if (isOpen) {
            document.querySelector(".confirm-bg").style.display = "flex"
            document.querySelector(".c-dialog").style.display = "flex"
            document.body.style.overflow = "hidden"
            setInputValue("")
        } else {
            document.querySelector(".c-dialog").style.display = "none"
            document.querySelector(".confirm-bg").style.display = "none"
            document.body.style.overflow = null
        }
    }, [isOpen])

    useEffect(() => {
        if (opts.option === 2) {
            if (codeGenere === "") {
                supervisionService.getCodeTournant()
                    .then(response => {
                        setCodeTournant(response)
                        setCodeTournantText(response)
                        setCodeGenere(response)
                        //console.log(response)
                    })
            }
            else {
                setCodeTournant(codeGenere)
                setCodeTournantText(codeGenere)
                //console.log(codeGenere)
            }
        }
    }, [opts.option, codeGenere])

    const handleShowCodeTournant = () => {
        setShowCodeTournant(!showCodeTournant)
        if (showCodeTournant) {
            // Attente de la fin de l'animation
            // du bouton avant de vider
            setTimeout(() => {
                setCodeTournantText("")
            }, 300)
        } else {
            setCodeTournantText(codeTournant)
        }
    }

    return (
        <div>
            <div className="c-dialog">
                <div className="c-dialog-content">
                    <div className="c-dialog-body">
                        {/*<button type="button" className="c-dialog-close-button" onClick={cancel}>×</button>*/}
                        <div className="c-dialog-body-content">
                            {/* Titre du Modal */}
                            <p className="title">{title}</p>
                            {opts.option === 2 && <p className="title">Vous devez appelez le siège pour un code de déblocage. Tel: 04.42.50.96.90</p>}
                            {/* Message du Modal */}
                            {opts.message !== "" && <p>{opts.message}</p>}
                            {/* Code tournant du Modal */}
                            {opts.option === 2 &&
                                <div className="code-container">
                                    <p className="title code-title">Code à donner au hotliner : </p>
                                    <div className="code-slide-container">
                                        <p className={"title code-title"}>{codeTournantText !== "" && codeTournantText}</p>
                                        <button type="button" className={"c-dialog-button" + (!showCodeTournant ? "" : " show")} onClick={handleShowCodeTournant}>
                                            {showCodeTournant ?
                                                <RiEyeOffFill /> :
                                                <RiEyeFill />
                                            }
                                        </button>
                                    </div>
                                </div>
                            }
                            {/* Champ de texte pour code de déblocage (option => 2) */}
                            {opts.option === 2 &&
                                <input
                                    type="text"
                                    maxLength={4}
                                    value={inputValue}
                                    onChange={(e) => { setInputValue((e.target.value).toUpperCase()); setCodeSaisi(e.target.value) }}
                                    className="form-control mt-2"
                                    placeholder="Saisir ici le code donné par le siège"
                                />
                            }
                        </div>
                    </div>
                    <div className="c-dialog-footer">
                        {(!opts["option"] || opts.option === 1) ?
                            <>
                                <button className="c-dialog-button c-dialog-button-secondary c-dialog-button_margin-right" onClick={() => proceed({ codeSaisi: codeSaisi, codeGenere: codeTournantText, activite: opts.activite, raison_code: opts.message, rapport_numero: opts.rapport_numero ? opts.rapport_numero : selectedIntervention && selectedIntervention.data.numero_oi ? selectedIntervention.data.numero_oi : null })}>
                                    {(!opts["option"] || opts.option === 1) && "Oui"}
                                </button>
                                <button className="c-dialog-button c-dialog-button-primary" onClick={cancel}>
                                    {(!opts["option"] || opts.option === 1) && "Non"}
                                </button>
                            </> :
                            <>
                                <button className="c-dialog-button c-dialog-button-secondary c-dialog-button_margin-right" onClick={cancel}>
                                    {opts.option === 2 && "Annuler"}
                                </button>
                                <button className="c-dialog-button c-dialog-button-primary" onClick={() => proceed({ codeSaisi: codeSaisi, codeGenere: codeTournantText, raison_code: opts.message, activite: opts.activite, rapport_numero: opts.rapport_numero ? opts.rapport_numero : selectedIntervention && selectedIntervention.data.numero_oi ? selectedIntervention.data.numero_oi : null })}>
                                    {opts.option === 2 && "Ok"}
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="confirm-bg">
            </div>
        </div>
    )
}

export default ConfirmModal