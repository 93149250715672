const activiteList = [
    /*{
        nom: "Toutes",
        numero: -2
    },*/
    {
        nom: "Gen",
        numero: 0
    },
    {
        nom: "Taximètre", // TAX
        numero: 1
    },
    {
        nom: "Gaz / Opa", // GZO
        numero: 2
    },
    {
        nom: "Tachygraphe", // TCO
        numero: 4
    },
    {
        nom: "Ethylotest", // EAD
        numero: 5
    },
    {
        nom: "Auto-Ecole", // AUT
        numero: 6
    },
    {
        nom: "Mtn",
        numero: 400
    },
]

export default activiteList;