import React, { useState, useRef, useEffect } from "react"
import { Link, NavLink } from "react-router-dom"
import { CgMenuGridR } from "react-icons/cg"
import { HiOutlineMenu } from "react-icons/hi"
import { FiChevronLeft } from "react-icons/fi"
import { IoMdClose } from "react-icons/io"
import { adherentMenuContents, ExterneMenuContents, MenuContents } from "./../../constants/userCase"
import history from "./../../helpers/history"
import { Profil } from "./../Profil"
import player from "./../../res/player.svg"
import { Role } from "./../../helpers/Role"
import { UserCase } from "./../Intervention/Saisie/constant/UserCase"
import useOutsideAlerter from "./../useOutsideAlerter"
import { FcPlanner } from 'react-icons/fc'
import Planning from "./Planning"
import { isNullOrUndefined, lockBody, unlockBody } from "../../helpers/utils"
import { useAuth } from "../../providers/authProvider"
import { userService } from "../../services/userService"
import ReactLoading from "react-loading"
import { VscTriangleDown } from "react-icons/vsc"
import Logo from './../../res/logo.svg'
import Logo_icon from './../../res/logo_icon.svg'
import { useDataContext } from "../../providers/DataContextProvider"
import { useAdherentContext } from "../../providers/AdherentContextProvider"
import { supervisionService } from "../../services/supervisionService"

const NavBar = (props) => {

    const [menuOpened, setmenuOpened] = useState(false)
    const [profilOpened, setprofilOpened] = useState(false)
    const [useExtendMenu, setuseExtendMenu] = useState(false)
    const [showMenuGeneral, setshowMenuGeneral] = useState(false)
    const [openPlanning, setOpenPlanning] = useState(false)
    const wrapperRef = useRef(null)
    let smallDevice = useMedia("(max-width: 640px)")
    let regexURL = /intervention\/+/
    const auth = useAuth()
    const user = auth.getLocalUser()
    const userRole = parseInt(user.role)
    const [avatar, setAvatar] = useState(localStorage.avatar)
    const [isLoading, setIsLoading] = useState(false)
    const { interventionInfoGeneral } = useDataContext()
    const { adherent, setAdherent } = useAdherentContext()

    useEffect(() => {
        if (adherent === null) {
            supervisionService.getAdherent(user.group)
                .then(response => { setAdherent(response) })
                .catch(error => console.log(error))
        }
    }, [adherent])

    const openMenu = () => {
        setmenuOpened(true)
        lockBody()
    }

    const hideMenu = () => {
        setmenuOpened(false)
        unlockBody()
    }

    const toggleProfil = () => {
        setprofilOpened(!profilOpened)
    }

    const toggleMenu = () => {
        setshowMenuGeneral(!showMenuGeneral)
    }

    const hideProfil = () => {
        setprofilOpened(false)
    }

    const togglePlanning = () => {
        setOpenPlanning(!openPlanning)
    }

    useOutsideAlerter(wrapperRef, hideProfil)

    useEffect(() => {
        // On est dans une intervention
        // On verifie la taille de l'ecran
        if (regexURL.test(history.location.pathname) === true) {
            // Petit ecran
            // On met le menu en plus dans le sidebar
            if (smallDevice) {
                setuseExtendMenu(true)
            } else {
                // Grand ecran
                setuseExtendMenu(false)
            }
            hideMenu()
        } else {
            setuseExtendMenu(false)
        }

    }, [history.location.pathname, smallDevice])

    useEffect(() => {
        if (isNullOrUndefined(localStorage.avatar)) {
            setIsLoading(true)
            userService.getAvatar(user.group, user.user_id)
                .then(response => {
                    setAvatar(response)
                    localStorage.setItem("avatar", response)
                    setIsLoading(false)
                })
        }
    }, [])


    let AdherentMenus = MenuContents.filter(menu => menu.url === '/')

    return (
        <header className="Header-myaccount Header-nav">
            {/* ------ Contenu du menu caché ---- */}
            <div className={"Comet-menu " + (menuOpened ? "Comet-menu-show" : "")}>
                <img src={Logo_icon} alt="Logo" />
                <span className="sidebar-brand">Comet Optima</span>
                <button className="btn Comet-close-menu" onClick={hideMenu}>
                    <IoMdClose className="Comet-icon-close-menu" />
                </button>
                <ul className="Comet-menu-navigation">
                    {/* ------------------------------------- -------- */}
                    {/* -------- Affichage pour les admins et siege -------- */}
                    {/* ------------------------------------- -------- */}
                    {user && (userRole === Role.Admin || userRole === Role.Siege) && (!useExtendMenu) && MenuContents.map((content, index) =>

                        ((userRole === Role.Admin) || (userRole !== Role.Admin && content.disabled !== 'disabled')) &&

                        <Link key={index} to={{ pathname: content.url, state: { from: history.location.pathname } }}>
                            <li className={content.url === history.location.pathname ? "Active" : ""}>
                                <div className="menu-icon">
                                    {content.i}
                                </div>
                                {content.title}
                            </li>
                        </Link>

                    )}
                    {/* ------------------------------------- -------- */}
                    {/* -------- Affichage pour les adhérents -------- */}
                    {/* ------------------------------------- -------- */}
                    {user && userRole === Role.Adherent && adherentMenuContents.map((content, index) =>
                        <Link key={index} to={{ pathname: content.url, state: { from: history.location.pathname } }}>
                            <li className={content.url === history.location.pathname ? "Active" : ""}>
                                <div className="menu-icon">
                                    {content.i}
                                </div>
                                {content.title}
                            </li>
                        </Link>
                    )}
                    {user && (userRole === Role.Admin || userRole === Role.Siege) && (useExtendMenu) &&
                        <>
                            <div className="menu-header">
                                <a href="#" className="menu-header-link" onClick={toggleMenu}>
                                    <div className="menu-header-link-icon">
                                        <FiChevronLeft className={showMenuGeneral ? "icon-menu-outils" : "icon-menu-general"} />
                                    </div>
                                    <div className="menu-header-link-label">
                                        {showMenuGeneral ? "Outils Intervention" : "Menu Général"}
                                    </div>
                                </a>
                            </div>
                            {showMenuGeneral ?
                                MenuContents.map((content, index) =>
                                    ((userRole === Role.Admin) || (userRole !== Role.Admin && content.disabled !== 'disabled')) &&
                                    <Link key={index} to={{ pathname: content.url, state: { from: history.location.pathname } }}>
                                        <li className={content.url === history.location.pathname ? "Active" : ""}>
                                            <div className="menu-icon">
                                                {content.i}
                                            </div>
                                            {content.title}
                                        </li>
                                    </Link>
                                )
                                :
                                <div className="navigation-body">
                                    {UserCase.map((g, n) => {
                                        if (((interventionInfoGeneral.que_vp === true && interventionInfoGeneral.d1 === false) === true && g.group !== "Installation") || (interventionInfoGeneral.que_vp === true && interventionInfoGeneral.d1 === false) === false) {
                                            return (
                                                <div key={n} className="group">
                                                    <div className="group-header">
                                                        <span>
                                                            {g.group}
                                                        </span>
                                                    </div>
                                                    <div className="group-body">
                                                        {g.items.map((u, m) => {
                                                            if (u.link === "/intervention/etalonnage") {
                                                                if ((interventionInfoGeneral.installation || interventionInfoGeneral.bris_scellement)) {
                                                                    return (
                                                                        <li key={m}>
                                                                            <Link to={u.link} className={u.link === history.location.pathname ? "saisie-active" : undefined}>
                                                                                <div className="saisie-sidebar-icon">
                                                                                    {u.image}
                                                                                </div>
                                                                                <div className="saisie-outils-texte">
                                                                                    {u.name}
                                                                                </div>
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                }
                                                                else {
                                                                    return null
                                                                }
                                                            } else {
                                                                return (
                                                                    <li key={m}>
                                                                        <Link to={u.link} className={u.link === history.location.pathname ? "saisie-active" : undefined}>
                                                                            <div className="saisie-sidebar-icon">
                                                                                {u.image}
                                                                            </div>
                                                                            <div className="saisie-outils-texte">
                                                                                {u.name}
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            }
                                                        }

                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        }
                                        else {
                                            return null
                                        }
                                    })}
                                </div>}
                        </>
                    }
                    {user && userRole === Role.Externe && !useExtendMenu && ExterneMenuContents.map((content, index) =>
                        <Link key={index} to={{ pathname: content.url, state: { from: history.location.pathname } }}>
                            <li className={content.url === history.location.pathname ? "Active" : ""}>
                                <div className="menu-icon">
                                    {content.i}
                                </div>
                                {content.title}
                            </li>
                        </Link>
                    )}
                </ul>
            </div>
            {/* ------ Background opacité à l'ouverture du menu ---- */}
            <div className={"Comet-background-opacity " + (menuOpened ? "Comet-background-opacity-show" : "")}
                onClick={hideMenu}>
            </div>
            {/* ------ Contenu de la navbar ---- */}
            <div className="navbar-content">
                <NavLink to="#" className={"navbar-menu-icon navbar-button" + (useExtendMenu ? " extend-menu-navbar-icon" : "")} onClick={openMenu}>
                    {useExtendMenu ?
                        <CgMenuGridR className="Comet-icon-sidebar" /> :
                        <HiOutlineMenu className="Comet-icon-sidebar" />
                    }
                </NavLink>
                <h1 className="navbar-brand navbar-title">
                    <Link to="/" className="navbar-brand-link">
                        <img src={Logo} alt="" />
                    </Link>
                </h1>
                {user && userRole !== Role.Externe &&
                    <h1 className="navbar-item navbar-title">
                        <Link to="/dashboard" className="navbar-item-link">Tableau de bord</Link>
                    </h1>
                }
                {/* ------  
                    <div className="Logo-Home-container">
                        <img className="Logo-Home" src={logo} alt="" />
                    </div>
                    ------ */}
                <div className="navbar-fonctions">
                    {/* Planning */}
                    <NavLink to="#" className={"navbar-planning" + (userRole !== Role.Admin ? " d-none" : "")} onClick={togglePlanning}>
                        <div className={"planning-content" + (openPlanning ? " bg-white" : "")}>
                            <FcPlanner />
                        </div>
                    </NavLink>
                </div>
                <div className="navbar-avatar" ref={wrapperRef}>
                    <div className="navbar-avatar-dropdown">
                        <button className="navbar-avatar-button disableoutline" onClick={toggleProfil}>
                            <div className="navbar-avatar-button-content">
                                <div className="navbar-avatar-image-container">
                                    {isLoading ? <ReactLoading className="loading-spinner loading-spinner-avatar" type="spin" />
                                        : <img className="navbar-avatar-image" src={!isNullOrUndefined(avatar) && avatar !== "" ? "data:image/jpeg;base64," + avatar : player} alt="Profil" />
                                    }
                                </div>
                            </div>
                            <div className="avatar-name-container">
                                <h4 className="avatar-name">{user.given_name}</h4>
                            </div>
                            <div className="dropdown-icon">
                                <VscTriangleDown />
                            </div>
                        </button>
                        <Profil open={profilOpened ? true : false} avatar={avatar} user={user} />
                    </div>
                </div>
            </div>
            {openPlanning &&
                <Planning onClose={togglePlanning} />
            }
        </header>
    )
}

function useMedia(query) {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useEffect(() => {
        let media = window.matchMedia(query)
        if (media.matches !== matches) {
            setMatches(media.matches)
        }
        let listener = () => setMatches(media.matches)
        media.addListener(listener)
    }, [query])

    return matches
}

export default NavBar