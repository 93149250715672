import React, { useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { IoMdClose } from "react-icons/io"

const SignaturePopup = (props) => {

    let sigCanvas = useRef({})

    const clearSigCanvas = () => {
        sigCanvas.clear()
    }

    const saveSigCanvas = () => {
        // Get the trimmed version of the signature (to remove extra whitespace)
        const trimmedCanvas = sigCanvas.getTrimmedCanvas()

        // Only proceed if there is content on the canvas
        if (trimmedCanvas.width > 0 && trimmedCanvas.height > 0) {
            const targetWidth = 300
            const targetHeight = 200

            // Create a new canvas for resizing
            const canvas = document.createElement('canvas')
            canvas.width = targetWidth
            canvas.height = targetHeight
            const ctx = canvas.getContext('2d')

            // Calculate the scale, but do not over-enlarge small content
            const scale = Math.min(targetWidth / trimmedCanvas.width, targetHeight / trimmedCanvas.height, 1)

            // Center the content within the target canvas
            const xOffset = (targetWidth - trimmedCanvas.width * scale) / 2
            const yOffset = (targetHeight - trimmedCanvas.height * scale) / 2

            // Draw the resized image on the new canvas
            ctx.drawImage(
                trimmedCanvas,
                0,
                0,
                trimmedCanvas.width,
                trimmedCanvas.height,
                xOffset,
                yOffset,
                trimmedCanvas.width * scale,
                trimmedCanvas.height * scale
            )

            // Convert the resized canvas to Base64 and save it in the form field
            const resizedBase64 = canvas.toDataURL('image/PNG').split(',')[1]
            props.setFieldValue(props.inputName, resizedBase64)
        } else {
            // If no content, save an empty string or default value
            props.setFieldValue(props.inputName, '')
        }

        props.close() // Close the popup
    }

    return (
        <div className="popup-cover">
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        {props.title}
                    </h3>
                    <div className="popup-close-button" onClick={props.close}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                {props.subtitle &&
                    <div className='p-3'>{props.subtitle}</div>
                }
                <div className="popup-body">
                    <div className="signature-container">
                        <SignatureCanvas
                            ref={(ref) => { sigCanvas = ref }}
                            penColor='black'
                            canvasProps={{ width: 350, height: 200, className: 'sigCanvas' }}
                        />
                    </div>
                    <div className="sig-button-container mt-4">
                        <button type="button" className="btn btn-danger" onClick={clearSigCanvas}>
                            Effacer
                        </button>
                        <button type="button" className="btn cbtn-success ml-4" onClick={saveSigCanvas}>
                            Enregistrer
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )

}

export default SignaturePopup