import { AuthConsumer } from "../providers/authProvider"
import logo from './../res/logo.svg'

export const Logout = () => (
    <AuthConsumer>
        {({ logout }) => {
            logout()
            return <div className="loading-container"><img src={logo} alt="Logo" />Deconnexion...</div>
        }}
    </AuthConsumer>
)