import { UserManager } from "oidc-client"

export const CONFIG = {
    authority: "https://login.cometoptima.com", //(string): The URL of the OIDC provider.
    client_id: process.env.REACT_APP_CLIENT, //(string): Your client application's identifier as registered with the OIDC provider.
    redirect_uri: "https://cometoptima.com/callback", //The URI of your client application to receive a response from the OIDC provider.
    response_type: "code",
    scope: "openid profile offline_access cometapi.read realcometapi",
    post_logout_redirect_uri: "https://cometoptima.com/logout/callback",
    automaticSilentRenew: false,
    silent_redirect_uri: "https://cometoptima.com/silentRenew"
}

export const METADATA_OIDC = {
    issuer: "https://login.cometoptima.com",
    jwks_uri: "https://login.cometoptima.com/.well-known/openid-configuration/jwks",
    authorization_endpoint: "https://login.cometoptima.com/connect/authorize",
    token_endpoint: "https://login.cometoptima.com/connect/token",
    userinfo_endpoint: "https://login.cometoptima.com/connect/userinfo",
    end_session_endpoint: "https://login.cometoptima.com/connect/endsession",
    check_session_iframe: "https://login.cometoptima.com/connect/checksession",
    revocation_endpoint: "https://login.cometoptima.com/connect/revocation",
    introspection_endpoint: "https://login.cometoptima.com/connect/introspect"
}

export const mgr = new UserManager({ ...CONFIG, metadata: { ...METADATA_OIDC } })