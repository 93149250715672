
const Timer = (props) => {
    return (
        <div className="timer">
            <span className="digits">
                {`${String(Math.floor(props.time / 1000)).padStart(2, '0')}`}:
            </span>
            <span className="digits mili-sec">
                {`${String(props.time % 1000).padStart(3, '0')}`}
            </span>
        </div>
    )
}

export default Timer