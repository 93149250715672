import React, { useEffect, useState } from 'react'
import { Field } from 'formik'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { IoMdClose } from "react-icons/io"
import { supervisionService } from '../../../services/supervisionService'
import activites from './../../../constants/activiteList'

const PopupDocument = (props) => {

    const [typesDocument, setTypesDocument] = useState(null)
    const [detailsDocuments, setDetailsDocuments] = useState(null)
    const [adherents, setAdherents] = useState(null)
    // const [intervenants, setIntervenants] = useState(null)

    useEffect(() => {
        if (!props.carteStationnement) {
            let data = {
                date_fichier: null,
                extension_fichier: ".pdf",
                fichier_contenu: "",
                fichier_original: "",
                nom_fichier: "",
                // props.activite
                numero_activite: 1,
                numero_adherent: props.adherent.numero_adherent,
                numero_detail_doc: 0,
                numero_intervenant: props.client.numero_client,
                numero_type_doc: 41,
                observations_fichier: "",
            }
            props.setCarteStationnement(data)
        }
        //props.activite
        // activite de l'adherent superviseur
        async function getTypes(activite = 1) {
            await supervisionService.getAllTypeDocByActivite(activite)
                .then(response => setTypesDocument(response))
        }
        async function getDetails(typedoc = 41) {
            await supervisionService.getAllDetailDocByTypeDoc(typedoc)
                .then(response => setDetailsDocuments(response))
                .catch(error => toast.info(error, { containerId: "App" }))
        }
        async function getAdherents() {
            await supervisionService.getAllAdherent()
                .then(response => setAdherents(response))
        }
        // async function getIntervenants(adherent = 0) {
        //     await supervisionService.getAllIntervenant(adherent)
        //         .then(response => setIntervenants(response))
        // }
        getTypes()
        getDetails()
        getAdherents()
        // getIntervenants(props.client.numero_adherent)
    },
        [])

    // Met à jour ou ajoute un document de rapport
    const uploadFile = (event, carteStationnement) => {
        // Récupération des infos de la supervision générale
        const file = event.currentTarget.files[0]
        if (!file) return
        let reader = new FileReader()
        // Nom du fichier PDF
        const name = file.name
        // Extension du fichier
        const extension = file.type.replace(/(.*)\//g, '') === "jpeg" ? "jpg" : file.type.replace(/(.*)\//g, '')
        // Date du fichier
        const date = new Date(file.lastModifiedDate)
        reader.readAsDataURL(file)

        reader.onloadend = () => {
            const fileContent = reader.result.split(',')[1]  // Récupérer le contenu en base64

            // Créer une nouvelle instance de l'objet pour garantir l'immutabilité
            const updatedCarteStationnement = {
                ...carteStationnement,
                date_fichier: date,
                extension_fichier: "." + extension,
                fichier_contenu: fileContent,
                fichier_original: name,
                nom_fichier: name.split('.')[0],
                numero_detail_doc: carteStationnement ? carteStationnement.numero_detail_doc || 0 : 0,
                numero_document: carteStationnement ? carteStationnement.numero_document || -1 : -1,
                numero_type_doc: 41,  // Ce numéro semble être constant dans votre code
                observations_fichier: carteStationnement ? carteStationnement.observations_fichier || "" : "",
            }

            // Mise à jour de l'état via props
            props.setCarteStationnement(updatedCarteStationnement)
        }
    }

    // Ouvre un document de Rapport
    const openFile = (file) => {
        var fileString = file.fichier_contenu
        var byteCharacters = atob(fileString)
        var byteNumbers = new Array(byteCharacters.length)
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        var byteArray = new Uint8Array(byteNumbers)
        var newFile = new Blob([byteArray], {
            type: file.extension_fichier === ".jpg" || file.extension_fichier === ".jpeg" ? 'image/jpeg' :
                file.extension_fichier === ".png" ? 'image/png' :
                    file.extension_fichier === ".pdf" ? 'application/pdf;base64' :
                        file.extension_fichier === ".xlsx" || file.extension_fichier === ".xls" ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' :
                            file.extension_fichier === ".docx" ? 'application/msword' :
                                undefined
        })
        var fileURL = URL.createObjectURL(newFile)
        var link = document.createElement('a')
        link.href = fileURL
        link.download = props.carteStationnement.nom_fichier
        link.click()
        setTimeout(function () {
            // Necessaire pour Firefox
            window.URL.revokeObjectURL(fileURL)
        }, 100)
    }

    // Supprime le fichier uploade
    const deleteFile = (carteStationnement) => {
        let newCarteStationnement = carteStationnement
        if (newCarteStationnement && newCarteStationnement.fichier_contenu) {
            if (window.confirm('Êtes-vous sûr de vouloir supprimer le document')) {
                // Créer une nouvelle instance de l'objet carteStationnement pour forcer la mise à jour visuelle
                const newCarteStationnement = {
                    ...carteStationnement,
                    fichier_contenu: "",
                    fichier_original: "",
                    nom_fichier: "",
                    date_fichier: null
                };

                // Mettre à jour l'état via props
                props.setCarteStationnement(newCarteStationnement)

            } else {
                // On fait rien !
            }
        }
    }

    return (
        <div className="popup">
            <div className="popup-document">
                <div className="popup-header">
                    <h3 className="title">
                        Rapport de supervision
                    </h3>
                    <div className="popup-close-button" onClick={props.close}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-main">
                    <div className="document-infos c-mt-2">
                        <>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor={"numero_type_doc"}>Type</label>
                                        <Field name={"numero_type_doc"} as="select" className="form-control" autoComplete="off" disabled value={props.carteStationnement?.numero_type_doc}>
                                            {typesDocument && typesDocument.map((type, index) =>
                                                <option key={index} value={type.numero_type_doc}>{type.nom_type_doc}</option>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor={"numero_detail_doc"}>Details</label>
                                        <Field name={"numero_detail_doc"} as="select" className="form-control" autoComplete="off" disabled value={props.carteStationnement?.numero_detail_doc}>
                                            {detailsDocuments && detailsDocuments.map((type, index) =>
                                                <option key={index} value={type.numero_detail_doc}>{type.nom_detail_doc}</option>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="col">
                                        <label htmlFor={"numero_activite"}>Activité</label>
                                        <Field name={"numero_activite"} as="select" className="form-control" autoComplete="off" disabled value={props.carteStationnement?.numero_activite}>
                                            {activites && activites.map((activite, index) =>
                                                <option key={index} value={activite.numero}>{activite.nom}</option>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor={"numero_adherent"}>Adherent</label>
                                        <Field name={"numero_adherent"} as="select" className="form-control" autoComplete="off" disabled value={props.adherent?.numero_adherent}>
                                            {adherents && adherents.map((adherent, index) =>
                                                <option key={index} value={adherent.numero_adherent}>{adherent.identification_adherent}</option>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="col">
                                        <label htmlFor={"numero_intervenant"}>Intervenant</label>
                                        <Field name={"numero_intervenant"} as="select" className="form-control" autoComplete="off" disabled value={props.client?.numero_client}>
                                            {props.clients && props.clients.map((intervenant, index) =>
                                                <option key={index} value={intervenant.numero_client}>{intervenant.nom_client}</option>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor={"nom_fichier"}>Nom fichier</label>
                                        <Field name={"nom_fichier"} value={props.carteStationnement ? props.carteStationnement.nom_fichier : ""} className="form-control" autoComplete="off" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor={"observations_fichier"}>Observations</label>
                                        <Field name={"observations_fichier"} value={props.carteStationnement ? props.carteStationnement.observations_fichier : ""} className="form-control" autoComplete="off" />
                                    </div>
                                </div>
                            </div>
                            <div className="popup-actions">
                                <div className="popup-action-delete">
                                    <button type="button" className={"btn c-action-button c-delete-button" + (props.carteStationnement && props.carteStationnement.fichier_contenu === "" ? " disabled" : "")} onClick={e => deleteFile(props.carteStationnement)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </button>
                                </div>
                                <div className="popup-action-open">
                                    <button type="button" className={"upload-file-btn upload-file-btn-open" + (props.carteStationnement && props.carteStationnement.fichier_contenu === "" ? " disabled" : "")} onClick={e => openFile(props.carteStationnement)}>
                                        <span>Ouvrir</span>
                                        <i className="fas fa-eye"></i>
                                    </button>
                                </div>
                                <div className="popup-action-upload">
                                    <div className="upload-file">
                                        <input
                                            type="file"
                                            name="file1"
                                            id="file1"
                                            className="file-input"
                                            accept="application/pdf,image/*, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            onChange={e => uploadFile(e, props.carteStationnement)}
                                            onBlur={props.handleBlur}
                                        />
                                        <label className={"upload-file-btn" + (props.carteStationnement && props.carteStationnement.fichier_contenu !== "" ? " upload-file-btn-edit" : " upload-file-btn-add")} htmlFor="file1"><i className="fas fa-file-upload"></i>{props.carteStationnement && props.carteStationnement.fichier_contenu !== "" ? "Modifier" : "Ajouter"}</label>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PopupDocument