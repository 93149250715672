import React, { useContext, useEffect, useRef, useState } from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { supervisionService } from "../../services/supervisionService"
import activiteList from './../../constants/activiteList'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Role } from "../../helpers/Role"
import { useAuth } from "../../providers/authProvider"
import { DataContext } from "../../providers/DataContextProvider"
import { canSeePage, interventionDetailArray, triArray } from "../../helpers/utils"
import Select from 'react-select'
import Skeleton from 'react-loading-skeleton'
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import ReactPaginate from 'react-paginate'

const PreIntervention = (props) => {

    const [adherents, setadherents] = useState(null)
    const [activites, setactivites] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const [selectedAdherent, setSelectedAdherent] = useState(null)
    const [isLoadingAdherent, setIsLoadingAdherent] = useState(false)
    const [isLoadingInterventions, setIsLoadingInterventions] = useState(false)
    const formikRef = useRef()
    const { selectedIntervention, setSelectedIntervention } = useContext(DataContext)
    const [pageCount, setPageCount] = useState(0)
    const [selectedDateItem, setSelectedDateItem] = useState({ content: "Toutes les dates", value: "0" })
    const [selectedTypeItem, setSelectedTypeItem] = useState({ content: "Type de l'intervention", value: "t0" })
    const [selectedTriItem, setSelectedTriItem] = useState({ content: "Trier", value: "0" })
    const [interventionTypes] = useState(interventionDetailArray)
    const [triTypes] = useState(triArray)
    const [addTabIndexDate, setAddTabIndexDate] = useState(false)
    const [addTabIndexTri, setAddTabIndexTri] = useState(false)
    const [isDateActive, setIsDateActive] = useState(false)
    const [addTabIndexType, setAddTabIndexType] = useState(false)
    const [isTypeActive, setIsTypeActive] = useState(false)
    const [isTriActive, setIsTriActive] = useState(false)
    const auth = useAuth()
    const userRole = auth.userRole

    async function getAllAdherent() {
        setIsLoadingAdherent(true)
        await supervisionService.getAllAdherent()
            .then(
                response => {
                    let data = response.map((adherent) => ({ ...adherent, value: adherent.numero_adherent, label: adherent.identification_adherent + " (" + adherent.nom_adherent + ")" }))
                    setadherents(data)
                    if (userRole === Role.Adherent) {
                        const numero = data[0].numero_adherent
                        setSelectedAdherent(data[0])
                        updateActivites(data, numero)
                    }
                    setIsLoadingAdherent(false)
                }
            )
            .catch(error => setIsLoadingAdherent(false))
    }

    useEffect(() => {
        getAllAdherent()
    }, [])

    /**
     * Met à jour la liste des activités
     */
    const updateActivites = (adherents, numero) => {
        // Réinitialise la liste à chaque mise à jour
        setactivites([])
        // Récupère les infos d'un adhérent avec le numéro donné
        // Ici on a besoin des infos des activités de l'adherent
        let adherent = adherents.filter(adherent => adherent.numero_adherent === numero)[0]
        if (adherent.numero_adherent === 0) {
            var activites = activiteList.filter(activite => {
                return activite.numero !== 0
            })
            activites.forEach(activite => {
                setactivites(prev =>
                    [...prev, activite]
                )
            })
        } else {
            if (adherent.tax === true) {
                setactivites(prev =>
                    [...prev, activiteList.filter(activite => {
                        return activite.numero === 1
                    })[0]]
                )
            }
            if (adherent.gaz === true && adherent.opa === true) {
                setactivites(prev =>
                    [...prev, activiteList.filter(activite => {
                        return activite.numero === 2
                    })[0]]
                )
            }
            if (adherent.tco === true) {
                setactivites(prev =>
                    [...prev, activiteList.filter(activite => {
                        return activite.numero === 4
                    })[0]]
                )
            }
            if (adherent.ead === true) {
                setactivites(prev =>
                    [...prev, activiteList.filter(activite => {
                        return activite.numero === 5
                    })[0]]
                )
            }
            if (adherent.aut === true) {
                setactivites(prev =>
                    [...prev, activiteList.filter(activite => {
                        return activite.numero === 6
                    })[0]]
                )
            }
        }
    }

    const filterOptions = (candidate, input) => {
        if (input) {
            return candidate.label.includes(input)
        }
        return true
    }

    const handleFetch = (pageNumber) => {
        setIsLoadingInterventions(true)
        let values = {
            adherent: formikRef.current.values.adherent,
            activite: formikRef.current.values.activite,
            dateOption: parseInt(selectedDateItem.value),
            typeOption: getCorrectType(selectedTypeItem.value),

        }
        async function execute() {
            await supervisionService.getInterventions(values, pageNumber)
                .then(response => {
                    props.passValuesToParent(response)
                    setPageCount(response.totalPages)
                    setIsLoadingInterventions(false)
                }) // Affichage des interventions à saisir
                .catch(error => {
                    setIsLoadingInterventions(false)
                    toast.error(error, { containerId: 'App' })
                    props.passValuesToParent([]) // Aucune intervention a été trouvée
                })
        }
        execute()
    }

    /**
     * Retourne la liste des interventions en cours
     * au changement de la date
     * @param {Number} date 
     * @param {Number} pageNumber 
     */
    const handleFetchOnDateChange = (date, pageNumber) => {
        setIsLoadingInterventions(true)
        let values = {
            adherent: formikRef.current.values.adherent,
            activite: formikRef.current.values.activite,
            dateOption: date,
            typeOption: getCorrectType(selectedTypeItem.value),
            orderOption: parseInt(selectedTriItem.value)
        }
        async function execute() {
            await supervisionService.getInterventions(values, pageNumber)
                .then(response => {
                    props.passValuesToParent(response)
                    setPageCount(response.totalPages)
                    setIsLoadingInterventions(false)
                }) // Affichage des interventions à saisir
                .catch(error => {
                    setIsLoadingInterventions(false)
                    setPageCount(0)
                    toast.error(error, { containerId: 'App' })
                    props.passValuesToParent([]) // Aucune intervention a été trouvée
                })
        }
        execute()
    }

    /**
     * Retourne la liste des interventions en cours
     * au changement de la date
     * @param {String[]} type 
     * @param {Number} pageNumber 
     */
    const handleFetchOnTypeChange = (type, pageNumber) => {
        setIsLoadingInterventions(true)
        let values = {
            adherent: formikRef.current.values.adherent,
            activite: formikRef.current.values.activite,
            dateOption: parseInt(selectedDateItem.value),
            typeOption: type,
            orderOption: parseInt(selectedTriItem.value),
        }
        async function execute() {
            await supervisionService.getInterventions(values, pageNumber)
                .then(response => {
                    props.passValuesToParent(response)
                    setPageCount(response.totalPages)
                    setIsLoadingInterventions(false)
                }) // Affichage des interventions à saisir
                .catch(error => {
                    setIsLoadingInterventions(false)
                    setPageCount(0)
                    toast.error(error, { containerId: 'App' })
                    props.passValuesToParent([]) // Aucune intervention a été trouvée
                })
        }
        execute()
    }

    const handleFetchOnTriChange = (tri, pageNumber) => {
        setIsLoadingInterventions(true)
        let values = {
            adherent: formikRef.current.values.adherent,
            activite: formikRef.current.values.activite,
            dateOption: parseInt(selectedDateItem.value),
            typeOption: parseInt(selectedTypeItem.value),
            orderOption: tri,
        }
        async function execute() {
            await supervisionService.getInterventions(values, pageNumber)
                .then(response => {
                    props.passValuesToParent(response)
                    setPageCount(response.totalPages)
                    setIsLoadingInterventions(false)
                }) // Affichage des interventions à saisir
                .catch(error => {
                    setIsLoadingInterventions(false)
                    setPageCount(0)
                    toast.error(error, { containerId: 'App' })
                    props.passValuesToParent([]) // Aucune intervention a été trouvée
                })
        }
        execute()
    }

    const handlePageChange = (selectedObject) => {
        let _currentPage = selectedObject.selected + 1
        handleFetch(_currentPage)
    }

    const handleClickDropdownDate = (e) => {
        setAddTabIndexDate(!addTabIndexDate)
        setIsDateActive(!isDateActive)
    }

    const handleClickDropdownTri = (e) => {
        setAddTabIndexTri(!addTabIndexTri)
        setIsTriActive(!isTriActive)
    }

    const handleBlurDropdownDate = (e) => {
        setAddTabIndexDate(false)
        setIsDateActive(false)
    }

    const handleBlurDropdownTri = (e) => {
        setAddTabIndexTri(false)
        setIsTriActive(false)
    }

    const handleClickDateItem = (e) => {
        setSelectedDateItem({ content: e.target.innerText, value: e.target.id })
        // Mise en place de la requete ici.
        let _currentPage = 1
        let selectedDate = parseInt(e.target.id)
        handleFetchOnDateChange(selectedDate, _currentPage)
    }

    const handleClickTriItem = (e) => {
        setSelectedTriItem({ content: e.target.innerText, value: e.target.id })
        // Mise en place de la requete ici.
        let _currentPage = 1
        let selectedTri = parseInt(e.target.id)
        handleFetchOnTriChange(selectedTri, _currentPage)
    }

    const handleClickDropdownType = (e) => {
        setAddTabIndexType(!addTabIndexType)
        setIsTypeActive(!isTypeActive)
    }

    const handleBlurDropdownType = (e) => {
        setAddTabIndexType(false)
        setIsTypeActive(false)
    }

    const handleClickTypeItem = (e) => {
        let previousContent = selectedTypeItem.content
        let previousValue = selectedTypeItem.value
        let newValue = ""
        let newContent = ""
        let addedTypeLength = ""
        let selectedItemId = e.target.id

        if (previousContent !== "Type de l'intervention") {
            // Element deja dans la liste des filtres
            if (previousValue.includes(selectedItemId)) {
                let textToDelete = ", " + selectedItemId
                newValue = previousValue.replace(textToDelete, "")
                // Le tableau de l'ancienne filtre est le meme que le nouveau apres suppression
                if (newValue === previousValue) {
                    textToDelete = selectedItemId
                    newValue = previousValue.replace(textToDelete, "")
                }
                // Suppression ok
            }
            else {
                newValue = previousValue + ", " + selectedItemId
            }

            if (newValue.split(",").length === 1) {
                newContent = previousContent.split("+")[0]
            }
            else {
                addedTypeLength = newValue.split(",").length - 1
                newContent = "Type de l'intervention  +" + addedTypeLength
            }
        }
        else {
            addedTypeLength = 1
            newContent = previousContent + "  +" + addedTypeLength
            newValue = previousValue + ", " + selectedItemId
        }
        setSelectedTypeItem({ content: newContent, value: newValue })
        // Mise en place de la requete ici.
        let _currentPage = 1
        let selectedTypes = getCorrectType(newValue)
        handleFetchOnTypeChange(selectedTypes, _currentPage)
    }

    const getCorrectType = (value) => {
        let selectedTypes = value.split(", ")
        if (selectedTypes.length !== 0 && selectedTypes.length !== 1) {
            selectedTypes = selectedTypes.filter(type => type !== "t0")
        }
        return selectedTypes
    }

    canSeePage(userRole)

    return (
        <div className="section-preintervention mt-5">
            <div className="header pt-4 px-1">
                <h1 className="m-0">Intervention</h1>
                <div className="preintervention">
                    <div className="previntervention-container">
                        <Formik
                            innerRef={formikRef}
                            enableReinitialize={true}
                            initialValues={{
                                adherent: selectedAdherent && selectedAdherent.value,
                                activite: ''
                            }}
                            validate={(values) => {
                                const errors = {}
                                const texte = "Ce champ est invalide"
                                const rPI = /^[0-9]+$/

                                if (!rPI.test(values.adherent)) {
                                    errors.adherent = texte
                                }
                                if (!rPI.test(values.activite)) {
                                    errors.activite = texte
                                }

                                return errors
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setIsLoadingInterventions(true)
                                values.adherent = parseInt(values.adherent)
                                values.activite = parseInt(values.activite)
                                async function execute() {
                                    if (values.activite === 1) {
                                        await supervisionService.getInterventions(values)
                                            .then(response => {
                                                // response => data et activite et donnees page
                                                props.passValuesToParent(response)
                                                setPageCount(response.totalPages)
                                                setSubmitted(true)
                                                localStorage.setItem("activite", values.activite)
                                                setSelectedIntervention({ ...selectedIntervention, activite: values.activite })
                                                setIsLoadingInterventions(false)
                                            }) // Affichage des interventions à saisir
                                            .catch(error => {
                                                setSubmitted(true)
                                                setIsLoadingInterventions(false)
                                                toast.error(error, { containerId: 'App' })
                                                props.passValuesToParent([]) // Aucune intervention a été trouvée
                                            })
                                    }
                                    else if (values.activite === 4) {
                                        await supervisionService.getInterventionsTachy(values)
                                            .then(response => {
                                                // response => data et activite et donnees page
                                                props.passValuesToParent(response)
                                                setPageCount(response.totalPages)
                                                setSubmitted(true)
                                                localStorage.setItem("activite", values.activite)
                                                setSelectedIntervention({ ...selectedIntervention, activite: values.activite })
                                                setIsLoadingInterventions(false)
                                            }) // Affichage des interventions à saisir
                                            .catch(error => {
                                                setSubmitted(true)
                                                setIsLoadingInterventions(false)
                                                toast.error(error, { containerId: 'App' })
                                                props.passValuesToParent([]) // Aucune intervention a été trouvée
                                            })
                                    }
                                }
                                execute()
                            }}
                        >
                            {({ setFieldValue, isSubmitting }) => (
                                <Form>
                                    <div className="preintervention-form-container">
                                        <div className="form-group form-group-adherent">
                                            {(!isLoadingAdherent && adherents &&
                                                <Select
                                                    placeholder="Adhérent..."
                                                    name="adherent"
                                                    value={selectedAdherent}
                                                    onChange={(values) => { setFieldValue('adherent', values.value); setSelectedAdherent(values); updateActivites(adherents, parseInt(values.value)); }}
                                                    options={adherents}
                                                    filterOption={filterOptions}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#e7ebf3',
                                                            primary: '#604888',
                                                        },
                                                    })}
                                                    isDisabled={adherents && adherents.length <= 1 ? true : false}
                                                />
                                            ) ||
                                                <Skeleton height={34} />
                                            }
                                            <ErrorMessage name="adherent" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <Field name="activite" as="select" className="form-control">
                                                <option value="empty">Activité...</option>
                                                {activites.map((item, i) =>
                                                    <option key={i} value={item.numero}>
                                                        {item.nom}
                                                    </option>
                                                )}
                                            </Field>
                                            <ErrorMessage name="activite" component="div" className="text-danger" />
                                        </div>
                                        <div className="previntervention-button-container">
                                            <button className="btn cbtn cbtn-primary float-right w-100" type="submit" disabled={isLoadingInterventions}>
                                                {isLoadingInterventions ? <UseAnimations strokeColor={'var(--comet-white)'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : "Valider"}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            {submitted &&
                <>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        previousLabel="<"
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        containerClassName="c-pagination"
                    />
                    <div className="filter">
                        <div
                            id="date"
                            className={"dropdown mr-3" + (isDateActive ? " active" : "")}
                            onClick={handleClickDropdownDate}
                            tabIndex={addTabIndexDate ? 1 : 0}
                            onBlur={handleBlurDropdownDate}
                        >
                            <div className="select">
                                <i className="fa fa-clock"></i>
                                <span>{selectedDateItem.content}</span>
                                <i className="fa fa-chevron-left"></i>
                            </div>
                            <input type="hidden" name="date" value={selectedDateItem.value} />
                            <ul className={"dropdown-menu" + (isDateActive ? " d-block" : " d-none")}>
                                <li id="0" onClick={handleClickDateItem} className={selectedDateItem.value.includes("0") ? "active-filtre-type" : undefined}>Toutes les dates</li>
                                <li id="1" onClick={handleClickDateItem} className={selectedDateItem.value.includes("1") ? "active-filtre-type" : undefined}>Aujourd'hui</li>
                                <li id="2" onClick={handleClickDateItem} className={selectedDateItem.value.includes("2") ? "active-filtre-type" : undefined}>7 prochains jours</li>
                                <li id="3" onClick={handleClickDateItem} className={selectedDateItem.value.includes("3") ? "active-filtre-type" : undefined}>14 prochains jours</li>
                                <li id="3" onClick={handleClickDateItem} className={selectedDateItem.value.includes("4") ? "active-filtre-type" : undefined}>30 prochains jours</li>
                            </ul>
                        </div>
                        <div
                            id="type"
                            className={"dropdown mr-3" + (isTypeActive ? " active" : "")}
                            onClick={handleClickDropdownType}
                            tabIndex={addTabIndexType ? 1 : 0}
                            onBlur={handleBlurDropdownType}
                        >
                            <div className="select">
                                <span>{selectedTypeItem.content}</span>
                                <i className="fa fa-chevron-left"></i>
                            </div>
                            <input type="hidden" name="type" value={selectedTypeItem.value} />
                            <ul className={"dropdown-menu" + (isTypeActive ? " d-block" : " d-none")}>
                                {interventionTypes.map((type, n) =>
                                    <li key={n} id={"t" + (n + 1)} onClick={handleClickTypeItem} className={selectedTypeItem.value.includes("t" + (n + 1)) ? "active-filtre-type" : undefined}>{type}</li>
                                )}
                            </ul>
                        </div>
                        <div
                            id="tri"
                            className={"dropdown tri-dropdown" + (isTriActive ? " active" : "")}
                            onClick={handleClickDropdownTri}
                            tabIndex={addTabIndexTri ? 1 : 0}
                            onBlur={handleBlurDropdownTri}
                        >
                            <div className="select">
                                <span>{selectedTriItem.content}</span>
                                <i className="fa fa-chevron-left"></i>
                            </div>
                            <input type="hidden" name="tri" value={selectedTriItem.value} />
                            <ul className={"dropdown-menu" + (isTriActive ? " d-block" : " d-none")}>
                                {triTypes.map((type, n) =>
                                    <li key={n} id={(n + 1)} onClick={handleClickTriItem} className={selectedTriItem.value.includes((n + 1)) ? "active-filtre-type" : undefined}>{type}</li>
                                )}
                            </ul>
                        </div>
                    </div>
                </>
            }
        </div>
    )

}

export default PreIntervention
