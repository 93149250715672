import React from "react"
import { Redirect, Route } from "react-router-dom"
import { AuthConsumer } from "./../providers/authProvider"

export const PrivateRoute = ({ component, ...rest }) => {

    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {(auth) => {
                if (auth.isLoggedIn()) {
                    if (rest.for) {
                        if (rest.for && rest.for.includes(auth.userRole)) {
                            return <Component {...props} />
                        }
                        else {
                            return <Redirect to="/" />
                        }
                    }
                    else {
                        return <Component {...props} />
                    }
                } else {
                    auth.login(props.location.pathname)
                }
            }}
        </AuthConsumer>
    )

    return (
        <Route {...rest} render={renderFn(component)}
        />
    )

}

/*
const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({ isAuthenticated, login }) => {
                console.log(isAuthenticated())
                if (isAuthenticated()) {
                    return <Component {...props} />
                } else {
                    login(props.location.pathname)
                }
            }}
        </AuthConsumer>
    )
*/