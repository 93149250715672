import { useEffect, useRef, useState } from "react"

// Path2D SVG de coeur
//const heartSVG = "M0 200 v-200 h200 a100,100 90 0,1 0,200 a100,100 90 0,1 -200,0 z"
//const SVG_PATH = new Path2D(heartSVG)

// Scaling Constants for Canvas
//const SCALE = 0.1
//const OFFSET = 80
export const canvasWidth = '685px'
export const canvasHeight = '261px'
export const canvas3Width = '332px'
export const canvas3Height = '240px'
export const canvasWidthInt = 685
export const canvasHeightInt = 261
export const canvas3WidthInt = 332
export const canvas3HeightInt = 240
const CIRCLE_RADIUS = 25
const CIRCLE_COLOR = "red"
const CIRCLE_STROKE_WIDTH = 10

export function draw(ctx, location, canvas) {
    ctx.lineWidth = CIRCLE_STROKE_WIDTH
    ctx.strokeStyle = CIRCLE_COLOR
    ctx.save()
    ctx.beginPath()
    ctx.arc(location.x, location.y, CIRCLE_RADIUS, 0, 2 * Math.PI)
    ctx.stroke()
    // .restore(): Canvas 2D API restores the most recently saved canvas state
    ctx.restore()
};

const useCanvas = () => {

    const canvasRef = useRef(null)
    const canvasRef2 = useRef(null)
    const canvasRef3 = useRef(null)
    const [coordinate, setCoordinate] = useState(null)
    const [coordinate2, setCoordinate2] = useState(null)
    const [coordinate3, setCoordinate3] = useState([])

    useEffect(() => {
        const canvasObj = document.getElementById("canvas1")
        const ctx = canvasObj && canvasObj.getContext('2d')
        const canvasObj2 = document.getElementById("canvas2")
        const ctx2 = canvasObj2 && canvasObj2.getContext('2d')
        const canvasObj3 = document.getElementById("canvas3")
        const ctx3 = canvasObj3 && canvasObj3.getContext('2d')

        if (canvasObj) {
            canvasObj.style.width = canvasWidth
            canvasObj.style.height = canvasHeight
            canvasObj.width = canvasObj.offsetWidth
            canvasObj.height = canvasObj.offsetHeight

            // clear the canvas area before rendering the coordinates held in state
            ctx.clearRect(0, 0, canvasWidth, canvasHeight)
            // draw all coordinates held in state
            coordinate && draw(ctx, coordinate, canvasObj)
        }
        if (canvasObj2) {
            canvasObj2.style.width = canvasWidth
            canvasObj2.style.height = canvasHeight
            canvasObj2.width = canvasObj2.offsetWidth
            canvasObj2.height = canvasObj2.offsetHeight

            ctx2.clearRect(0, 0, canvasWidth, canvasHeight)
            coordinate2 && draw(ctx2, coordinate2, canvasObj2)
        }
        if (canvasObj3) {
            canvasObj3.style.width = canvas3Width
            canvasObj3.style.height = canvas3Height
            canvasObj3.width = canvasObj3.offsetWidth
            canvasObj3.height = canvasObj3.offsetHeight

            ctx3.clearRect(0, 0, canvas3Width, canvas3Height)
            coordinate3 && coordinate3.forEach((coordinate) => { draw(ctx3, coordinate, canvasObj3) })
        }
    })

    return { coordinate, coordinate2, coordinate3, setCoordinate, setCoordinate2, setCoordinate3, canvasRef, canvasRef2, canvasRef3, canvasWidthInt, canvasHeightInt, canvas3WidthInt, canvas3HeightInt }

}

export default useCanvas