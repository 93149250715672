import React, { useState, useEffect } from 'react'
import NavBar from '../../components/NavBar/NavBar'
import company from '../../res/buildings.png'
import user from "../../res/user.svg";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import Geocode from "react-geocode"
import history from '../../helpers/history';
import { supervisionService } from '../../services/supervisionService';
import Checkbox from '@material-ui/core/Checkbox';
import { useParams, NavLink } from 'react-router-dom';

// Style de la map
const mapStyles = {
    position: 'relative !important',
    width: '100%',
    height: '160px'
}

const AdherentPage = (props) => {

    let { id } = useParams()
    const [adherent, setAdherent] = useState(null)
    const [intervenants, setIntervenants] = useState([])
    const [coordonnees, setCoordonnees] = useState({})
    // Etat d'ouverture d'un filtre
    const [filterOpened, setFilterOpened] = useState(false)
    // Filtre (Actif pour le moment)
    const [qualifie, setQualifie] = useState(true)

    /**
     * Récupère l'adhérent par son numéro d'identification
     * @param {string} identification 
     * @returns Adherent
     */
    const getAdherentByIdentification = async (identification) => {
        return await supervisionService.getAdherentByIdentification(identification)
            .then(response => {
                setAdherent(response)
                return response
            })
    }

    /**
     * Récupère tous les intervenants de l'adhérent sélectionné
     * @param {number} adherent 
     * @param {Boolean} qualifie 
     */
    const getAllIntervenant = async (adherent, qualifie) => {
        await supervisionService.getAllIntervenant(adherent, qualifie)
            .then(
                response => setIntervenants(response)
            )
    }

    useEffect(() => {
        // Adherent n'a pas encore été initialisé
        // On récupère l'adhérent et ses intervenants
        if (!adherent) {
            getAdherentByIdentification(id)
                .then(response => {
                    getAllIntervenant(response.numero_adherent, qualifie)
                    // Utilisation de l'api pour le Geocode
                    Geocode.setApiKey(process.env.REACT_APP_SECRET)

                    if (response) {
                        // Récupère les coordonnées en latitude et longitude
                        // en lui passant une adresse pour placer un marqueur
                        // sur la map sinon cela ne marchera pas 
                        Geocode.fromAddress(response.nom_adherent + ", " + response.adresse1_adherent + " , " + response.cP_adherent + " " + response.ville_adherent)
                            .then(
                                response2 => {
                                    const { lat, lng } = response2.results[0].geometry.location
                                    setCoordonnees({
                                        lng: lng,
                                        lat: lat
                                    })
                                },
                                error => {

                                }
                            )
                    }
                })
                .catch(error => history.push('/404'))
        }
        // Adherent déjà initialisé, on met à jour
        // que les intervenants
        else {
            getAllIntervenant(adherent.numero_adherent, qualifie)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qualifie])

    const toggleFiltre = () => {
        setFilterOpened(!filterOpened)
    }

    const handleCheck = () => {
        setQualifie(!qualifie)
    }

    // Accéder au profil d'un intervenant
    const goToIntervenant = async (event) => {
        let intervenant = event.currentTarget.id
        await supervisionService.getIntervenantQualif(adherent.numero_adherent, parseInt(intervenant))
            .then(
                response => {
                    localStorage.setItem('monIntervenant', JSON.stringify(response))
                }
            )
        history.push(id + '/intervenant')
    }

    return (
        <>
            <NavBar />
            {adherent &&
                <div className="jumbotron">
                    <div className="container">
                        <div className="mes-adherents c-mt-5">
                            <div className="presentation-adherent c-mt-2">
                                <div className="image-adherent">
                                    <img src={adherent && adherent.logo ? "data:image/jpeg;base64," + adherent.logo : company} alt="Pas de photo1 pour cette supervision" />
                                </div>
                                <div className="coordonnees">
                                    <h4>{adherent && adherent.identification_adherent}</h4>
                                    <span>{adherent && adherent.nom_adherent}</span>

                                    <div className="adresse c-mt-1">
                                        <div className="adresse-content">
                                            <p>{adherent && adherent.adresse1_adherent}</p>
                                            <p>{adherent && adherent.adresse2_adherent}</p>
                                            <p>{adherent && adherent.cP_adherent} - {adherent && adherent.ville_adherent}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="join-adherent">
                                <a className="cbtn join-button" href={"tel:+" + (adherent && adherent.tel_adherent)}>Appeler</a>
                                <a className="cbtn join-button" disabled={adherent && adherent.email === "" ? true : false} href={"mailto:" + (adherent && adherent.email)}>Email</a>
                                <a className="cbtn join-button" rel="noreferrer" target="_blank" href={"https://maps.google.com/?q=" + (adherent && adherent.nom_adherent) + ", " + (adherent && adherent.adresse1_adherent) + ", " + (adherent && adherent.cP_adherent) + " " + (adherent && adherent.ville_adherent)}>
                                    Y Aller
                                </a>
                            </div>
                            <div className="details-adherent">
                                {/* Si coordonnées bien récupérée ou existant 
                                    avec l'adresse donnée de l'adhérent */}
                                {coordonnees && coordonnees.lng &&
                                    <Map
                                        className="map c-mt-2 c-mb-2"
                                        google={props.google}
                                        zoom={14}
                                        style={mapStyles}
                                        initialCenter={{
                                            lat: coordonnees.lat,
                                            lng: coordonnees.lng
                                        }}
                                    >
                                        <Marker
                                            google={props.google}
                                            name={adherent.adresse1_adherent}
                                        />

                                    </Map>
                                }
                            </div>
                            <div className="others-adherent c-mt-2">
                                <div className="members-header">
                                    <h2 className="section-title">
                                        Membres
                                    </h2>
                                    <span className="filtre adherent-filter">
                                        <NavLink className="link" to="#" onClick={toggleFiltre} >
                                            <span>
                                                {qualifie ? "Qualifié" : "Tous"}
                                            </span>
                                            <i className={"fas fa-angle-up " + (filterOpened ? "reverse-icon" : "")}></i>
                                        </NavLink>
                                        <div className={"c-dropdown-list " + (filterOpened ? "c-show" : "")}>
                                            <ul>
                                                <li>
                                                    <Checkbox
                                                        value={4}
                                                        checked={qualifie}
                                                        onChange={handleCheck}
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox'
                                                        }} />
                                                    Qualifié
                                                </li>
                                            </ul>
                                        </div>
                                    </span>
                                </div>
                                <div className="intervenants-adherent c-mt-2">
                                    {intervenants && intervenants.map((intervenant, i) =>
                                        <div id={intervenant.numero_intervenant} key={i} className="intervenant" onClick={goToIntervenant}>
                                            <div className="intervenant-image c-mb-1">
                                                <img src={intervenant.photo_intervenant ? "data:image/jpeg;base64," + intervenant.photo_intervenant : user} alt="Pas de photo1 pour cette supervision" />
                                            </div>
                                            <div className="intervenant-infos">
                                                {intervenant.prenom} {intervenant.nom}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )

}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_SECRET
})(AdherentPage)
