import axios from 'axios'
import { toast } from 'react-toastify'
import history from '../helpers/history'
import { authService } from '../services/authService'

// Traitement personnalisé des requêtes s'il y a une erreur dans la réponse
export const errorInterceptor = /*async*/ () => {
    axios.interceptors.response.use(null/*(response) => {

        const currentTime = new Date().getTime()
        const startTime = response.config.headers['request-startTime']
        response.headers['request-duration'] = currentTime - startTime
        console.log("duration - " + (currentTime - startTime))

        return response
    }*/, async (error) => {
            const { response } = error
            if (!response) {
                // Problème de connexion
                //console.log(error)
                toast.error("Problème de connexion au serveur.", { containerId: "App" })
                return Promise.reject({ ...error })
            }

            if (!response.ok) {
                const user = await authService.getUser()

                if ([401, 403].includes(response.status) && user) {
                    // Déconnexion de l'utilisateur s'il a exécuté une méthode non autorisée (401 ou 403)
                    history.push("/logout")
                    process.env.NODE_ENV === "development" && console.log(response.status)
                }

                const errorMessage = response.data?.message || response.statusText
                console.log("ERROR: ", errorMessage)
                return Promise.reject(errorMessage)
            }



            /*const start = response.config.headers['request-startTime']
            const end = process.hrtime(start)
            const milliseconds = Math.round((end[0] * 1000) + (end[1] / 1000000))
            console.log(milliseconds)
            response.headers['request-duration'] = milliseconds*/

        })
}