import { Field, Form, Formik } from "formik"
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Refus } from './../../../../../res/siren.svg'
import { ReactComponent as Intervention } from './../../../../../res/alerte.svg'
import { ReactComponent as SO } from './../../../../../res/erreur.svg'

const TixVIIVitesse = () => {
    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container">
                            <div className="reception-header d-flex flex-wrap flex-column">
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Survitesses</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    Sans Objet
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    Intervention
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Refus />
                                                    Refus :
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="marque_vignette" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Rappel */}
                            <div className="form-row w-100">
                                <div className="form-row w-100">
                                    <div className="form-cell-input m-w-100 w-100">
                                        <div className="form-input">
                                            <Field name="anomalies_initiales" as="textarea" className="form-control" disabled
                                                value={'Simu sur 18 min : << 100 km/h : Max : 180 km/h / Moy 180 sur 5-6 min (cas rares 4 min)\n' +
                                                    'Simu sur 18 min : << 90 km/h :  Max : 180 km/h / Moy 140 sur 11-12 min (cas rares 10 min)\n' +
                                                    'Simulation sur 4 minutes : Max et Moyenne 180 sur 4 min\n' +
                                                    'Simulation sur 1 minute : Max et Moyenne 180 sur 1 min (possibilité non enregistrée par VU)'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Vitesse Enregistrée */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">Vitesse Enregistrée</div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    +- 6.5 km/h
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Refus />
                                                    Impossible
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Refus />
                                                    Refus
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    Interv.
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default TixVIIVitesse