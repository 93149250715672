import React, { useEffect, useState } from "react"
import { Formik, Field, Form } from 'formik'
import './receptionV.scss'
import Switch from '@material-ui/core/Switch'
import { useLovelySwitchStyles } from '@mui-treasury/styles/switch/lovely'
import { useSaisieContext } from "../SaisieContext"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { fr } from "date-fns/locale"
import { supervisionService } from "../../../../services/supervisionService"
import { toast } from "react-toastify"
import useCanvas from "../../../Canvas/useCanvas"
import moment from 'moment'
import { formatDate } from "../../../../helpers/utils"
import { useDataContext } from "../../../../providers/DataContextProvider"

const ReceptionV = (props) => {

    const [receptionV] = useState(JSON.parse(localStorage.getItem("intervention")))
    const { setFormIntervention } = useSaisieContext()
    const lovelyStyles = useLovelySwitchStyles()
    const [errors, setErrors] = useState(null)
    const { coordinate3, setCoordinate3, canvas3WidthInt, canvas3HeightInt } = useCanvas()
    const data = {
        numero_adherent: receptionV.numero_adherent,
        numero_client: receptionV.numero_client,
        clef_stationnement: receptionV.clef_stationnement,
        clef_lien: receptionV.clef_lien,
        numero_intervention: receptionV.numero_intervention,
        date_intervention: receptionV.date_intervention,
    }
    const { interventionInfoGeneral } = useDataContext()

    const handleChangeCB = (e, values, setFieldValue) => {
        let name = e.target.name
        let value = !values[name]
        setFieldValue(name, value)
        setFieldValue("date_validite", null)
        setFieldValue("marque_vignette", "")
        setFieldValue("numero_vignette", "")
        // Mise à jour de l'etat global
        let intervention = JSON.parse(localStorage.intervention)
        intervention[name] = value
        intervention["date_validite"] = null
        intervention["marque_vignette"] = ""
        intervention["numero_vignette"] = ""
        setFormIntervention(intervention)
        localStorage.setItem("taximetre", JSON.stringify(intervention))
    }

    const handleEgalBase = (e, setValues) => {
        supervisionService.egalePrecedentVignette(data)
            .then(response => {
                setValues(prev => ({
                    ...prev,
                    pas_vignette_presente: response.pas_vignette_presente,
                    date_validite: response.date_validite,
                    marque_vignette: response.marque_vignette,
                    numero_vignette: response.numero_vignette,
                }))
                toast.success("Dernière intervention trouvée. Modification effectuée.", { containerId: 'PRE' })
            })
            .catch(error => {
                setValues(prev => ({
                    ...prev,
                    numero_vignette: 0
                }))
                toast.info(error, { containerId: 'PRE' })
            })
    }

    // Initialisation des pbs
    useEffect(() => {
        let listCoords = []
        if (receptionV.pb_vehicule1 !== "" && receptionV.pb_vehicule1 !== null) {
            let coords = receptionV.pb_vehicule1.split("|")
            let x = Math.round(coords[0] * canvas3WidthInt)
            let y = Math.round(coords[1] * canvas3HeightInt)
            const currentCoord = { x, y }
            listCoords.push(currentCoord)
        }
        if (receptionV.pb_vehicule2 !== "" && receptionV.pb_vehicule2 !== null) {
            let coords = receptionV.pb_vehicule2.split("|")
            let x = Math.round(coords[0] * canvas3WidthInt)
            let y = Math.round(coords[1] * canvas3HeightInt)
            const currentCoord = { x, y }
            listCoords.push(currentCoord)
        }
        if (receptionV.pb_vehicule3 !== "" && receptionV.pb_vehicule3 !== null) {
            let coords = receptionV.pb_vehicule3.split("|")
            let x = Math.round(coords[0] * canvas3WidthInt)
            let y = Math.round(coords[1] * canvas3HeightInt)
            const currentCoord = { x, y }
            listCoords.push(currentCoord)
        }
        if (receptionV.pb_vehicule4 !== "" && receptionV.pb_vehicule4 !== null) {
            let coords = receptionV.pb_vehicule4.split("|")
            let x = Math.round(coords[0] * canvas3WidthInt)
            let y = Math.round(coords[1] * canvas3HeightInt)
            const currentCoord = { x, y }
            listCoords.push(currentCoord)
        }
        setCoordinate3(listCoords)
    }, [])

    const handleCanvasClick = (event) => {
        let canvas = document.getElementById("canvas3")
        let rect = canvas.getBoundingClientRect()
        let scaleX = canvas.width / rect.width
        let scaleY = canvas.height / rect.height
        // si cercle pas de offset
        // si rect on ajoute offset rect.left + offset
        // Coordonnées reelles
        let x = Math.round((event.clientX - (rect.left)) * scaleX)
        let y = Math.round((event.clientY - (rect.top)) * scaleY)

        const currentCoord = { x, y }
        let tableCoordsLength = coordinate3.length
        if (coordinate3.length < 4) {
            setCoordinate3([...coordinate3, currentCoord])

            // Coordonnées pourcentage
            x = Math.round((x / canvas3WidthInt) * 1000) / 1000
            y = Math.round((y / canvas3HeightInt) * 1000) / 1000

            let _intervention = JSON.parse(localStorage.intervention)
            _intervention["pb_vehicule" + (tableCoordsLength + 1)] = x + "|" + y
            localStorage.setItem("intervention", JSON.stringify(_intervention))
            setFormIntervention(_intervention)
        }
    }

    const handleClearCanvas = (event) => {
        setCoordinate3([])
        let _intervention = JSON.parse(localStorage.intervention)
        _intervention.pb_vehicule1 = ""
        _intervention.pb_vehicule2 = ""
        _intervention.pb_vehicule3 = ""
        _intervention.pb_vehicule4 = ""
        setFormIntervention(_intervention)
        localStorage.setItem("intervention", JSON.stringify(_intervention))
    }

    return (
        <div className="receptionv-section">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    kilometrage: receptionV.kilometrage,
                    coefficient_k_avant: receptionV.coefficient_k_avant,
                    anomalies_initiales: receptionV.anomalies_initiales,
                    pas_vignette_presente: receptionV.pas_vignette_presente,
                    date_validite: receptionV.date_validite ? moment(formatDate(new Date(receptionV.date_validite).setFullYear(new Date(receptionV.date_validite).getFullYear() - 1), 2)).utcOffset(0, true).format("YYYY-MM-DD[T]HH:mm:ss") : null,
                    marque_vignette: receptionV.marque_vignette,
                    numero_vignette: receptionV.numero_vignette,
                    etat_vehicule1: receptionV.etat_vehicule1,
                    etat_vehicule2: receptionV.etat_vehicule2,
                    date_effectuee: receptionV.date_effectuee ? receptionV.date_effectuee : null,
                    nom_chauffeur: receptionV.nom_chauffeur,
                }}
                validate={(values) => {
                    const errors = {}
                    let intervention = JSON.parse(localStorage.intervention)
                    // Mise à jour des interventions
                    intervention.kilometrage = values.kilometrage !== "" ? parseInt(values.kilometrage) : ""
                    intervention.coefficient_k_avant = values.coefficient_k_avant !== "" ? parseInt(values.coefficient_k_avant) : ""
                    intervention.anomalies_initiales = values.anomalies_initiales
                    intervention.pas_vignette_presente = values.pas_vignette_presente
                    intervention.date_validite = values.date_validite ? moment(formatDate(values.date_validite, 2)).utcOffset(0, true).format("YYYY-MM-DD[T]HH:mm:ss") : null
                    intervention.marque_vignette = values.marque_vignette
                    intervention.numero_vignette = values.numero_vignette !== "" ? parseInt(values.numero_vignette) : ""
                    intervention.etat_vehicule1 = values.etat_vehicule1
                    intervention.etat_vehicule2 = values.etat_vehicule2
                    intervention.date_effectuee = values.date_effectuee ? new Date(values.date_effectuee) : null
                    intervention.nom_chauffeur = values.nom_chauffeur
                    // Partage des données
                    setFormIntervention(intervention)
                    setErrors({ ...errors })
                    // Mise à jour de localStorage
                    localStorage.setItem("intervention", JSON.stringify(intervention))
                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500))
                    alert(JSON.stringify(values, null, 2))
                }}
            >
                {({ values, setFieldValue, setValues, handleChange }) => (
                    <Form autoComplete="off">
                        <div className="affichage-form-container etiquette">
                            {/*Pneumatique*/}
                            <div className="reception-accordion">
                                {/* Kilometrage */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Kilométrage</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="kilometrage" className="form-control input-small" type="text" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">km</div>
                                        </div>
                                    </div>
                                </div>
                                {/* 'k' */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">'k' (imp/km)</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="coefficient_k_avant" className="form-control input-small" type="text" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">lu sur etiquette k</div>
                                        </div>
                                    </div>
                                </div>
                                {/* Anomalies initiales */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Anomalies initiales / manquements détenteurs
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="anomalies_initiales" className="form-control input-infos" as="textarea" />
                                        </div>
                                    </div>
                                </div>
                                {interventionInfoGeneral.a1 === false &&
                                    <div className="section-one">
                                        {/* Pas de vignette */}
                                        <div className="form-cell-input">
                                            <div className="form-group checkbox-group">
                                                <label className="options-label">Pas de vignette</label>
                                                <Switch
                                                    name="pas_vignette_presente"
                                                    classes={lovelyStyles}
                                                    checked={values.pas_vignette_presente}
                                                    onChange={e => handleChangeCB(e, values, setFieldValue)}
                                                />
                                            </div>
                                        </div>
                                        {/* Section vignette */}
                                        <div className="section-vignette">
                                            {/* Date pose */}
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Date pose
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input m-w-100">
                                                    <div className="form-input">
                                                        <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                id="date-picker-dialog"
                                                                label="Date pose"
                                                                inputVariant="outlined"
                                                                format="dd/MM/yyyy"
                                                                value={values.date_validite}
                                                                onChange={value => setFieldValue("date_validite", value)}
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change date"
                                                                }}
                                                                maxDate={new Date()}
                                                                disabled={values.pas_vignette_presente}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Marque */}
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">Marque</div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="marque_vignette" className="form-control input-small" type="text" disabled={values.pas_vignette_presente} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <button type="button" className="cbtn cbtn-success mt-0 mb-2" disabled={values.pas_vignette_presente} onClick={e => setFieldValue("marque_vignette", "FG13")}>
                                                            FG13
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* N° */}
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            N°
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="numero_vignette" className="form-control input-small" type="text" disabled={values.pas_vignette_presente} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="section-precedent">
                                                <button type="button" className="cbtn cbtn-success mt-0 mb-2" onClick={e => handleEgalBase(e, setValues)}>Egal Base</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="canva-container my-4">
                                    <canvas
                                        id="canvas3"
                                        className="App-canvas App-canvas3"
                                        onClick={handleCanvasClick}></canvas>
                                    <div className="button">
                                        <button onClick={handleClearCanvas} type="button" className="comet-btn comet-btn-danger">Effacer</button>
                                    </div>
                                </div>
                                {/* Details etat vehicule */}
                                <div className="form-row">
                                    <div className="form-col d-flex align-items-center">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Detail état véhicule s'il y a lieu</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-col m-w-100">
                                        <div className="form-row m-m-0">
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="etat_vehicule1" className="form-control input-infos" type="text" />
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="etat_vehicule2" className="form-control input-infos" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Date */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Date
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    id="date-picker-dialog"
                                                    label="Date"
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    value={values.date_effectuee}
                                                    onChange={value => setFieldValue("date_effectuee", value)}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "Date"
                                                    }}
                                                    maxDate={new Date()}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </div>
                                {/* Nom Chauffeur */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Nom Chauffeur (optionnel)</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="nom_chauffeur" className="form-control input-infos" type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )

}

export default ReceptionV