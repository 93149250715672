import { createContext, useContext, useState } from "react"

export const AdherentContext = createContext()

const AdherentContextProvider = ({ children }) => {
    const [adherent, setAdherent] = useState(null)

    return (
        <AdherentContext.Provider value={{
            adherent,
            setAdherent,
        }}>
            {children}
        </AdherentContext.Provider>
    )
}

export const useAdherentContext = () => useContext(AdherentContext)

export default AdherentContextProvider