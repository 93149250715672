import React, { useEffect, useState } from "react"
import { Formik, Field, Form } from 'formik'
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../../res/erreur.svg'
import { ReactComponent as Intervention } from './../../../../../res/alerte.svg'

const ReceptionV = (props) => {

    const [receptionV] = useState(JSON.parse(localStorage.getItem("intervention")))

    return (
        <div className="receptionv-section">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue, setValues, handleChange }) => (
                    <Form autoComplete="off">
                        <div className="affichage-form-container etiquette">
                            {/*Pneumatique*/}
                            <div className="reception-accordion">
                                {/* Kilometrage */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Kilométrage</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="kilometrage" className="form-control input-small" type="text" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">km</div>
                                        </div>
                                    </div>
                                </div>
                                {/* Véhicule vide */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Véhicule vide
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        Non
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-3"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 0)}
                                                        /*checked={values.usure_pneumatique === 0 ? true : false} */ />
                                                    <label htmlFor="usure_pneumatique-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        SO
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Etat de marche */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Etat de Marche
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        Non
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-3"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 0)}
                                                        /*checked={values.usure_pneumatique === 0 ? true : false} */ />
                                                    <label htmlFor="usure_pneumatique-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        SO
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Etat de marche */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Ticket 0 et I
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        Refus
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_r91"
                                                        id="c_r91-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_r91', 3)} />
                                                    <label htmlFor="c_r91-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        Intervention
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-3"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 0)}
                                                        /*checked={values.usure_pneumatique === 0 ? true : false} */ />
                                                    <label htmlFor="usure_pneumatique-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        SO
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Vérifications */}
                                <div className="form-row">
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="anomalies_initiales" className="form-control input-infos" as="textarea" disabled value="Vérifications : VU Activée, Ticket Cohérent, Vérification x et ! en cours (code 7 ou h)" />
                                        </div>
                                    </div>
                                </div>
                                {/* Numéro série */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Numéro de série véhicule carte grise</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="kilometrage" className="form-control" type="text" />
                                        </div>
                                    </div>
                                </div>
                                {/* Carte grise = Frappe à froid */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Carte grise = Frappe à froid
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        Refus
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_r91"
                                                        id="c_r91-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_r91', 3)} />
                                                    <label htmlFor="c_r91-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        VIN = VU mais différent CG
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-3"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 0)}
                                                        /*checked={values.usure_pneumatique === 0 ? true : false} */ />
                                                    <label htmlFor="usure_pneumatique-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        SO
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Carte grise = Ticket I */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Carte grise = Ticket I
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        Refus
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_r91"
                                                        id="c_r91-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_r91', 3)} />
                                                    <label htmlFor="c_r91-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        Intervention
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-3"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 0)}
                                                        /*checked={values.usure_pneumatique === 0 ? true : false} */ />
                                                    <label htmlFor="usure_pneumatique-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        SO
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Si Frappe à froid */}
                                <div className="form-row">
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="anomalies_initiales" className="form-control input-infos" as="textarea" disabled value="Si Frappe à froid = ticket mais différent de carte grise : prévenir client" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )

}

export default ReceptionV