import React, { useState } from "react"
import { Formik, Field, Form } from 'formik'
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../../res/erreur.svg'
import { Tabs } from 'antd'
import { Accordion, Card, Button } from 'react-bootstrap'
import pneu from "./../../../../../assets/saisie-tachy/pneu.png"
import Checkbox from '@material-ui/core/Checkbox'

const { TabPane } = Tabs

const ReceptionP = (props) => {

    const [ReceptionP] = useState(JSON.parse(localStorage.getItem("intervention")))

    // Accordion
    const [isOpenG, setIsOpenG] = useState(true)
    const [isOpenD, setIsOpenD] = useState(false)

    return (
        <div className="receptionp-section">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue, setValues, handleChange }) => (
                    <Form autoComplete="off">
                        <div className="affichage-form-container etiquette">
                            {/* Marque */}
                            <div className="form-row">
                                <div className="form-col">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Marque</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-col m-w-100">
                                    <div className="form-row">
                                        <div className="form-col m-w-100">
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="util_mano" className="form-control" as="select">
                                                        <option value="0">Autre</option>
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-col m-w-100">
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="util_mano" className="form-control" as="select">
                                                        <option value="0">DAYTON</option>
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Modele */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">Modele</div>
                                    </div>
                                </div>
                                <div className="form-col m-w-100">
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control" as="select">
                                                <option value="0"></option>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Aide */}
                            <div className="form-row">
                                <div className="form-col">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Aide
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-col">
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="aide_pneu_av" className="form-control" as="select">
                                                <option value="0"></option>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Dimensions avant
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-col">
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                        </div>
                                    </div>
                                    <div className="pneumatique-separateur">
                                        <span>X</span>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="pneu_dim2" className="form-control input-extra-small" type="text" />
                                        </div>
                                    </div>
                                    <div className="pneumatique-separateur">
                                        <span>X</span>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="pneu_dim3" className="form-control input-extra-small" type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Manomètre</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-col m-w-100">
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control" as="select">
                                                <option value="0"></option>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Egal precedent */}
                            <div className="section-precedent">
                                <button type="button" className="cbtn cbtn-success mt-0 mb-2">Egal précédent</button>
                            </div>
                            {/* Pression pneu ajusté ou non */}
                            <div className="form-row">
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    Pression Pneus ajustée
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    Pression non ajustée
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Pression */}
                            <div className="form-row">
                                <div className="form-col">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Pression
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-col">
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="pneu_pression" className="form-control input-small" type="text" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                bars
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* I idéal */}
                            <div className="form-row">
                                <div className="form-col">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                I idéal (selon pneus)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-col">
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="pneu_pression" className="form-control input-small" type="text" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Détail et Sanction Usure */}
                            <Tabs defaultActiveKey="1" tabPosition={'top'} style={{ height: 220, width: '100%' }}>
                                <TabPane tab={'Détail Usure'} key={1}>
                                    {/* Rappel */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Rappel PTAC (t)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Tolérance */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Tolérance sur chaque pneu 1 maxi avec usure (mm) :
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="reception-accordion">
                                        <Accordion defaultActiveKey="0">
                                            {/* Gauche */}
                                            <Card>
                                                <Accordion.Toggle
                                                    className="comet-collapse supervision-collapse card-header font-weight-bold"
                                                    as={Card.Header}
                                                    eventKey="0"
                                                    onClick={() => { setIsOpenG(!isOpenG); setIsOpenD(false) }}
                                                >
                                                    <label>Gauche (en mm)</label>
                                                    <Button
                                                        onClick={() => { setIsOpenG(!isOpenG); setIsOpenD(false) }}
                                                        aria-controls="example-collapse-text"
                                                        aria-expanded={isOpenG}
                                                        className="font-weight-bold"
                                                    >
                                                        {isOpenG === false ?
                                                            <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                            <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                        }
                                                    </Button>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <div className="form-row pneu-container">
                                                            {/* Gauche 1 */}
                                                            <div className="form-col flex-column">
                                                                <div className="tachy-pneu">
                                                                    <div className="tachy-pneu-img" >
                                                                        <div className="image-container">
                                                                            <img src={pneu} alt="pneu" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="tachy-pneu-input">
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input float-right">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input float-right">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tachy-actions d-flex align-items-center">
                                                                    <button type="button" className="btn btn-primary">4x=</button>
                                                                    <Field name="pneu_dim1" className="form-control input-extra-small" type="text" value="OK" />
                                                                </div>
                                                            </div>
                                                            {/* Gauche 2 */}
                                                            <div className="form-col flex-column">
                                                                <div className="tachy-pneu">
                                                                    <div className="tachy-pneu-img" >
                                                                        <div className="image-container">
                                                                            <img src={pneu} alt="pneu" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="tachy-pneu-input">
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input float-right">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input float-right">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tachy-actions d-flex align-items-center">
                                                                    <button type="button" className="btn btn-primary">4x=</button>
                                                                    <Field name="pneu_dim1" className="form-control input-extra-small" type="text" value="OK" />
                                                                </div>
                                                                <div className="form-cell-input checkbox-container">
                                                                    <div className="form-input">
                                                                        <Checkbox
                                                                            name="c_r20"
                                                                            inputProps={{
                                                                                'aria-label': 'primary checkbox'
                                                                            }} />
                                                                    </div>
                                                                    <div className="form-cell-label">
                                                                        <div className="form-text">
                                                                            <div className="indication-title">
                                                                                Pression impossibble
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            {/* Droite */}
                                            <Card>
                                                <Accordion.Toggle
                                                    className="comet-collapse supervision-collapse card-header font-weight-bold"
                                                    as={Card.Header}
                                                    eventKey="1"
                                                    onClick={() => { setIsOpenD(!isOpenD); setIsOpenG(false) }}
                                                >
                                                    <label>Droite (en mm)</label>
                                                    <Button
                                                        onClick={() => { setIsOpenD(!isOpenD); setIsOpenG(false) }}
                                                        aria-controls="example-collapse-text"
                                                        aria-expanded={isOpenD}
                                                        className="font-weight-bold"
                                                    >
                                                        {isOpenD === false ?
                                                            <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                            <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                        }
                                                    </Button>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body>
                                                        <div className="form-row pneu-container">
                                                            {/* Droite 1 */}
                                                            <div className="form-col flex-column">
                                                                <div className="tachy-pneu">
                                                                    <div className="tachy-pneu-img" >
                                                                        <div className="image-container">
                                                                            <img src={pneu} alt="pneu" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="tachy-pneu-input">
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input float-right">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input float-right">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tachy-actions d-flex align-items-center">
                                                                    <button type="button" className="btn btn-primary">4x=</button>
                                                                    <Field name="pneu_dim1" className="form-control input-extra-small" type="text" value="OK" />
                                                                </div>
                                                                <div className="form-cell-label">
                                                                    <div className="form-text">
                                                                        <div className="indication-title">
                                                                            Pression impossibble
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* Droite 2 */}
                                                            <div className="form-col flex-column">
                                                                <div className="tachy-pneu">
                                                                    <div className="tachy-pneu-img" >
                                                                        <div className="image-container">
                                                                            <img src={pneu} alt="pneu" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="tachy-pneu-input">
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input float-right">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-cell-input">
                                                                            <div className="form-input float-right">
                                                                                <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tachy-actions d-flex align-items-center">
                                                                    <button type="button" className="btn btn-primary">4x=</button>
                                                                    <Field name="pneu_dim1" className="form-control input-extra-small" type="text" value="OK" />
                                                                </div>
                                                                <div className="tachy-actions d-flex align-items-center">
                                                                    <div className="form-cell-label">
                                                                        <div className="form-text">
                                                                            <div className="indication-title">
                                                                                D &lt; 5mm
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <Field name="pneu_dim1" className="form-control input-extra-small" type="text" value="OK" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </div>
                                </TabPane>
                                <TabPane tab={'Sanction Usure'} key={2}>
                                    {/* Usure chaque pneu essieu */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    Usure chaque pneu essieu
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                        <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            OK
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-2"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Erreur />
                                                            Non
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-3"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 0)}
                                                        /*checked={values.usure_pneumatique === 0 ? true : false} */ />
                                                        <label htmlFor="usure_pneumatique-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <SO />
                                                            SO
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field name="anomalies_initiales" className="form-control input-infos" as="textarea" disabled
                                                    value="Si PTAC > 3.5 tonnes :
                                                - la profondeur peut être inférieure à 1 mm  sur 1 point sur 4 maxi. Sinon : Refus intervention si PTAC < 3.5 tonnes :
                                                - la profondeur peut être inférieure à 1.6 mm  sur 1 point sur 4 maxi. Sinon : Refus intervention"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Usure des pneumatiques de tous les pneumatiques du même essieu (y compris jumelé) */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    Usure des pneumatiques de tous les pneumatiques du même essieu
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                        <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            OK
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-2"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Erreur />
                                                            Non
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-3"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 0)}
                                                        /*checked={values.usure_pneumatique === 0 ? true : false} */ />
                                                        <label htmlFor="usure_pneumatique-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <SO />
                                                            SO
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field name="anomalies_initiales" className="form-control input-infos" as="textarea" disabled
                                                    value="Différence d'usure entre TOUS les pneus d'un même essieu doit être :
                                                    - inférieure à 5 mm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-col">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Jauge</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-col m-w-100">
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field name="util_mano" className="form-control" as="select">
                                                    <option value="0"></option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )

}

export default ReceptionP