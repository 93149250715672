import { Field, Form, Formik } from "formik"
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Intervention } from './../../../../../res/alerte.svg'
import Checkbox from '@material-ui/core/Checkbox'

const Sanction = () => {

    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container">
                            {/* Selection */}
                            <div className="form-row">
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-1"
                                                    className="sr-only" />
                                                <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-2"
                                                    className="sr-only"
                                                />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    AXX :
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* A20 */}
                            <div className="form-row m-m-0">
                                <div className="form-cell-input checkbox-container">
                                    <div className="form-input">
                                        <Checkbox
                                            name="c_r99"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </div>
                                </div>
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            A20 : Inscription d'identification NC dispo. réglementaires
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        <Field className="form-control" type="text" name="r99" />
                                    </div>
                                </div>
                            </div>
                            {/* A21 */}
                            <div className="form-row m-m-0">
                                <div className="form-cell-input checkbox-container">
                                    <div className="form-input">
                                        <Checkbox
                                            name="c_r10"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </div>
                                </div>
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            A21 (2) : Non concordance paramètres affichés et VU, capeur, DSRC, GNSS
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* A22 */}
                            <div className="form-row">
                                <div className="form-cell-input checkbox-container">
                                    <div className="form-input">
                                        <Checkbox
                                            name="c_r19"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </div>
                                </div>
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            A22 (2) : Absence ou non intégrité scellements VU
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* A50 */}
                            <div className="form-row">
                                <div className="form-cell-input checkbox-container">
                                    <div className="form-input">
                                        <Checkbox
                                            name="c_r19"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </div>
                                </div>
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            A50 : pneusT2 : Non concordance Taille des pneus
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* A50 */}
                            <div className="form-row">
                                <div className="form-cell-input checkbox-container">
                                    <div className="form-input">
                                        <Checkbox
                                            name="c_r19"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </div>
                                </div>
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            A50 pneusC2 : Non concordance Circonférence des pneus
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Sanction