import React, { useEffect, useState } from 'react'
import { Field } from 'formik'
import { supervisionService } from '../../services/supervisionService'
import activites from './../../constants/activiteList'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { IoMdClose } from "react-icons/io"

const PopUpDocument = (props) => {

    const [typesDocument, setTypesDocument] = useState(null)
    const [detailsDocuments, setDetailsDocuments] = useState(null)
    const [adherents, setAdherents] = useState(null)
    const [intervenants, setIntervenants] = useState(null)

    useEffect(() => {
        if (props.initial.documentliste && props.initial.documentliste.length === 0) {
            let data = [{
                date_fichier: "",
                extension_fichier: ".pdf",
                fichier_contenu: "",
                fichier_original: "",
                nom_fichier: "",
                // props.activite
                numero_activite: 0,
                numero_adherent: props.superviseur.group,
                numero_detail_doc: 0,
                numero_intervenant: props.superviseur.user_id,
                numero_type_doc: 21,
                observations_fichier: "",
            }, {
                date_fichier: null,
                extension_fichier: ".pdf",
                fichier_contenu: "",
                fichier_original: "",
                nom_fichier: "",
                // props.activite
                numero_activite: 0,
                numero_adherent: props.superviseur.group,
                numero_detail_doc: 0,
                numero_intervenant: props.superviseur.user_id,
                numero_type_doc: 21,
                observations_fichier: "",
            }]
            props.setInitial({
                ...props.initial, documentliste: data
            })
            props.passFormToParent({ ...props.initial, documentliste: data }, {})
        } else if (props.initial.documentliste && props.initial.documentliste.length !== 0 && props.initial.documentliste[1] === undefined) {
            props.setInitial((recent) => {
                let documents = recent.documentliste
                documents.push({
                    date_fichier: null,
                    extension_fichier: ".pdf",
                    fichier_contenu: "",
                    fichier_original: "",
                    nom_fichier: "",
                    // props.activite
                    numero_activite: 0,
                    numero_adherent: props.superviseur.group,
                    numero_detail_doc: 0,
                    numero_intervenant: props.superviseur.user_id,
                    numero_type_doc: 21,
                    observations_fichier: "",
                })
                let data = {
                    ...recent,
                    documentliste: documents
                }
                props.passFormToParent(data, {})
                return data
            })
        }
        //props.activite
        // activite de l'adherent superviseur
        async function getTypes(activite = 0) {
            await supervisionService.getAllTypeDocByActivite(activite)
                .then(response => setTypesDocument(response))
        }
        async function getDetails(typedoc = 21) {
            await supervisionService.getAllDetailDocByTypeDoc(typedoc)
                .then(response => setDetailsDocuments(response))
                .catch(error => toast.info(error, { containerId: "App" }))
        }
        async function getAdherents() {
            await supervisionService.getAllAdherent()
                .then(response => setAdherents(response))
        }
        async function getIntervenants(adherent = 0) {
            await supervisionService.getAllIntervenant(adherent)
                .then(response => setIntervenants(response))
        }
        getTypes()
        getDetails()
        getAdherents()
        getIntervenants(props.initial.numero_adherent)
    },
        [])

    // Met à jour ou ajoute un document de rapport
    const uploadFile = (event, documentliste, index) => {
        // Récupération des infos de la supervision générale
        let newDocuments = documentliste
        let reader = new FileReader()
        // Nom fichier pdf
        var name = event.currentTarget.files[0].name
        // Extension du fichier
        var extension = (event.currentTarget.files[0].type).replace(/(.*)\//g, '') === "jpeg" ? "jpg" : (event.currentTarget.files[0].type).replace(/(.*)\//g, '')
        // Date du fichier
        var date = new Date(event.currentTarget.files[0].lastModifiedDate)

        reader.readAsDataURL(event.currentTarget.files[0])

        reader.onloadend = event => {
            // Recupere le fichier binaire en base64 du pdf
            if (!newDocuments[index]) {
                // Pas de fichier PDF existant
                // Nouveau document
                newDocuments.push({
                    date_fichier: date,
                    extension_fichier: "." + extension,
                    fichier_contenu: reader.result.split(',')[1],
                    fichier_original: name,
                    nom_fichier: name.split('.')[0],
                    numero_detail_doc: 0,
                    numero_document: -1,
                    numero_type_doc: 21,
                    observations_fichier: "",
                })
            } else {
                // Fichier existant
                // Modification du document avec les infos
                // du fichier uploade
                newDocuments[index].fichier_contenu = reader.result.split(',')[1]
                newDocuments[index].fichier_original = name
                newDocuments[index].nom_fichier = name.split('.')[0]
                newDocuments[index].date_fichier = date
                newDocuments[index].extension_fichier = "." + extension
            }
            // Mise à jour de la liste des documents
            //setFieldValue('file' + index + 1, reader.result)
            props.setInitial((recent) => {
                return {
                    ...recent,
                    documentliste: newDocuments
                }
            })
        }
    }

    // Ouvre un document de Rapport
    const openFile = (event, file) => {
        var fileString = file.fichier_contenu
        var byteCharacters = atob(fileString)
        var byteNumbers = new Array(byteCharacters.length)
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        var byteArray = new Uint8Array(byteNumbers)
        var newFile = new Blob([byteArray], {
            type: file.extension_fichier === ".jpg" || file.extension_fichier === ".jpeg" ? 'image/jpeg' :
                file.extension_fichier === ".png" ? 'image/png' :
                    file.extension_fichier === ".pdf" ? 'application/pdf;base64' :
                        file.extension_fichier === ".xlsx" || file.extension_fichier === ".xls" ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' :
                            file.extension_fichier === ".docx" ? 'application/msword' :
                                undefined
        })
        var fileURL = URL.createObjectURL(newFile)
        var link = document.createElement('a')
        link.href = fileURL
        link.download = props.initial.documentliste[props.id].nom_fichier
        link.click()
        setTimeout(function () {
            // Necessaire pour Firefox
            window.URL.revokeObjectURL(fileURL)
        }, 100)
    }

    // Supprime le fichier uploade
    const deleteFile = (documentliste, index) => {
        let newDocuments = documentliste
        if (newDocuments[index] && newDocuments[index].fichier_contenu) {
            if (window.confirm('Êtes-vous sûr de vouloir supprimer le document')) {

                newDocuments[index].fichier_contenu = ""
                newDocuments[index].fichier_original = ""
                newDocuments[index].nom_fichier = ""
                newDocuments[index].date_fichier = null
                props.setInitial((recent) => {
                    return {
                        ...recent,
                        documentliste: newDocuments
                    }
                })
            } else {
                // On fait rien !
            }
        }
    }

    return (
        <div className="popup">
            <div className="popup-document">
                <div className="popup-header">
                    <h3 className="title">
                        Rapport de supervision
                    </h3>
                    <div className="popup-close-button" onClick={props.close}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-main">
                    <div className="document-infos c-mt-2">
                        <>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor={"documentliste[" + props.id + "].numero_type_doc"}>Type</label>
                                        <Field name={"documentliste[" + props.id + "].numero_type_doc"} as="select" className="form-control" autoComplete="off" disabled>
                                            {typesDocument && typesDocument.map((type, index) =>
                                                <option key={index} value={type.numero_type_doc}>{type.nom_type_doc}</option>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor={"documentliste[" + props.id + "].numero_detail_doc"}>Details</label>
                                        <Field name={"documentliste[" + props.id + "].numero_detail_doc"} as="select" className="form-control" autoComplete="off" disabled>
                                            {detailsDocuments && detailsDocuments.map((type, index) =>
                                                <option key={index} value={type.numero_detail_doc}>{type.nom_detail_doc}</option>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="col">
                                        <label htmlFor={"documentliste[" + props.id + "].numero_activite"}>Activité</label>
                                        <Field name={"documentliste[" + props.id + "].numero_activite"} as="select" className="form-control" autoComplete="off" disabled>
                                            {activites && activites.map((activite, index) =>
                                                <option key={index} value={activite.numero}>{activite.nom}</option>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor={"documentliste[" + props.id + "].numero_adherent"}>Adherent</label>
                                        <Field name={"documentliste[" + props.id + "].numero_adherent"} as="select" className="form-control" autoComplete="off" disabled>
                                            {adherents && adherents.map((adherent, index) =>
                                                <option key={index} value={adherent.numero_adherent}>{adherent.identification_adherent}</option>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="col">
                                        <label htmlFor={"documentliste[" + props.id + "].numero_intervenant"}>Intervenant</label>
                                        <Field name={"documentliste[" + props.id + "].numero_intervenant"} as="select" className="form-control" autoComplete="off" disabled>
                                            {intervenants && intervenants.map((intervenant, index) =>
                                                <option key={index} value={intervenant.numero_intervenant}>{intervenant.prenom} {intervenant.nom}</option>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor={"documentliste[" + props.id + "].nom_fichier"}>Nom fichier</label>
                                        <Field name={"documentliste[" + props.id + "].nom_fichier"} value={props.initial.documentliste[props.id] ? props.initial.documentliste[props.id].nom_fichier : ""} className="form-control" autoComplete="off" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor={"documentliste[" + props.id + "].observations_fichier"}>Observations</label>
                                        <Field name={"documentliste[" + props.id + "].observations_fichier"} value={props.initial.documentliste[props.id] ? props.initial.documentliste[props.id].observations_fichier : ""} className="form-control" autoComplete="off" />
                                    </div>
                                </div>
                            </div>
                            <div className="popup-actions">
                                <div className="popup-action-delete">
                                    <button type="button" className={"btn c-action-button c-delete-button" + (props.initial.documentliste[props.id] && props.initial.documentliste[props.id].fichier_contenu === "" ? " disabled" : "")} onClick={e => deleteFile(props.initial.documentliste, props.id)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </button>
                                </div>
                                <div className="popup-action-open">
                                    <button type="button" className={"upload-file-btn upload-file-btn-open" + (props.initial.documentliste[props.id] && props.initial.documentliste[props.id].fichier_contenu === "" ? " disabled" : "")} onClick={e => openFile(e, props.initial.documentliste[props.id])}>
                                        <span>Ouvrir</span>
                                        <i className="fas fa-eye"></i>
                                    </button>
                                </div>
                                <div className="popup-action-upload">
                                    <div className="upload-file">
                                        <input
                                            type="file"
                                            name="file1"
                                            id="file1"
                                            className="file-input"
                                            accept="application/pdf,image/*, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            onChange={e => uploadFile(e, props.initial.documentliste, props.id)}
                                            onBlur={props.handleBlur}
                                        />
                                        <label className={"upload-file-btn" + (props.initial.documentliste[props.id] && props.initial.documentliste[props.id].fichier_contenu !== "" ? " upload-file-btn-edit" : " upload-file-btn-add")} htmlFor="file1"><i className="fas fa-file-upload"></i>{props.initial.documentliste[props.id] && props.initial.documentliste[props.id].fichier_contenu !== "" ? "Modifier" : "Ajouter"}</label>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </div >
    )

}

export default PopUpDocument