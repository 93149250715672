import React, { useEffect, useState } from "react"
import { IoMdClose } from "react-icons/io"
import { Formik, Form, Field } from 'formik'
import { supervisionService } from "../../services/supervisionService"
import { Table } from 'antd'
import { formatDate } from './../../helpers/utils'
import { toast, ToastContainer } from "react-toastify"
import 'antd/dist/antd.css'

const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    },
    getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
    }),
}

const Planning = (props) => {

    const [adherents, setAdherents] = useState(null)
    const [oisos, setOisos] = useState([])
    const [columns, setColumns] = useState([])
    const [oisosData, setOisosData] = useState([])

    const getAllAdherent = () => {
        supervisionService.getAllAdherent(undefined, undefined, 2)
            .then(response => setAdherents(response))
    }

    useEffect(() => {
        getAllAdherent()
    }, [])

    const getPlanning = (values) => {
        supervisionService.getPlanning(values)
            .then(response => {
                setOisos(response)
                let table1 = []
                let table2 = []
                table1.push(
                    {
                        title: 'Adhérent',
                        dataIndex: 'adherent'
                    },
                    {
                        title: 'Client',
                        dataIndex: 'client'
                    },
                    {
                        title: 'Date prévue',
                        dataIndex: 'date_prevue'
                    },
                    {
                        title: 'Technicien',
                        dataIndex: 'technicien'
                    },
                )
                // A utiliser si on souhaite avoir tous les champs
                /*for (var name in response[0]) {
                    table.push({
                        title: (name.charAt(0).toUpperCase() + name.slice(1)),
                        dataIndex: name,
                    })
                }*/
                setColumns(table1)
                for (let i = 0; i < response.length; i++) {
                    table2.push(
                        {
                            key: i + 1,
                            adherent: (response[i].identification_adherent).replace("-", " "),
                            client: response[i].nom_client,
                            date_prevue: formatDate(response[i].date_vp),
                            technicien: response[i].nom + " " + response[i].prenom
                        }
                    )
                }
                setOisosData(table2)
                toast.success("Données existantes.", { containerId: "App" })
            })
            .catch(error => {
                toast.error(error, { containerId: "App" })
                setColumns([])
                setOisosData([])
            })
    }

    return (
        <div className="popup-cover right-popup-cover">
            <div className="popup-area right-popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        Planning
                    </h3>
                    <div className="popup-close-button" onClick={props.onClose}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-body">
                    <Formik
                        enableReinitialize
                        initialValues={{
                            adherent: 0,
                            activite: -1,
                            non_envoyes_max: 10,
                            que_non_envoyes: true,
                        }}
                        validate={values => {
                            values.adherent = parseInt(values.adherent)
                            values.activite = parseInt(values.activite)
                            values.non_envoyes_max = parseInt(values.non_envoyes_max)
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            getPlanning(values)
                        }}
                    >
                        {({ isSubmitting, setFieldValue, setValues, values }) => (
                            <Form>
                                <div className="form-planning-container">
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="adherent">Adhérent</label>
                                                <Field as="select" className="form-control" name="adherent" >
                                                    <option value="0">Tous</option>
                                                    {adherents && adherents.map((a, n) =>
                                                        <option key={n} value={a.numero_adherent}>{a.identification_adherent}</option>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="activite">Activité</label>
                                                <Field as="select" className="form-control" name="activite">
                                                    <option value="-2"></option>
                                                    <option value="-1">Tous</option>
                                                    <option value="1">Tax</option>
                                                    <option value="2">Gzo</option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group d-flex align-items-center">
                                        <label className="mb-0" htmlFor="non_envoyes_max">Non envoyés entre 1 et </label>
                                        <div className="col-3 col-sm-2 col-md-2">
                                            <Field type="text" className="form-control" name="non_envoyes_max" />
                                        </div>
                                        <label className="mb-0">jours</label>
                                    </div>
                                    <button type="submit" className="btn cbtn cbtn-primary mt-0 btn-planning">Visualiser</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    {oisosData.length > 0 &&
                        <div className="result-section planning-result">
                            <div className="document-liste">
                                <Table
                                    rowSelection={{
                                        type: "checkbox",
                                        ...rowSelection,
                                    }}
                                    columns={columns}
                                    dataSource={oisosData}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}

export default Planning