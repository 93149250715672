import { Formik, Field, Form } from 'formik'
import { useEffect } from 'react'
import { useState } from 'react'
import { getTextInterventionDetail, lockBody, unlockBody } from '../../../../helpers/utils'
import { useAuth } from '../../../../providers/authProvider'
import { useDataContext } from '../../../../providers/DataContextProvider'
import { supervisionService } from '../../../../services/supervisionService'
import SignaturePopup from '../../../Popup/SignaturePopup'
import { useSaisieContext } from '../SaisieContext'

const Installateur = () => {

    const [intervention] = useState(JSON.parse(localStorage.intervention))
    const [infos] = useState(JSON.parse(localStorage.infos))
    const [showPopup, setShowPopup] = useState(false)
    const { selectedIntervention } = useDataContext()
    const { setFormIntervention, stopAll, setStopAll } = useSaisieContext()
    const [intervenants, setintervenants] = useState([])
    const auth = useAuth()
    const userId = auth.userId
    const userGroup = parseInt(auth.userGroup)

    useEffect(() => {
        getIntervenants(infos)
    }, [])

    const togglePopup = () => {
        setShowPopup(!showPopup)
        showPopup ? unlockBody() : lockBody()
    }

    const getIntervenants = async (infos) => {
        setStopAll(true)
        if (userGroup === 0) {
            await supervisionService.getAllIntervenant(infos.numero_adherent, false)
                .then(response => setintervenants(response))
                .finally(() => setStopAll(false))
        }
        else {
            await supervisionService.getAllIntervenant(userGroup, true)
                .then(response => setintervenants(response))
                .finally(() => setStopAll(false))
        }
    }

    return (
        <div className="installateur-section">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    sign2: intervention.sign2,
                    numero_intervenant: intervention.numero_intervenant
                }}
                validate={(values) => {
                    const errors = {}
                    let intervention = JSON.parse(localStorage.intervention)
                    // Mise à jour des interventions
                    intervention.sign2 = values.sign2
                    intervention.numero_intervenant = parseInt(values.numero_intervenant)
                    setFormIntervention(intervention)
                    // Mise à jour de localStorage
                    localStorage.setItem("intervention", JSON.stringify(intervention))
                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500))
                    alert(JSON.stringify(values, null, 2))
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form autoComplete="off">
                        {values &&
                            <>
                                <div className="form-row m-m-0">
                                    <div className="modele-actions mb-3">
                                        <button type="button" className={"modele-btn" + (values.sign2 ? " bg-success" : " bg-danger")} onClick={togglePopup}>
                                            Signature Installateur
                                            <i className="fas fa-external-link-alt ml-2"></i>
                                        </button>
                                        <span className={"details-item" + (values.sign2 ? " option-yes" : "")}>{!values.sign2 ? "Vide" : "Signé"}</span>
                                    </div>
                                </div>
                                {values.sign2 ?
                                    <div className="form-row m-m-0">
                                        <div className="signature-img-container">
                                            <img src={"data:image/png;base64," + values.sign2} alt="" />
                                        </div>
                                    </div> : null
                                }
                                {showPopup &&
                                    <SignaturePopup close={togglePopup} title="Signature Installateur" subtitle={"Validation Intervention : " + getTextInterventionDetail(selectedIntervention.data)} setFieldValue={setFieldValue} inputName="sign2" />
                                }
                            </>
                        }
                        <div className="form-group mt-4">
                            <div className="col">
                                <label htmlFor="numero_intervenant">Installateur</label>
                                <Field className="form-control" as="select" name="numero_intervenant" value={(values && !values.numero_intervenant ? "0" : undefined) || (values && values.numero_intervenant) || ""}>
                                    <option value="0"></option>
                                    {intervenants && intervenants.map((i, n) =>
                                        <option key={n} value={i.numero_intervenant}>{i.prenom + " " + i.nom}</option>
                                    )}
                                </Field>
                            </div>
                        </div>
                    </Form>
                )}

            </Formik>

        </div>
    )

}

export default Installateur