import { Field, Form, Formik } from "formik"
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Refus } from './../../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../../res/erreur.svg'

import { Accordion, Card, Button } from 'react-bootstrap'
import { useState } from "react"

const ScellementGen2 = () => {

    const [accordion1, setAccordion1] = useState({ isOpen1: true, isOpen2: false, isOpen3: false, isOpen4: false })
    const [accordion2, setAccordion2] = useState({ isOpen1: true, isOpen2: false, isOpen3: false, isOpen4: false })

    return (
        <div className="taximetre-section">
            <div className="taximetre-container">
                <Formik
                    enableReinitialize={true}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div className="affichage-form-container">
                                <div className="form-row w-100">
                                    {/* Scellements présents */}
                                    <div className="form-row w-100">
                                        <div className="form-cell-input m-w-100 w-100">
                                            <div className="form-input">
                                                <Field name="anomalies_initiales" className="form-control" disabled value="Scellements présents selon saisie : Vérifier avec ceux programmés dans VU" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="reception-accordion w-100">
                                    <Accordion defaultActiveKey="0">
                                        {/* Capteur */}
                                        <Card>
                                            <Accordion.Toggle
                                                className="comet-collapse supervision-collapse card-header font-weight-bold"
                                                as={Card.Header}
                                                eventKey="0"
                                                onClick={() => { setAccordion1({ isOpen1: !accordion1.isOpen1, isOpen2: false, isOpen3: false, isOpen4: false }) }}
                                            >
                                                <label>Capteur</label>
                                                <Button
                                                    onClick={() => { setAccordion1({ isOpen1: !accordion1.isOpen1, isOpen2: false, isOpen3: false, isOpen4: false }) }}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={accordion1.isOpen1}
                                                    className="font-weight-bold"
                                                >
                                                    {accordion1.isOpen1 === false ?
                                                        <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                        <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                    }
                                                </Button>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    {/* Fabricant */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Fabricant</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Numéro */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">N°</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Marque */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Marque</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        {/* GNSS Externe */}
                                        <Card>
                                            <Accordion.Toggle
                                                className="comet-collapse supervision-collapse card-header font-weight-bold"
                                                as={Card.Header}
                                                eventKey="1"
                                                onClick={() => { setAccordion1({ isOpen1: false, isOpen2: !accordion1.isOpen2, isOpen3: false, isOpen4: false }) }}
                                            >
                                                <label>GNSS Externe</label>
                                                <Button
                                                    onClick={() => { setAccordion1({ isOpen1: false, isOpen2: !accordion1.isOpen2, isOpen3: false, isOpen4: false }) }}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={accordion1.isOpen2}
                                                    className="font-weight-bold"
                                                >
                                                    {accordion1.isOpen2 === false ?
                                                        <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                        <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                    }
                                                </Button>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    {/* Fabricant */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Fabricant</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Numéro */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">N°</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Marque */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Marque</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        {/* Plaque d'installation */}
                                        <Card>
                                            <Accordion.Toggle
                                                className="comet-collapse supervision-collapse card-header font-weight-bold"
                                                as={Card.Header}
                                                eventKey="2"
                                                onClick={() => { setAccordion1({ isOpen1: false, isOpen2: false, isOpen3: !accordion1.isOpen3, isOpen4: false }) }}
                                            >
                                                <label>Plaque d'installation</label>
                                                <Button
                                                    onClick={() => { setAccordion1({ isOpen1: false, isOpen2: false, isOpen3: !accordion1.isOpen3, isOpen4: false }) }}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={accordion1.isOpen3}
                                                    className="font-weight-bold"
                                                >
                                                    {accordion1.isOpen3 === false ?
                                                        <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                        <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                    }
                                                </Button>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="2">
                                                <Card.Body>
                                                    {/* Fabricant */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Fabricant</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Numéro */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">N°</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Marque */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Marque</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        {/* Adaptateur M1N1 */}
                                        <Card>
                                            <Accordion.Toggle
                                                className="comet-collapse supervision-collapse card-header font-weight-bold"
                                                as={Card.Header}
                                                eventKey="3"
                                                onClick={() => { setAccordion1({ isOpen1: false, isOpen2: false, isOpen3: false, isOpen4: !accordion1.isOpen4 }) }}
                                            >
                                                <label>Adaptateur M1N1</label>
                                                <Button
                                                    onClick={() => { setAccordion1({ isOpen1: false, isOpen2: false, isOpen3: false, isOpen4: !accordion1.isOpen4 }) }}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={accordion1.isOpen4}
                                                    className="font-weight-bold"
                                                >
                                                    {accordion1.isOpen4 === false ?
                                                        <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                        <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                    }
                                                </Button>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="3">
                                                <Card.Body>
                                                    {/* Fabricant */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Fabricant</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Numéro */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">N°</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Marque */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Marque</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                    <div className="form-row">
                                        {/* Conformité 1 */}
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                        <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            Conforme
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Refus />
                                                            NON conforme :
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-cell-input">
                                                        <div className="form-input">
                                                            <Field name="marque_vignette" className="form-control input-small" type="text" />
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <SO />
                                                            SO
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* Accordion 2 */}
                                <div className="form-row w-100">
                                    {/* Scellements enregistrés */}
                                    <div className="form-row w-100">
                                        <div className="form-cell-input m-w-100 w-100">
                                            <div className="form-input">
                                                <Field name="anomalies_initiales" className="form-control" disabled value="Scellements présents selon saisie : Vérifier avec ceux programmés dans VU" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="reception-accordion w-100">
                                    <Accordion defaultActiveKey="0">
                                        {/* Capteur */}
                                        <Card>
                                            <Accordion.Toggle
                                                className="comet-collapse supervision-collapse card-header font-weight-bold"
                                                as={Card.Header}
                                                eventKey="0"
                                                onClick={() => { setAccordion2({ isOpen1: !accordion1.isOpen1, isOpen2: false, isOpen3: false, isOpen4: false }) }}
                                            >
                                                <label>Capteur</label>
                                                <Button
                                                    onClick={() => { setAccordion2({ isOpen1: !accordion1.isOpen1, isOpen2: false, isOpen3: false, isOpen4: false }) }}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={accordion2.isOpen1}
                                                    className="font-weight-bold"
                                                >
                                                    {accordion2.isOpen1 === false ?
                                                        <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                        <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                    }
                                                </Button>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    {/* Fabricant */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Fabricant</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Numéro */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">N°</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Marque */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Marque</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        {/* GNSS Externe */}
                                        <Card>
                                            <Accordion.Toggle
                                                className="comet-collapse supervision-collapse card-header font-weight-bold"
                                                as={Card.Header}
                                                eventKey="1"
                                                onClick={() => { setAccordion2({ isOpen1: false, isOpen2: !accordion1.isOpen2, isOpen3: false, isOpen4: false }) }}
                                            >
                                                <label>GNSS Externe</label>
                                                <Button
                                                    onClick={() => { setAccordion2({ isOpen1: false, isOpen2: !accordion1.isOpen2, isOpen3: false, isOpen4: false }) }}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={accordion2.isOpen2}
                                                    className="font-weight-bold"
                                                >
                                                    {accordion2.isOpen2 === false ?
                                                        <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                        <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                    }
                                                </Button>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    {/* Fabricant */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Fabricant</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Numéro */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">N°</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Marque */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Marque</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        {/* Adaptateur M1N1 */}
                                        <Card>
                                            <Accordion.Toggle
                                                className="comet-collapse supervision-collapse card-header font-weight-bold"
                                                as={Card.Header}
                                                eventKey="3"
                                                onClick={() => { setAccordion2({ isOpen1: false, isOpen2: false, isOpen3: false, isOpen4: !accordion1.isOpen4 }) }}
                                            >
                                                <label>Adaptateur M1N1</label>
                                                <Button
                                                    onClick={() => { setAccordion2({ isOpen1: false, isOpen2: false, isOpen3: false, isOpen4: !accordion1.isOpen4 }) }}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={accordion2.isOpen4}
                                                    className="font-weight-bold"
                                                >
                                                    {accordion2.isOpen4 === false ?
                                                        <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                        <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                    }
                                                </Button>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="3">
                                                <Card.Body>
                                                    {/* Fabricant */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Fabricant</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Numéro */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">N°</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Marque */}
                                                    <div className="form-row">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Marque</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="kilometrage" className="form-control input-small" type="text" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                    <div className="form-row">
                                        {/* Conformité 1 */}
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                        <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            Enregistrement OK
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Refus />
                                                            Impossible :
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-cell-input">
                                                        <div className="form-input">
                                                            <Field name="marque_vignette" className="form-control input-small" type="text" />
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <SO />
                                                            SO
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default ScellementGen2