import NavBar from "../../components/NavBar/NavBar"
import { useState } from "react"
import { SaisieContextProvider } from "../../components/Intervention/Saisie/SaisieContext"
import SaisieIntervention from "../../components/Intervention/Saisie/SaisieIntervention"
import PreIntervention from "./components/PreIntervention"

const PreInterventionPage = () => {

    const [showSaisie, setShowSaisie] = useState(false)

    const triggerShowSaisie = () => {
        setShowSaisie(true)
    }

    return (
        <>
            <NavBar />
            <div className="container">
                <div className="intervention mt-section">
                    {showSaisie === false ?
                        <PreIntervention startSaisie={triggerShowSaisie} /> :
                        <SaisieContextProvider>
                            <SaisieIntervention />
                        </SaisieContextProvider>
                    }
                </div>
            </div>
        </>
    )
}

export default PreInterventionPage