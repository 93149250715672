import { useContext } from "react"
import { DataContext } from "../providers/DataContextProvider"
import { DeblocageContext } from "../providers/DeblocageContextProvider"
import { supervisionService } from "../services/supervisionService"
import { ConfirmContext } from "./../providers/ConfirmContextProvider"

const useConfirm = () => {
    const [confirm, setConfirm] = useContext(ConfirmContext)
    const { setCodeGenere } = useContext(DeblocageContext)
    const { selectedIntervention } = useContext(DataContext)

    const isConfirmed = (title, opts) => {
        const promise = new Promise((resolve, reject) => {
            setConfirm({
                title,
                opts,
                isOpen: true,
                proceed: resolve,
                cancel: reject,
            })
        })

        return promise.then(
            () => {
                setConfirm({
                    ...confirm,
                    isOpen: false,
                })
                return true
            },
            () => {
                setConfirm({
                    ...confirm,
                    isOpen: false,
                })
                return false
            }
        )
    }

    const isConfirmedDeblocage = (title, opts) => {
        const promise = new Promise((resolve, reject) => {
            setConfirm({
                title,
                opts,
                isOpen: true,
                proceed: resolve,
                cancel: reject
            })
        })

        return promise.then(
            (value) => {
                setConfirm({
                    ...confirm,
                    isOpen: false,
                })

                const data = {
                    entered_code: value.codeSaisi,
                    activite: value.activite ? value.activite : selectedIntervention.activite,
                    raison_code: value.raison_code,
                    rapport_numero: value.rapport_numero
                }

                return supervisionService.verifyCodeDeblocage(value.codeGenere, data)
                    .then(response => {
                        if (response === true) {
                            setCodeGenere("")
                            return "code_bon"
                        }
                        else {
                            return "code_incorrect"
                        }
                    })
            },
            () => {
                setConfirm({
                    ...confirm,
                    isOpen: false,
                })
                setCodeGenere("")
                return false
            }
        )
    }

    return {
        ...confirm,
        isConfirmed,
        isConfirmedDeblocage
    }
}

export default useConfirm