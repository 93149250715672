import { Field, Form, Formik } from "formik"
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../../res/erreur.svg'
import { ReactComponent as Alerte } from './../../../../../res/alerte.svg'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import { Tabs } from 'antd'
import Visuel from "./Visuel"
import Sanction from "./Sanction"
const { TabPane } = Tabs

const Vu = () => {

    // Style de la table
    const useStyles = makeStyles({
        table: {
            width: "100%",
        },
    })
    const classes = useStyles()

    return (
        <div className="plaque-section">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container etiquette">
                            <div className="reception-accordion">
                                {/* Avant insertion */}
                                <div className="form-row">
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="kilometrage" className="form-control" disabled type="text" value="Avant insertion, changer la Pile." />
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only" />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OUI
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-2"
                                                        className="sr-only" />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        Pas de pile : SO
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Réglage de l'heure */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Réglage de l'heure</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only" />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        Non
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-2"
                                                        className="sr-only" />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Alerte />
                                                        Réglage
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <Field name="kilometrage" className="form-control" type="text" value="Avant insertion, changer la Pile." />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="section-one">
                                    {/* UTC : */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">UTC :</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <Field name="kilometrage" className="form-control" disabled type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Locale : */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Locale :</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <Field name="kilometrage" className="form-control" disabled type="text" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Certificats d'homologation / Version Logiciel */}
                                <div className="form-row">
                                    <div className="form-col">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Certificats d'homologation / Version Logiciel</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only" />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-2"
                                                        className="sr-only"
                                                    />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Alerte />
                                                        AIC1
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <Field name="util_mano" className="form-control" as="select">
                                                    <option value="0">Vers : 3.0 - Aff : 03.00.37 - Imp : 3037 - Certif : e1-84</option>
                                                </Field>
                                            </li>
                                            <li>
                                                <Field name="util_mano" className="form-control" as="select" disabled>
                                                    <option value="0">Aix-en-Provence</option>
                                                </Field>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Stick fabricants */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Stick fabricants</div>
                                        </div>
                                    </div>
                                </div>
                                {/* Liste */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">1 :</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="kilometrage" className="form-control" disabled type="text" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">2 :</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="kilometrage" className="form-control" disabled type="text" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">3 :</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="kilometrage" className="form-control" disabled type="text" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">4 :</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="kilometrage" className="form-control" disabled type="text" />
                                        </div>
                                    </div>
                                </div>
                                {/* Rappel */}
                                <div className="form-row">
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="kilometrage" className="form-control" disabled type="text" value="A rouge : Refus d'IP immédiat." />
                                        </div>
                                    </div>
                                </div>
                                {/* Activation (Block 1) */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Activation (Block 1)</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="kilometrage" className="form-control" type="text" />
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <button type="button" className="cbtn cbtn-success mt-0 mb-2">
                                                Aujourd'hui
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <button type="button" className="cbtn cbtn-success mt-0 mb-2">
                                                = Préc
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <Tabs defaultActiveKey={1} tabPosition={'top'} style={{ height: 220, width: '100%' }} /*onChange={handleChangeTab}*/ >
                <TabPane tab={<div className={"tab-title"}>Visuel</div>} key={1}>
                    <Visuel />
                </TabPane>
                <TabPane tab={<div className={"tab-title"}>Sanction</div>} key={2}>
                    <Sanction />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default Vu