import { createContext, useState } from "react"

export const DeblocageContext = createContext()

const DeblocageContextProvider = ({ children }) => {
    const [codeGenere, setCodeGenere] = useState("")

    return (
        <DeblocageContext.Provider value={{ codeGenere, setCodeGenere }}>
            {children}
        </DeblocageContext.Provider>
    )
}

export default DeblocageContextProvider