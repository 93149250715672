import { Formik, Field, Form, ErrorMessage } from 'formik'
import { IoMdClose } from "react-icons/io"
import { ReactComponent as OK } from './../../../res/checked.svg'
import { ReactComponent as Horse } from './../../../assets/horse.svg'
import { ReactComponent as Ecole } from './../../../assets/tableau.svg'
import { ReactComponent as Alerte } from './../../../res/alerte.svg'
import { useRef, useState } from 'react'
import { useEffect } from 'react'
import { supervisionService } from '../../../services/supervisionService'
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import { toast } from 'react-toastify'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import PopupDocument from './PopupDocument'
import { isNullOrUndefined } from '../../../helpers/utils'
import useConfirm from '../../../hooks/useConfirm'

const StationnementPopup = ({ stationnement, newStationnement, adherent, ...props }) => {
    const [saving, setSaving] = useState(false)
    const formikRef = useRef()
    const hide = true
    const [departements, setDepartements] = useState([])
    const [selectedDepartement, setSelectedDepartement] = useState(-1)
    const [proprio, setProprio] = useState(null)
    const [exploitant, setExploitant] = useState(null)
    const [nomProprio, setNomProprio] = useState("")
    const [nomExploitant, setNomExploitant] = useState("")
    const numero_client = parseInt(props.client.id.split("_")[1])
    const [carteStationnement, setCarteStationnement] = useState(null)
    const [file, setFile] = useState(null)
    const [showPopup, setShowPopup] = useState(false)
    const { isConfirmedDeblocage } = useConfirm()

    useEffect(() => {
        supervisionService.getAllDepartement()
            .then(response => setDepartements(response))
            .catch(error => { toast.error(error, { containerId: 'App' }); console.log(error) })

        const loadDocument = (numero_document) => {
            supervisionService.getDocumentListeByNumeroDocument(numero_document)
                .then(response => setCarteStationnement(response))
                .catch(error => process.env.NODE_ENV === "development" && console.log(error))
        }

        if (stationnement && stationnement.numero_document_carte_stationnement) {
            loadDocument(stationnement.numero_document_carte_stationnement)
        }
        else {
            setFile(null)
        }
    }, [])

    useEffect(() => {

        if (stationnement && newStationnement === false) {
            setSelectedDepartement(stationnement.clef_dpt)
        }

        const data = {
            numero_adherent: 0,
            numero_client: parseInt(props.client.id.split("_")[1])
        }
        supervisionService.getClient(data)
            .then(response => {
                setProprio(response);
                setNomProprio(response.nom_client);
                setExploitant(response);
                setNomExploitant(response.nom_client);
                // setSelectedDepartement(parseInt(response.cp_client.substring(0, 2))) 
            })
            .catch(error => { toast.error(error, { containerId: 'App' }); console.log(error) })
    }, [props.client.id])


    const handleChangeCP = e => {
        let cp = e.target.value
        formikRef.current.setFieldValue("cp_stationnement", cp)
        if (cp.length >= 5) {
            supervisionService.getCommuneByCP(cp)
                .then(response => { formikRef.current.setFieldValue("c_stationnement", response); formikRef.current.setFieldValue("clef_dpt", parseInt(cp.substring(0, 2))); setSelectedDepartement(parseInt(cp.substring(0, 2))) })
                .catch(error => { console.log(error); formikRef.current.setFieldValue("c_stationnement", "") })
        }
        else {
            formikRef.current.setFieldValue("c_stationnement", "")
        }
    }

    const handleSaveClient = e => {
        if (formikRef.current) {
            formikRef.current.handleSubmit()
        }
    }

    const handleChangeProprio = (e) => {
        setNomProprio(e.target.value)
        // Recherche du propriétaire
        if (e.target.value && e.target.value.length >= 3) {
            let clients = props.clients
            let newClients = clients.filter(c => (c.nom_client.toLowerCase()).indexOf((e.target.value).toLowerCase()) !== -1)
            setProprio(newClients[0])
        }
    }

    const handleChangeExploitant = (e) => {
        setNomExploitant(e.target.value)
        // Recherche du propriétaire
        if (e.target.value && e.target.value.length >= 3) {
            let clients = props.clients
            let newClients = clients.filter(c => (c.nom_client.toLowerCase()).indexOf((e.target.value).toLowerCase()) !== -1)
            setExploitant(newClients[0])
        }
    }

    const haveErrors = async (data) => {
        let message = ""
        let error = true
        if (data.clef_dpt === -1) {
            message = "Vous devez identifier le département du stationnement."
        }
        else if (data.cp_stationnement.length !== 5) {
            message = "Vous devez indiquer un code postal valide."
        }
        else if (parseInt(data.cp_stationnement.substring(0, 2)) !== parseInt(data.clef_dpt)) {
            message = "Incohérence entre CP et département."
        }
        else if (data.c_stationnement === "") {
            message = "Vous devez indiquer la commune du stationnement."
        }
        else if (data.type_de_stationnement !== 0 && data.type_de_stationnement !== 1 && data.type_de_stationnement !== 2) {
            message = "Vous devez indiquer le type de stationnement."
        }
        else if (data.type_de_stationnement === 0 && data.numero_stationnement === "") {
            message = "Vous devez indiquer le numéro de stationnement."
        }
        else if (newStationnement === false && data.etat_stationnement === 0) {
            if (data.numero_stationnement !== stationnement.numero_stationnement && stationnement.numero_stationnement !== "-1") {
                toast.error("Changement de numéro de stationnement", { autoClose: 5000, containerId: "App" })
                let show_modal_deblocage = true
                while (show_modal_deblocage) {
                    try {
                        let confirmCodeDeblocage = await isConfirmedDeblocage("Code de déblocage", { message: "Changement numéro de stationnement : " + data.numero_stationnement, option: 2, activite: 1 })

                        if (confirmCodeDeblocage === "code_bon") {
                            show_modal_deblocage = false
                            error = false
                        }
                        else if (confirmCodeDeblocage === "code_incorrect") {
                            toast.error("Code de déblocage erroné.", { autoClose: 5000, containerId: "App" })
                        }
                        else {
                            toast.error("Arrêt...", { autoClose: 5000, containerId: "App" })
                            message = "Arrêt..."
                            show_modal_deblocage = false
                            return true
                        }
                    } catch (err) {
                        toast.error("Erreur de déblocage.", { autoClose: 5000, containerId: "App" });
                        show_modal_deblocage = false
                    }
                }
            }
            if (data.clef_dpt !== stationnement.clef_dpt) {
                toast.error("Changement de département de stationnement", { autoClose: 5000, containerId: "App" })
                let show_modal_deblocage = true
                while (show_modal_deblocage) {
                    try {
                        let departement = departements.find(d => d.clef_dpt === data.clef_dpt)
                        let confirmCodeDeblocage = await isConfirmedDeblocage("Code de déblocage", { message: "Changement de département de stationnement : " + departement.numero_dpt + " (" + departement.nom_dpt + ")", option: 2, activite: 1 })

                        if (confirmCodeDeblocage === "code_bon") {
                            show_modal_deblocage = false
                            error = false
                        }
                        else if (confirmCodeDeblocage === "code_incorrect") {
                            toast.error("Code de déblocage erroné.", { autoClose: 5000, containerId: "App" })
                        }
                        else {
                            toast.error("Arrêt...", { autoClose: 5000, containerId: "App" })
                            message = "Arrêt..."
                            show_modal_deblocage = false
                            return true
                        }
                    } catch (err) {
                        toast.error("Erreur de déblocage.", { autoClose: 5000, containerId: "App" });
                        show_modal_deblocage = false;
                    }
                }
            }
            if (data.c_stationnement !== stationnement.c_stationnement) {
                toast.error("Changement de ville de stationnement", { autoClose: 5000, containerId: "App" })
                let show_modal_deblocage = true
                while (show_modal_deblocage) {
                    try {
                        let confirmCodeDeblocage = await isConfirmedDeblocage("Code de déblocage", { message: "Changement de ville de stationnement : " + data.c_stationnement, option: 2, activite: 1 })

                        if (confirmCodeDeblocage === "code_bon") {
                            show_modal_deblocage = false
                            error = false
                        }
                        else if (confirmCodeDeblocage === "code_incorrect") {
                            toast.error("Code de déblocage erroné.", { autoClose: 5000, containerId: "App" })
                        }
                        else {
                            toast.error("Arrêt...", { autoClose: 5000, containerId: "App" })
                            message = "Arrêt..."
                            show_modal_deblocage = false
                            return true
                        }
                    } catch (err) {
                        toast.error("Erreur de déblocage.", { autoClose: 5000, containerId: "App" });
                        show_modal_deblocage = false
                    }
                }
            }
        }
        else {
            error = false
        }

        return { error: error, message: message }
    }

    const saveStationnement = async (aRequest, sRequest) => {
        const response = await supervisionService.checkStationnementAvailability(aRequest)
        if (response.isAvailable === true) {
            const stationnement = await supervisionService.saveStationnement(sRequest)
            setSaving(false)
            props.close(stationnement)
        }
        else {
            toast.error(response.message)
            setSaving(false)
        }
    }

    const updateStationnement = async (aRequest, sRequest) => {
        const stationnement = await supervisionService.updateStationnement(sRequest)
        setSaving(false)
        props.close(stationnement)
    }

    const handleIdemClient = (e) => {
        setSelectedDepartement(parseInt(props.clientBrut.cp_client.substring(0, 2)))
        formikRef.current.setFieldValue("cp_stationnement", props.clientBrut.cp_client)
        if (props.clientBrut.cp_client.length >= 5) {
            supervisionService.getCommuneByCP(props.clientBrut.cp_client)
                .then(response => { formikRef.current.setFieldValue("c_stationnement", response); formikRef.current.setFieldValue("clef_dpt", parseInt(props.clientBrut.cp_client.substring(0, 2))); setSelectedDepartement(parseInt(props.clientBrut.cp_client.substring(0, 2))) })
                .catch(error => { console.log(error); formikRef.current.setFieldValue("c_stationnement", "") })
        }
        else {
            formikRef.current.setFieldValue("c_stationnement", "")
        }
    }

    const handleEquals75 = (e) => {
        var cp = "75000"
        formikRef.current.setFieldValue("clef_dpt", 75)
        formikRef.current.setFieldValue("cp_stationnement", "75000")
        if (props.clientBrut.cp_client.length >= 5) {
            supervisionService.getCommuneByCP(cp)
                .then(response => { formikRef.current.setFieldValue("c_stationnement", response); setSelectedDepartement(75) })
                .catch(error => { console.log(error); formikRef.current.setFieldValue("c_stationnement", "") })
        }
        else {
            formikRef.current.setFieldValue("c_stationnement", "")
        }
    }

    const togglePopUp = () => {
        setShowPopup(!showPopup)
    }

    return (
        <div className="popup-cover">
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        {props.title}
                    </h3>
                    <div className="popup-close-button" onClick={e => props.close(null)}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-body pt-0 popup-liaison-body">
                    <div className="form-saisie-container">
                        <Formik
                            innerRef={formikRef}
                            enableReinitialize
                            initialValues={{
                                numero_client: !newStationnement ? stationnement.numero_client : numero_client,
                                etat_stationnement: !newStationnement ? (isNullOrUndefined(stationnement.date_fin) ? 0 : 1) : 0,
                                couleur_lumineux: !newStationnement ? stationnement.couleur_lumineux : "",
                                derniere_vp: !newStationnement ? stationnement.derniere_vp : "",
                                clef_dpt: !newStationnement ? stationnement.clef_dpt : -1,
                                cp_stationnement: !newStationnement ? stationnement.cp : "",
                                c_stationnement: !newStationnement ? stationnement.ville : "",
                                type_de_stationnement: !newStationnement ? stationnement.type_de_stationnement : 0,
                                numero_stationnement: !newStationnement ? stationnement.numero_stationnement : "",
                                recherche_proprio: !newStationnement ? stationnement.recherche_proprio : "",
                                proprio_licence: !newStationnement ? stationnement.proprio_licence : numero_client,
                                recherche_exploitant: !newStationnement ? stationnement.recherche_exploitant : "",
                                exploitant_licence: !newStationnement ? stationnement.exploitant_licence : numero_client,
                            }}
                            validate={values => {
                                const errors = {}

                                if (values.clef_dpt === "") {
                                    errors.clef_dpt = "Vous devez identifier le département du stationnement."
                                }
                                if (values.cp_stationnement === "") {
                                    errors.cp_stationnement = "Vous devez indiquer le code postal du stationnement."
                                }
                                if (values.cp_stationnement.length !== 5) {
                                    errors.cp_stationnement = "Le format du code postal saisi est incorrect."
                                }
                                if (values.c_stationnement === "") {
                                    errors.c_stationnement = "Vous devez indiquer la commune du stationnement."
                                }

                                return errors
                            }}
                            onSubmit={async (values, errors) => {
                                setSaving(true)
                                let data = values
                                data.numero_adherent = adherent.numero_adherent
                                const isError = await haveErrors(data)
                                if (isError.error) {
                                    toast.error(isError.message, { containerId: 'App' })
                                    setSaving(false)
                                    return
                                }
                                else {
                                    const aResquest = {
                                        numero_adherent: adherent.numero_adherent,
                                        ville: values.c_stationnement,
                                        numero_stationnement: values.numero_stationnement,
                                    }
                                    const sResquest = {
                                        numero_adherent: adherent.numero_adherent,
                                        numero_client: values.numero_client,
                                        Ville: values.c_stationnement,
                                        clef_dpt: values.clef_dpt,
                                        cp: values.cp_stationnement,
                                        numero_stationnement: values.numero_stationnement,
                                        proprio_licence: values.proprio_licence,
                                        exploitant_licence: values.exploitant_licence,
                                        type_de_stationnement: values.type_de_stationnement,
                                        etat_stationnement: values.etat_stationnement,
                                        carteStationnement: carteStationnement
                                    }

                                    // values.carteStationnement = carteStationnement
                                    // console.log(values)

                                    if (newStationnement) {
                                        // Nouveau stationnement
                                        console.log("Nouveau")
                                        console.log("sResquest", sResquest)
                                        saveStationnement(aResquest, sResquest)
                                    }
                                    else {
                                        // Mise à jour stationnement
                                        console.log("Update")
                                        sResquest.clef_stationnement = stationnement.clef_stationnement
                                        updateStationnement(aResquest, sResquest)
                                    }
                                }
                            }}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    {/* Formulaire Confiance */}
                                    <div className="form-group">
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="etat_stationnement"
                                                            id="etat_stationnement-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('etat_stationnement', 0)}
                                                            checked={values.etat_stationnement === 0 ? true : false} />
                                                        <label htmlFor="etat_stationnement-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            Actif
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="etat_stationnement"
                                                            id="etat_stationnement-2"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('etat_stationnement', 1)}
                                                            checked={values.etat_stationnement === 1 ? true : false} />
                                                        <label htmlFor="etat_stationnement-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Alerte />
                                                            Inactif
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Client</label>
                                        {/* Client */}
                                        <div className="form-row">
                                            <div className="col">
                                                <Field disabled name="numero_client" className="form-control" as="select">
                                                    <option value="empty"></option>
                                                    {props.data && props.data.children.map((client, index) =>
                                                        <option key={index} value={client.id.split('_')[1]}>{client.name}</option>
                                                    )}
                                                </Field>
                                                <ErrorMessage name="numero_client" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        {/* Données client */}
                                        <div className="form-row">
                                            <div className="col">
                                                <Field disabled className="form-control" type="text" name="cp" value={props.clientBrut.cp_client} />
                                            </div>
                                            <div className="col">
                                                <Field disabled className="form-control" type="text" name="ville" value={props.clientBrut.ville_client} />
                                            </div>
                                        </div>
                                    </div>
                                    {!hide &&
                                        <>
                                            {/* Couleur lumineux */}
                                            <div div className="form-group">
                                                <label htmlFor="couleur_lumineux">Couleur lumineux</label>
                                                <Field className="form-control" type="text" name="couleur_lumineux" placeholder="Couleur lumineux" />
                                            </div>
                                            {/* Dernière VP */}
                                            <div className="form-group">
                                                <label htmlFor="derniere_vp">Dernière VP</label>
                                                <Field className="form-control" type="text" name="derniere_vp" placeholder="Dernière VP" />
                                            </div>
                                        </>
                                    }
                                    {/* Département */}
                                    <div className="form-group">
                                        <label htmlFor="clef_dpt">Département</label>
                                        <Field name="clef_dpt" className="form-control" as="select" value={selectedDepartement} onChange={e => { setSelectedDepartement(e.target.value); setFieldValue("clef_dpt", e.target.value) }}>
                                            <option value="-1"></option>
                                            {departements && departements.map((departement, index) =>
                                                <option key={index} value={departement.clef_dpt}>{departement.numero_dpt + " (" + departement.nom_dpt + ")"}</option>
                                            )}
                                        </Field>
                                        <ErrorMessage name="clef_dpt" component="div" className="text-danger" />
                                    </div>
                                    {/* CP / Ville */}
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <Field className="form-control" type="text" name="cp_stationnement" placeholder="Code postal" onChange={handleChangeCP} />
                                                <ErrorMessage name="cp_stationnement" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <Field name="c_stationnement" className="form-control" type="text" />
                                                <ErrorMessage name="c_stationnement" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <button type="button" className="cbtn Comet-button-primary mt-0" onClick={handleIdemClient}>
                                                        <span>Idem client</span>
                                                    </button>
                                                    <button type="button" className="cbtn btn-secondary ml-2 mt-0" onClick={handleEquals75}>
                                                        <span>Dpt = 75</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Stationnement */}
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="adresse1_client">Stationnement</label>
                                                <div className="form-cell-input">
                                                    <ul>
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="type_de_stationnement"
                                                                    id="type_de_stationnement-1"
                                                                    className="sr-only"
                                                                    onChange={() => setFieldValue('type_de_stationnement', 0)}
                                                                    checked={values.type_de_stationnement === 0 ? true : false} />
                                                                <label htmlFor="type_de_stationnement-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <OK />
                                                                    Numéro :
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field className="form-control" type="text" name="numero_stationnement" placeholder="" />
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="type_de_stationnement"
                                                                    id="type_de_stationnement-2"
                                                                    className="sr-only"
                                                                    onChange={() => setFieldValue('type_de_stationnement', -1)}
                                                                    checked={values.type_de_stationnement === -1 ? true : false} />
                                                                <label htmlFor="type_de_stationnement-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <Horse />
                                                                    Relais/Mulet
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="type_de_stationnement"
                                                                    id="type_de_stationnement-3"
                                                                    className="sr-only"
                                                                    onChange={() => setFieldValue('type_de_stationnement', -2)}
                                                                    checked={values.type_de_stationnement === -2 ? true : false} />
                                                                <label htmlFor="type_de_stationnement-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <Ecole />
                                                                    Ecole
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Propriétaire */}
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="recherche_proprio">Propriétaire</label>
                                                <Field className="form-control" type="text" name="recherche_proprio" onChange={handleChangeProprio} value={nomProprio} />
                                                <ErrorMessage name="recherche_proprio" component="div" className="text-danger" />
                                            </div>
                                            <div className="form-group">
                                                <Field name="proprio_licence" className="form-control" as="select">
                                                    {proprio &&
                                                        <option value={proprio.numero_client}>{proprio.nom_client + " - " + proprio.cp_client + " " + proprio.ville_client} </option>
                                                    }
                                                </Field>
                                                <ErrorMessage name="proprio_licence" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Exploitant */}
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="recherche_exploitant">Exploitant</label>
                                                <Field className="form-control" type="text" name="recherche_exploitant" onChange={handleChangeExploitant} value={nomExploitant} />
                                                <ErrorMessage name="recherche_exploitant" component="div" className="text-danger" />
                                            </div>
                                            <div className="form-group">
                                                <Field name="exploitant_licence" className="form-control" as="select">
                                                    {exploitant &&
                                                        <option value={exploitant.numero_client}>{exploitant.nom_client + " - " + exploitant.cp_client + " " + exploitant.ville_client} </option>
                                                    }
                                                </Field>
                                                <ErrorMessage name="exploitant_licence" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-container">
                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Documents <span className="details-item">(Carte de Stationnement)</span></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <div className="button-document-container">
                                                                {/* Fichier PDF */}
                                                                <div className="rapport-sup-button">
                                                                    <button type="button" className="btn btn-document" onClick={e => { togglePopUp() }}>
                                                                        <span>{carteStationnement && carteStationnement.nom_fichier !== "" ? carteStationnement.nom_fichier : "Document (vide)"}</span>
                                                                        <i className="fas fa-external-link-alt"></i>
                                                                    </button>
                                                                    {showPopup === true &&
                                                                        <PopupDocument
                                                                            carteStationnement={carteStationnement}
                                                                            setCarteStationnement={setCarteStationnement}
                                                                            client={props.clientBrut}
                                                                            close={togglePopUp}
                                                                            clients={props.clients}
                                                                            adherent={adherent}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div >
                <div className="popup-actions popup-actions-liaisons">
                    <div className="popup-action-open">
                        <button disabled={saving} type="button" className={"upload-file-btn" + (saving ? " disabled" : "")} onClick={handleSaveClient}>
                            <span> {saving ? <UseAnimations strokeColor={'var(--comet-white)'} animation={loading} size={24} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : "Enregistrer"}</span>
                        </button>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default StationnementPopup