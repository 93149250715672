import React, { useEffect, useState } from "react"
import { Table } from 'react-bootstrap'
import { supervisionService } from "../../../../services/supervisionService"
import Checkbox from '@material-ui/core/Checkbox'
import { Formik, Form } from 'formik'
import DetailAutresPopup from "../../../Popup/DetailAutresPopup"
import { useSaisieContext } from "../SaisieContext"
import { dataService } from "../../../../helpers/dataService"
import { toast } from "react-toastify"
import { lockBody, unlockBody } from "../../../../helpers/utils"
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"

const Autres = (props) => {

    const [typeAppareils, setTypeAppareils] = useState(null)
    const [autres] = useState(JSON.parse(localStorage.materiel))
    const [autres_intervention] = useState(JSON.parse(localStorage.intervention))
    const [selectedAppareils, setSelectedAppareils] = useState(new Map())
    const [popupOpened, setPopupOpened] = useState(false)
    const [selectedMateriel, setSelectedMateriel] = useState(null)
    const [selectedMaterielText, setSelectedMaterielText] = useState("")
    const [taximetre] = useState(JSON.parse(localStorage.taximetre))
    const { setFormVerif, setFormMateriel, okList } = useSaisieContext()
    const [errors, setErrors] = useState(null)
    const [loadingButton, setLoadingButton] = useState(false)

    const data = {
        numero_adherent: autres_intervention.numero_adherent,
        numero_client: autres_intervention.numero_client,
        clef_stationnement: autres_intervention.clef_stationnement,
        clef_lien: autres_intervention.clef_lien,
        numero_intervention: autres_intervention.numero_intervention,
        date_intervention: autres_intervention.date_intervention,
    }

    useEffect(() => {

        const getTypeAppareils = async () => {
            await supervisionService.getTypeTousAppareils()
                .then(response => {
                    let _typeAppareils = response.filter(appareil => appareil.numero_type !== 5 && appareil.numero_type !== 8 && appareil.numero_type !== 15)
                    setTypeAppareils(_typeAppareils)
                })
                .catch(error => console.log(error))
        }

        const saveAppareils = () => {
            if (localStorage.liaisons) {
                let liaisons = JSON.parse(localStorage.liaisons)
                for (let i = 0; i < liaisons.length; i++) {
                    setSelectedAppareils(selectedAppareils.set(liaisons[i][0], liaisons[i][1]))
                }
            } else {
                if (autres.length === 1) {
                    autres[0].numero_materiel = 1
                    autres[0]["numero_type_interne"] = 1
                    setSelectedAppareils(selectedAppareils.set(autres[0].numero_type_interne, autres[0]))
                } else {
                    for (let i = 0; i < autres.length; i++) {
                        setSelectedAppareils(selectedAppareils.set(autres[i].numero_type_interne, autres[i]))
                    }
                }
                localStorage.setItem("liaisons", JSON.stringify(Array.from(selectedAppareils.entries())))
            }
        }

        getTypeAppareils()
        saveAppareils()
    }, [selectedAppareils, autres])

    const handleChange = (e, values, setFieldValue) => {
        let numero = parseInt(e.target.value)
        let newSet = new Map(values.set)
        let l_materiel = JSON.parse(localStorage.materiel)
        if (numero !== 1 && !values.set.has(numero)) {
            dataService.getIntervention().subscribe(store => {
                if (store.currentInt) {
                    newSet.set(numero, {
                        cet: "",
                        clef_lien: store.currentInt.clef_lien,
                        clef_stationnement: store.currentInt.clef_stationnement,
                        emplacement1: 0,
                        emplacement2: 0,
                        emplacement3: 0,
                        emplacement4: 0,
                        etat: 0,
                        marque_1: "",
                        marque_1_optionnel: false,
                        marque_2: "",
                        marque_2_optionnel: false,
                        marque_3: "",
                        marque_3_optionnel: false,
                        marque_4: "",
                        marque_4_optionnel: false,
                        marque_5: "",
                        marque_5_optionnel: false,
                        marque_appareil: 0,
                        modele_appareil: 0,
                        numero_adherent: store.currentInt.numero_adherent,
                        numero_cet: 0,
                        numero_client: store.currentInt.numero_client,
                        numero_intervention: store.currentInt.numero_intervention,
                        numero_marque_1: 0,
                        numero_marque_2: 0,
                        numero_marque_3: 0,
                        numero_marque_4: 0,
                        numero_marque_5: 0,
                        numero_type_interne: numero,
                        scelles_fabricant: 0,
                        scelles_installateur: 0,
                        serie_appareil: "",
                    })
                    l_materiel.push(newSet.get(numero))
                }
            })
        } else if (numero !== 1 && values.set.has(numero)) {
            newSet.delete(numero)
            l_materiel = l_materiel.filter(mat => mat.numero_type_interne !== numero)
        }
        setFieldValue("set", newSet)
        localStorage.setItem("liaisons", JSON.stringify(Array.from(newSet.entries())))
        setFormMateriel(l_materiel)
        localStorage.setItem("materiel", JSON.stringify(l_materiel))
    }

    const openDetail = (e, values, numero) => {
        e.preventDefault()
        // Evite d'ouvir le popup lors du click sur le Checkbox
        //console.log(values.set)
        if (e.target.type !== "checkbox" && values.set.has(numero)) {
            setSelectedMateriel(values.set.get(numero))
            setPopupOpened(true)
            lockBody()
        }
    }

    const closeDetail = () => {
        setPopupOpened(false)
        unlockBody()
    }

    const setMaterielText = (appareil) => {
        // Réduit la taille de texte des types des appareils
        if (appareil.numero_type === 3
            || appareil.numero_type === 4) {
            setSelectedMaterielText((appareil.nom_type).split(" ")[0])
        } else {
            setSelectedMaterielText((appareil.nom_type))
        }
    }

    const handleEgalPrecedentAutres = (e, values, setFieldValue, setValues) => {
        setLoadingButton(true)
        supervisionService.egalePrecedentAutres(data)
            .then(response => {
                let appareils = new Map()
                let l_materiels = []
                // Gestion taximetre
                let taximetre = JSON.parse(localStorage.taximetre)
                //let taximetre = values.taximetre
                taximetre.couleur_lumineux = response.couleur_lumineux
                setFieldValue("taximetre", taximetre)
                setFormVerif(taximetre)
                localStorage.setItem("taximetre", JSON.stringify(taximetre))
                // Fin gestion taximètre
                if (response.materiels.length === 1) {
                    response.materiels[0].numero_materiel = 1
                    response.materiels[0]["numero_type_interne"] = 1
                    // Modification des champs
                    let l_selectedAppareil = values.set.get(response.materiels[0].numero_type_interne)
                    /* On remet les données de l'intervention actuelle */
                    l_selectedAppareil.numero_adherent = data.numero_adherent
                    l_selectedAppareil.numero_client = data.numero_client
                    l_selectedAppareil.clef_stationnement = data.clef_stationnement
                    l_selectedAppareil.clef_lien = data.clef_lien
                    l_selectedAppareil.numero_intervention = data.numero_intervention
                    /* Fin */
                    l_selectedAppareil.marque_appareil = response.materiels[0].marque_appareil
                    l_selectedAppareil.modele_appareil = response.materiels[0].modele_appareil
                    l_selectedAppareil.modeleTousAppareils.marque_appareil = response.materiels[0].marque_appareil
                    l_selectedAppareil.modeleTousAppareils.modele_appareil = response.materiels[0].modele_appareil
                    l_selectedAppareil.serie_appareil = response.materiels[0].serie_appareil
                    l_selectedAppareil.modeleTousAppareils.marque_nom_appareil = response.materiels[0].marque_nom_appareil
                    l_selectedAppareil.modeleTousAppareils.modele_nom_appareil = response.materiels[0].modele_nom_appareil
                    l_selectedAppareil.etat = response.materiels[0].etat
                    l_selectedAppareil.emplacement1 = response.materiels[0].emplacement1
                    l_selectedAppareil.emplacement2 = response.materiels[0].emplacement2
                    l_selectedAppareil.emplacement3 = response.materiels[0].emplacement3
                    l_selectedAppareil.emplacement4 = response.materiels[0].emplacement4
                    l_selectedAppareil.modeleTousAppareils.serie_obligatoire = response.materiels[0].serie_obligatoire
                    for (var a = 1; a <= 5; a++) {
                        if (response.materiels[0]["marque_" + a] !== "") {
                            l_selectedAppareil["marque_" + a] = response.materiels[0]["marque_" + a]
                            l_selectedAppareil["numero_marque_" + a] = response.materiels[0]["numero_marque_" + a]
                        }
                    }
                    for (var b = 1; b <= 5; b++) {
                        if (response.materiels[0]["marque_" + a] !== "") {
                            let compare_modele = false
                            for (var j = 1; j <= 5; j++) {
                                if (response.materiels[0]["marque_" + a] === response.materiels[0].modeleTousAppareils["marque_" + a]) {
                                    compare_modele = true
                                }
                                if (compare_modele === false) {
                                    l_selectedAppareil.modeleTousAppareils["marque_" + a] = response.materiels[0].modeleTousAppareils["marque_" + a]
                                }
                            }
                        }
                    }
                    appareils.set(response.materiels[0].numero_type_interne, l_selectedAppareil)
                    l_materiels.push(l_selectedAppareil)
                } else {
                    // C'est pas grave de mettre a peu pres tout dans le egale base pour le moment
                    if (response.materiels[0].numero_type_interne === 1) {
                        //console.log("Taximetre traité")
                        let l_selectedTaximetre = values.set.get(1)
                        let infos = JSON.parse(localStorage.infos)
                        /* On remet les données de l'intervention actuelle */
                        l_selectedTaximetre.numero_adherent = data.numero_adherent
                        l_selectedTaximetre.numero_client = data.numero_client
                        l_selectedTaximetre.clef_stationnement = data.clef_stationnement
                        l_selectedTaximetre.clef_lien = data.clef_lien
                        l_selectedTaximetre.numero_intervention = data.numero_intervention
                        /* Fin */
                        l_selectedTaximetre.marque_appareil = infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_appareil
                        l_selectedTaximetre.modele_appareil = infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.modele_appareil
                        l_selectedTaximetre.modeleTousAppareils.marque_appareil = infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_appareil
                        l_selectedTaximetre.modeleTousAppareils.modele_appareil = infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.modele_appareil
                        l_selectedTaximetre.modeleTousAppareils.marque_nom_appareil = infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_nom_appareil
                        l_selectedTaximetre.modeleTousAppareils.modele_nom_appareil = infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.modele_nom_appareil
                        l_selectedTaximetre.emplacement1 = response.materiels[0].emplacement1
                        l_selectedTaximetre.emplacement2 = response.materiels[0].emplacement2
                        l_selectedTaximetre.emplacement3 = response.materiels[0].emplacement3
                        l_selectedTaximetre.emplacement4 = response.materiels[0].emplacement4
                        for (var n = 1; n <= 5; n++) {
                            if (response.materiels[0]["marque_" + n] !== "") {
                                l_selectedTaximetre["marque_" + n] = response.materiels[0]["marque_" + n]
                                l_selectedTaximetre["numero_marque_" + n] = response.materiels[0]["numero_marque_" + n]
                            }
                        }
                        appareils.set(1, l_selectedTaximetre)
                        l_materiels.push(l_selectedTaximetre)
                    }

                    let l_selectedMateriel = null
                    for (let i = 1; i < response.materiels.length; i++) {
                        l_selectedMateriel = response.materiels[i]
                        /* On remet les données de l'intervention actuelle */
                        l_selectedMateriel.numero_adherent = data.numero_adherent
                        l_selectedMateriel.numero_client = data.numero_client
                        l_selectedMateriel.clef_stationnement = data.clef_stationnement
                        l_selectedMateriel.clef_lien = data.clef_lien
                        l_selectedMateriel.numero_intervention = data.numero_intervention
                        /* Fin */
                        appareils.set(response.materiels[i].numero_type_interne, l_selectedMateriel)
                        l_materiels.push(l_selectedMateriel)
                    }
                }
                localStorage.setItem("liaisons", JSON.stringify(Array.from(appareils.entries())))
                setValues(prev => ({
                    ...prev,
                    set: appareils
                }))
                setFieldValue("set", appareils)
                setFormMateriel(l_materiels)
                setSelectedAppareils(appareils)
                localStorage.setItem("materiel", JSON.stringify(l_materiels))
                toast.success("Dernière intervention trouvée. Modification effectuée.", { containerId: 'App' })
                setLoadingButton(false)
            })
            .catch(error => {
                toast.info(error, { containerId: 'App' })
                setLoadingButton(false)
            })
    }

    return (
        <div className="autres">
            <div className="infos-section-container">
                <h1>Sélection d'appareils</h1>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ set: selectedAppareils, taximetre: taximetre }}
                    validate={(values => {
                        const errors = {}
                        let taximetre = JSON.parse(localStorage.taximetre)
                        // Mise à jour des interventions
                        taximetre.c_autoblocage = values.taximetre.c_autoblocage
                        taximetre.c_ampoules = values.taximetre.c_ampoules
                        taximetre.c_lumineux_amovible = values.taximetre.c_lumineux_amovible
                        taximetre.logiciel_afficheur = values.taximetre.logiciel_afficheur
                        taximetre.c_r40 = values.taximetre.c_r40
                        taximetre.r40 = values.taximetre.r40
                        taximetre.c_r41 = values.taximetre.c_r41
                        taximetre.c_r42 = values.taximetre.c_r42
                        taximetre.c_r45 = values.taximetre.c_r45
                        taximetre.c_r46 = values.taximetre.c_r46
                        taximetre.c_r47 = values.taximetre.c_r47
                        taximetre.c_r30 = values.taximetre.c_r30
                        taximetre.r30 = values.taximetre.r30
                        taximetre.c_r31 = values.taximetre.c_r31
                        taximetre.r31 = values.taximetre.r31
                        taximetre.c_r35 = values.taximetre.c_r35
                        taximetre.r35 = values.taximetre.r35
                        taximetre.c_r38 = values.taximetre.c_r38
                        taximetre.r38 = values.taximetre.r38
                        // Partage des données
                        setFormVerif(taximetre)
                        setErrors({ ...errors })
                        // Mise à jour de localStorage
                        localStorage.setItem("taximetre", JSON.stringify(taximetre))
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            alert(JSON.stringify(values, null, 2))
                            setSubmitting(false)
                        }, 400)
                    }}
                >
                    {({ isSubmitting, values, setFieldValue, setValues }) => (
                        <>
                            <div className="section-precedent">
                                <button type="button" className="cbtn cbtn-success mt-0 mb-2 c-egal-base-right c-absolute" onClick={e => handleEgalPrecedentAutres(e, values, setFieldValue, setValues)}>
                                    {loadingButton ? <UseAnimations strokeColor={'var(--comet-white)'} animation={loading} size={24} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }} /> : "Egal Base"}
                                </button>
                            </div>
                            <div className="appareils-liste">
                                <Form autoComplete="off">
                                    <Table hover size="sm">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Type d'appareils</th>
                                                <th>N° Série</th>
                                                <th>Marque</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {typeAppareils && typeAppareils.map((appareil, index) =>
                                                <tr key={index} onClick={e => { openDetail(e, values, appareil.numero_type); setMaterielText(appareil) }} className={(okList.indexOf("A" + appareil.numero_type) !== -1 ? "isok" : undefined)}>
                                                    <td>
                                                        <Checkbox
                                                            checked={values.set.has(appareil.numero_type)}
                                                            name="appareil"
                                                            onChange={e => handleChange(e, values, setFieldValue)}
                                                            value={appareil.numero_type}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </td>
                                                    <td>{appareil.nom_type}</td>
                                                    <td>{values.set.has(appareil.numero_type) && values.set.get(appareil.numero_type).serie_appareil}</td>
                                                    <td>{values.set.has(appareil.numero_type) && values.set.get(appareil.numero_type).modeleTousAppareils && values.set.get(appareil.numero_type).modeleTousAppareils.marque_nom_appareil}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    {popupOpened && <DetailAutresPopup setValues={setValues} title={selectedMaterielText} close={closeDetail} data={selectedMateriel} setFieldValue={setFieldValue} values={values} setFormMateriel={setFormMateriel} />}
                                </Form>
                            </div>
                        </>
                    )}
                </Formik>

            </div>
        </div>
    )

}

export default Autres