import { useEffect, useRef, useState } from "react"
import i_client from './../../../assets/tree-view/user.png'
import i_stationnement from './../../../assets/tree-view/parking.png'
import i_vehicule from './../../../assets/tree-view/car.png'
import i_taximetre from './../../../assets/tree-view/taximetre.png'
import i_intervention_1 from './../../../assets/tree-view/vignette-rouge-verte.png'
import i_intervention_2 from './../../../assets/tree-view/cle.png'
import i_intervention_3 from './../../../assets/tree-view/vignetteverte.png'
import i_intervention_4 from './../../../assets/tree-view/vignette-rouge-verte-euro.png'
import i_intervention_17 from './../../../assets/tree-view/vignette-rouge-verte-clef.png'
import i_new_user from './../../../assets/tree-view/team.png'
import ClientPopup from "./../Popup/ClientPopup"

import Box from '@mui/material/Box'
import TreeView from '@mui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { supervisionService } from "../../../services/supervisionService"
import TreeNode from './../TreeNode/TreeNode'
import { formatDate, getArbreInterventionDetail, isNullOrUndefined, isSiege, lockBody, loggerDev, unlockBody } from "../../../helpers/utils"
import { StyledTreeItem } from "./../TreeNode/TreeStyle"
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import StationnementPopup from "./../Popup/StationnementPopup"
import VehiculePopup from "./../Popup/VehiculePopup"
import TaximetrePopup from "./../Popup/TaximetrePopup"
import InterventionPopup from "./../Popup/InterventionPopup"
import { useAuth } from "../../../providers/authProvider"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Role } from "../../../helpers/Role"
import activiteList from "../../../constants/activiteList"
import { toast } from "react-toastify"
import Select from 'react-select'
import Skeleton from "react-loading-skeleton"
import { useDataContext } from "../../../providers/DataContextProvider"
import useConfirm from "../../../hooks/useConfirm"
import { dataService } from "../../../helpers/dataService"

const PreIntervention = (props) => {

    let auth = useAuth()
    const userGroup = auth.userGroup
    const userRole = auth.userRole
    const CLIENTNODEID = '1'
    const [expanded, setExpanded] = useState([])
    const [selected, setSelected] = useState("")
    const [loadingNode, setLoadingNode] = useState(false)
    const [currentLoadingNode, setCurrentLoadingNode] = useState(null)
    const [clientPopup, setClientPopup] = useState(false)
    const [stationnementPopup, setStationnementPopup] = useState(false)
    const [vehiculePopup, setVehiculePopup] = useState(false)
    const [taximetrePopup, setTaximetrePopup] = useState(false)
    const [interventionPopup, setInterventionPopup] = useState(false)
    const [activeClient, setActiveClient] = useState(true)
    const [activeStationnement, setActiveStationnement] = useState(false)
    const [activeVehicule, setActiveVehicule] = useState(false)
    const [activeTaximetre, setActiveTaximetre] = useState(false)
    const [activeIntervention, setActiveIntervention] = useState(false)
    const [currentClient, setCurrentClient] = useState(null)
    const [stationnements, setStationnements] = useState(null)
    const [stationnementNode, setStationnementNode] = useState(null)
    const [showSearch, setShowSearch] = useState(false)
    const [showSaisie, setShowSaisie] = useState(false)
    const [currentIntervention, setCurrentIntervention] = useState(null)
    const [currentStationnement, setCurrentStationnement] = useState(null)
    const [currentVehicule, setCurrentVehicule] = useState(null)
    const [currentTaximetre, setCurrentTaximetre] = useState(null)
    const [clientAutorisation, setClientAutorisation] = useState(null)
    const [clientsTree, setClientsTree] = useState(
        new TreeNode({
            id: "1",
            name: "Clients",
            subid: "",
            children: [],
        })
    )
    const [clients, setClients] = useState([])
    const [taximetre, setTaximetre] = useState(null)
    const [adherent, setAdherent] = useState(null)
    const [newIntervention, setNewIntervention] = useState(true)
    const [newClient, setNewClient] = useState(true)
    const [newStationnement, setNewStationnement] = useState(true)
    const [newVehicule, setNewVehicule] = useState(true)
    const [newTaximetre, setNewTaximetre] = useState(true)
    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const formikRef = useRef()
    const [selectedAdherent, setSelectedAdherent] = useState(null)
    const [isLoadingAdherent, setIsLoadingAdherent] = useState(false)
    const [adherents, setadherents] = useState(null)
    const [activites, setactivites] = useState([])
    const [isLoadingClients, setIsLoadingClients] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const { isConfirmed, isConfirmedDeblocage } = useConfirm()
    const { selectedIntervention, setSelectedIntervention, setInterventionInfoGeneral } = useDataContext()

    const handleClick = async (intervention) => {
        try {
            localStorage.removeItem("typeAppareils")
            setSelectedIntervention({ ...selectedIntervention, data: intervention })

            const dataLectureInterventionRequest = {
                intervention: {
                    numero_adherent: intervention.numero_adherent,
                    numero_client: intervention.numero_client,
                    clef_stationnement: intervention.clef_stationnement,
                    clef_lien: intervention.clef_lien,
                    numero_intervention: intervention.numero_intervention,
                },
                stock: {
                    activite: 1,
                },
            }

            if (!isSiege(userRole)) {
                if (isNullOrUndefined(intervention.date_effectuee)) {
                    const confirmed = await isConfirmed("Impartialité (norme 17020:2012)", {
                        message: "Avez-vous avec ce client : Des liens familiaux/sentimentaux/amitié... ?",
                    })

                    if (confirmed) {
                        toast.error(
                            "RISQUE IMPARTIALITE (norme 17020:2012) : \n Vous avez répondu oui à l'une des questions précédentes, contactez le siège pour savoir la marche à suivre. Vous ne pouvez pas faire cette intervention",
                            { containerId: "App" }
                        )
                        return
                    } else {
                        if (process.env.NODE_ENV === "production") {
                            if (adherent.absence_blocage === false) {
                                const response = await supervisionService.checkVignetteStockIntervention(dataLectureInterventionRequest)

                                if (response.code !== 3) {
                                    toast.error(response.message, { containerId: "App" })
                                    return
                                }
                            }
                        }

                        const interventionInfo = await supervisionService.lectureIntervention(dataLectureInterventionRequest.intervention)
                        setInterventionInfoGeneral(interventionInfo)

                        await dataService.sendIntervention(intervention)
                        try {
                            localStorage.setItem("intervention", JSON.stringify(intervention))
                            props.startSaisie()
                        } catch (error) {
                            console.log(error)
                        }
                    }
                } else if (formatDate(intervention.date_effectuee) !== formatDate(Date.now()) && new Date(intervention.date_effectuee) > new Date("01/01/1900")) {
                    if (process.env.NODE_ENV !== "development") {
                        const confirmEditIntervention = await isConfirmed("Modification Intervention", {
                            message: "Vous souhaitez modifier les données, celle-ci a été déjà terminée depuis plus de 24h donc vous devez appeler le siège pour un code de déblocage. \n Souhaitez vous continuer ?",
                        })

                        if (confirmEditIntervention) {
                            let confirmCodeDeblocage = null
                            let show_modal_deblocage = true

                            while (show_modal_deblocage) {
                                confirmCodeDeblocage = await isConfirmedDeblocage("Code de déblocage", {
                                    message: "Modification d'une intervention de plus de 24h",
                                    rapport_numero: intervention.numero_oi,
                                    option: 2,
                                })

                                if (confirmCodeDeblocage === "code_bon") {
                                    show_modal_deblocage = false
                                    const interventionInfo = await supervisionService.lectureIntervention(dataLectureInterventionRequest.intervention)
                                    setInterventionInfoGeneral(interventionInfo)

                                    await dataService.sendIntervention(intervention)
                                    localStorage.setItem("intervention", JSON.stringify(intervention))
                                    props.startSaisie()
                                } else if (confirmCodeDeblocage === "code_incorrect") {
                                    toast.error("Code de déblocage erroné.", { autoClose: 5000 })
                                } else {
                                    toast.error("Arrêt... Modification impossible.", { autoClose: 5000 })
                                    show_modal_deblocage = false
                                }
                            }
                        }
                        return
                    } else {
                        const interventionInfo = await supervisionService.lectureIntervention(dataLectureInterventionRequest.intervention)
                        setInterventionInfoGeneral(interventionInfo)

                        await dataService.sendIntervention(intervention)
                        localStorage.setItem("intervention", JSON.stringify(intervention))
                        props.startSaisie()
                    }
                }
                else {
                    const interventionInfo = await supervisionService.lectureIntervention(dataLectureInterventionRequest.intervention)
                    setInterventionInfoGeneral(interventionInfo)

                    await dataService.sendIntervention(intervention)
                    localStorage.setItem("intervention", JSON.stringify(intervention))
                    props.startSaisie()
                }
            } else {
                const interventionInfo = await supervisionService.lectureIntervention(dataLectureInterventionRequest.intervention)
                setInterventionInfoGeneral(interventionInfo)

                localStorage.removeItem("arretes")
                localStorage.removeItem("doc_arrete")
                localStorage.removeItem("doc_dpt")
                localStorage.removeItem("instruments_etalonnage")
                localStorage.removeItem("time")

                const arretesResponse = await supervisionService.getAllArreteNationalDpt(intervention.stationnement.clef_dpt)
                localStorage.setItem("arretes", JSON.stringify(arretesResponse))

                await dataService.sendIntervention(intervention)
                localStorage.setItem("intervention", JSON.stringify(intervention))
                props.startSaisie()
            }

        } catch (error) {
            console.error(error)
            // Gérer les erreurs selon vos besoins
        } finally {
            // props.startSaisie()
        }

    }

    useEffect(() => {
        getAllAdherent()
    }, [])

    useEffect(() => {
        if (selected.split("_")[0] === "c") {
            setActiveStationnement(true)
            setActiveTaximetre(false)
            setActiveVehicule(false)
            setActiveIntervention(false)
            setCurrentClient(clientsTree.search(selected))
        }
        else if (selected.split("_")[0] === "s") {
            setActiveVehicule(true)
            setActiveStationnement(false)
            setActiveTaximetre(false)
            setActiveIntervention(false)
            setCurrentClient(clientsTree.search(selected))
        }
        else if (selected.split("_")[0] === "v") {
            setActiveTaximetre(true)
            setActiveStationnement(false)
            setActiveVehicule(false)
            setActiveIntervention(false)
            setCurrentClient(clientsTree.search(selected))
        }
        else if (selected.split("_")[0] === "t") {
            setActiveIntervention(true)
            setActiveTaximetre(false)
            setActiveStationnement(false)
            setActiveVehicule(false)
            setCurrentClient(clientsTree.search(selected))
        }
        else if (selected.split("_")[0] === "i") {
            setActiveIntervention(false)
            setActiveTaximetre(false)
            setActiveStationnement(false)
            setActiveVehicule(false)
            setCurrentClient(clientsTree.search(selected))
        }
        else {
            setActiveStationnement(false)
            setActiveVehicule(false)
            setActiveTaximetre(false)
            setActiveIntervention(false)
            setCurrentClient(null)
        }
    }, [selected])

    async function getAllAdherent() {
        setIsLoadingAdherent(true)
        await supervisionService.getAllAdherent()
            .then(
                response => {
                    let data = response.map((adherent) => ({ ...adherent, value: adherent.numero_adherent, label: adherent.identification_adherent + " (" + adherent.nom_adherent + ")" }))
                    setadherents(data)
                    if (userRole === Role.Adherent) {
                        const numero = data[0].numero_adherent
                        setSelectedAdherent(data[0])
                        updateActivites(data, numero)
                    }
                    setIsLoadingAdherent(false)
                }
            )
            .catch(error => setIsLoadingAdherent(false))
    }

    const updateActivites = (adherents, numero) => {
        // Réinitialise la liste à chaque mise à jour
        setactivites([])
        // Récupère les infos d'un adhérent avec le numéro donné
        // Ici on a besoin des infos des activités de l'adherent
        let adherent = adherents.filter(adherent => adherent.numero_adherent === numero)[0]
        if (adherent.numero_adherent === 0) {
            var activites = activiteList.filter(activite => {
                return activite.numero !== 0
            })
            activites.forEach(activite => {
                setactivites(prev =>
                    [...prev, activite]
                )
            })
        } else {
            if (adherent.tax === true) {
                setactivites(prev =>
                    [...prev, activiteList.filter(activite => {
                        return activite.numero === 1
                    })[0]]
                )
            }
            if (adherent.gaz === true && adherent.opa === true) {
                setactivites(prev =>
                    [...prev, activiteList.filter(activite => {
                        return activite.numero === 2
                    })[0]]
                )
            }
            if (adherent.tco === true) {
                setactivites(prev =>
                    [...prev, activiteList.filter(activite => {
                        return activite.numero === 4
                    })[0]]
                )
            }
            if (adherent.ead === true) {
                setactivites(prev =>
                    [...prev, activiteList.filter(activite => {
                        return activite.numero === 5
                    })[0]]
                )
            }
            if (adherent.aut === true) {
                setactivites(prev =>
                    [...prev, activiteList.filter(activite => {
                        return activite.numero === 6
                    })[0]]
                )
            }
        }
    }

    const filterOptions = (candidate, input) => {
        if (input) {
            return candidate.label.includes(input)
        }
        return true
    }

    const getClientForTree = async (data, nodeId) => {
        await supervisionService.getClients(data)
            .then(response => {
                let client = {}
                let _clients = response.map((c, n) => {
                    client = {
                        id: "c_" + c.numero_client,
                        name: c.nom_client + " (" + c.ville_client + ")",
                        loading: true,
                        children: [],
                    }
                    return client
                })
                setClientsTree(clientsTree.addChildren(_clients, nodeId))
                // setInitialClientsTree(clientsTree.addChildren(_clients, nodeId))
                setClients(response)
                expand(nodeId)
            })
            .catch(error => console.log(error))
            .finally(() => {
                setSelected(nodeId)
                setLoadingNode(false)
            })
    }

    const getStationnementsForTree = (data, nodeId) => {
        supervisionService.getStationnements(data)
            .then(response => {
                setStationnements(response)
                let stationnement = {}
                let stationnements = response.map((s, n) => {
                    stationnement = {
                        id: "s_" + data.client + "_" + s.clef_stationnement,
                        name: "Stat. n° : " + s.numero_stationnement + " - " + s.ville + " (" + s.cp + ")",
                        subid: s.clef_dpt,
                        loading: true,
                        children: [],
                    }
                    return stationnement
                })
                setClientsTree(clientsTree.addChildren(stationnements, nodeId))
                expand(nodeId)
                if (response === null) {
                    unexpand(nodeId)
                }
            })
            .catch(error => console.log(error))
            .finally(() => {
                setSelected(nodeId)
                setLoadingNode(false)
            })
    }

    const getVehiculeForTree = (data, nodeId) => {
        supervisionService.getVehiculeStationnement(data)
            .then(response => {
                let vehicule = new TreeNode({
                    id: "v_" + data.client + "_" + data.stationnement + "_" + response.numero_vehicule,
                    name: response.marque_nom_vehicule + " " + response.modele_nom_vehicule + " (" + response.immatriculation_vehicule + ")",
                    loading: true,
                    subid: response.clef_lien,
                    children: [],
                })
                setClientsTree(clientsTree.addChildren(vehicule, nodeId))
                expand(nodeId)
                if (response === null) {
                    unexpand(nodeId)
                }
            })
            .catch(error => console.log(error))
            .finally(() => {
                setSelected(nodeId)
                setLoadingNode(false)
            })
    }

    const getTaximetreForTree = (data, nodeId) => {
        supervisionService.getArbreTaximetre(data)
            .then(async response => {
                var afficheur = null
                if (response.taximetre.modeleTousAppareils.afficheur === true && response.taximetre.modele_afficheur !== null) {
                    afficheur = await supervisionService.getModeleTousAppareils(response.taximetre.marque_taximetre, response.taximetre.modele_afficheur)
                }
                setTaximetre(response.taximetre)
                let taximetre = {
                    id: "t_" + data.client + "_" + data.stationnement + "_" + data.vehicule + "_" + response.clef_lien,
                    name: response.taximetre.modeleTousAppareils.marque_nom_appareil + " " + response.taximetre.modeleTousAppareils.modele_nom_appareil + " " + response.taximetre.numero_serie_taximetre + (response.taximetre.numero_serie_afficheur ? (" / " + (afficheur ? afficheur.modele_nom_appareil : "") + " " + response.taximetre.numero_serie_afficheur) : "") + "",
                    loading: true,
                    children: [],
                    subid: response
                }
                setClientsTree(clientsTree.addChildren(taximetre, nodeId))
                expand(nodeId)
                if (response === null) {
                    unexpand(nodeId)
                }
            })
            .catch(error => console.log(error))
            .finally(() => {
                setSelected(nodeId)
                setLoadingNode(false)
            })
    }

    const getInterventionForTree = (data, nodeId) => {
        supervisionService.getArbreInterventions(data)
            .then(response => {
                let intervention = {}
                let interventions = response.map((i, n) => {
                    intervention = {
                        id: "i_" + data.client + "_" + data.stationnement + "_" + data.lien + "_" + i.numero_intervention,
                        name: (i.date_effectuee ? formatDate(i.date_effectuee) : "Prévue le : " + formatDate(i.date_intervention)) + " - " + getArbreInterventionDetail(i).text,
                        loading: false,
                        img: getArbreInterventionDetail(i).image,
                        subid: i.date_intervention,
                        ok: i.date_effectuee ? true : false
                    }
                    return intervention
                })
                setClientsTree(clientsTree.addChildren(interventions, nodeId))
                expand(nodeId)
                if (response === null) {
                    unexpand(nodeId)
                }
            })
            .catch(error => console.log(error))
            .finally(() => {
                setSelected(nodeId)
                setLoadingNode(false)
            })
    }

    const onNodeSelect = async (event, nodeId) => {
        // Chargement des données
        const node = clientsTree.search(nodeId)
        if (expanded.includes(nodeId)) {
            unexpand(nodeId)
            setSelected(nodeId)
            if (node.id.startsWith("t_")) {
                setTaximetre(node.subid.taximetre)
            }
            return
        }
        if (node.children.length !== 0 && !expanded.includes(nodeId)) {
            expand(nodeId)
            setSelected(nodeId)
            if (node.id.startsWith("t_")) {
                setTaximetre(node.subid.taximetre)
                const data = {
                    adherent: adherent.numero_adherent,
                    client: parseInt(node.id.split("_")[1]),
                    stationnement: parseInt(node.id.split("_")[2]),
                    lien: parseInt(node.id.split("_")[4]),
                }
                getInterventionForTree(data, nodeId)

                supervisionService.getTaximetre(data)
                    .then(response => {
                        setCurrentTaximetre(response)
                    })
            }
            return
        }
        if (node && !node.children.length) {
            setLoadingNode(true)
            setCurrentLoadingNode(nodeId)
            // Clique sur clients
            if (node.name === "Clients") {
                const data = {
                    addherent: adherent.numero_adherent,
                    activite: 1,
                }
                getClientForTree(data, nodeId)
            }
            // On récupère la liste des stationnements
            else if (node.id.startsWith("c_")) {
                const data = {
                    adherent: adherent.numero_adherent,
                    client: parseInt(node.id.split("_")[1])
                }
                getStationnementsForTree(data, nodeId)
            }
            else if (node.id.startsWith("s_")) {
                const data = {
                    adherent: adherent.numero_adherent,
                    client: parseInt(node.id.split("_")[1]),
                    stationnement: parseInt(node.id.split("_")[2]),
                }
                getVehiculeForTree(data, nodeId)
                // Prepare la saisie
                const getStationnementRequest = {
                    adherent: adherent.numero_adherent,
                    client: parseInt(node.id.split("_")[1]),
                    stationnement: parseInt(node.id.split("_")[2]),
                }
                supervisionService.getStationnement(getStationnementRequest)
                    .then(response => {
                        setCurrentStationnement(response)
                    })
            }
            else if (node.id.startsWith("v_")) {
                const data = {
                    adherent: adherent.numero_adherent,
                    client: parseInt(node.id.split("_")[1]),
                    stationnement: parseInt(node.id.split("_")[2]),
                    vehicule: parseInt(node.id.split("_")[3]),
                }
                getTaximetreForTree(data, nodeId)
                // Prepare la saisie
                const getVehiculeRequest = {
                    adherent: adherent.numero_adherent,
                    client: parseInt(node.id.split("_")[1]),
                    vehicule: parseInt(node.id.split("_")[3]),
                }
                supervisionService.getVehicule(getVehiculeRequest)
                    .then(response => {
                        setCurrentVehicule(response)
                    })
            }
            else if (node.id.startsWith("t_")) {
                const data = {
                    adherent: adherent.numero_adherent,
                    client: parseInt(node.id.split("_")[1]),
                    stationnement: parseInt(node.id.split("_")[2]),
                    lien: parseInt(node.id.split("_")[4]),
                }
                getInterventionForTree(data, nodeId)

                supervisionService.getTaximetre(data)
                    .then(response => {
                        setCurrentTaximetre(response)
                    })
            }
            else if (node.id.startsWith("i_")) {
                // Prepare la saisie
                const getInterventionRequest = {
                    numero_adherent: adherent.numero_adherent,
                    numero_client: parseInt(node.id.split("_")[1]),
                    clef_stationnement: parseInt(node.id.split("_")[2]),
                    clef_lien: parseInt(node.id.split("_")[3]),
                    numero_intervention: parseInt(node.id.split("_")[4]),
                }

                const request = {
                    numero_adherent: adherent.numero_adherent,
                    numero_client: parseInt(node.id.split("_")[1])
                }

                supervisionService.getClientAutorisation(request)
                    .then(response => setClientAutorisation(response))
                    .catch(error => { setClientAutorisation(null); console.log(error) })

                supervisionService.getIntervention(getInterventionRequest)
                    .then(response => {
                        setCurrentIntervention(response)
                    })
            }
        }
        setSelected(nodeId)
    }

    const createItemsFromTree = (fromTree) => {
        if (fromTree.children.length) {
            return (
                <StyledTreeItem key={fromTree.id} nodeId={fromTree.id} label={createTreeItemData(fromTree)}>
                    {fromTree.children.length > 0 &&
                        fromTree.children.map((child) => createItemsFromTree(child))}
                </StyledTreeItem>
            )
        }
        return (
            <StyledTreeItem className={fromTree.id.startsWith("i_") ? (fromTree.ok === true ? "tree-group-done" : "tree-group-not-done") : ""} key={fromTree.id} nodeId={fromTree.id} label={createTreeItemData(fromTree)} />
        )
    }

    const createTreeItemData = (fromTree) => {
        return (
            fromTree.id ?
                fromTree.id.startsWith("c_") ?
                    <div className="tree-group">
                        <div className="tree-image">
                            {loadingNode && currentLoadingNode === fromTree.id ? <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : <img src={i_new_user} alt={"Client"} />}
                        </div>
                        <div className="tree-text">
                            {fromTree.name}
                        </div>
                    </div> :
                    fromTree.id.startsWith("s_") ?
                        <div className="tree-group">
                            <div className="tree-image">
                                {loadingNode && currentLoadingNode === fromTree.id ? <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : <img src={i_stationnement} alt={"Stationnement"} />}
                            </div>
                            <div className="tree-text-stat">
                                {fromTree.name}
                            </div>
                        </div> :
                        fromTree.id.startsWith("v_") ?
                            <div className="tree-group">
                                <div className="tree-image">
                                    {loadingNode && currentLoadingNode === fromTree.id ? <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : <img src={i_vehicule} alt={"Vehicule"} />}
                                </div>
                                <div className="tree-text-car">
                                    {fromTree.name}
                                </div>
                            </div> :
                            fromTree.id.startsWith("t_") ?
                                <div className="tree-group">
                                    <div className="tree-image">
                                        {loadingNode && currentLoadingNode === fromTree.id ? <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : <img src={i_taximetre} alt={"Taximètre"} />}
                                    </div>
                                    <div className="tree-text-tax">
                                        {fromTree.name}
                                    </div>
                                </div> :
                                fromTree.id.startsWith("i_") ?
                                    <div className="tree-group">
                                        <div className="tree-image">
                                            <img src={fromTree.img === 13 ? i_intervention_1 : fromTree.img === 11 ? i_intervention_3 : fromTree.img === 18 ? i_intervention_4 : fromTree.img === 17 ? i_intervention_17 : i_intervention_2} alt={"Intervention"} />
                                        </div>
                                        <div className="tree-text-tax">
                                            {fromTree.name}
                                        </div>
                                    </div> : fromTree.name : fromTree.name
        )
    }

    const unexpand = (nodeId) => {
        setExpanded(oldExpanded => {
            return oldExpanded.filter(element => element !== nodeId)
        })
    }

    const expand = (nodeId) => {
        if (expanded.indexOf(nodeId) === -1) {
            setExpanded([...expanded, nodeId])
        }
    }

    const openPopup = (value) => {
        if (value === 'client') {
            setClientPopup(true)
        } else if (value === 'stationnement') {
            setStationnementPopup(true)
        } else if (value === 'vehicule') {
            setVehiculePopup(true)
        } else if (value === 'taximetre') {
            setTaximetrePopup(true)
        } else if (value === 'intervention') {
            const splitedData = selected.split("_")
            const stationnementNode = clientsTree.search("s_" + splitedData[1] + "_" + splitedData[2])
            // const interventionNode = clientsTree.search("i_" + splitedData[1] + "_" + splitedData[2] + "_" + splitedData[3] + "_" + splitedData[4])
            // const interventionData = {
            //     numero_adherent: userGroup,
            //     numero_client: parseInt(splitedData[1]),
            //     clef_stationnement: parseInt(splitedData[2]),
            //     clef_lien: parseInt(splitedData[3]),
            //     numero_intervention: parseInt(splitedData[4]),
            //     date_intervention: interventionNode.subid
            // }
            setStationnementNode(stationnementNode)
            // supervisionService.getIntervention(interventionData)
            //     .then((response) => {
            //         setIntervention(response)
            //     })
            //     .catch((error) => {
            //         loggerDev(error)
            //     })
            // console.log(interventionNode)
            // console.log(selected)
            setInterventionPopup(true)
        }
        lockBody()
    }

    const closeClientPopup = (response = null) => {
        if (!isNullOrUndefined(response)) {
            const data = response.data
            let newClientsTree = clientsTree
            let newClients = clients

            let newClientTree = new TreeNode({
                id: "c_" + data.numero_client,
                name: data.nom_client + " (" + data.ville_client + ")",
                loading: true,
                children: [],
            })


            if (newClient) {
                newClients.push(data)
                setClientsTree(newClientsTree.addChildren(newClientTree, CLIENTNODEID))
                setClients(newClients)
            }
            else {
                const index = newClients.findIndex(obj => obj.numero_client === data.numero_client)
                if (index !== -1) {
                    // Mettre à jour l'objet
                    newClients[index] = { ...newClients[index], ...data }
                }
                setClients(newClients)

                var newClientsTree1 = new TreeNode({
                    id: "1",
                    name: "Clients",
                    subid: "",
                    children: [],
                })
                let _clients = newClients.map((c, n) => {
                    var client = {
                        id: "c_" + c.numero_client,
                        name: c.nom_client + " (" + c.ville_client + ")",
                        loading: true,
                        children: [],
                    }
                    return client
                })

                setClientsTree(newClientsTree1.addChildren(_clients, CLIENTNODEID))
            }
        }
        setClientPopup(false)
        unlockBody()
    }

    const closeStationnementPopup = (response = null) => {
        if (!isNullOrUndefined(response)) {
            let newClientsTree = { ...clientsTree }

            let stationnement = new TreeNode({
                id: "s_" + response.numero_client + "_" + response.clef_stationnement,
                name: "Stat. n° : " + response.numero_stationnement + " - " + response.ville + " (" + response.cp + ")",
                loading: true,
                children: [],
            })

            if (newStationnement) {
                setClientsTree(newClientsTree.addChildren(stationnement, selected))
            }
            else {
                setCurrentStationnement(response)

                let newStationnements = [...stationnements]

                const index = newStationnements.findIndex(obj => obj.clef_stationnement === response.clef_stationnement)
                if (index !== -1) {
                    // Mettre à jour l'objet
                    newStationnements[index] = { ...newStationnements[index], ...response }
                }

                setStationnements(newStationnements)

                const nodeId = "c_" + selected.split("_")[1]
                setClientsTree(newClientsTree.removeNode(stationnement).addNode(stationnement, nodeId))
                expand(nodeId)
                if (response === null) {
                    unexpand(nodeId)
                }
            }
        }
        setStationnementPopup(false)
        unlockBody()
    }

    const closeVehiculePopup = (response = null) => {
        if (!isNullOrUndefined(response)) {
            let newClientsTree = { ...clientsTree }

            let vehicule = {
                id: "v_" + response.numero_client + "_" + currentStationnement.clef_stationnement + "_" + response.numero_vehicule,
                name: response.marque_nom_vehicule + " " + response.modele_nom_vehicule + " (" + response.immatriculation_vehicule + ")",
                loading: true,
                children: [],
                subid: response.clef_lien,
            }

            if (newVehicule) {
                setClientsTree(newClientsTree.addChildren(vehicule, selected))
            }
            else {
                setCurrentVehicule(response)
                const nodeId = "s_" + selected.split("_")[1] + "_" + selected.split("_")[2]
                setClientsTree(newClientsTree.removeNode(vehicule).addNode(vehicule, nodeId))
                setSelected(nodeId)
                unexpand("v_" + response.numero_client + "_" + currentStationnement.clef_stationnement + "_" + response.numero_vehicule)
            }
        }
        setVehiculePopup(false)
        unlockBody()
    }

    const closeInterventionPopup = (response = null) => {
        if (!isNullOrUndefined(response)) {
            let newClientsTree = clientsTree

            let intervention = {
                id: "i_" + response.numero_client + "_" + response.clef_stationnement + "_" + response.clef_lien + "_" + response.numero_intervention,
                name: (response.date_effectuee ? formatDate(response.date_effectuee) : "Prévue le : " + formatDate(response.date_intervention)) + " - " + getArbreInterventionDetail(response).text,
                loading: false,
                img: getArbreInterventionDetail(response).image,
                subid: response.date_intervention,
                children: [],
            }

            if (newIntervention) {
                setClientsTree(newClientsTree.addChildren(intervention, selected))
            }
            else {
                setCurrentIntervention(response)
            }
        }
        setInterventionPopup(false)
        unlockBody()

        expand(selected)
    }

    const closeTaximetrePopup = async (response = null) => {
        if (!isNullOrUndefined(response)) {
            let newClientsTree = clientsTree
            var afficheur = null
            if (response.taximetre.modeleTousAppareils.afficheur === true) {
                afficheur = await supervisionService.getModeleTousAppareils(response.taximetre.marque_taximetre, response.taximetre.modele_afficheur)
            }
            let taximetre = new TreeNode({
                id: "t_" + response.numero_client + "_" + response.clef_stationnement + "_" + response.numero_vehicule + "_" + response.clef_lien,
                name: response.taximetre.marque_nom_taximetre + " " + response.taximetre.modele_nom_taximetre + " " + response.taximetre.numero_serie_taximetre + (response.taximetre.numero_serie_afficheur ? (" / " + afficheur.modele_nom_appareil + " " + response.taximetre.numero_serie_afficheur) : "") + "",
                loading: true,
                children: [],
            })
            setClientsTree(newClientsTree.addChildren(taximetre, selected))

            if (newTaximetre) {
                setClientsTree(newClientsTree.addChildren(taximetre, selected))
            }
            else {
                setCurrentTaximetre(response)
                const nodeId = "v_" + selected.split("_")[1] + "_" + selected.split("_")[2] + "_" + selected.split("_")[3]
                setClientsTree(newClientsTree.removeNode(taximetre).addNode(taximetre, nodeId))
                setSelected(nodeId)
                unexpand("t_" + response.numero_client + "_" + response.clef_stationnement + "_" + response.numero_vehicule + "_" + response.clef_lien)
            }

        }
        setTaximetrePopup(false)
        unlockBody()

        expand(selected)
    }

    const handleChangeSearch = (value) => {
        // Copie en profondeur de l'objet clientsTree
        var newClientsTree = new TreeNode({
            id: "1",
            name: "Clients",
            subid: "",
            children: [],
        })
        var filteredClients = clients.filter(element => element.nom_client.toLowerCase().includes(value.toLowerCase()))
        let _clients = filteredClients.map((c, n) => {
            var client = {
                id: "c_" + c.numero_client,
                name: c.nom_client + " (" + c.ville_client + ")",
                loading: true,
                children: [],
            }
            return client
        })

        setClientsTree(newClientsTree.addChildren(_clients, CLIENTNODEID))

        // const _clientsTree = {
        //     ...clientsTree,
        //     children: initialClientsTree.children.filter(child => child.name.toLowerCase().includes(value.toLowerCase()))
        // }

        // _clientsTree.children.forEach((child) => {
        //     child.root = _clientsTree
        // })

        // console.log("_clientsTree", _clientsTree)

        // // Mise à jour de l'état avec un nouvel objet
        // setClientsTree(_clientsTree)
        // console.log(_clientsTree)
    }

    return (
        <>
            <div>
                <div className="intervention mt-section pt-4 px-1">
                    Preintervention
                </div>
                <div>
                    <Formik
                        enableReinitialize
                        innerRef={formikRef}
                        initialValues={{
                            adherent: selectedAdherent && selectedAdherent.numero_adherent,
                            activite: ''
                        }}
                        validate={(values) => {
                            const errors = {}
                            const texte = "Ce champ est invalide"
                            const rPI = /^[0-9]+$/

                            if (!rPI.test(values.adherent)) {
                                errors.adherent = texte
                            }
                            if (!rPI.test(values.activite)) {
                                errors.activite = texte
                            }

                            return errors
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setIsLoadingClients(true)
                            values.adherent = parseInt(values.adherent)
                            values.activite = parseInt(values.activite)
                            async function execute() {
                                if (values.activite === 1) {
                                    const data = {
                                        adherent: parseInt(values.adherent),
                                        activite: parseInt(values.activite),
                                    }

                                    supervisionService.getAdherent(parseInt(values.adherent))
                                        .then(response => {
                                            setAdherent(response)
                                        })
                                    supervisionService.getClients(data)
                                        .then(response => {
                                            setClients(response)
                                            let client = {}
                                            let _clients = response.map((c, n) => {
                                                client = {
                                                    id: "c_" + c.numero_client,
                                                    name: c.nom_client + " (" + c.ville_client + ")",
                                                    loading: true,
                                                    children: [],
                                                }
                                                return client
                                            })
                                            setClientsTree(clientsTree.addChildren(_clients, CLIENTNODEID))
                                            // setInitialClientsTree(clientsTree.addChildren(_clients, CLIENTNODEID))
                                            expand(CLIENTNODEID)
                                            setSelected(CLIENTNODEID)
                                            setShowSearch(true)
                                        })
                                        .then(() => {
                                            setSubmitted(true)
                                            localStorage.setItem("activite", values.activite)
                                            // setSelectedIntervention({ ...selectedIntervention, activite: values.activite })
                                            setIsLoadingClients(false)
                                            // Affichage des interventions à saisir
                                        })
                                        .catch(error => {
                                            setSubmitted(true)
                                            setIsLoadingClients(false)
                                            toast.error(error, { containerId: 'App' })
                                            console.log(error)
                                        })
                                    // fetchClients(data)
                                    //     .then(response => {
                                    //         setSubmitted(true)
                                    //         localStorage.setItem("activite", values.activite)
                                    //         // setSelectedIntervention({ ...selectedIntervention, activite: values.activite })
                                    //         setIsLoadingClients(false)
                                    //         // Affichage des interventions à saisir
                                    //     })
                                    //     .catch(error => {
                                    //         setSubmitted(true)
                                    //         setIsLoadingClients(false)
                                    //         toast.error(error, { containerId: 'App' })
                                    //         // props.passValuesToParent([]) // Aucune intervention a été trouvée
                                    //     })

                                }
                                // else if (values.activite === 4) {
                                //     await supervisionService.getInterventionsTachy(values)
                                //         .then(response => {
                                //             // response => data et activite et donnees page
                                //             props.passValuesToParent(response)
                                //             setPageCount(response.totalPages)
                                //             setSubmitted(true)
                                //             localStorage.setItem("activite", values.activite)
                                //             setSelectedIntervention({ ...selectedIntervention, activite: values.activite })
                                //             setIsLoadingInterventions(false)
                                //         }) // Affichage des interventions à saisir
                                //         .catch(error => {
                                //             setSubmitted(true)
                                //             setIsLoadingInterventions(false)
                                //             toast.error(error, { containerId: 'App' })
                                //             props.passValuesToParent([]) // Aucune intervention a été trouvée
                                //         })
                                // }
                            }
                            execute()
                        }}
                    >
                        {({ setFieldValue, isSubmitting }) => (
                            <Form>
                                <div className="preintervention-form-container d-flex flex-column flex-sm-row">
                                    <div className="form-group form-group-adherent w-100">
                                        {(!isLoadingAdherent && adherents &&
                                            <Select
                                                placeholder="Adhérent..."
                                                name="adherent"
                                                value={selectedAdherent}
                                                onChange={(values) => { setFieldValue('adherent', values.value); setSelectedAdherent(values); updateActivites(adherents, parseInt(values.value)); }}
                                                options={adherents}
                                                filterOption={filterOptions}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#e7ebf3',
                                                        primary: '#604888',
                                                    },
                                                })}
                                                isDisabled={adherents && adherents.length <= 1 ? true : false}
                                            />
                                        ) ||
                                            <Skeleton height={34} />
                                        }
                                        <ErrorMessage name="adherent" component="div" className="text-danger" />
                                    </div>
                                    <div className="form-group w-100 mx-0 mx-sm-4">
                                        <Field name="activite" as="select" className="form-control">
                                            <option value="empty">Activité...</option>
                                            {activites.map((item, i) =>
                                                <option key={i} value={item.numero}>
                                                    {item.nom}
                                                </option>
                                            )}
                                        </Field>
                                        <ErrorMessage name="activite" component="div" className="text-danger" />
                                    </div>
                                    <div className="previntervention-button-container w-100 w-sm-25">
                                        <button className="btn cbtn cbtn-primary float-right w-100 mt-0" type="submit" disabled={isLoadingClients}>
                                            {isLoadingClients ? <UseAnimations strokeColor={'var(--comet-white)'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : "Valider"}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                {
                    showSearch &&
                    <div className="input-group rounded mb-3 mt-1">
                        <input onChange={e => handleChangeSearch(e.target.value)} type="search" className="form-control rounded" placeholder="Rechercher" aria-label="Rechercher" aria-describedby="search-addon" />
                        <span className="input-group-text border-0" id="search-addon">
                            <i className="fas fa-search"></i>
                        </span>
                    </div>
                }
                {clientsTree && clientsTree.children.length > 0 &&
                    <div>
                        <div className="d-flex flex-column actions-button-arbre">
                            {/* Barre de navigation top */}
                            <div className="saisie-header mb-2 mt-2">
                                <div className="saisie-actions d-flex">
                                    <div className={"saisie-actions-container" + (isOpenMenu ? " open" : "")}>
                                        <div className="btn-group">
                                            <div className="dropdown">
                                                <button className="btn Comet-btn-primary py-2 dropdown-toggle d-flex align-items-center" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-2 dropdown-icon-arbre mr-1">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                    </svg>
                                                    Nouveau
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <button className="dropdown-item" onClick={e => { setNewClient(true); openPopup('client') }} disabled={!activeClient}>Client</button>
                                                    <button className="dropdown-item" onClick={e => { setNewStationnement(true); openPopup('stationnement') }} disabled={!activeStationnement}>Stationnement</button>
                                                    <button className="dropdown-item" onClick={e => { setNewVehicule(true); openPopup('vehicule') }} disabled={!(activeVehicule && clientsTree.search(selected) && clientsTree.search(selected).children.length === 0)}>Véhicule</button>
                                                    <button className="dropdown-item" onClick={e => { setNewTaximetre(true); openPopup('taximetre') }} disabled={!(activeTaximetre && clientsTree.search(selected).children.length === 0)}>Taximètre</button>
                                                    <button className="dropdown-item" onClick={e => { setNewIntervention(true); openPopup('intervention') }} disabled={!activeIntervention}>Intervention</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {selected.includes("i_") &&
                                        <button className="btn comet-btn bg-success mr-2" onClick={() => { setNewIntervention(false); openPopup('intervention') }}>
                                            Ouvrir
                                        </button>
                                    } */}
                                    <button
                                        className="btn comet-btn bg-success mr-2"
                                        onClick={() => {
                                            if (selected.includes("c_")) {
                                                setNewClient(false)
                                                openPopup('client')
                                            }
                                            else if (selected.includes("s_")) {
                                                setNewStationnement(false)
                                                openPopup('stationnement')
                                            }
                                            else if (selected.includes("v_")) {
                                                setNewVehicule(false)
                                                openPopup('vehicule')
                                            }
                                            else if (selected.includes("t_")) {
                                                setNewTaximetre(false)
                                                openPopup('taximetre')
                                            }
                                            else if (selected.includes("i_")) {
                                                setNewIntervention(false)
                                                openPopup('intervention')
                                            }
                                        }}
                                    >
                                        Ouvrir
                                    </button>
                                    {selected.includes("i_") && (currentIntervention?.sign1 || (clientAutorisation && new Date(clientAutorisation) >= new Date())) &&
                                        <button className="btn comet-btn btn-primary" onClick={() => handleClick(currentIntervention)}>
                                            Commencer saisie
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* Popup */}
                        {clientPopup &&
                            <ClientPopup
                                adherent={adherent}
                                title="Nouveau client"
                                new={true}
                                close={closeClientPopup}
                                newClient={newClient}
                                client={currentClient ? clients.filter(c => c.numero_client === parseInt(currentClient.id.split('_')[1]))[0] : null}
                            />
                        }
                        {stationnementPopup &&
                            <StationnementPopup
                                title="Nouveau Stationnement"
                                new={true}
                                close={closeStationnementPopup}
                                data={clientsTree}
                                stationnement={currentStationnement}
                                clients={clients}
                                client={currentClient}
                                clientBrut={clients.filter(c => c.numero_client === parseInt(currentClient.id.split('_')[1]))[0]}
                                adherent={adherent}
                                newStationnement={newStationnement}
                            />
                        }
                        {vehiculePopup &&
                            <VehiculePopup
                                title="Nouveau Véhicule"
                                new={true}
                                close={closeVehiculePopup}
                                data={clientsTree}
                                clients={clients}
                                client={currentClient}
                                selected={selected}
                                newVehicule={newVehicule}
                                vehicule={currentVehicule}
                            />
                        }
                        {taximetrePopup &&
                            <TaximetrePopup
                                title="Nouveau Taximètre"
                                new={true}
                                close={closeTaximetrePopup}
                                data={clientsTree}
                                clients={clients}
                                client={currentClient}
                                selected={selected}
                                newTaximetre={newTaximetre}
                                taximetre={currentTaximetre}
                                adherent={adherent}
                            />
                        }
                        {interventionPopup &&
                            <InterventionPopup
                                title="Intervention"
                                new={true}
                                close={closeInterventionPopup}
                                data={clientsTree}
                                clients={clients}
                                client={currentClient}
                                selected={selected}
                                newIntervention={newIntervention}
                                stationnementNode={stationnementNode}
                                intervention={currentIntervention}
                                taximetre={taximetre}
                                adherent={adherent}
                                clientAutorisation={clientAutorisation}
                                startSaisie={() => handleClick(currentIntervention)}
                            />
                        }
                        {/* Arbre des interventions */}
                        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
                            <TreeView
                                aria-label="controlled"
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                expanded={expanded}
                                selected={selected}
                                onNodeSelect={onNodeSelect}
                            >
                                {clientsTree && clientsTree.children.length < 100 &&
                                    createItemsFromTree(clientsTree)
                                }
                                {clientsTree && clientsTree.children.length > 100 &&
                                    "Veuillez saisir à minima 2 caractères dans la recherche pour voir la liste des clients"
                                }
                            </TreeView>
                        </Box>
                    </div>
                }
            </div>
        </>
    )
}

export default PreIntervention