import React, { useEffect, useState } from 'react'
import { supervisionService } from '../../services/supervisionService'
import { IoMdClose } from "react-icons/io"

const Popup = (props) => {

    const [file, setFile] = useState(null)

    useEffect(() => {
        const loadDocument = () => {
            supervisionService.getDocumentListeByNumeroDocument(props.numero)
                .then(response => setFile(response))
                .catch(error => process.env.NODE_ENV === "development" && console.log(error))
        }
        loadDocument()
    }, [])

    // Ouvre un document de Rapport
    const openPDF = (event, afile) => {

        var byteCharacters = atob(afile.fichier_contenu);
        var byteNumbers = new Array(byteCharacters.length)
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers)
        var file = new Blob([byteArray], { type: 'application/' + afile.extension_fichier.split[1] + ';base64' })
        var fileURL = URL.createObjectURL(file)

        var element = document.createElement('a')
        element.setAttribute('href', fileURL)
        element.setAttribute('download', afile.fichier_original)
        document.body.appendChild(element)
        element.click()
        setTimeout(function () {
            // Necessaire pour Firefox
            window.URL.revokeObjectURL(fileURL)
        }, 100)
        document.body.removeChild(element)

    }

    return (
        <div className="popup">
            <div className="popup-document">
                <div className="popup-header">
                    <h3 className="title">
                        {props.type}
                    </h3>
                    <div className="popup-close-button" onClick={props.close}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-main">
                    <span className="c-mb-2">{props.nom}</span>
                    <span>{props.detail}</span>
                    {file && file.fichier_contenu &&
                        <div className="popup-actions">
                            <div className="popup-action-open">
                                <button type="button" className={"upload-file-btn upload-file-btn-open"} onClick={e => openPDF(e, file)}>
                                    <span>Ouvrir</span>
                                    <i className="fas fa-eye"></i>
                                </button>
                            </div>
                        </div>
                    }
                </div>

            </div>
        </div>
    )

}

export default Popup