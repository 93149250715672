import { BehaviorSubject } from 'rxjs'

const subscriber = new BehaviorSubject(0)

const dataService = {
    sendIntervention: int => subscriber.next({ currentInt: int }),
    clearInterventions: () => subscriber.next(),
    getIntervention: () => subscriber.asObservable()
}

export {
    dataService
}