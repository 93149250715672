import { Tabs } from 'antd'
import Reception from './Reception'
import Scellement from './Scellement'
import Liaison from './Liaison'
import CableReference from './CableReference'
import KITASDirect from './KITASDirect'
import MouleInacessible from './MouleInacessible'
import Liberation from './Liberation'
import { Field, Form, Formik } from 'formik'
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Intervention } from './../../../../../res/alerte.svg'
const { TabPane } = Tabs

const Capteur = () => {

    return (
        <div className="taximetre-section">
            <div className="taximetre-container">
                <Formik
                    enableReinitialize={true}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div className="affichage-form-container">
                                <div className="form-row">
                                    {/* Capteur */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Capteur</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        Existant
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        Non présent
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <Tabs defaultActiveKey={1} tabPosition={'top'} style={{ height: 220, width: '100%' }} /*onChange={handleChangeTab}*/ >
                    <TabPane tab={<div className={"tab-title"}>Réception</div>} key={1}>
                        <Reception />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title"}>Scellement</div>} key={2}>
                        <Scellement />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title"}>Liaison</div>} key={3}>
                        <Liaison />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title"}>Câble référence</div>} key={4}>
                        <CableReference />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title"}>KITASDirect</div>} key={5}>
                        <KITASDirect />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title"}>moulé/inaccessible</div>} key={6}>
                        <MouleInacessible />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title"}>Liberation</div>} key={7}>
                        <Liberation />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default Capteur