import React from "react"
import { AuthConsumer } from "../providers/authProvider"

export const SilentRenew = () => (
    <AuthConsumer>
        {({ signInSilentCallback }) => {
            signInSilentCallback()
            return null
        }}
    </AuthConsumer>
)