import React, { useState } from "react"
import { Tabs } from 'antd'
import "antd/dist/antd.css"
import Affichage from "./Affichage"
import Logiciel from "./Logiciel"
import Indication from "./Indication"
import Etiquette from "./Etiquette"
import Marquage from "./Marquage"
import Position from "./Position"
import Autres from "./Autres"
import { useSaisieContext } from "../SaisieContext"
import { useDataContext } from "../../../../providers/DataContextProvider"

const { TabPane } = Tabs

const Taximetre = (props) => {

    //const [formAffichage, setFormAffichage] = useContext(AffichageContext)
    const { setFormVerif, setFormIntervention, okList, activeKeyTab, handleChangeTab } = useSaisieContext()
    const [errors, setErrors] = useState(null)
    const { interventionInfoGeneral } = useDataContext()

    // Recupere les valeurs du formulaire affichage
    // et sauvegarde sa valeur dans son etat
    const getFormVerif = (childData, errors) => {
        setFormVerif(childData)
        setErrors({ ...errors })
    }

    const getFormVerifAndIntervention = (taximetre, intervention, errors) => {
        setFormVerif(taximetre)
        setFormIntervention(intervention)
        setErrors({ ...errors })
    }

    return (
        <div className="taximetre-section">
            <div className="taximetre-container">
                <Tabs defaultActiveKey={activeKeyTab} activeKey={activeKeyTab} tabPosition={'top'} style={{ height: 220, width: '100%' }} onChange={handleChangeTab} >
                    <TabPane tab={<div className={"tab-title" + (okList.indexOf("Affichage") !== -1 ? " isok" : "")}>Affichage</div>} key={1}>
                        <Affichage passFormToParent={getFormVerif} interventionInfoGeneral={interventionInfoGeneral} />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title" + (okList.indexOf("Logiciel") !== -1 ? " isok" : "")}>Logiciel</div>} key={2}>
                        <Logiciel passFormToParent={getFormVerifAndIntervention} interventionInfoGeneral={interventionInfoGeneral} />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title" + (okList.indexOf("Indication") !== -1 ? " isok" : "")}>Indication</div>} key={3}>
                        <Indication passFormToParent={getFormVerif} interventionInfoGeneral={interventionInfoGeneral} />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title" + (okList.indexOf("Etiquettek") !== -1 ? " isok" : "")}>Etiquette 'k'</div>} key={4}>
                        <Etiquette passFormToParent={getFormVerif} interventionInfoGeneral={interventionInfoGeneral} />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title" + (okList.indexOf("Marquages") !== -1 ? " isok" : "")}>Marquages</div>} key={5}>
                        <Marquage passFormToParent={getFormVerifAndIntervention} interventionInfoGeneral={interventionInfoGeneral} />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title" + (okList.indexOf("Position") !== -1 ? " isok" : "")}>Position</div>} key={6}>
                        <Position passFormToParent={getFormVerifAndIntervention} interventionInfoGeneral={interventionInfoGeneral} />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title" + (okList.indexOf("TAutres") !== -1 ? " isok" : "")}>Autres</div>} key={7}>
                        <Autres passFormToParent={getFormVerif} interventionInfoGeneral={interventionInfoGeneral} />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )

}

export default Taximetre