import { Field, Form, Formik } from "formik"
import { Tabs } from 'antd'
import TixIV from "./TixIV"
import TixV from "./TixV"
import TixVI from "./TixVI"
import TixVIIVitesse from "./TixVIIVitesse"
import TixVII from "./TixVII"
import TixIII from "./TixIII"
import Imprimante from "./Imprimante"
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Refus } from './../../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../../res/erreur.svg'
const { TabPane } = Tabs

const Impression = () => {

    return (
        <div className="taximetre-section">
            <div className="taximetre-container">
                <Formik
                    enableReinitialize={true}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div className="affichage-form-container">
                                <div className="form-row w-100">
                                    {/* Scellements présents */}
                                    <div className="form-row w-100">
                                        <div className="form-cell-input m-w-100 w-100">
                                            <div className="form-input">
                                                <Field
                                                    name="anomalies_initiales"
                                                    className="form-control"
                                                    disabled
                                                    value="Si non fait avant : Carte dans lecteur 2 avec 'DISPO' (>1 min) Puis carte lecteur 1" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* 2ème source : */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">2ème source :</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Refus />
                                                        NON activée
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        UEV Non concernée
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* Rappel */}
                                    <div className="form-row w-100">
                                        <div className="form-row w-100">
                                            <div className="form-cell-input m-w-100 w-100">
                                                <div className="form-input">
                                                    <Field name="anomalies_initiales" as="textarea" className="form-control" disabled
                                                        value={'Affichage : Point Noir à côté conduite (contact coupé)\n' +
                                                            'Ticket : en bas données techniques voir photo'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Source */}
                                    <div className="form-row">
                                        <div className="form-img-container">
                                            <img src="" alt="Source" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <Tabs defaultActiveKey={1} tabPosition={'top'} style={{ height: 220, width: '100%' }} /*onChange={handleChangeTab}*/ >
                    <TabPane tab={<div className={"tab-title"}>Tix IV</div>} key={1}>
                        <TixIV />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title"}>Tix V</div>} key={2}>
                        <TixV />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title"}>Tix VI</div>} key={3}>
                        <TixVI />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title"}>Tix VII et diag. vitesse</div>} key={4}>
                        <TixVIIVitesse />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title"}>Tix VII</div>} key={5}>
                        <TixVII />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title"}>Tix III</div>} key={6}>
                        <TixIII />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title"}>Imprimante</div>} key={7}>
                        <Imprimante />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default Impression