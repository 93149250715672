import { Field, Form, Formik } from "formik"
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../../res/erreur.svg'
import { ReactComponent as Intervention } from './../../../../../res/alerte.svg'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { fr } from "date-fns/locale"

const Adaptateur = () => {

    return (
        <div className="receptionv-section">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue, setValues, handleChange }) => (
                    <Form autoComplete="off">
                        <div className="affichage-form-container etiquette">
                            <div className="reception-accordion">
                                {/* Adaptateur */}
                                <div className="form-row">
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        Existence d'un adaptateur (véhicule N1 ou M1)
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        Pas d'adaptateur : capteur connecté à la BV
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-3"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 0)}
                                                        /*checked={values.usure_pneumatique === 0 ? true : false} */ />
                                                    <label htmlFor="usure_pneumatique-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        Pas d'adaptateur : capteur non connecté à la BV
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="container-vertical">
                                    {/* Marque */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Marque</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field name="util_mano" className="form-control" as="select">
                                                    <option value="0">Continental</option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Modèle */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Modèle</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field name="util_mano" className="form-control" as="select">
                                                    <option value="0">Kitas 2171</option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Certificat */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Certificat</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field name="util_mano" className="form-control" as="select">
                                                    <option value="0"></option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    {/* N° Série */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">N° Série</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <Field name="marque_vignette" className="form-control input-small" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Année Fabrication */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Année Fabrication</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <Field name="marque_vignette" className="form-control input-small" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Date installation */}
                                    <div className="form-row">
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        id="date-picker-dialog"
                                                        label="Date installation"
                                                        inputVariant="outlined"
                                                        format="dd/MM/yyyy"
                                                        KeyboardButtonProps={{
                                                            "aria-label": "Date installation"
                                                        }}
                                                        maxDate={new Date()}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Couleur câble adaptateur/véhicule */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Couleur câble adaptateur/véhicule</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control" type="text" />
                                        </div>
                                    </div>
                                </div>
                                {/* Partie Véhicule installation capteur/adaptateur */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Partie Véhicule installation capteur/adaptateur</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control" type="text" />
                                        </div>
                                    </div>
                                </div>
                                {/* Année Fabrication du capteur (obligatoire si adaptateur) */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Année Fabrication du capteur (obligatoire si adaptateur)</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control input-small" type="text" />
                                        </div>
                                    </div>
                                </div>
                                {/* Taux de multiplication / division */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Taux de multiplication / division</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control input-small" type="text" />
                                        </div>
                                    </div>
                                </div>
                                {/* Capteur à libération */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Scellements 1C</div>
                                        </div>
                                    </div>
                                </div>
                                {/* Présent */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Présent</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        Altéré (A11)
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        Absent (A11)
                                                    </label>
                                                </div>
                                            </li>
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="marque_vignette" className="form-control input-small" disabled type="text" value={""} />
                                                </div>
                                            </div>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        Sans Objet
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Identification */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Identification</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control" as="select">
                                                <option value="0">Continental</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control input-small" type="text" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">MQ</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control" as="select">
                                                <option value="0">Continental</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <button type="button" className="cbtn cbtn-success mt-0 mb-2">
                                                FG13
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* Apposé */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Apposé</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OUI
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        NON
                                                    </label>
                                                </div>
                                            </li>
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="marque_vignette" className="form-control input-small" disabled type="text" value={""} />
                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                                {/* Identification */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Identification</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control" as="select">
                                                <option value="0">Continental</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control input-small" type="text" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">MQ</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control" as="select">
                                                <option value="0">Continental</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <button type="button" className="cbtn cbtn-success mt-0 mb-2">
                                                FG13 : Contrôle pose scellement
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Adaptateur