import NavBar from "../components/NavBar/NavBar"
import { userCase, externeCase, adherentCase } from "../constants/userCase"
import { Role } from "../helpers/Role"
import { NavLink } from "react-router-dom"
import moodle from "./../assets/moodle.png"
import { useAuth } from "../providers/authProvider"
import { useEffect } from "react"

// Ne pas oublier d'enlever export default quand on va rajouter les fonctionnalités specifique a un utilisateur
export const HomePage = () => {

    const auth = useAuth()
    const user = auth.getLocalUser()
    const userRole = parseInt(user.role)

    useEffect(() => {
        /*supervisionService.testReport()
            .then(response => console.log(response))*/
    }, [])

    return (
        <>
            <NavBar />
            <div className="Tools-section mt-section">
                <div className="container">
                    <h2 className="Tools-title pt-4 px-1">
                        Outils
                    </h2>
                    <div className="c-cards">
                        {user && (userRole === Role.Admin || userRole === Role.Siege) && userCase.map((item, i) =>

                            ((userRole === Role.Admin) || (userRole !== Role.Admin && item.disabled !== 'disabled')) &&
                            <div className="c-card" key={i}>
                                <NavLink to={item.link}>
                                    <div className="card Comet-card" >
                                        <div className="card-body">
                                            <div className="usercase-icon mb-4">
                                                <img src={item.image} alt={item.name} />
                                            </div>
                                            <label className="mb-0">{item.name}</label>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>

                        )}
                        {user && (userRole === Role.Adherent || userRole === null) && adherentCase.map((item, i) =>
                            <div className="c-card" key={i}>
                                <NavLink to={item.link}>
                                    <div className="card Comet-card" >
                                        <div className="card-body">
                                            <div className="usercase-icon mb-4">
                                                <img src={item.image} alt={item.name} />
                                            </div>
                                            <label className="mb-0">{item.name}</label>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        )}
                        {user && userRole === Role.Externe && externeCase.map((item, i) =>
                            <div className="c-card" key={i}>
                                <NavLink to={item.link}>
                                    <div className="card Comet-card" >
                                        <div className="card-body">
                                            <div className="usercase-icon mb-4">
                                                <img src={item.image} alt={item.name} />
                                            </div>
                                            <label className="mb-0">{item.name}</label>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        )}
                    </div>
                    {user && userRole !== Role.Externe &&
                        <>
                            <h2 className="Tools-title">
                                Formations
                            </h2>
                            <div className="c-cards">
                                <div className="c-card">
                                    <a href="https://moodle.cometoptima.com" target="_blank" rel="noreferrer">
                                        <div className="card Comet-card">
                                            <div className="card-body">
                                                <div className="usercase-icon mb-4">
                                                    <img src={moodle} alt="Moodle" />
                                                </div>
                                                <label className="mb-0">Moodle</label>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}