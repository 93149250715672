import React, { useState } from "react"
import NavBar from "../../components/NavBar/NavBar"
import { Accordion, Card } from 'react-bootstrap';
import user from "./../../res/user.svg"
import Switch from '@material-ui/core/Switch'
import { useLovelySwitchStyles } from '@mui-treasury/styles/switch/lovely';
// Formatte les dates en JJ/MM/AAAA
import { formatDate } from './../../helpers/utils'
import { toast } from "react-toastify"
import Compress from "react-image-file-resizer"
import { supervisionService } from "../../services/supervisionService"

const IntervenantPage = () => {

    // Récupère l'intervenant sélectionné sur la page Précédente
    const [intervenant, setIntervenant] = useState(JSON.parse(localStorage.getItem('monIntervenant')))

    // Style de switch
    const lovelyStyles = useLovelySwitchStyles()

    const [selectedFile, setSelectedFile] = useState(null)
    const [previewUrl, setPreviewUrl] = useState(null)

    const fileSelectedHandler = (event) => {
        const file = event.target.files[0]
        setSelectedFile(file)
        const reader = new FileReader()
        reader.onloadend = () => {
            setPreviewUrl(reader.result)
        }
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const fileUploadHandler = async () => {
        if (!selectedFile) {
            toast.error("Aucun fichier sélectionné.")
            return
        }
        compressImage(selectedFile)
    }

    const compressImage = (file) => {
        Compress.imageFileResizer(
            file, // fichier file de l'input
            1000, // width
            1000, // height
            file && file.type === "image/png" ? "PNG" : "JPEG", // compress format WEBP, JPEG, PNG
            85, // qualité
            0, // rotation
            (uri) => {
                const image = uri.split(',')[1]
                let _monIntervenant = intervenant
                _monIntervenant.photo_intervenant = image
                localStorage.setItem('monIntervenant', JSON.stringify(_monIntervenant))
                setIntervenant(_monIntervenant)
                setPreviewUrl(null)

                const data = {
                    numero_adherent: _monIntervenant.numero_adherent,
                    numero_intervenant: _monIntervenant.numero_intervenant,
                    photo: image
                }

                supervisionService.savePhoto(data)
                    .then(
                        response => {
                            toast.success("Photo sauvegardée.")
                        }
                    )
                    .catch(error => toast.error(error))
            },
            "base64" // blob ou base64 par défaut base64
        )
    }

    return (
        <>
            <NavBar />
            <div className="jumbotron">
                <div className="container">
                    <div className="one-intervenant c-mt-5">
                        <div className="presentation-intervenant c-mt-2">
                            <div className="c-img-wrap image-intervenant c-border">
                                {previewUrl ? <img src={previewUrl} alt="Preview" /> :
                                    <img src={intervenant && intervenant.photo_intervenant ? "data:image/jpeg;base64," + intervenant.photo_intervenant : user} alt="Intervenant" />
                                }
                            </div>
                            <div className="intervenant-identite c-ml-2">
                                <span>{intervenant.prenom}  {intervenant.nom}</span>
                                <span>{intervenant.login}</span>
                            </div>
                        </div>
                        <div className="c-mt-3">
                            <input
                                type="file"
                                name="photo_intervenant"
                                id="photo_intervenant"
                                className="photo-input"
                                accept="image/x-png,image/gif,image/jpeg"
                                capture
                                onChange={fileSelectedHandler}
                            />
                            <label htmlFor="photo_intervenant">Ajouter ou modifier la photo</label>
                            {/* <label>Photo de l'intervenant</label>
                            <input type="file" onChange={fileSelectedHandler} />
                            {previewUrl && <img src={previewUrl} alt="Preview" style={{ width: '100px', height: '100px' }} />} */}

                            {previewUrl &&
                                <div className="c-mt-1">
                                    <button type="button" className="btn btn-success mr-2" onClick={fileUploadHandler}>Confirmer</button>
                                    <button type="button" className="btn btn-danger" onClick={() => setPreviewUrl(null)}>Annuler</button>
                                </div>
                            }
                        </div>
                        <div className="details-intervenant c-mt-2">
                            <div>
                                <a className={intervenant.tel_portable === "" ? "disabled-a" : ""} href={intervenant.email && ("mailto:+" + intervenant.email)}>
                                    <span className="details-item">
                                        {intervenant.email === "" ? "Email inconnu" : intervenant.email}
                                    </span>
                                </a>

                                <a className={intervenant.tel_portable === "" ? "disabled-a" : ""} href={intervenant.tel_portable && ("tel:+" + intervenant.tel_portable)}>
                                    <span className="details-item">
                                        {intervenant.tel_portable === "" ? "Numéro inconnu" : intervenant.tel_portable}
                                    </span>
                                </a>
                            </div>
                            <div>
                                <span className="details-item">
                                    {formatDate(intervenant.naissance)}
                                </span>
                            </div>
                        </div>
                        <div className="qualification c-mt-3">
                            <h2 className="ch2">
                                Qualification
                            </h2>
                            <div className="details-qualification">
                                <Accordion defaultActiveKey="0">
                                    {/* TAXIMETRE */}
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="0">
                                            Tax
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <div className="parametre">
                                                    <span>
                                                        Correspondant Local
                                                    </span>
                                                    <Switch
                                                        name="correspondant_t"
                                                        classes={lovelyStyles}
                                                        checked={intervenant.correspondant_t}
                                                    />
                                                </div>
                                                <div className="parametre">
                                                    <span>
                                                        Verificateur
                                                    </span>
                                                    <Switch
                                                        name="correspondant_t"
                                                        classes={lovelyStyles}
                                                        checked={intervenant.correspondant_t}
                                                    />
                                                </div>
                                                <div className="parametre">
                                                    <span>
                                                        Correspondant Local
                                                    </span>
                                                    <Switch
                                                        name="correspondant_t"
                                                        classes={lovelyStyles}
                                                        checked={intervenant.correspondant_t}
                                                    />
                                                </div>
                                                <div className="parametre">
                                                    <span>
                                                        Correspondant Local
                                                    </span>
                                                    <Switch
                                                        name="correspondant_t"
                                                        classes={lovelyStyles}
                                                        checked={intervenant.correspondant_t}
                                                    />
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    {/* G/O */}
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="1">
                                            G/O
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>Mouss</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    {/* TCO */}
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="2">
                                            TCO
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body>Mouss</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    {/* EAD */}
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="3">
                                            EAD
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="3">
                                            <Card.Body>Mouss</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    {/* AUTO-ECOLE */}
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="4">
                                            Auto-ecole
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="4">
                                            <Card.Body>Mouss</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default IntervenantPage