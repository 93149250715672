import React, { useState, useEffect } from "react"
import Skeleton from "react-loading-skeleton"
import NavBar from "../../components/NavBar/NavBar"
import activiteList from "../../constants/activiteList"
import Checkbox from '@material-ui/core/Checkbox'
import { supervisionService } from "../../services/supervisionService"
import { formatDate } from "../../helpers/utils"
import Popup from "./Popup"

const TextePage = () => {

    const [activite, setactivite] = useState("all")
    const [visionAdherent, setvisionAdherent] = useState(false)
    const [abroges, setabroges] = useState(false)
    const [textes, settextes] = useState(null)
    const [showPopup, setshowPopup] = useState(false)
    const [nom, setnom] = useState("")
    const [detail, setdetail] = useState("")
    const [numero, setnumero] = useState("")
    const [type, settype] = useState("")

    useEffect(() => {

        async function getTextes() {
            await supervisionService.getTextes(activite)
                .then(response => { settextes(response) })
        }
        getTextes()

    }, [])

    const toggleAbroges = () => {
        setabroges(!abroges)
    }

    const toggleVisionAdherent = () => {
        setvisionAdherent(!visionAdherent)
    }

    const togglePopup = () => {
        setshowPopup(!showPopup)
    }

    const handleChangeActivite = event => {
        let act = event.currentTarget.value === "all" ? "all" : event.currentTarget.value
        setactivite(act)
        async function getTextes() {
            await supervisionService.getTextes(act, false, false)
                .then(response => settextes(response))
        }
        getTextes()
    }

    const handleChangeCB = event => {
        async function getTextes() {
            await supervisionService.getTextes(activite, event.currentTarget.checked, false)
                .then(response => settextes(response))
        }
        getTextes()
    }

    return (
        <>
            <NavBar />
            <div className="jumbotron">
                <div className="container">
                    <div className="texte c-mt-6">
                        <h1 className="c-mt-2 c-mb-3">Texte</h1>
                        <div className="filtre-section c-mb-3">
                            <div className="row">
                                <div className="col">
                                    <div className="filtre-activite c-mb-3">
                                        <div className="form-group">
                                            <label htmlFor="">Activité</label>
                                            <select className="form-control" name="activite" onChange={handleChangeActivite}>
                                                <option value="all">Toutes</option>
                                                {activiteList && activiteList.map((activite, index) =>
                                                    <option key={index} value={activite.numero}>{activite.nom}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="filtre-vision-adherent">
                                        <div className="form-group checkbox-group checkbox-group-texte">
                                            <label className="options-label">Vision Adhérent</label>
                                            <Checkbox
                                                name={"visionAdherent"}
                                                checked={visionAdherent}
                                                onChange={event => { toggleVisionAdherent(); handleChangeCB(event); }}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox'
                                                }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="filtre-abroge">
                                        <div className="form-group checkbox-group checkbox-group-texte">
                                            <label className="options-label">Abrogés</label>
                                            <Checkbox
                                                name={"abroges"}
                                                checked={abroges}
                                                onChange={toggleAbroges}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox'
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="result-search-section">
                            {textes && textes.map((texte, i) =>
                                <div key={i} className="col">
                                    <div className="texte-item c-mb-2" onClick={() => { togglePopup(); setnom(texte.nom_texte); setnumero(texte.numero_document_texte); setdetail(texte.detail_texte); settype(texte.nom_detail_doc) }}>
                                        <div className="texte-header c-mb-3">
                                            <img src="" alt="" />
                                            <span>{(texte.nom_texte).substring(0, 35)}...</span>
                                        </div>
                                        <div className="texte-body">
                                            <span className="">
                                                {texte.numero_activite === 0 ? "Gen" :
                                                    texte.numero_activite === 1 ? "Tax" :
                                                        texte.numero_activite === 2 ? "Gzo" :
                                                            texte.numero_activite === 4 ? "Tco" :
                                                                texte.numero_activite === 5 ? "Ead" :
                                                                    texte.numero_activite === 6 ? "Aut" :
                                                                        "Mtn"}
                                            </span>
                                            <span className="texte-type">{texte.nom_detail_doc}</span>
                                            <span className="texte-date">{formatDate(texte.debut_texte)}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {textes && textes.length === 0 &&
                                <div className="col">
                                    <span>Aucun résultat</span>
                                </div>
                            }
                            {showPopup ?
                                <Popup close={togglePopup} nom={nom} numero={numero} type={type} detail={detail} /> :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default TextePage