import { Field, Form, Formik } from "formik"
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../../res/siren.svg'
import { ReactComponent as Intervention } from './../../../../../res/alerte.svg'
import { ReactComponent as SO } from './../../../../../res/erreur.svg'
import { Checkbox } from "antd"
import Ticket2Image from './../../../../../assets/saisie-tachy/ticket2.png'

const Liberation = () => {
    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container">
                            <div className="reception-header d-flex flex-wrap">
                                <div className="container-horizontal">
                                    {/* Capteur à libération */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Capteur à libération</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-vertical">
                                    {/* Marque */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Marque</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field name="util_mano" className="form-control" as="select">
                                                    <option value="0">Continental</option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Modèle */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Modèle</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field name="util_mano" className="form-control" as="select">
                                                    <option value="0">Kitas 2171</option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ss-modèle */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">ss-modèle</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field name="util_mano" className="form-control" as="select">
                                                    <option value="0"></option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    {/* N° Série */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">N° Série</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <Field name="marque_vignette" className="form-control input-small" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Hex (calcul n° sur Actia) */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Hex (calcul n° sur Actia)</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <Field name="marque_vignette" className="form-control input-small" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Certif */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Certif</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <Field name="marque_vignette" className="form-control input-small" disabled type="text" value={""} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Egal precedent */}
                                    <div className="section-precedent">
                                        <button type="button" className="cbtn cbtn-success mt-0 mb-2">Egal précédent</button>
                                    </div>
                                </div>
                            </div>
                            <div className="reception-body flex flex-column">
                                <div className="container-horizontal">
                                    {/* Scellement numéroté A LIBERATION */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Scellement numéroté A LIBERATION</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-vertical">
                                    {/* Scellement apposé */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Scellement apposé</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                        <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            OUI
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-2"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <SO />
                                                            SO
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Erreur />
                                                            NON :
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <Field name="marque_vignette" className="form-control input-small" disabled type="text" value={""} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Identification */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Identification</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field name="util_mano" className="form-control" as="select">
                                                    <option value="0">Continental</option>
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <Field name="marque_vignette" className="form-control input-small" type="text" />
                                            </div>
                                        </div>
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">MQ</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field name="util_mano" className="form-control" as="select">
                                                    <option value="0">Continental</option>
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <button type="button" className="cbtn cbtn-success mt-0 mb-2">
                                                    FG13 : Contrôle pose scellement
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="reception-body flex flex-column">
                                <div className="container-horizontal">
                                    {/* Concordance TICKET I et TICKET II */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Concordance TICKET I et TICKET II</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                        <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            OK
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Intervention />
                                                            AXX :
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* A21 (3) */}
                                    <div className="form-row m-m-0">
                                        <div className="form-cell-input checkbox-container">
                                            <div className="form-input">
                                                <Checkbox
                                                    checked={true}
                                                    name="c_r99"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    A21 (3) : Non concordance affichés et capteur de mouvement
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* A21 (4) */}
                                    <div className="form-row m-m-0">
                                        <div className="form-cell-input checkbox-container">
                                            <div className="form-input">
                                                <Checkbox
                                                    checked={true}
                                                    name="c_r99"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    A21 (4) : Non concordance imprimés et capteur de mouvement
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* A21 (5) */}
                                    <div className="form-row m-m-0">
                                        <div className="form-cell-input checkbox-container">
                                            <div className="form-input">
                                                <Checkbox
                                                    checked={true}
                                                    name="c_r99"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    A21 (5) : Non concordance imprimés et VU
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Ticket II */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Ticket II</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <img src={Ticket2Image} alt=""></img>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                        <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            OK
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Erreur />
                                                            Refus
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Intervention />
                                                            Intervention
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <SO />
                                                            SO
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Liberation