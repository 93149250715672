import React from "react"
import { AuthConsumer } from "../providers/authProvider"
import logo from './../res/logo.svg'

export const Callback = () => (
    <AuthConsumer>
        {({ signinRedirectCallback }) => {
            signinRedirectCallback()
            return <div className="loading-container"><img src={logo} alt="Logo" />Chargement...</div>
        }}
    </AuthConsumer>
)