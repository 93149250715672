import { Field, Form, Formik } from "formik"
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Intervention } from './../../../../../res/alerte.svg'
import { Accordion, Card, Button } from 'react-bootstrap'
import { useState } from "react"

const LAuBanc = (props) => {

    // Accordion
    const [isOpenMesure1, setIsOpenMesure1] = useState(true)
    const [isOpenMesure2, setIsOpenMesure2] = useState(false)
    const [isOpenMesure3, setIsOpenMesure3] = useState(false)

    return (
        <div className="receptionp-section">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue, setValues, handleChange }) => (
                    <Form autoComplete="off">
                        <div className="affichage-form-container etiquette">
                            {/* Intsrument */}
                            <div className="form-row">
                                <div className="form-col">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Instrument</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-col m-w-100">
                                    <div className="form-row">
                                        <div className="form-col m-w-100">
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="util_mano" className="form-control" as="select">
                                                        <option value="0">Autre</option>
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Mesure */}
                            <div className="form-row">
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    Sur 1 mesure
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    Sur 3 mesures
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Mesures */}
                            <div className="reception-accordion">
                                <Accordion defaultActiveKey="0">
                                    {/* 1ère Mesure */}
                                    <Card>
                                        <Accordion.Toggle
                                            className="comet-collapse supervision-collapse card-header font-weight-bold"
                                            as={Card.Header}
                                            eventKey="0"
                                            onClick={() => { setIsOpenMesure1(!isOpenMesure1); setIsOpenMesure2(false); setIsOpenMesure3(false); }}
                                        >
                                            <label>1ère Mesure</label>
                                            <Button
                                                onClick={() => { setIsOpenMesure1(!isOpenMesure1); setIsOpenMesure2(false); setIsOpenMesure3(false); }}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={isOpenMesure1}
                                                className="font-weight-bold"
                                            >
                                                {isOpenMesure1 === false ?
                                                    <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                    <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                }
                                            </Button>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <div className="form-row pneu-container">
                                                    {/* Gauche */}
                                                    <div className="form-col">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Gauche</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input m-w-100">
                                                            <div className="form-input">
                                                                <Field name="util_mano" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Droite */}
                                                    <div className="form-col">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Droite</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input m-w-100">
                                                            <div className="form-input">
                                                                <Field name="util_mano" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Moyenne */}
                                                    <div className="form-col">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Moyenne</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input m-w-100">
                                                            <div className="form-input">
                                                                <Field name="util_mano" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    {/* 2ème Mesure */}
                                    <Card>
                                        <Accordion.Toggle
                                            className="comet-collapse supervision-collapse card-header font-weight-bold"
                                            as={Card.Header}
                                            eventKey="1"
                                            onClick={() => { setIsOpenMesure2(!isOpenMesure2); setIsOpenMesure1(false); setIsOpenMesure3(false); }}
                                        >
                                            <label>2ème Mesure</label>
                                            <Button
                                                onClick={() => { setIsOpenMesure2(!isOpenMesure2); setIsOpenMesure1(false); setIsOpenMesure3(false); }}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={isOpenMesure2}
                                                className="font-weight-bold"
                                            >
                                                {isOpenMesure2 === false ?
                                                    <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                    <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                }
                                            </Button>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <div className="form-row pneu-container">
                                                    {/* Gauche */}
                                                    <div className="form-col">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Gauche</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input m-w-100">
                                                            <div className="form-input">
                                                                <Field name="util_mano" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Droite */}
                                                    <div className="form-col">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Droite</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input m-w-100">
                                                            <div className="form-input">
                                                                <Field name="util_mano" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Moyenne */}
                                                    <div className="form-col">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Moyenne</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input m-w-100">
                                                            <div className="form-input">
                                                                <Field name="util_mano" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    {/* 3ème Mesure */}
                                    <Card>
                                        <Accordion.Toggle
                                            className="comet-collapse supervision-collapse card-header font-weight-bold"
                                            as={Card.Header}
                                            eventKey="2"
                                            onClick={() => { setIsOpenMesure3(!isOpenMesure3); setIsOpenMesure1(false); setIsOpenMesure2(false) }}
                                        >
                                            <label>3ème Mesure</label>
                                            <Button
                                                onClick={() => { setIsOpenMesure3(!isOpenMesure3); setIsOpenMesure1(false); setIsOpenMesure2(false) }}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={isOpenMesure3}
                                                className="font-weight-bold"
                                            >
                                                {isOpenMesure3 === false ?
                                                    <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                    <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                }
                                            </Button>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body>
                                                <div className="form-row pneu-container">
                                                    {/* Gauche */}
                                                    <div className="form-col">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Gauche</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input m-w-100">
                                                            <div className="form-input">
                                                                <Field name="util_mano" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Droite */}
                                                    <div className="form-col">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Droite</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input m-w-100">
                                                            <div className="form-input">
                                                                <Field name="util_mano" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Moyenne */}
                                                    <div className="form-col">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">Moyenne</div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input m-w-100">
                                                            <div className="form-input">
                                                                <Field name="util_mano" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                                {/* Moyenne */}
                                <div className="form-row">
                                    <div className="form-col">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    Moyenne
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-col">
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <button type="button" className="btn cbtn-primary"> - </button>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <Field name="pneu_pression" className="form-control input-small" type="text" />
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <button type="button" className="btn cbtn-primary"> + </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Dimension */}
                                <div className="card mb-4">
                                    {/* 'l' minimum (mm) */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        'l' minimum (mm)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* 'l' maximum (mm) */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        'l' maximum (mm)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Dimension 3 mini */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Dimension 3 mini
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Reference */}
                                <div className="card">
                                    {/* l idéal (selon pneus) */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        l idéal (selon pneus)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Différence (en %) */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Différence (en %)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Tolérance (en %) */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Tolérance (en %)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Résultat */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Résultat
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Différence D/G (en %) */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Différence D/G (en %)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Tolérance D/G (en %) */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Tolérance D/G (en %)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Résultat */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Résultat
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Dispersion (en %) */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Dispersion (en %)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Tolérance Dispersion (en %) */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Tolérance Dispersion (en %)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Résultat */}
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Résultat
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="pneu_pression" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default LAuBanc