
import { useEffect, useState } from 'react'
import { supervisionService } from '../services/supervisionService'
import NavBar from './../components/NavBar/NavBar'
import Skeleton from 'react-loading-skeleton'
import { useAuth } from '../providers/authProvider'
import { Role } from '../helpers/Role'
import { canSeePage } from '../helpers/utils'
import LastUsedSaisie from '../components/Dashboard/LastUsedSaisie'

const DashboardPage = () => {

    const auth = useAuth()
    const userRole = auth.userRole
    const userGroup = auth.userGroup
    const [stock, setStock] = useState(null)
    const [loading, setLoading] = useState(true)
    const currentYear = new Date().getFullYear()
    const [adherents, setadherents] = useState(null)
    const [activite] = useState(1)
    const [selectedAdherent, setSelectedAdherent] = useState(userGroup)

    async function getAllAdherent() {
        await supervisionService.getAllAdherent(undefined, undefined, activite, undefined)
            .then(response => setadherents(response))
    }

    useEffect(() => {
        getAllAdherent()
    }, [])

    useEffect(() => {
        if (selectedAdherent !== "") {
            setLoading(true)
            const data = {
                numero_adherent: selectedAdherent,
                activite: activite,
            }
            supervisionService.getStockVignetteAdherent(data)
                .then(response => {
                    setStock(response)
                    setLoading(false)
                })
        }
    }, [selectedAdherent, activite])

    const handleChangeAdherent = (e) => {
        setSelectedAdherent(e.target.value)
    }

    canSeePage(userRole)

    return (
        <>
            <NavBar />
            <div className="jumbotron">
                <div className="container">
                    <div className="presaisie-container c-mt-3">
                        <div className="sub-header">
                            <h1 className="c-mt-2 c-mb-3">
                                Dashboard
                            </h1>
                            <div className="filter-container">
                                <div className="adherent-select">
                                    <select className='form-control' value={selectedAdherent} onChange={handleChangeAdherent} disabled={(userRole !== Role.Admin && userRole !== Role.Siege) && selectedAdherent !== '' ? true : false}>
                                        <option value="">------</option>
                                        {adherents && adherents.map((item, i) =>
                                            <option key={i} value={item.numero_adherent}>
                                                {item.identification_adherent + " (" + item.nom_adherent + ")"}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-header">
                            {(!loading && stock &&
                                <div className="c-cards">
                                    <div className={"dashboard-card" + (stock.current === 0 ? " bg-light-red" : " bg-light-green")}>
                                        <p>Vignette verte</p>
                                        <span>Stock - {currentYear + 1}</span>
                                        <h1>{stock.current}</h1>
                                    </div>
                                    <div className={"dashboard-card"}>
                                        <p>Vignette verte</p>
                                        <span>Stock - {currentYear + 2}</span>
                                        <h1>{stock.next}</h1>
                                    </div>
                                    <div className={"dashboard-card" + (stock.rouge === 0 ? " bg-light-red" : " bg-light-green")}>
                                        <p>Vignette rouge</p>
                                        <span>Stock - {currentYear + 1}</span>
                                        <h1>{stock.rouge}</h1>
                                    </div>
                                </div>) ||
                                <div className="c-cards">
                                    <div className="dashboard-card">
                                        <Skeleton height={15} className="mb-2" />
                                        <Skeleton height={25} />
                                        <Skeleton height={25} />
                                    </div>
                                    <div className={"dashboard-card"}>
                                        <Skeleton height={15} className="mb-2" />
                                        <Skeleton height={25} />
                                        <Skeleton height={25} />
                                    </div>
                                    <div className="dashboard-card">
                                        <Skeleton height={15} className="mb-2" />
                                        <Skeleton height={25} />
                                        <Skeleton height={25} />
                                    </div>
                                </div>
                            }
                        </div>
                        {(userRole === Role.Admin || userRole === Role.Siege) &&
                            <LastUsedSaisie />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardPage