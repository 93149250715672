import { ReactComponent as Voiture } from "./../../../../res/voiture.svg"
import { ReactComponent as Pneu } from "./../../../../assets/saisie-menu/wheel.svg"
import { ReactComponent as Taximetre } from "./../../../../res/taximeter.svg"
import { ReactComponent as Autres } from "./../../../../res/caisse-enregistreuse.svg"
import { ReactComponent as Lumineux } from "./../../../../res/signal-de-taxi.svg"
import { ReactComponent as Liaisons } from "./../../../../res/chaine-a-maillons.svg"
import { ReactComponent as Metrologie } from "./../../../../res/route.svg"
import { ReactComponent as Imprimante } from "./../../../../res/imprimante.svg"
import { ReactComponent as Photos } from "./../../../../res/camera.svg"
import { ReactComponent as Sanction } from "./../../../../res/arbitre.svg"
import { ReactComponent as Conception } from "./../../../../res/conception.svg"
import { ReactComponent as Procedure } from "./../../../../res/classeur.svg"
import { ReactComponent as Infos } from "./../../../../res/info.svg"
import { ReactComponent as Etalonnage } from "./../../../../assets/saisie-menu/etalonnage.svg"
import { ReactComponent as Installateur } from "./../../../../assets/saisie-menu/installateur.svg"

const UserCase = [
    {
        group: "Réception",
        items: [
            {
                name: 'Réception Véhicule',
                image: <Voiture />,
                link: '/intervention/reception-vehicule',
            },
            {
                name: 'Réception Pneumatiques',
                image: <Pneu />,
                link: '/intervention/reception-pneumatique',
            },
        ]
    },
    {
        group: "Installation",
        items: [
            {
                name: 'Etalonnage',
                image: <Etalonnage />,
                link: '/intervention/etalonnage',
            },
            {
                name: 'Installateur',
                image: <Installateur />,
                link: '/intervention/installateur',
            },
        ]
    },
    {
        group: "Vérification",
        items: [
            {
                name: 'Taximètre',
                image: <Taximetre />,
                link: '/intervention/taximetre',
            },
            {
                name: 'Autres',
                image: <Autres />,
                link: '/intervention/autres',
            },
            {
                name: 'Lumineux',
                image: <Lumineux />,
                link: '/intervention/lumineux',
            },
            {
                name: 'Liaisons',
                image: <Liaisons />,
                link: '/intervention/liaisons',
            },
            {
                name: 'Métrologie',
                image: <Metrologie />,
                link: '/intervention/metrologie',
                disabled: 'disabled'
            },
            {
                name: 'Imprimante',
                image: <Imprimante />,
                link: '/intervention/imprimante',
                disabled: 'disabled'
            },
            {
                name: 'Photos',
                image: <Photos />,
                link: '/intervention/photos',
                disabled: 'disabled'
            },
            {
                name: 'Sanction Vérification',
                image: <Sanction />,
                link: '/intervention/sanction',
                disabled: 'disabled'
            },
        ]
    },
    {
        group: "Support",
        items: [
            /*{
                name: 'Conception',
                image: <Conception />,
                link: '/intervention/conception',
            },*/
            {
                name: 'Procédure',
                image: <Procedure />,
                link: '/intervention/procedure',
            },
            {
                name: 'Modèle',
                image: <Taximetre />,
                link: '/intervention/modele',
            },
        ]
    },
    {
        group: "Aides",
        items: [
            {
                name: 'Infos',
                image: <Infos />,
                link: '/intervention/infos',
            },
        ]
    },
]

const UserCaseV2 = [
    {
        group: "Réception",
        items: [
            {
                name: 'Réception Véhicule',
                image: <Voiture />,
                link: '/intervention-v2/reception-vehicule',
            },
            {
                name: 'Réception Pneumatiques',
                image: <Pneu />,
                link: '/intervention-v2/reception-pneumatique',
            },
        ]
    },
    {
        group: "Installation",
        items: [
            {
                name: 'Etalonnage',
                image: <Etalonnage />,
                link: '/intervention-v2/etalonnage',
            },
            {
                name: 'Installateur',
                image: <Installateur />,
                link: '/intervention-v2/installateur',
            },
        ]
    },
    {
        group: "Vérification",
        items: [
            {
                name: 'Taximètre',
                image: <Taximetre />,
                link: '/intervention-v2/taximetre',
            },
            {
                name: 'Autres',
                image: <Autres />,
                link: '/intervention-v2/autres',
            },
            {
                name: 'Lumineux',
                image: <Lumineux />,
                link: '/intervention-v2/lumineux',
            },
            {
                name: 'Liaisons',
                image: <Liaisons />,
                link: '/intervention-v2/liaisons',
            },
            {
                name: 'Métrologie',
                image: <Metrologie />,
                link: '/intervention-v2/metrologie',
                disabled: 'disabled'
            },
            {
                name: 'Imprimante',
                image: <Imprimante />,
                link: '/intervention-v2/imprimante',
                disabled: 'disabled'
            },
            {
                name: 'Photos',
                image: <Photos />,
                link: '/intervention-v2/photos',
                disabled: 'disabled'
            },
            {
                name: 'Sanction Vérification',
                image: <Sanction />,
                link: '/intervention-v2/sanction',
                disabled: 'disabled'
            },
        ]
    },
    {
        group: "Support",
        items: [
            /*{
                name: 'Conception',
                image: <Conception />,
                link: '/intervention/conception',
            },*/
            {
                name: 'Procédure',
                image: <Procedure />,
                link: '/intervention-v2/procedure',
            },
            {
                name: 'Modèle',
                image: <Taximetre />,
                link: '/intervention-v2/modele',
            },
        ]
    },
    {
        group: "Aides",
        items: [
            {
                name: 'Infos',
                image: <Infos />,
                link: '/intervention-v2/infos',
            },
        ]
    },
]

export {
    UserCase,
    UserCaseV2
}