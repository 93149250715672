import React, { useEffect, useState } from 'react'
import { supervisionService } from '../services/supervisionService'
import { IoMdClose } from "react-icons/io"
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"

const PopUpDoc = (props) => {

    const [file, setFile] = useState(null)
    const [isLoadingDocumentPdf, setIsLoadingDocumentPdf] = useState(false)

    useEffect(() => {
        const loadDocument = () => {
            setIsLoadingDocumentPdf(true)
            supervisionService.getDocumentListeByNumeroDocument(props.numero)
                .then(response => {
                    setFile(response)
                    setIsLoadingDocumentPdf(false)
                })
                .catch(error => {
                    console.log(error)
                    setIsLoadingDocumentPdf(false)
                })
        }
        loadDocument()
    }, [])

    // Ouvre un document de Rapport
    const openPDF = (event, afile) => {

        var byteCharacters = atob(afile.fichier_contenu);
        var byteNumbers = new Array(byteCharacters.length)
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers)
        var file = new Blob([byteArray], { type: 'application/' + afile.extension_fichier.split[1] + ';base64' })
        var fileURL = URL.createObjectURL(file)

        var element = document.createElement('a')
        element.setAttribute('href', fileURL)
        element.setAttribute('download', afile.fichier_original)
        document.body.appendChild(element)
        element.click()
        setTimeout(function () {
            // Necessaire pour Firefox
            window.URL.revokeObjectURL(fileURL)
        }, 100)
        document.body.removeChild(element)
        if (!isNaN(props.intervenant)) {
            handleReadDocument()
        }
    }

    const handleReadDocument = () => {

        const Model = {
            numero_document: props.document.numero_document,
            numero_version: props.document.numero_version,
            numero_adherent: parseInt(props.adherent),
            numero_intervenant: parseInt(props.intervenant)
        }

        supervisionService.readDocument(Model)
            .then(response => { props.resetListeDocuments() })
    }

    return (
        <div className="popup-cover popupdoc">
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        Docs Qualite
                    </h3>
                    <div className="popup-close-button" onClick={props.close}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-body">
                    <div className='mb-2'>Historique Mise à jour :</div>
                    <span className='c-line-break'>{props.modifications}</span>
                    {isLoadingDocumentPdf &&
                        <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={50} style={{ padding: 50 }} />
                    }
                    {file && file.fichier_contenu &&
                        <div className="popup-actions">
                            <div className="popup-action-open">
                                <button type="button" className={"upload-file-btn upload-file-btn-open"} onClick={e => openPDF(e, file)}>
                                    <span>Ouvrir</span>
                                    <i className="fas fa-eye"></i>
                                </button>
                            </div>
                        </div>
                    }
                </div>

            </div>
        </div>
    )

}

export default PopUpDoc