import React, { useState, useEffect } from 'react'
import NavBar from '../components/NavBar/NavBar'
import { Tabs, Tab } from 'react-bootstrap'
import PointControle from '../components/Supervision/PointControle'
import General from '../components/Supervision/General'
import { supervisionService } from '../services/supervisionService'
import player from "../res/player.svg"
import LienRapport from '../components/Supervision/LienRapport'
import Photo from '../components/Supervision/Photo'
import Skeleton from 'react-loading-skeleton'
import { toast, ToastContainer } from 'react-toastify'
import loading from "react-useanimations/lib/loading"
import UseAnimations from "react-useanimations"
import { useAuth } from "../providers/authProvider"
import { Prompt } from 'react-router-dom'
import { isNullOrUndefined, saveLineBreaks } from '../helpers/utils'

const SupervisionPage = () => {

    const [infos, setInfos] = useState(null)
    const [adherents, setAdherents] = useState(null)
    const [intervenants, setIntervenants] = useState(null)
    const [adherent, setAdherent] = useState("")
    const [intervenant, setIntervenant] = useState("")
    const [formGeneral, setFormGeneral] = useState(null)
    const [formPointControle, setFormPointControle] = useState(null)
    const [formLienRapport, setFormLienRapport] = useState(null)
    const [formPhoto, setFormPhoto] = useState(null)
    const [errors, setErrors] = useState(null)
    const [loadingscreen, setLoadingscreen] = useState(true)
    const [status] = useState(localStorage.getItem("status"))
    const [presaisie, setPresaisie] = useState(JSON.parse(localStorage.getItem("presaisie")))
    const [pointcontrole, setpointcontrole] = useState(JSON.parse(localStorage.getItem("pointcontrole")))
    const [focus] = useState(JSON.parse(localStorage.getItem("focus")))
    const [saving, setSaving] = useState(false)
    const [shouldBlockNavigation] = useState(true)
    let auth = useAuth()
    const user = auth.getLocalUser()

    const getPreSaisieModel1 = async (info, superviseur) => {
        await supervisionService.getPreSaisieModel(info)
            .then(response => {
                setInfos(response)
                setAdherent(superviseur.adh_superviseur)
                setIntervenant(superviseur.int_superviseur)
            })
    }

    const getPreSaisieModel2 = async (info, superviseur) => {
        await supervisionService.getPreSaisieModel(info)
            .then(response => {
                setInfos(response)
                setAdherent(superviseur.group)
                setIntervenant(superviseur.user_id)
            })
    }

    const getAllAdherent = async () => {
        await supervisionService.getAllAdherent()
            .then(response => setAdherents(response))
    }

    const getAllIntervenant = async (adherent, qualifie) => {
        await supervisionService.getAllIntervenant(adherent, qualifie)
            .then(response => setIntervenants(response))
    }

    const rechargerVidePointControle = async (data) => {
        await supervisionService.rechargerVidePointControle(data)
            .then(response => {
                setpointcontrole(response)
                setFormPointControle({ pointcontrole: response })
            })
    }

    const saveSupervision = async (supervision) => {
        await supervisionService.saveSupervision(supervision)
            .then(response => {
                localStorage.setItem("tempsup", response.numero_supervision)
                localStorage.setItem("status", "3")
                toast.success('Sauvegardé avec succès.', { containerId: 'App' })
            })
            .catch(error => toast.error(error, { containerId: 'App' }))
    }

    const updateSupervision = async (supervision) => {
        await supervisionService.updateSupervision(supervision)
            .then(response => {
                toast.success('Sauvegardé avec succès.', { containerId: 'App' })
            })
            .catch(error => toast.error(error, { containerId: 'App' }))
    }

    const saveSupervisionPartially = async (supervision) => {
        await supervisionService.saveSupervisionPartially(supervision)
            .then(response => {
                localStorage.setItem("tempsup", response.numero_supervision)
                localStorage.setItem("status", "3")
                toast.success('Sauvegardé avec succès', { containerId: 'App' })
            })
            .catch(error => toast.error(error, { containerId: 'App' }))
    }

    const updateSupervisionPartially = async (supervision) => {
        await supervisionService.updateSupervisionPartially(supervision)
            .then(response => {
                toast.success('Sauvegardé avec succès', { containerId: 'App' })
            })
            .catch(error => toast.error(error, { containerId: 'App' }))
    }

    useEffect(() => {
        const info = localStorage.getItem("presaisie")
        //process.env.NODE_ENV === "development" && console.log(info)
        let superviseur = null
        // Récupération de tous les adhérents
        getAllAdherent()
        // Autres données
        // Récupération de tous les intervenants par rapport à l'adherent
        if (localStorage.getItem("general")) {
            superviseur = JSON.parse(localStorage.getItem("general"))
            // Récupération des infos supplementaires des données saisies
            // dans le presaisie de supervision
            getPreSaisieModel1(info, superviseur)
                .then(getAllIntervenant(superviseur.adh_superviseur, false))
        } else {
            superviseur = user
            // Récupération des infos supplementaires des données saisies
            // dans le presaisie de supervision
            getPreSaisieModel2(info, superviseur)
                .then(getAllIntervenant(superviseur.group, false))
        }
        setLoadingscreen(false)
    }, [])

    const handleChangeAdherent = (e) => {
        setAdherent(e.target.value)
        getAllIntervenant(e.target.value, false)
        let general = formGeneral
        general.adh_superviseur = parseInt(e.target.value)
        setFormGeneral(general)
    }

    const handleChangeIntervenant = (e) => {
        setIntervenant(e.target.value)
        let general = formGeneral
        general.int_superviseur = parseInt(e.target.value)
        setFormGeneral(general)
    }

    const handleChangePointControle = async (e) => {
        let data = presaisie
        data.type_supervision = parseInt(e.currentTarget.value)
        formGeneral.type_supervision = parseInt(e.currentTarget.value)
        setFormGeneral(formGeneral)
        setPresaisie(data)
        rechargerVidePointControle(data)
    }

    // Recupere les valeurs du formulaire general
    // et sauvegarde sa valeur dans son etat
    const getFormGeneral = (childData, errors) => {
        setFormGeneral(childData)
        setErrors({ ...errors }) // ou ...errors
    }

    // Recupere les valeurs du formulaire point de controle
    // et sauvegarde sa valeur dans son etat
    const getFormPointControle = (childData, errors) => {
        setFormPointControle(childData)
        setErrors({ ...errors })
    }

    // Recupere les valeurs du formulaire Lien Rapport
    // et sauvegarde sa valeur dans son etat
    const getFormLienRapport = (childData, errors) => {
        setFormLienRapport(childData)
        setErrors({ ...errors })
    }

    // Recupere les valeurs du formulaire Photo
    // et sauvegarde sa valeur dans son etat
    const getFormPhoto = (childData, errors) => {
        setFormPhoto(childData)
        setErrors({ ...errors })
    }

    // Validation des champs de la supervision
    const isErrors = () => {
        if (errors) {
            if (errors.date_supervision) {
                toast.error("Date supervision : " + errors.date_supervision, { containerId: 'App' })
                return true
            }
            if (errors.contexte) {
                toast.error("Contexte : " + errors.contexte, { containerId: 'App' })
                return true
            }
            if (errors.action_apres) {
                toast.error("Action : " + errors.action_apres, { containerId: 'App' })
                return true
            }
            if (errors.intervalle_preconise) {
                toast.error("Intervalle : " + errors.intervalle_preconise, { containerId: 'App' })
                return true
            }
            if (errors.detail_action_apres) {
                toast.error("Détail : " + errors.detail_action_apres, { containerId: 'App' })
                return true
            }
            if (formGeneral.int_superviseur === -1 && formGeneral.supervision_collegiale === false) {
                toast.error("Collégiale : Vous devez identifier un technicien superviseur ou indiquer que c'est collégial", { containerId: 'App' })
                return true
            }
            if (formGeneral.type_supervision === 0 && formGeneral.supervision_collegiale === true) {
                toast.error("Collégiale : Impossible de faire une supervision collégiale sur une supervision technique", { containerId: 'App' })
                return true
            }
            if (formGeneral.supervision_collegiale === false) {
                if (formGeneral.adherent === 0 && (formGeneral.int_superviseur === 1 || formGeneral.int_superviseur === 0) && formGeneral.type === 0 && (new Date(formGeneral.date_supervision)).getFullYear() >= 2016) {
                    toast.error("Depuis 2016, le RTS ou son suppléant ne font plus de supervisions mais des évaluations (pendant ce temps là la chine...)", { containerId: 'App' })
                    return true
                }
            }

            // Validation Point de Contrôle
            if (formPointControle && formPointControle.pointcontrole) {
                for (var i = 0; i < formPointControle.pointcontrole.length; i++) {
                    for (var j = 0; j < formPointControle.pointcontrole[i].details.length; j++) {
                        if (formPointControle.pointcontrole[i].details[j].resultat_sup !== 0 &&
                            formPointControle.pointcontrole[i].details[j].resultat_sup !== 1 &&
                            formPointControle.pointcontrole[i].details[j].resultat_sup !== 2 &&
                            formPointControle.pointcontrole[i].details[j].resultat_sup !== 3 &&
                            formPointControle.pointcontrole[i].details[j].resultat_sup !== 4) {
                            toast.error("Tous les points de contrôle n'ont pas été remplis !", { containerId: 'App' })
                            return true
                        }
                        if (formGeneral.action_apres < 2) {
                            if (formPointControle.pointcontrole[i].details[j].resultat_sup === 4) {
                                toast.error("Le point " + formPointControle.pointcontrole[i].details[j].titre_supervision + " est en Refus donc l'action à la fin ne peut pas être sans objet.", { containerId: 'App' })
                                return true
                            }
                        }
                    }
                }
            }

            if (formLienRapport.lienrapports.length !== 0) {
                for (var a = 0; a < formLienRapport.lienrapports.length; a++) {
                    if (isNaN(parseInt(formLienRapport.lienrapports[a].numero_adherent)) === true) {
                        toast.error("Veuillez rensigner un adhérent dans lien rapport", { containerId: 'App' })
                        return true
                    }
                    if (isNaN(parseInt(formLienRapport.lienrapports[a].clef_verificateur)) === true) {
                        toast.error("Veuillez rensigner un intervenant dans lien rapport", { containerId: 'App' })
                        return true
                    }
                    if (isNaN(parseInt(formLienRapport.lienrapports[a].numero_rapport_annee)) === true) {
                        toast.error("Veuillez rensigner une année dans lien rapport", { containerId: 'App' })
                        return true
                    }
                    if (isNaN(parseInt(formLienRapport.lienrapports[a].numero_rapport)) === true) {
                        toast.error("Veuillez rensigner un numero rapport dans lien rapport", { containerId: 'App' })
                        return true
                    }
                }
            }

            return false
        }
    }

    // Enregistrement de la supervision
    const handleSubmit = () => {
        setSaving(true)
        let currentStatus = localStorage.status
        if (isErrors() === false) {

            formGeneral.contexte = parseInt(formGeneral.contexte)
            formGeneral.intervalle_preconise = parseInt(formGeneral.intervalle_preconise)
            formGeneral.action_apres = parseInt(formGeneral.action_apres)
            formGeneral.photo_1 = formPhoto.photo_1
            formGeneral.photo_2 = formPhoto.photo_2
            // Utilisation des sauts de ligne
            formGeneral.points_forts = saveLineBreaks(formGeneral.points_forts)
            formGeneral.points_faibles = saveLineBreaks(formGeneral.points_faibles)
            formGeneral.commentaires = saveLineBreaks(formGeneral.commentaires)

            // if (formGeneral.supervision_collegiale === true) {
            //     formGeneral.int_superviseur = -1
            // }

            if (formLienRapport.lienrapports.length !== 0) {
                for (var a = 0; a < formLienRapport.lienrapports.length; a++) {
                    formLienRapport.lienrapports[a].numero_adherent = parseInt(formLienRapport.lienrapports[a].numero_adherent)
                    formLienRapport.lienrapports[a].numero_intervenant = parseInt(formLienRapport.lienrapports[a].numero_intervenant)
                    formLienRapport.lienrapports[a].numero_rapport = parseInt(formLienRapport.lienrapports[a].numero_rapport)
                    formLienRapport.lienrapports[a].numero_rapport_annee = parseInt(formLienRapport.lienrapports[a].numero_rapport_annee)
                }
            }
            if (formLienRapport.documentliste.length !== 0) {
                for (var i = 0; i < formLienRapport.documentliste.length; i++) {
                    if (formLienRapport.documentliste[i].date_fichier !== "" || formLienRapport.documentliste[i].date_fichier !== null) {
                        formLienRapport.documentliste[i].date_fichier = new Date(formLienRapport.documentliste[i].date_fichier)
                    }
                }
            }

            let pointcontrole = []

            if (!isNullOrUndefined(formPointControle)) {
                pointcontrole = formPointControle.pointcontrole
            }

            const supervision = {
                general: formGeneral,
                pointcontrole: pointcontrole,
                lienauxrapports: formLienRapport
            }

            if (currentStatus === "new") {
                saveSupervision(supervision)
            } else if (currentStatus === "edit") {
                updateSupervision(supervision)
            } else if (currentStatus === "3") {
                supervision.general.numero_supervision = JSON.parse(localStorage.tempsup)
                updateSupervision(supervision)
            }
        }
        setSaving(false)
    }

    // Enregistrement partiel de la supervision
    const handleSubmitPartial = () => {
        setSaving(true)
        //console.log(formGeneral)
        //console.log(formPointControle)
        //console.log(formLienRapport)
        //console.log(formPhoto)
        let currentStatus = localStorage.status

        formGeneral.contexte = parseInt(formGeneral.contexte)
        formGeneral.intervalle_preconise = parseInt(formGeneral.intervalle_preconise)
        formGeneral.action_apres = parseInt(formGeneral.action_apres)
        formGeneral.photo_1 = formPhoto.photo_1
        formGeneral.photo_2 = formPhoto.photo_2
        // Utilisation des sauts de ligne
        formGeneral.points_forts = saveLineBreaks(formGeneral.points_forts)
        formGeneral.points_faibles = saveLineBreaks(formGeneral.points_faibles)
        formGeneral.commentaires = saveLineBreaks(formGeneral.commentaires)

        // if (formGeneral.supervision_collegiale === true) {
        //     formGeneral.int_superviseur = -1
        // }

        if (formLienRapport.documentliste.length !== 0) {
            for (var i = 0; i < formLienRapport.documentliste.length; i++) {
                if (formLienRapport.documentliste[i].date_fichier !== "" || formLienRapport.documentliste[i].date_fichier !== null) {
                    formLienRapport.documentliste[i].date_fichier = new Date(formLienRapport.documentliste[i].date_fichier)
                }
            }
        }

        if (formLienRapport.lienrapports.length !== 0) {
            for (var j = 0; j < formLienRapport.lienrapports.length; j++) {
                formLienRapport.lienrapports[j].numero_rapport_annee = parseInt(formLienRapport.lienrapports[j].numero_rapport_annee)
                formLienRapport.lienrapports[j].numero_rapport = parseInt(formLienRapport.lienrapports[j].numero_rapport)
                formLienRapport.lienrapports[j].numero_adherent = parseInt(formLienRapport.lienrapports[j].numero_adherent)
                formLienRapport.lienrapports[j].numero_intervenant = parseInt(formLienRapport.lienrapports[j].numero_intervenant)
            }
        }

        let pointcontrole = []

        if (!isNullOrUndefined(formPointControle)) {
            pointcontrole = formPointControle.pointcontrole
            // On remplit les points de controle non saisis
            if (pointcontrole.length !== 0) {
                for (var a = 0; a < pointcontrole.length; a++) {
                    for (var b = 0; b < pointcontrole[a].details.length; b++) {
                        if (pointcontrole[a].details[b].resultat_sup === -1 || pointcontrole[a].details[b].resultat_sup === null) {
                            pointcontrole[a].details[b].resultat_sup = 0
                        }
                    }
                }
            }
        }

        const supervision = {
            general: formGeneral,
            pointcontrole: pointcontrole,
            lienauxrapports: formLienRapport
        }

        if (currentStatus === "new") {
            saveSupervisionPartially(supervision)
                .then(response => setSaving(false))
        } else if (currentStatus === "edit") {
            updateSupervisionPartially(supervision)
                .then(response => setSaving(false))
        } else if (currentStatus === "3") {
            supervision.general.numero_supervision = JSON.parse(localStorage.tempsup)
            updateSupervisionPartially(supervision)
                .then(response => setSaving(false))
        }
    }

    /*process.env.NODE_ENV === "development" && console.log(formGeneral)
    process.env.NODE_ENV === "development" && console.log(formPointControle)*/
    //process.env.NODE_ENV === "development" && console.log(formLienRapport)
    /*process.env.NODE_ENV === "development" && console.log(formPhoto)
    process.env.NODE_ENV === "development" && console.log(errors)
    process.env.NODE_ENV === "development" && console.log(status)*/

    // Blocage de la fenêtre si fermeture
    useEffect(() => {
        if (shouldBlockNavigation) {
            window.onbeforeunload = () => true
        }
    }, [shouldBlockNavigation])

    return (
        <>
            <Prompt
                when={shouldBlockNavigation}
                message='Êtes-vous sûr de vouloir quitter la supervision ?'
            />
            <NavBar />
            <div className="jumbotron supervision-bg">
                <div className="container">
                    <ToastContainer
                        autoClose={4000}
                        containerId={'A'}
                        enableMultiContainer
                        newestOnTop={true} />
                    <div className="supervision-container">
                        <div className="presaisie-content Comet-mt">
                            <div className="superviseur c-mb-1">
                                {(!loadingscreen &&
                                    <p className="c-mb-1">
                                        <i className="fas fa-user-shield"></i>
                                        Superviseur
                                    </p>
                                ) ||
                                    <Skeleton height={17} />}
                                <div className="superviseur-data">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="superviseur-adherent">
                                                {(!loadingscreen &&
                                                    <select className="form-control" name="adherent" id="adherent" value={adherent} onChange={handleChangeAdherent}>
                                                        {adherents && adherents.map((item, i) =>
                                                            <option key={i} value={item.numero_adherent}>{item.identification_adherent}</option>
                                                        )}
                                                    </select>
                                                ) ||
                                                    <Skeleton height={33} />}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="superviseur-intervenant">
                                                {(!loadingscreen &&
                                                    <select className="form-control" name="intervenant" id="adherent" value={intervenant} onChange={handleChangeIntervenant}>
                                                        <option value="-1">---</option>
                                                        {intervenants && intervenants.map((item, i) =>
                                                            <option key={i} value={item.numero_intervenant}>{item.prenom + " " + item.nom}</option>
                                                        )}
                                                    </select>
                                                ) ||
                                                    <Skeleton height={33} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="supervision-user-edit">
                                {(!loadingscreen && infos &&
                                    <div className="supervise-image c-img-wrap">
                                        <img className="Player" src={infos.photo ? "data:image/jpeg;base64," + infos.photo.fileContents : player} alt="Profil" />
                                    </div>
                                ) ||
                                    <Skeleton circle={true} width={80} height={80} />}
                                <div className="supervise-content c-ml-2">
                                    {(!loadingscreen && infos &&
                                        <p> {infos.prenom + " " + infos.nom} </p>) ||
                                        <Skeleton height={15} />
                                    }
                                    {(!loadingscreen && infos &&
                                        <div>
                                            <span className="details-item">
                                                {infos.identification}
                                            </span>
                                            <span className="details-item">
                                                {infos.activite}
                                            </span>
                                        </div>) ||
                                        <Skeleton count={2} height={15} />
                                    }
                                    {(!loadingscreen && infos &&
                                        <div className="c-mt-2">
                                            <span className="details-item">
                                                <select className="select-sup" name="type_supervision" defaultValue={presaisie.type_supervision} id="type_supervision" onChange={handleChangePointControle}>
                                                    <option value="0">Technique</option>
                                                    <option value="1">Documentaire</option>
                                                </select>
                                            </span>
                                        </div>) ||
                                        <Skeleton width={145} height={30} />
                                    }
                                </div>
                            </div>
                        </div>
                        {focus && focus !== "" &&
                            <div className="focus">{focus}</div>
                        }
                        <Tabs className="nav-tabs-sup" defaultActiveKey="general" id="uncontrolled-tab-example">
                            <Tab tabClassName="tab-supervision" eventKey="general" title={<div className="tab-container" ><i className="fas fa-globe-europe"></i><p>Général</p></div>}>
                                <General passFormToParent={getFormGeneral} />
                            </Tab>
                            <Tab tabClassName="tab-supervision" eventKey="pointcontrole" title={<div className="tab-container" ><i className="fas fa-hand-point-up"></i><p>Contrôle</p></div>}>
                                <PointControle passFormToParent={getFormPointControle} state={pointcontrole} presaisie={presaisie} />
                            </Tab>
                            <Tab tabClassName="tab-supervision" eventKey="lienrapport" title={<div className="tab-container" ><i className="fas fa-file-alt"></i><p>Lien Rapport</p></div>}>
                                <LienRapport passFormToParent={getFormLienRapport} />
                            </Tab>
                            <Tab tabClassName="tab-supervision" eventKey="photo" title={<div className="tab-container" ><i className="fas fa-photo-video"></i><p>Photo</p></div>}>
                                <Photo passFormToParent={getFormPhoto} />
                            </Tab>
                        </Tabs>
                        <div className="save-button-container c-mt-5">

                            <button type="submit" className="btn Comet-button Comet-button-primary" onClick={handleSubmitPartial} disabled={saving ? true : false}>
                                <i className="fas fa-cloud"></i>
                                Enreg. Partiel
                                {saving ?
                                    <UseAnimations strokeColor={'#fff'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : undefined
                                }
                            </button>

                            <button type="submit" className="btn Comet-button Comet-button-primary" onClick={handleSubmit} disabled={saving ? true : false}>
                                <i className="fas fa-save"></i>
                                Enregistrer
                                {saving ?
                                    <UseAnimations strokeColor={'#fff'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : undefined
                                }
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SupervisionPage