import React, { useState, useEffect } from 'react'
import NavBar from './../components/NavBar/NavBar'
import { supervisionService } from '../services/supervisionService';
import activiteList from '../constants/activiteList';
import PopUpDoc from './../components/PopUpDoc'
import { formatDate } from './../helpers/utils'
import Skeleton from 'react-loading-skeleton'
import { Role } from "../helpers/Role";
import { useAuth } from '../providers/authProvider';

const DocQualitePage = () => {

    const [adherents, setAdherents] = useState(null)
    const [intervenants, setIntervenants] = useState(null)
    const [selectedAdherent, setSelectedAdherent] = useState(0)
    const [documentsQualites, setDocumentsQualites] = useState(null)
    const [showPopUp, setShowPopUp] = useState(false)
    const [modifications, setModifications] = useState("")
    const [search, setSearch] = useState("")
    const [documentsQualitesBeforeSearch, setDocumentsQualitesBeforeSearch] = useState([])
    const [numero, setNumero] = useState(null)
    const [loading, setLoading] = useState(true)
    const auth = useAuth()
    const user = auth.getLocalUser()
    const userRole = parseInt(user.role)

    useEffect(() => {
        const loadAdherents = async () => {
            await supervisionService.getAllAdherent()
                .then(response => setAdherents(response))
                .catch(error => process.env.NODE_ENV === "development" && console.log(error))
        }
        const loadIntervenants = async () => {
            await supervisionService.getAllIntervenant(selectedAdherent)
                .then(response => setIntervenants(response))
                .catch(error => process.env.NODE_ENV === "development" && console.log(error))
        }
        const loadDocumentsQualites = async () => {
            await supervisionService.getAllDocumentsQualites()
                .then(response => { setDocumentsQualites(response); setDocumentsQualitesBeforeSearch(response); })
                .catch(error => process.env.NODE_ENV === "development" && console.log(error))
        }
        loadAdherents()
        loadIntervenants()
        loadDocumentsQualites()
        setLoading(false)
    }, [])

    const handleChangeAdherent = (event) => {
        var optionValue = parseInt(event.target.value)
        // Mise à jour du champ adherent avec la valeur selectionnee
        setSelectedAdherent(optionValue)
        // Mise à jour des intervenants
        updateIntervenants(optionValue)
    }

    const updateIntervenants = (adherent) => {
        const loadIntervenants = async () => {
            await supervisionService.getAllIntervenant(adherent)
                .then(response => setIntervenants(response))
                .catch(error => process.env.NODE_ENV === "development" && console.log(error))
        }
        loadIntervenants()
    }

    const handleChangeActivite = (event) => {
        const loadDocumentsQualites = async () => {
            await supervisionService.getAllDocumentsQualites(-1, -1, event.target.value.toString())
                .then(response => { setDocumentsQualites(response); setDocumentsQualitesBeforeSearch(response); })
                .catch(error => process.env.NODE_ENV === "development" && console.log(error))
        }
        loadDocumentsQualites()
        setSearch("")
    }

    const togglePopUp = () => {
        setShowPopUp(!showPopUp)
    }

    const handleChangeSearch = (e) => {
        let value = e.target.value
        setSearch(value)
        let docs = documentsQualitesBeforeSearch
        if (value !== "") {
            let results = docs.filter(doc => {
                return ((doc.codification_doc).toLowerCase().includes(value.toLowerCase()) || (doc.nom_doc).toLowerCase().includes(value.toLowerCase()))
            })
            setDocumentsQualites(results)
        }
        else {
            setDocumentsQualites(docs)
        }
    }

    return (
        <>
            <NavBar />
            <div className="jumbotron">
                <div className="container">
                    <div className="docs-qualites c-mt-6">
                        <h1 className="c-mt-2 c-mb-3">{(!loading && "Docs Qualites") || <Skeleton height={28} />}</h1>
                        <div className="filtre-section c-mb-3">
                            {user && userRole !== Role.Externe &&
                                <div className="row">
                                    <div className="col">
                                        <div className="filtre-adherent">
                                            <div className="form-group">
                                                <>
                                                    {(!loading && <label htmlFor="">Adherent</label>) ||
                                                        <Skeleton height={24} />
                                                    }
                                                    {(!loading &&
                                                        <select className="form-control" disabled name="adherent" value={selectedAdherent} onChange={handleChangeAdherent}>
                                                            {adherents && adherents.map((adherent, index) =>
                                                                <option key={index} value={adherent.numero_adherent}>
                                                                    {adherent.identification_adherent + " (" + adherent.nom_adherent + ")"}
                                                                </option>
                                                            )}
                                                        </select>
                                                    ) || <Skeleton height={38} />}
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                {user && userRole !== Role.Externe &&
                                    <div className="col">
                                        <div className="filtre-intervenant">
                                            <div className="form-group">
                                                {(!loading && <label htmlFor="">Intervenant</label>) ||
                                                    <Skeleton height={24} />
                                                }
                                                {(!loading &&
                                                    <select className="form-control" disabled name="intervenant">
                                                        <option value="-1">Tous</option>
                                                        {intervenants && intervenants.map((intervenant, index) =>
                                                            <option key={index} value={intervenant.numero_intervenant}>
                                                                {intervenant.prenom + " " + intervenant.nom}
                                                            </option>
                                                        )}
                                                    </select>
                                                ) || <Skeleton height={38} />}
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col">
                                    <div className="filtre-activite">
                                        <div className="form-group">
                                            {(!loading && <label htmlFor="">Activité</label>) ||
                                                <Skeleton height={24} />
                                            }
                                            {(!loading &&
                                                <select className="form-control" name="activite" onChange={handleChangeActivite}>
                                                    <option value="all">Toutes</option>
                                                    {activiteList && activiteList.map((activite, index) =>
                                                        <option key={index} value={activite.numero}>{activite.nom}</option>
                                                    )}
                                                </select>
                                            ) || <Skeleton height={38} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <input
                                        type="search"
                                        name="search"
                                        id="search-form"
                                        className="form-control"
                                        placeholder="Rechercher un document..."
                                        value={search}
                                        onChange={handleChangeSearch}
                                    />
                                    <span className="sr-only">Rechercher un document...</span>
                                </div>
                            </div>
                        </div>
                        <div className="result-section">
                            <div className="document-liste">
                                {(!loading && documentsQualites && documentsQualites.map((doc, index) =>
                                    <div key={index} className="adherent" onClick={() => { togglePopUp(); setModifications(doc.modifications_version); setNumero(doc.numero_document_version); }}>
                                        <span>
                                            {doc.numero_activite === 0 ? "GEN" :
                                                doc.numero_activite === 1 ? "TAX" :
                                                    doc.numero_activite === 2 ? "GZO" :
                                                        doc.numero_activite === 4 ? "TCO" :
                                                            doc.numero_activite === 5 ? "EAD" :
                                                                doc.numero_activite === 6 ? "AUT" :
                                                                    doc.numero_activite === 400 ? "MTN" : null} / {doc.codification_doc} / Version : {doc.type_doc} - {formatDate(doc.maj_version)}
                                        </span>
                                        <p>
                                            {doc.nom_doc}
                                        </p>
                                    </div>
                                )) ||
                                    <>
                                        <div className="adherent">
                                            <Skeleton height={20} />
                                            <Skeleton height={17} />
                                        </div>
                                        <div className="adherent">
                                            <Skeleton height={20} />
                                            <Skeleton height={17} />
                                        </div>
                                        <div className="adherent">
                                            <Skeleton height={20} />
                                            <Skeleton height={17} />
                                        </div>
                                        <div className="adherent">
                                            <Skeleton height={20} />
                                            <Skeleton height={17} />
                                        </div>
                                    </>
                                }
                            </div>

                            {showPopUp ?
                                <PopUpDoc close={togglePopUp} modifications={modifications} numero={numero} /> :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DocQualitePage