import React, { useEffect, useState } from "react"
import { IoMdClose } from "react-icons/io"
import { Formik, Form, Field } from 'formik'
import { supervisionService } from '../../services/supervisionService'
import { toast } from "react-toastify"
import { formatDate, isNullOrUndefined, openPDF } from "../../helpers/utils"
import Checkbox from '@material-ui/core/Checkbox'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { fr } from "date-fns/locale"

const AutorisationCouleurPopup = (props) => {

    const [departements, setDepartements] = useState([])
    const [communes, setCommunes] = useState([])
    const [selections, setSelections] = useState([])
    const [couleurs, setCouleurs] = useState([])
    const [file, setFile] = useState(null)
    //const [loadingWork, setLoadingWork] = useState(false)

    useEffect(() => {
        // Le département est connu =>
        // Ouverture du popup à partir d'une intervention
        if (props.infos) {
            getDepartement(props.infos.stationnements[0].clef_dpt)
            getCommunes(props.infos.stationnements[0].clef_dpt)
        } else {
            getAllDepartement()
        }
        getAllCouleur()
    }, [])

    const getAllDepartement = async () => {
        await supervisionService.getAllDepartement()
            .then(response => setDepartements(response))
            .catch(error => toast.error(error))
    }

    const getDepartement = async (clef_dpt) => {
        await supervisionService.getDepartement(clef_dpt)
            .then(response => setDepartements(response))
            .catch(error => toast.error(error))
    }

    const getAllCouleur = async () => {
        await supervisionService.getAllCouleur()
            .then(response => setCouleurs(response))
            .catch(error => toast.error(error))
    }

    const getCommunes = (clef_dpt) => {
        supervisionService.getCodePostalsByClefDpt(clef_dpt)
            .then(response => setCommunes(response))
            .catch(error => toast.error(error))
    }

    const initAutorisation = (setValues, name, value) => {
        setValues(prev => ({
            ...prev,
            [name]: value,
            selection: 0,
            numero_autorisation: "",
            couleur: -2,
            nominative: false,
            a_qui_autorisation: "",
            autorisation_active: false,
            obligatoire: false,
            date_debut: null,
            date_fin: null,
            precisions: ""
        }))
    }

    const getAutorisationCouleurLum = (dept, cp) => {
        supervisionService.getAutorisationCouleurLum(dept, cp)
            .then(response => setSelections(response))
            .catch(error => {
                toast.error(error)
                setSelections([])
            })
    }

    const handleChangeDepartement = (e, setValues) => {
        let value = parseInt(e.target.value)
        let name = e.target.name
        getCommunes(value)
        setSelections([])
        setFile(null)
        initAutorisation(setValues, name, value)
    }

    const handleChangeCommune = (e, setValues) => {
        let value = e.target.value
        let name = e.target.name
        let commune = communes.filter(c => c.code_postal === value)[0]
        if (value === "0") {
            setSelections([])
            setFile(null)
        }
        else {
            getAutorisationCouleurLum(commune.clef_dpt, commune.clef_cp)
        }
        initAutorisation(setValues, name, value)
    }

    const handleChangeSelection = (e, setValues) => {
        let value = e.target.value
        let name = e.target.name
        let dept = value.split(",")[0]
        let cp = value.split(",")[1]
        let aut = value.split(",")[2]
        let autorisation = selections.filter(s => s.clef_dpt === parseInt(dept) && s.clef_cp === parseInt(cp) && s.clef_autorisation === parseInt(aut))[0]
        if (!isNullOrUndefined(autorisation)) {
            setValues(prev => ({
                ...prev,
                [name]: value,
                numero_autorisation: autorisation.numero_autorisation,
                couleur: autorisation.couleur.numero_couleur,
                nominative: autorisation.nominative,
                a_qui_autorisation: autorisation.a_qui_autorisation,
                autorisation_active: autorisation.autorisation_active,
                obligatoire: autorisation.obligatoire,
                date_debut: autorisation.date_debut,
                date_fin: autorisation.date_fin,
                precisions: autorisation.precisions
            }))
            loadFile(autorisation.numero_document_autorisation)
        }
        else {
            setValues(prev => ({
                ...prev,
                [name]: value,
                numero_autorisation: "",
                couleur: -2,
                nominative: false,
                a_qui_autorisation: "",
                autorisation_active: false,
                obligatoire: false,
                date_debut: null,
                date_fin: null,
                precisions: ""
            }))
            setFile(null)
        }
    }

    // Chargement du document
    const loadFile = async (numero) => {
        //setLoadingWork(true)
        await supervisionService.getDocumentListeByNumeroDocument(numero)
            .then(response => setFile(response))
            .catch(setFile(null))
        //setLoadingWork(false)
    }

    return (
        <div className="popup-cover">
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        Autorisation couleur
                    </h3>
                    <div className="popup-close-button" onClick={props.onClose}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-body">
                    <Formik
                        enableReinitialize
                        initialValues={{
                            departement: props.infos ? props.infos.stationnements[0].clef_dpt : 0,
                            commune: props.infos ? props.infos.stationnements[0].cp : 0,
                            selection: 0,
                            numero_autorisation: "",
                            couleur: -2,
                            nominative: false,
                            a_qui_autorisation: "",
                            autorisation_active: false,
                            obligatoire: false,
                            date_debut: null,
                            date_fin: null,
                            precisions: ""
                        }}
                        validate={values => {
                            const errors = {}
                            return errors
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                alert(JSON.stringify(values, null, 2))
                                setSubmitting(false)
                            }, 400)
                        }}
                    >
                        {({ isSubmitting, setFieldValue, setValues, values }) => (
                            <Form>
                                <div className="form-group">
                                    <label htmlFor="departement">Département</label>
                                    <Field as="select" className="form-control" name="departement" onChange={e => { handleChangeDepartement(e, setValues) }} disabled={props.infos ? true : false}>
                                        <option value="0"></option>
                                        {departements && departements.map((dept, n) =>
                                            <option key={n} value={dept.clef_dpt}>{dept.numero_dpt + " (" + dept.nom_dpt + ")"}</option>
                                        )}
                                    </Field>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="code_postal">Commune</label>
                                    <Field as="select" className="form-control" name="code_postal" value={props.infos ? props.infos.stationnements[0].cp : undefined} onChange={e => handleChangeCommune(e, setValues)}>
                                        <option value="0"></option>
                                        {communes && communes.map((commune, n) =>
                                            <option key={n} value={commune.code_postal}>{commune.ville + " (" + commune.code_postal + ")"}</option>
                                        )}
                                    </Field>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="selection">Sélection</label>
                                    <Field as="select" className="form-control" name="selection" onChange={e => handleChangeSelection(e, setValues)}>
                                        <option value="0"></option>
                                        {selections && selections.map((selection, n) =>
                                            <option key={n} value={selection.clef_dpt + "," + selection.clef_cp + "," + selection.clef_autorisation}>
                                                {"Début : " + formatDate(selection.date_debut) + " (" + selection.couleur.nom_couleur + ")" + (selection.nominative ? (" - Nominative : " + selection.a_qui_autorisation) : "")}
                                            </option>
                                        )}
                                    </Field>
                                </div>
                                {file && file.fichier_contenu &&
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <div className="popup-action-open">
                                                <button type="button" className={"upload-file-btn upload-file-btn-open"} onClick={e => openPDF(e, file)}>
                                                    <span>Ouvrir</span>
                                                    <i className="fas fa-eye"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="selection-details">
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="numero_autorisation">N° Autorisation</label>
                                                <Field type="text" className="form-control" name="numero_autorisation" />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="couleur">Couleur</label>
                                                <Field as="select" className="form-control" name="couleur">
                                                    <option value="-2"></option>
                                                    {couleurs && couleurs.map((couleur, n) =>
                                                        <option key={n} value={couleur.numero_couleur}>
                                                            {couleur.nom_couleur}
                                                        </option>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-input checkbox-container">
                                            <div className="form-input">
                                                <Checkbox
                                                    checked={values.nominative}
                                                    name={"nominative"}
                                                    inputProps={{
                                                        'aria-label': 'primary checkbox'
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <label className="options-label">Nominative : </label>
                                            </div>
                                        </div>
                                        <div className="form-cell-input reste">
                                            <div className="form-input">
                                                <Field type="text" className="form-control" name="a_qui_autorisation" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-input checkbox-container">
                                            <div className="form-input">
                                                <Checkbox
                                                    checked={values.autorisation_active}
                                                    name={"autorisation_active"}
                                                    inputProps={{
                                                        'aria-label': 'primary checkbox'
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <label className="options-label">Autorisation active </label>
                                            </div>
                                        </div>
                                        <div className="form-cell-input checkbox-container">
                                            <div className="form-input">
                                                <Checkbox
                                                    checked={values.obligatoire}
                                                    name={"obligatoire"}
                                                    inputProps={{
                                                        'aria-label': 'primary checkbox'
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <label className="options-label">Disposition obligatoire </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Dates */}
                                    <div className="form-row spacing-form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">Date Début</div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            id="date-picker-dialog"
                                                            label="Date Début"
                                                            inputVariant="outlined"
                                                            format="dd/MM/yyyy"
                                                            value={values.date_debut}
                                                            onChange={value => setFieldValue("date_debut", value)}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                            maxDate={new Date()}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">Date Fin</div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            id="date-picker-dialog"
                                                            label="Date Fin"
                                                            inputVariant="outlined"
                                                            format="dd/MM/yyyy"
                                                            value={values.date_fin}
                                                            onChange={value => setFieldValue("date_fin", value)}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                            maxDate={new Date()}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    Précisions
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input reste">
                                            <div className="form-input">
                                                <Field name="precisions" className="form-control input-infos" as="textarea" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div >
    )


}

export default AutorisationCouleurPopup