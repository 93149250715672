import { Field, Form, Formik } from "formik"
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Refus } from './../../../../../res/siren.svg'
import { ReactComponent as Intervention } from './../../../../../res/alerte.svg'
import { ReactComponent as SO } from './../../../../../res/erreur.svg'
import { ReactComponent as Stop } from './../../../../../assets/general/stop.svg'
import { ReactComponent as PlaquetteOk } from './../../../../../assets/general/plaquette_ok.svg'
import { ReactComponent as PlaquettePasOk } from './../../../../../assets/general/plaquette_pas_ok.svg'
import { ReactComponent as PasPlaquette } from './../../../../../assets/general/pas_plaquette.svg'

import { Accordion, Card, Button } from 'react-bootstrap'
import { useState } from "react"
import SignaturePopup from "../../../../Popup/SignaturePopup"
import { lockBody, unlockBody } from "../../../../../helpers/utils"

const Sanction = () => {

    const [accordion1, setAccordion1] = useState({ isOpen1: true, isOpen2: false, isOpen3: false, isOpen4: false })
    const [accordion2, setAccordion2] = useState({ isOpen1: true, isOpen2: false, isOpen3: false, isOpen4: false })
    const [showPopup, setShowPopup] = useState(false)

    const togglePopup = () => {
        setShowPopup(!showPopup)
        showPopup ? unlockBody() : lockBody()
    }

    return (
        <div className="taximetre-section">
            <div className="taximetre-container">
                <Formik
                    enableReinitialize={true}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div className="affichage-form-container">
                                <div className="form-row w-100">
                                    {/* Scellements présents */}
                                    <div className="form-row w-100">
                                        <div className="form-cell-input m-w-100 w-100">
                                            <div className="form-input">
                                                <Field name="anomalies_initiales" className="form-control" disabled
                                                    value="SCELLEMENTS INSTALLATEURS (HORS SCELLEMENTS CAPTEUR G2)" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Scellements apposés (nombre + marque) */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Scellements apposés (nombre + marque)</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field name="util_mano" className="form-control" as="select">
                                                    <option value="0">Inconnu</option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Scellements déjà présents (nombre + marque) */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Scellements déjà présents (nombre + marque)</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field name="util_mano" className="form-control" as="select">
                                                    <option value="0">Inconnu</option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Détection d'un Dispositif */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Détection d'un Dispositif Frauduleux</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                        <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            Non
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Refus />
                                                            Refus (A15)
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Intervention />
                                                            Intervention (A15)
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-cell-input m-w-100">
                                                        <div className="form-input">
                                                            <Field name="util_mano" className="form-control" />
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* Sanction */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Sanction </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Pose plaquette */}
                                    <div className="form-row">
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                        <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <PlaquetteOk />
                                                            Acceptation =&rsaquo; pose plaquette
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <PasPlaquette />
                                                            Refus =&rsaquo; pas de plaque + dépose de la VU
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <PlaquettePasOk />
                                                            Refus : pas de plaque / pas de démontage
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Stop />
                                                            Refus de prise en charge (A50)
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="modele-actions mb-3">
                                            <button type="button" className={"modele-btn" + (values && values.sign3 ? " bg-success" : " bg-danger")} onClick={togglePopup}>
                                                Signature vérificateur
                                                <i className="fas fa-external-link-alt ml-2"></i>
                                            </button>
                                            <span className={"details-item" + (values && values.sign3 ? " option-yes" : "")}>{!(values && values.sign3) ? "Vide" : "Signé"}</span>
                                        </div>
                                    </div>
                                    {values && values.sign3 ?
                                        <div className="form-row">
                                            <div className="signature-img-container">
                                                <img src={"data:image/png;base64," + (values && values.sign3)} alt="" />
                                            </div>
                                        </div> : null
                                    }
                                    {showPopup &&
                                        <SignaturePopup close={togglePopup} title="Signature Vérificateur" setFieldValue={setFieldValue} inputName="sign3" />
                                    }
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default Sanction