import { Field, Form, Formik } from "formik"
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../../res/siren.svg'
import { ReactComponent as Intervention } from './../../../../../res/alerte.svg'
import { ReactComponent as SO } from './../../../../../res/erreur.svg'

const Reception = () => {
    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container">
                            <div className="reception-header d-flex flex-wrap">
                                {/* Marque */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Marque</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control" as="select">
                                                <option value="0">Continental</option>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                {/* Modèle */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Modèle</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control" as="select">
                                                <option value="0">Kitas 2171</option>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                {/* ss-modèle */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">ss-modèle</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control" as="select">
                                                <option value="0"></option>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                {/* N° Série */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">N° Série</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control input-small" type="text" />
                                        </div>
                                    </div>
                                </div>
                                {/* Hex (calcul n° sur Actia) */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Hex (calcul n° sur Actia)</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control input-small" type="text" />
                                        </div>
                                    </div>
                                </div>
                                {/* Certif */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Certif</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control input-small" disabled type="text" value={""} />
                                        </div>
                                    </div>
                                </div>
                                {/* Egal precedent */}
                                <div className="section-precedent">
                                    <button type="button" className="cbtn cbtn-success mt-0 mb-2">Egal précédent</button>
                                </div>
                            </div>
                            <div className="reception-body flex flex-column">
                                {/* N° Scellement Fabricant (ne pas confondre aveec scellement Gen2) */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">N° Scellement Fabricant (ne pas confondre aveec scellement Gen2)</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control input-small" disabled type="text" value={""} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Visuel : Identification du Capteur Conformité, Cohérence */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Visuel : Identification du Capteur Conformité, Cohérence
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    Refus (A32)
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    Intervention (A32)
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 0)}
                                                        /*checked={values.usure_pneumatique === 0 ? true : false} */ />
                                                <label htmlFor="usure_pneumatique-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Visuel : Intégrité du capteur */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Visuel : Intégrité du capteur
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    Refus (A31)
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    Intervention (A31)
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 0)}
                                                        /*checked={values.usure_pneumatique === 0 ? true : false} */ />
                                                <label htmlFor="usure_pneumatique-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Reception