import { Field, Form, Formik } from "formik"
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Refus } from './../../../../../res/siren.svg'
import { ReactComponent as Intervention } from './../../../../../res/alerte.svg'
import { ReactComponent as SO } from './../../../../../res/erreur.svg'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Table } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const Imprimante = () => {

    // Style de la table
    const useStyles = makeStyles({
        table: {
            width: "100%",
        },
    })

    const classes = useStyles()

    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container">
                            <div className="reception-header d-flex flex-wrap flex-column">
                                {/* Fonctionnement */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Fonctionnement</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    Sans Objet
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    Intervention
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Refus />
                                                    Refus :
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="marque_vignette" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Rappel */}
                            <div className="form-row w-100">
                                <div className="form-row w-100">
                                    <div className="form-cell-input m-w-100 w-100">
                                        <div className="form-input">
                                            <Field name="anomalies_initiales" as="textarea" className="form-control" disabled
                                                value={'Absence de ligne blanche\n' +
                                                    'Pas de caractère manquant\n' +
                                                    'Ticket non coupé si fin de rouleau\n' +
                                                    'Pas de ligne brouillée'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Exemple Non Conforme */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-titl text-danger">Exemple Non Conforme</div>
                                    </div>
                                </div>
                            </div>
                            {/* Image */}
                            <div className="form-row">
                                <div className="form-img-container">
                                    <img src="" alt="Ticket" />
                                </div>
                            </div>
                            {/*---------- Photo Ticket Qualité ---------- */}
                            <TableContainer component={Paper} className="mb-5">
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Photo Ticket Qualité</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                <div className="button-document-container">
                                                    {values && values.photo_20 ?
                                                        <button id="photo1" type="button" className="btn btn-document">
                                                            Photo 1
                                                            <i className="fas fa-eye"></i>
                                                        </button>
                                                        :
                                                        "Pas encore de photo de ticket"
                                                    }
                                                </div>
                                                <div className="action-button-container">
                                                    <input
                                                        type="file"
                                                        name="photo_20"
                                                        id="photo_20"
                                                        className="photo-input"
                                                        accept="image/x-png,image/gif,image/jpeg"
                                                        key={Date.now() + 1}
                                                    />
                                                    <label htmlFor="photo_20">Ajouter ou modifier</label>
                                                    <button id="del1" className="photo-action-button" type="button">
                                                        <i className="fas fa-trash-alt"></i>
                                                    </button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Imprimante