import React, { useEffect, useState } from "react"
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import Skeleton from 'react-loading-skeleton'
import NavBar from "../components/NavBar/NavBar"
import { userService } from "../services/userService"

export const DeblocagePage = () => {

    const [code, setCode] = useState({ code: "" })
    const [unlock, setUnlock] = useState("")
    const [codes, setCodes] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [generating, setGenerating] = useState(false)
    const [loadingcode, setLoadingcode] = useState(true)
    const [currentPosition, setCurrentPosition] = useState(1)

    const caractereValide = /^[a-zA-Z0-9]*/

    useEffect(() => {
        loadUnlockCodeAncien()
    }, [])

    const loadUnlockCodeAncien = async () => {
        return await setTimeout(async () => {
            await userService.getUnlockCodeAncien()
                .then(response => {
                    setCodes(response)
                    setLoadingcode(false)
                })
        }, 500)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setSubmitted(true)
        if (code.code !== "" && code.code.length === 4 && code.code.match(caractereValide)[0].length === 4) {
            setGenerating(true)
            userService.getUnlockCode(code)
                .then(response => {
                    setUnlock(response.code)
                    setGenerating(false)
                })
                .catch(error => {
                    setGenerating(false)
                    return Promise.reject(error)
                })
        }
    }

    const handleChangeTestInput = e => {
        const inputName = e.target.name
        const inputValue = e.target.value

        setCode({ [inputName]: inputValue.toUpperCase() })
        setUnlock("")
        setCurrentPosition(inputValue.length + 1)
    }

    return (
        <>
            <NavBar />
            <div className="container">
                <div className="Functionnality-section mt-section">
                    <h2 className="Functionnality-title pt-4 px-1 Comet-mb-30">
                        Code de déblocage
                    </h2>
                </div>
                <div className="Codes-section">
                    <div className="day-code">
                        <p className="c-mb-1"> Code du jour</p>
                        {(!loadingcode && codes &&
                            <span className="Comet-code details-item" >{codes.todayCode}</span>)
                            || <Skeleton width={61.35} height={33} />
                        }
                    </div>
                    <div className="day-code">
                        <p className="c-mb-1"> Code de demain</p>
                        {(!loadingcode && codes &&
                            <span className="Comet-code details-item" >{codes.tomorrowCode}</span>)
                            || <Skeleton width={61.35} height={33} />
                        }
                    </div>
                </div>
                <div className="Code-section Comet-mt">
                    <form onSubmit={handleSubmit}>
                        <div className="code-container">
                            <label className="code-label">
                                <span>Code adhérent</span>
                            </label>
                            <div className="code-input-container">
                                <input type="text" className="code-input" autoComplete="off" name="code" size="4" maxLength="4" value={code.code} onChange={handleChangeTestInput} />
                                <div className="strokes">
                                    <span className={"stroke" + (currentPosition === 1 ? " stroke-activated" : "")}></span>
                                    <span className={"stroke" + (currentPosition === 2 ? " stroke-activated" : "")}></span>
                                    <span className={"stroke" + (currentPosition === 3 ? " stroke-activated" : "")}></span>
                                    <span className={"stroke" + (currentPosition === 4 ? " stroke-activated" : "")}></span>
                                </div>
                            </div>
                        </div>
                        {submitted && (code.code === "" || code.code.length !== 4) &&
                            <div className="Help-authentication" style={{ marginTop: '15px' }}>Veuillez saisir un code à 4 caractères</div>
                        }
                        {submitted && code.code.match(caractereValide)[0].length < 4 &&
                            <div className="Help-authentication">Veuillez saisir des lettres ou des chiffres </div>
                        }
                        <div className="code-button-container">
                            <button className="btn btn-generate Comet-button-primary" type="submit">
                                {generating ?
                                    <UseAnimations strokeColor={'#fff'} animation={loading} size={18}
                                        style={{ padding: 20 }}
                                        wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                    : "Générer"
                                }
                            </button>
                        </div>
                        <div className="UnlockCode-section">
                            <div className="code-generated-container">
                                <p className="title">Code à fournir</p>
                                <div className="content">
                                    {submitted && code.code !== "" && code.code.length === 4 && code.code.match(caractereValide)[0].length === 4 &&
                                        <span>
                                            {unlock}
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default DeblocagePage