import axios from "axios"
import apilink from "../constants/apilink"
import { authService } from "../services/authService"

export const jwtInterceptor = () => {
    axios.interceptors.request.use(async request => {
        // Ajoute le accessToken dans le header si l'utilisateur est connecté
        const user = await authService.getUser()
        const accessToken = user.access_token
        const isApiUrl = request.url.includes(apilink)

        if (authService.isAuthenticated() && isApiUrl) {
            request.headers.common.Authorization = "Bearer " + accessToken
            process.env.NODE_ENV === "development" && console.log("API Call : Using Access Token...")
        }

        //request.headers['request-startTime'] = process.hrtime()
        //request.headers['request-startTime'] = new Date().getTime()

        return request
    })
}