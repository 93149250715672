import { createContext, useContext, useState } from "react"

export const DataContext = createContext()

const DataContextProvider = ({ children }) => {
    const [selectedIntervention, setSelectedIntervention] = useState({})
    const [interventionInfoGeneral, setInterventionInfoGeneral] = useState({})
    const [interventionStationnement, setInterventionStationnement] = useState({})

    return (
        <DataContext.Provider value={{
            selectedIntervention,
            setSelectedIntervention,
            interventionInfoGeneral,
            setInterventionInfoGeneral,
            interventionStationnement,
            setInterventionStationnement,
        }}>
            {children}
        </DataContext.Provider>
    )
}

export const useDataContext = () => useContext(DataContext)

export default DataContextProvider