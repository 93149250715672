import { Router, Route, Switch, Link, Redirect, Prompt } from "react-router-dom"
import history from "../../../../helpers/history"
import { useEffect, useState } from "react"
import { BiArrowFromRight } from "react-icons/bi"
import { UserCase } from "../constant/UserCase"
import { useSaisieContext } from "../SaisieContext"
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from "formik"
import { TachyNavigation } from "../constant/TachyNavigation"
import ReceptionV from "./ReceptionV/ReceptionV"
import ReceptionP from "./ReceptionP/ReceptionP"
import Photos from "./Photos/Photos"
import LAuSol from "./LAuSol/LAuSol"
import LAuBanc from "./LAuBanc/LAuBanc"
import Plaque from "./Plaque/Plaque"
import Capteur from "./Capteur/Capteur"
import Adaptateur from "./Adaptateur/Adaptateur"
import Gnss from "./Gnss/Gnss"
import ScellementGen2 from "./ScellementGen2/ScellementGen2"
import CalculW from "./CalculW/CalculW"
import Parcours from "./Parcours/Parcours"
import Simulation from "./Simulation/Simulation"
import Dsrc from "./Dsrc/Dsrc"
import Impression from "./Impression/Impression"
import Telechargement from "./Telechargement/Telechargement"
import Sanction from "./Sanction/Sanction"
import Vu from "./Vu/Vu"

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}))

const SaisieInterventionTachy = () => {

    const classes = useStyles()
    const [showMenu, setShowMenu] = useState(true)
    const [stickySave, setStickySave] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', stickySaveFunction)
        return () => {
            window.removeEventListener('scroll', stickySaveFunction)
        }
    }, [])

    const stickySaveFunction = () => {
        if (window !== undefined) {
            if (window.matchMedia('only screen and (max-width: 767px)').matches) {
                if (window.scrollY > 60) {
                    setStickySave(true)
                    document.body.style.paddingTop = '55px'
                }
                else {
                    setStickySave(false)
                    document.body.style.paddingTop = '0'
                }
            }
        }
    }

    const toggleMenu = () => {
        setShowMenu(!showMenu)
    }

    const handleChangePage = () => {

    }

    return (
        <>
            <Prompt
                message={(location, action) => {
                    return location.pathname.startsWith("/intervention")
                        ? true
                        : `Êtes-vous sûr de vouloir quitter l'intervention ?`
                }}
            />
            <h2 className='pt-4 px-1'>{"TEST"}</h2>
            <Router history={history}>
                <div className="section-saisie mt-3">
                    <div className="saisie-container">
                        <div className={"saisie-sidebar" + (!showMenu ? " saisie-sidebar-hide" : "")}>
                            <div className="saisie-sidebar-header">
                                <button className="btn saisie-sidebar-btn-close" onClick={toggleMenu}>
                                    <BiArrowFromRight className="saisie-sidebar-btn-icon" />
                                </button>
                            </div>
                            <div className="saisie-sidebar-body">
                                <ul>
                                    {TachyNavigation && TachyNavigation.map((g, n) =>
                                        <>
                                            <div className="group-header">
                                                <span>
                                                    {g.group}
                                                </span>
                                            </div>
                                            <div className="group-body">
                                                {g.items.map((u, m) =>
                                                    <li key={m}>
                                                        <Link to={{ pathname: u.link, state: { prevPath: history.location.pathname.replace("/intervention/", "") } }} className={((u.link === history.location.pathname))}>
                                                            <div className="saisie-sidebar-icon">
                                                                {u.image}
                                                            </div>
                                                            <div className="saisie-outils-texte">
                                                                {u.name}
                                                            </div>
                                                        </Link>
                                                    </li>
                                                )}
                                            </div>
                                        </>
                                    )
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className={"saisie-main" + (!showMenu ? " saisie-main-expand" : "")}>
                            <div className={"saisie-header" + (stickySave ? " saisie-fixed-top" : "")}>
                                <div className="saisie-actions">
                                    <div className="saisie-actions-container">
                                        <button className="btn saisie-btn">
                                            <i className="fas fa-save"></i>
                                            Enregistrement
                                        </button>
                                        <button className="btn saisie-btn">
                                            <i className="fas fa-cloud"></i>
                                            Enreg. partiel
                                        </button>
                                    </div>
                                </div>
                                <div className="saisie-numerotations mt-2">
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>Numérotations et Signature Clients</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="form-saisie-container">

                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="saisie-body mt-2">
                                <div className="content">
                                    <Switch>
                                        <Route path="/intervention/l-au-sol" component={LAuSol} />
                                        <Route path="/intervention/l-au-banc" component={LAuBanc} />
                                        <Route path="/intervention/reception-pneumatique" component={ReceptionP} />
                                        <Route path="/intervention/reception-vehicule" component={ReceptionV} />
                                        <Route path="/intervention/photos" component={Photos} />
                                        <Route path="/intervention/plaque" component={Plaque} />
                                        <Route path="/intervention/capteur" component={Capteur} />
                                        <Route path="/intervention/adaptateur" component={Adaptateur} />
                                        <Route path="/intervention/gnss" component={Gnss} />
                                        <Route path="/intervention/scellement-gen-2" component={ScellementGen2} />
                                        <Route path="/intervention/calcul-w" component={CalculW} />
                                        <Route path="/intervention/parcours" component={Parcours} />
                                        <Route path="/intervention/simulation" component={Simulation} />
                                        <Route path="/intervention/dsrc" component={Dsrc} />
                                        <Route path="/intervention/impression" component={Impression} />
                                        <Route path="/intervention/telechargement" component={Telechargement} />
                                        <Route path="/intervention/sanction" component={Sanction} />
                                        <Route path="/intervention/vu" component={Vu} />
                                        <Redirect from="*" to="/intervention/infos" />
                                    </Switch >
                                </div >
                            </div >
                        </div >
                        <div className="saisie-navigation-rapide">
                            <div className="saisie-navigation-rapide-container">
                                <button id="b1" className="btn comet-btn-primary comet-btn-nr" onClick={handleChangePage} >
                                    <span>&lsaquo;</span>
                                </button>
                                <button id="b2" className="btn comet-btn-primary comet-btn-nr" onClick={handleChangePage}>
                                    <span>&rsaquo;</span>
                                </button>
                            </div>
                        </div>
                        <div className="saisie-navigation">
                            <div className="saisie-navigation-bottom">
                                <div className="saisie-navigation-bottom-container">
                                    {TachyNavigation && TachyNavigation.map((g, n) =>
                                        <div className="group-body">
                                            {g.items.map((u, m) =>
                                                <div key={m} >
                                                    <Link to={{ pathname: u.link, state: { prevPath: history.location.pathname.replace("/intervention/", "") } }} className={(u.link === history.location.pathname)}>
                                                        <div className="saisie-sidebar-icon">
                                                            {u.image}
                                                        </div>
                                                        <div className="saisie-outils-texte">
                                                            {u.name}
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </Router >
        </>
    )
}

export default SaisieInterventionTachy