import React, { useState, useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import NavBar from '../components/NavBar/NavBar'
import activiteList from '../constants/activiteList'
import { supervisionService } from '../services/supervisionService'
import { formatDate } from './../helpers/utils'
import history from '../helpers/history'
import { ToastContainer, toast } from 'react-toastify';
import UseAnimations from "react-useanimations";
import loading from "react-useanimations/lib/loading";
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@material-ui/core/Switch'
import { useLovelySwitchStyles } from '@mui-treasury/styles/switch/lovely'
import Select from 'react-select'

const data = {
    adherent: '',
    intervenant: '',
    activite: '',
    supervision: '',
    type_supervision: null
}

const PreSupervisionPage = () => {

    const [adherents, setAdherents] = useState(null)
    const [intervenants, setIntervenants] = useState(null)
    const [supervisions, setSupervisions] = useState(null)
    const [activites, setActivites] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const [btn, setBtn] = useState(null)
    const [editdisabled, setEditdisabled] = useState(true)
    const [newdisabled, setNewdisabled] = useState(true)
    const [loadingEdit, setLoadingEdit] = useState(false)
    const [loadingNew, setLoadingNew] = useState(false)
    const [qualifie, setQualifie] = useState(true)
    const [superviseur, setSuperviseur] = useState("Inconnu")
    // Style de switch
    const lovelyStyles = useLovelySwitchStyles()

    const getAllAdherent = async (search = "", qualif = true) => {
        // Récupérer tous les adhérents
        await supervisionService.getAllAdherent(search, qualif)
            .then(
                response => {
                    let data = response.map((adherent) => ({ ...adherent, value: adherent.numero_adherent, label: adherent.identification_adherent + " (" + adherent.nom_adherent + ")" }))
                    setAdherents(data)
                    setIsLoading(false)
                    localStorage.setItem('adherents', JSON.stringify(data))
                }
            )
    }

    const getAllIntervenant = async (adherent, qualif) => {
        await supervisionService.getAllIntervenant(adherent, qualif)
            .then(
                response => {
                    setIntervenants(response)
                    setError(null)
                    toast.success("Intervenants chargés avec succès", { containerId: 'App' })
                }
            ).catch(
                error => {
                    setError(error)
                    setIntervenants(null)
                    toast.error(error, { containerId: 'App' })
                }
            )
    }

    useEffect(() => {
        if (localStorage.adherents) {
            let _adherents = JSON.parse(localStorage.adherents)
            let data = _adherents.map((adherent) => ({ ...adherent, value: adherent.numero_adherent, label: adherent.identification_adherent + " (" + adherent.nom_adherent + ")" }))
            setAdherents(data)
        }
        else {
            getAllAdherent()
            // Message de chargement des adherents
            isLoading ? toast.info("Chargement des adherents...", { containerId: 'App' }) : toast.dismiss()
        }
    }, [isLoading])

    // Récupérer tous les intervenants
    const handleGetAllIntervenant = async (value, qualif) => {
        if (value !== "empty") {
            getAllIntervenant(value, qualif)
        }
    }

    const handleChangeActivites = (numero) => {
        let newActivites = activites
        newActivites.length = 0
        let adherent = adherents.filter(adherent => adherent.numero_adherent === numero)[0]
        let activite = null
        if (qualifie === true) {
            if (adherent.tax === true &&
                adherent.gaz === true &&
                adherent.opa === true &&
                adherent.tco === true &&
                adherent.aut === true) {
                activite = activiteList.filter(activite => {
                    return activite.numero === 0
                })[0]
                newActivites.push(activite)
            } else {
                if (adherent.tax === true) {
                    activite = activiteList.filter(activite => {
                        return activite.numero === 1
                    })[0]
                    newActivites.push(activite)
                }
                if (adherent.gaz === true && adherent.opa === true) {
                    activite = activiteList.filter(activite => {
                        return activite.numero === 2
                    })[0]
                    newActivites.push(activite)
                }
                if (adherent.tco === true) {
                    activite = activiteList.filter(activite => {
                        return activite.numero === 4
                    })[0]
                    newActivites.push(activite)
                }
                if (adherent.ead === true) {
                    activite = activiteList.filter(activite => {
                        return activite.numero === 5
                    })[0]
                    newActivites.push(activite)
                }
                if (adherent.aut === true) {
                    activite = activiteList.filter(activite => {
                        return activite.numero === 6
                    })[0]
                    newActivites.push(activite)
                }
            }
        } else {
            if (adherent.numero_adherent === 0 &&
                adherent.tax === true &&
                adherent.gaz === true &&
                adherent.opa === true &&
                adherent.tco === true &&
                adherent.aut === true) {
                activite = activiteList.filter(activite => {
                    return activite.numero === 0
                })[0]
                newActivites.push(activite)
            } else {
                activite = activiteList.filter(activite =>
                    activite.numero === 1 || activite.numero === 2 || activite.numero === 4 || activite.numero === 5)
                newActivites = activite
            }
        }
        setActivites(newActivites)
    }

    // Récupérer toutes les supervisions
    const handleGetSupervisionDate = async (values) => {
        const saisie = {
            adherent: parseInt(values.adherent),
            intervenant: parseInt(values.intervenant),
            activite: parseInt(values.activite)
        }
        await supervisionService.getSupervisionDate(saisie)
            .then(
                response => {
                    setSupervisions(response)
                    setError(null)
                    toast.success("Supervisions trouvées !", { containerId: 'App' })
                }
            )
            .catch(
                error => {
                    setSupervisions(null)
                    setError(error)
                    toast.warning(error, { containerId: 'App' })
                }
            )
    }

    const handleChangeIntervenant = (e, setFieldValue, values) => {
        setFieldValue('intervenant', e.target.value)
        values.intervenant = e.target.value
        if (values.intervenant === "empty" || values.intervenant === "") {
            setNewdisabled(true)
        } else {
            setNewdisabled(false)
        }
        // Vérification que tous les champs sont remplis 
        // avant de chercher les supervisions
        if (isNaN(values.intervenant) === false && values.activite !== "" && isNaN(values.activite) === false && isNaN(values.adherent) === false) {
            handleGetSupervisionDate(values)
        }
        else {
            toast.error("Veuillez d'abord remplir tous les champs.", { containerId: "App" })
        }
    }

    const handleChangeSupervision = (event, setFieldValue) => {
        let valeur = event.target.value
        setFieldValue('supervision', valeur)
        if (valeur !== "empty") {
            let selectedSupervision = supervisions.filter(sup => sup.numero_supervision === parseInt(valeur))[0]
            let _superviseur = selectedSupervision.supervision_collegiale === true ? "Collégiale" : selectedSupervision.superviseur.prenom + " " + selectedSupervision.superviseur.nom
            setSuperviseur(_superviseur)
        }
        else {
            setSuperviseur("Inconnu")
        }
        let index = event.target.selectedIndex
        let option = event.target.childNodes[index]
        let type = null
        setFieldValue('type_supervision', type)
        if (valeur === "empty") {
            type = true
            setFieldValue('type_supervision', type)
            setEditdisabled(true)
        } else {
            type = option.attributes.getNamedItem('data-sup').value === "true" ? true : false
            setFieldValue('type_supervision', type)
            setEditdisabled(false)
        }
    }

    const handleChangeFilter = (values) => {
        let qualification = !qualifie
        setQualifie(qualification)
        getAllAdherent(undefined, qualification)
        if (values.adherent !== 'empty' && values.adherent !== '') {
            getAllIntervenant(values.adherent, qualification)
        }
    }

    const handleSubmit = async (valeurForm) => {
        localStorage.removeItem("general")
        localStorage.removeItem("lienrapport")
        localStorage.removeItem("pointcontrole")
        localStorage.removeItem("focus")
        // Nouvelle Supervision
        if (btn === 2) {
            setLoadingNew(true)
            await supervisionService.rechargerVidePointControle(valeurForm)
                .then(
                    response => {
                        localStorage.setItem("pointcontrole", JSON.stringify(response))
                    }
                )
                .catch(error => setLoadingNew(false))
            await supervisionService.getFocusSupervision({ numero_adherent: valeurForm.adherent, numero_intervenant: valeurForm.intervenant })
                .then(response => localStorage.setItem("focus", JSON.stringify(response)))
                .catch(error => setLoadingNew(false))
            setLoadingNew(false)
            if (window.confirm('Quel est le type de supervision : Ok -> Technique, Annuler -> Documentaire ?')) {
                valeurForm.type_supervision = 0
            } else {
                valeurForm.type_supervision = 1
            }
            valeurForm.numero_supervision = ""
            localStorage.setItem("status", "new")
            localStorage.setItem("presaisie", JSON.stringify(valeurForm))
            history.push('/supervision')
        }
        // Modifier une supervision
        if (btn === 1) {
            localStorage.setItem("presaisie", JSON.stringify(valeurForm))
            setLoadingEdit(true)
            if (valeurForm.supervision !== "" && valeurForm.supervision !== "empty" && valeurForm.supervision !== null) {
                valeurForm.supervision = parseInt(valeurForm.supervision)
                await supervisionService.initSupervision(valeurForm)
                    .then(response => localStorage.setItem("general", JSON.stringify(response)))
                    .catch(error => setLoadingEdit(false))
                await supervisionService.initPointControle(valeurForm)
                    .then(response => localStorage.setItem("pointcontrole", JSON.stringify(response)))
                    .catch(error => setLoadingEdit(false))
                await supervisionService.initLienRapport(valeurForm)
                    .then(response => localStorage.setItem("lienrapport", JSON.stringify(response)))
                    .catch(error => setLoadingEdit(false))
                await supervisionService.getFocusSupervision({ numero_adherent: valeurForm.adherent, numero_intervenant: valeurForm.intervenant })
                    .then(response => localStorage.setItem("focus", JSON.stringify(response)))
                    .catch(error => setLoadingEdit(false))
                setLoadingEdit(false)
                localStorage.setItem("status", "edit")
                history.push('/supervision')
            }
        }
    }

    const filterOptions = (candidate, input) => {
        if (input) {
            return candidate.label.includes(input)
        }
        return true
    }

    return (
        <>
            <NavBar />
            <div className="jumbotron">
                <ToastContainer
                    enableMultiContainer
                    containerId={'A'}
                    position="top-center"
                    autoClose={false}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="container">
                    <div className="presaisie-container">
                        <h1 className="c-mt-3 c-mb-3">
                            Supervision
                        </h1>
                        <Formik
                            enableReinitialize
                            initialValues={data}
                            validate={
                                values => {
                                    const errors = {}
                                    if (isNaN(values.adherent) === true || values.adherent === "empty") {
                                        errors.adherent = "Champ requis"
                                    } if (!values.intervenant || values.intervenant === "empty") {
                                        errors.intervenant = "Champ requis"
                                    } if (!values.activite || values.activite === "empty") {
                                        errors.activite = "Champ requis"
                                    }
                                    return errors
                                }
                            }
                            onSubmit={
                                (values, { setSubmitting }) => {
                                    setTimeout(
                                        () => {
                                            values.adherent = parseInt(values.adherent)
                                            values.intervenant = parseInt(values.intervenant)
                                            values.activite = parseInt(values.activite)
                                            values.type_supervision = values.type_supervision === true ? 1 : 0
                                            setSubmitting(false)
                                            handleSubmit(values)
                                        }, 400
                                    )
                                }
                            }
                        >
                            {
                                ({ setFieldValue, values }) => (
                                    <Form>
                                        <div className="supervision-filtre checkbox-group">
                                            <div className="filter-option">
                                                <Switch
                                                    className="switch"
                                                    name="type_supervision"
                                                    classes={lovelyStyles}
                                                    checked={qualifie}
                                                    onChange={e => handleChangeFilter(values)}
                                                />
                                                <label className="options-label">Afficher les Adh/Interv qualifiés</label>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-adherent">
                                            <label htmlFor="adherent">Adherent</label>
                                            {adherents &&
                                                <Select
                                                    name="adherent"
                                                    defaultValue={0}
                                                    onChange={(values) => { setFieldValue('adherent', values.value); setFieldValue('activite', "empty"); setFieldValue('intervenant', "empty"); setFieldValue('supervision', "empty"); handleGetAllIntervenant(values.value, qualifie); handleChangeActivites(parseInt(values.value)) }}
                                                    options={adherents}
                                                    filterOption={filterOptions}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#e7ebf3',
                                                            primary: '#604888',
                                                        },
                                                    })}
                                                />
                                            }
                                            <ErrorMessage name="adherent" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="activite">Activité</label>
                                            <Field name="activite" as="select" className="form-control">
                                                <option value="empty"> ------ </option>
                                                {activites.map((item, i) =>
                                                    <option key={i} value={item.numero}>
                                                        {item.nom}
                                                    </option>
                                                )}
                                            </Field>
                                            <ErrorMessage name="activite" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="intervenant">Intervenant</label>
                                            <Field name="intervenant" as="select" className="form-control" onChange={(e) => handleChangeIntervenant(e, setFieldValue, values)}>
                                                <option value="empty"> ------ </option>
                                                {intervenants && intervenants.map((item, i) =>
                                                    <option key={i} value={item.numero_intervenant}>
                                                        {item.prenom + ' ' + item.nom}
                                                    </option>
                                                )}
                                            </Field>
                                            <ErrorMessage name="intervenant" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="supervision">Supervisions</label>
                                            <Field as="select" name="supervision" className="form-control" onChange={(e) => handleChangeSupervision(e, setFieldValue)}>
                                                <option value="empty">------</option>
                                                {supervisions && supervisions.map((supervision, i) =>
                                                    <option
                                                        className={supervision.type_supervision === true ? "sup-doc" : "sup-tech"}
                                                        key={i}
                                                        data-sup={supervision.type_supervision}
                                                        value={supervision.numero_supervision}>
                                                        {(supervision.type_supervision === true ? "DOC" : "TECH") + " " + ((supervision.date_supervision === undefined) ? "A COMPLETER" : formatDate(supervision.date_supervision)) + " " + (supervision.resultat === 1 ? "OK" : "Refus")}
                                                    </option>
                                                )}
                                            </Field>
                                            <ErrorMessage name="supervision" component="div" className="text-danger" />
                                        </div>
                                        <div className="form-group">
                                            <div className="focus">{"Superviseur : " + superviseur}</div>
                                        </div>
                                        <button className={"cbtn cbtn-l cbtn-edit " + (editdisabled === true ? "disabled" : "")} type="submit" disabled={editdisabled} onClick={() => setBtn(1)}>
                                            {loadingEdit ? <UseAnimations strokeColor={'#fff'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                                : "Modifier"
                                            }
                                        </button>
                                        <button className={"cbtn cbtn-l cbtn-success " + (newdisabled === true ? "disabled" : "")} type="submit" disabled={newdisabled} onClick={() => setBtn(2)}>
                                            {loadingNew ? <UseAnimations strokeColor={'#fff'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                                : "Nouvelle"
                                            }
                                        </button>
                                    </Form>
                                )
                            }
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreSupervisionPage