import { Field, Form, Formik } from "formik"

const Visuel = () => {

    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container">
                            <div className="reception-header d-flex flex-wrap flex-column">
                                {/* Rappel */}
                                <div className="form-row w-100">
                                    <div className="form-row w-100">
                                        <div className="form-cell-input m-w-100 w-100">
                                            <div className="form-input">
                                                <Field name="anomalies_initiales" as="textarea" className="form-control" disabled
                                                    value={'Vérifications :\n' +
                                                        'Scellements, Etiquettes, marque, type, série, logiciel, certificat,...)'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Champ de texte */}
                            <div className="form-row">
                                <div className="form-cell-input">
                                    <div className="form-input">
                                        <Field name="marque_vignette" className="form-control input-small" type="text" />
                                    </div>
                                </div>
                            </div>
                            {/* Image */}
                            <div className="form-row">
                                <div className="form-img-container">
                                    <img src="" alt="Ticket" />
                                </div>
                            </div>
                            {/* Image */}
                            <div className="form-row">
                                <div className="form-img-container">
                                    <img src="" alt="Ticket" />
                                </div>
                            </div>
                            {/* Image */}
                            <div className="form-row">
                                <div className="form-img-container">
                                    <img src="" alt="Ticket" />
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Visuel