import React, { useEffect, useRef, useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { IoMdCloseCircle } from "react-icons/io"
import { CSSTransition } from 'react-transition-group'
/* ------------------------------------------- */
import { Formik, Form } from "formik"
import { useSaisieContext } from "../SaisieContext"
import localForage from 'localforage'
import { supervisionService } from "../../../../services/supervisionService"
import { toast } from "react-toastify"
import { isNullOrUndefined } from "../../../../helpers/utils"
import Compress from "react-image-file-resizer"
import { useDataContext } from "../../../../providers/DataContextProvider"
/* ------------------------------------------- */

const Photos = (props) => {

    const [intervention] = useState(JSON.parse(localStorage.getItem("intervention")))
    const [photo, setPhoto] = useState({ open: false })
    const [photos, setPhotos] = useState({})
    const formikRef = useRef()
    const { setFormPhotos } = useSaisieContext()
    const { selectedIntervention } = useDataContext()
    const data = {
        numero_adherent: intervention.numero_adherent,
        numero_client: intervention.numero_client,
        clef_stationnement: intervention.clef_stationnement,
        clef_lien: intervention.clef_lien,
        numero_intervention: intervention.numero_intervention,
        date_intervention: intervention.date_intervention,
    }

    useEffect(() => {
        localForage.getItem('photos', (err, value) => {
            setFormPhotos(value)
            if (!value.length) {
                // Pas de photos dans la base
                for (let i = 1; i <= 6; i++) {
                    setPhotos(prev => ({ ...prev, ["photo_" + i]: null }))
                }
            } else {
                // Photos existantes dans la base
                // Recuperer les photos de la base
                for (let i = 1; i <= value.length; i++) {
                    if (value[i - 1].numero_photo !== 20) {
                        setPhotos(prev => ({ ...prev, ["photo_" + value[i - 1].numero_photo]: value[i - 1].photo_detail }))
                    }
                }
            }
        })
    }, [])

    const openImage = (event, index) => {
        setPhoto({
            open: true,
            image: photos["photo_" + index]
        })
    }

    const closeImage = () => {
        setPhoto({
            open: false
        })
    }

    const compressImage = (file, setFieldValue, index) => {
        Compress.imageFileResizer(
            file, // fichier file de l'input
            200, // width
            200, // height
            "PNG", // compress format WEBP, JPEG, PNG
            100, // qualité
            0, // rotation
            (uri) => {
                setPhotos({
                    ...photos,
                    ["photo_" + index]: uri.split(',')[1]
                })
                setFieldValue("photo_" + index, uri.split(',')[1])
                let photosArray = []
                localForage.getItem('photos', (err, value) => {
                    photosArray = value
                    let photoEdited = photosArray.filter(photo => photo.numero_photo === index)[0]
                    process.env.NODE_ENV === "development" && console.log(photoEdited)
                    // On modifie une photo existante
                    if (photoEdited !== undefined) {
                        photoEdited.photo_detail = uri.split(',')[1]
                        let newPhotosArray = photosArray.filter(photo => photo.numero_photo !== index)
                        newPhotosArray.push(photoEdited)
                    }
                    // On ajoute une photo
                    else {
                        const currentIntervention = selectedIntervention.data
                        photosArray.push({
                            clef_lien: currentIntervention.clef_lien,
                            clef_stationnement: currentIntervention.clef_stationnement,
                            numero_adherent: currentIntervention.numero_adherent,
                            numero_client: currentIntervention.numero_client,
                            numero_intervention: currentIntervention.numero_intervention,
                            numero_photo: index,
                            photo_detail: uri.split(',')[1]
                        })
                    }
                    localForage.setItem('photos', photosArray)
                    setFormPhotos(photosArray)
                })
            },
            "base64" // blob ou base64 par défaut base64
        )
    }

    const uploadPhoto = (event, setFieldValue, index) => {
        let reader = new FileReader()
        const file = event.currentTarget.files[0]
        reader.readAsDataURL(event.currentTarget.files[0])
        reader.onloadend = (e) => {
            // Recupération de la partie binaire
            if (reader.result !== null) {
                compressImage(file, setFieldValue, index)
            }
        }
    }

    // Supprimer une photo
    const handleDelete = (event, setFieldValue, index) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer la photo' + index + ' ?')) {
            setPhotos({
                ...photos,
                ["photo_" + index]: null
            })
            setFieldValue("photo_" + index, null)
            let photosArray = []
            localForage.getItem('photos', (err, value) => {
                photosArray = value
                photosArray = photosArray.filter(photo => photo.numero_photo !== index)
                setFormPhotos(photosArray)
                localForage.setItem('photos', photosArray)
            })
        } else {
            // Do nothing!
        }
    }

    // Style de la table
    const useStyles = makeStyles({
        table: {
            width: "100%",
        },
    })

    const classes = useStyles()

    const handleEgalPrecedent = (e, setValues, setFieldValue) => {
        supervisionService.egalePrecedentPhotos(data)
            .then(response => {
                let photo_1 = response.find(element => element.numero_photo === 1)
                let photo_2 = response.find(element => element.numero_photo === 2)
                let photo_3 = response.find(element => element.numero_photo === 3)
                let photo_4 = response.find(element => element.numero_photo === 4)
                let photo_5 = response.find(element => element.numero_photo === 5)
                let photo_6 = response.find(element => element.numero_photo === 6)

                setPhotos({
                    ...photos,
                    photo_1: photo_1 ? photo_1.photo_detail : null,
                    photo_2: photo_2 ? photo_2.photo_detail : null,
                    photo_3: photo_3 ? photo_3.photo_detail : null,
                    photo_4: photo_4 ? photo_4.photo_detail : null,
                    photo_5: photo_5 ? photo_5.photo_detail : null,
                    photo_6: photo_6 ? photo_6.photo_detail : null,
                })
                setFieldValue("photo_1", photo_1 ? photo_1.photo_detail : null)
                setFieldValue("photo_2", photo_2 ? photo_2.photo_detail : null)
                setFieldValue("photo_3", photo_3 ? photo_3.photo_detail : null)
                setFieldValue("photo_4", photo_4 ? photo_4.photo_detail : null)
                setFieldValue("photo_5", photo_5 ? photo_5.photo_detail : null)
                setFieldValue("photo_6", photo_6 ? photo_6.photo_detail : null)

                let photosArray = []
                let result = []
                localForage.getItem('photos', (err, value) => {
                    photosArray = value
                    let photoArrayWith20 = photosArray.filter(photo => photo.numero_photo === 20)
                    process.env.NODE_ENV === "development" && console.log(photoArrayWith20)
                    if (photoArrayWith20.length !== 0) {
                        result.push(photoArrayWith20[0])
                    }
                    if (!isNullOrUndefined(photo_1)) {
                        photo_1.numero_intervention = intervention.numero_intervention
                        result.push(photo_1)
                    }
                    if (!isNullOrUndefined(photo_2)) {
                        photo_2.numero_intervention = intervention.numero_intervention
                        result.push(photo_2)
                    }
                    if (!isNullOrUndefined(photo_3)) {
                        photo_3.numero_intervention = intervention.numero_intervention
                        result.push(photo_3)
                    }
                    if (!isNullOrUndefined(photo_4)) {
                        photo_4.numero_intervention = intervention.numero_intervention
                        result.push(photo_4)
                    }
                    if (!isNullOrUndefined(photo_5)) {
                        photo_5.numero_intervention = intervention.numero_intervention
                        result.push(photo_5)
                    }
                    if (!isNullOrUndefined(photo_6)) {
                        photo_6.numero_intervention = intervention.numero_intervention
                        result.push(photo_6)
                    }
                    localForage.setItem('photos', result)
                    setFormPhotos(result)
                })
                toast.success("Dernière intervention trouvée. Modification effectuée.", { containerId: 'PRE' })
            })
            .catch(error => toast.info(error, { containerId: 'PRE' }))
    }

    return (
        <div className="infos-section">
            <div className="infos-section-container">
                <div>
                    <CSSTransition
                        in={photo.open}
                        timeout={300}
                        onExit={closeImage}
                        classNames="exemple"
                        unmountOnExit
                    >
                        <div className={"show-image" + (photo.open === true ? " show" : "")}>
                            <button className="btn" onClick={closeImage}>
                                <IoMdCloseCircle />
                            </button>
                            <div className="photo-saisie">
                                <input type="checkbox" className="zoomCheck" id="zoomCheck1" />
                                <label htmlFor="zoomCheck1">
                                    <img src={photo && photo.image ? "data:image/png;base64," + photo.image : ""} alt="Pas de photo1 pour cette saisie" />
                                </label>
                            </div>
                        </div>
                    </CSSTransition>
                    <div className="saisie-photo">
                        <div className="table-container">
                            <Formik
                                innerRef={formikRef}
                                enableReinitialize
                                initialValues={photos}
                                validateOnMount={true}
                                validateOnChange={true}
                                validateOnBlur={true}
                            >
                                {({ values, setFieldValue, setValues }) => (
                                    <Form>
                                        {/* Egale precedent */}
                                        <div className="section-precedent">
                                            <button type="button" className="cbtn cbtn-success mt-0 mb-2" onClick={e => handleEgalPrecedent(e, setValues, setFieldValue)}>Egal précédent</button>
                                        </div>
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Photos</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <div className="button-document-container">
                                                                {values && values.photo_1 ?
                                                                    <button id="photo1" type="button" className="btn btn-document" onClick={e => openImage(e, 1)}>
                                                                        Photo 1
                                                                        <i className="fas fa-eye"></i>
                                                                    </button>
                                                                    :
                                                                    "Pas de photo1 pour cette saisie"
                                                                }
                                                            </div>
                                                            <div className="action-button-container">
                                                                <input
                                                                    type="file"
                                                                    name="photo_1"
                                                                    id="photo_1"
                                                                    className="photo-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    onChange={e => uploadPhoto(e, setFieldValue, 1)}
                                                                    key={Date.now() + 1}
                                                                />
                                                                <label htmlFor="photo_1">Ajouter ou modifier</label>
                                                                <button id="del1" className="photo-action-button" type="button" onClick={e => handleDelete(e, setFieldValue, 1)}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={!values.photo_1 && !values.photo_2 ? "c-disabled" : undefined}>
                                                            <div className="button-document-container">
                                                                {values && values.photo_2 ?
                                                                    <button id="photo2" type="button" className="btn btn-document" onClick={(e) => openImage(e, 2)}>
                                                                        Photo 2
                                                                        <i className="fas fa-eye"></i>
                                                                    </button>
                                                                    :
                                                                    "Pas de photo2 pour cette saisie"
                                                                }
                                                            </div>
                                                            <div className="action-button-container">
                                                                <input
                                                                    type="file"
                                                                    name="photo_2"
                                                                    id="photo_2"
                                                                    className="photo-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    onChange={e => { uploadPhoto(e, setFieldValue, 2) }}
                                                                    key={Date.now() + 2}
                                                                    disabled={!values.photo_1 && !values.photo_2 ? true : undefined}
                                                                />
                                                                <label htmlFor="photo_2">Ajouter ou modifier</label>
                                                                <button id="del2" className="photo-action-button" type="button" onClick={e => handleDelete(e, setFieldValue, 2)} disabled={!values.photo_1 && !values.photo_2 ? true : undefined}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={!values.photo_2 && !values.photo_3 ? "c-disabled" : undefined}>
                                                            <div className="button-document-container">
                                                                {values && values.photo_3 ?
                                                                    <button id="photo3" type="button" className="btn btn-document" onClick={(e) => openImage(e, 3)}>
                                                                        Photo 3
                                                                        <i className="fas fa-eye"></i>
                                                                    </button>
                                                                    :
                                                                    "Pas de photo3 pour cette saisie"
                                                                }
                                                            </div>
                                                            <div className="action-button-container">
                                                                <input
                                                                    type="file"
                                                                    name="photo_3"
                                                                    id="photo_3"
                                                                    className="photo-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    onChange={e => { uploadPhoto(e, setFieldValue, 3) }}
                                                                    key={Date.now() + 3}
                                                                    disabled={!values.photo_2 && !values.photo_3 ? true : undefined}
                                                                />
                                                                <label htmlFor="photo_3">Ajouter ou modifier</label>
                                                                <button id="del3" className="photo-action-button" type="button" onClick={e => handleDelete(e, setFieldValue, 3)} disabled={!values.photo_2 && !values.photo_3 ? true : undefined}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={!values.photo_3 && !values.photo_4 ? "c-disabled" : undefined}>
                                                            <div className="button-document-container">
                                                                {values && values.photo_4 ?
                                                                    <button id="photo4" type="button" className="btn btn-document" onClick={(e) => openImage(e, 4)}>
                                                                        Photo 4
                                                                        <i className="fas fa-eye"></i>
                                                                    </button>
                                                                    :
                                                                    "Pas de photo4 pour cette saisie"
                                                                }
                                                            </div>
                                                            <div className="action-button-container">
                                                                <input
                                                                    type="file"
                                                                    name="photo_4"
                                                                    id="photo_4"
                                                                    className="photo-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    onChange={e => { uploadPhoto(e, setFieldValue, 4) }}
                                                                    key={Date.now() + 4}
                                                                    disabled={!values.photo_3 && !values.photo_4 ? true : undefined}
                                                                />
                                                                <label htmlFor="photo_4">Ajouter ou modifier</label>
                                                                <button id="del4" className="photo-action-button" type="button" onClick={e => handleDelete(e, setFieldValue, 4)} disabled={!values.photo_3 && !values.photo_4 ? true : undefined}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={!values.photo_4 && !values.photo_5 ? "c-disabled" : undefined}>
                                                            <div className="button-document-container">
                                                                {values && values.photo_5 ?
                                                                    <button id="photo5" type="button" className="btn btn-document" onClick={(e) => openImage(e, 5)}>
                                                                        Photo 5
                                                                        <i className="fas fa-eye"></i>
                                                                    </button>
                                                                    :
                                                                    "Pas de photo5 pour cette saisie"
                                                                }
                                                            </div>
                                                            <div className="action-button-container">
                                                                <input
                                                                    type="file"
                                                                    name="photo_5"
                                                                    id="photo_5"
                                                                    className="photo-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    onChange={e => { uploadPhoto(e, setFieldValue, 5) }}
                                                                    key={Date.now() + 5}
                                                                    disabled={!values.photo_4 && !values.photo_5 ? true : undefined}
                                                                />
                                                                <label htmlFor="photo_5">Ajouter ou modifier</label>
                                                                <button id="del5" className="photo-action-button" type="button" onClick={e => handleDelete(e, setFieldValue, 5)} disabled={!values.photo_4 && !values.photo_5 ? true : undefined}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={!values.photo_5 && !values.photo_6 ? "c-disabled" : undefined}>
                                                            <div className="button-document-container">
                                                                {values && values.photo_6 ?
                                                                    <button id="photo2" type="button" className="btn btn-document" onClick={(e) => openImage(e, 6)}>
                                                                        Photo 6
                                                                        <i className="fas fa-eye"></i>
                                                                    </button>
                                                                    :
                                                                    "Pas de photo6 pour cette saisie"
                                                                }
                                                            </div>
                                                            <div className="action-button-container">
                                                                <input
                                                                    type="file"
                                                                    name="photo_6"
                                                                    id="photo_6"
                                                                    className="photo-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    onChange={e => { uploadPhoto(e, setFieldValue, 6) }}
                                                                    key={Date.now() + 6}
                                                                    disabled={!values.photo_5 && !values.photo_6 ? true : undefined}
                                                                />
                                                                <label htmlFor="photo_6">Ajouter ou modifier</label>
                                                                <button id="del6" className="photo-action-button" type="button" onClick={e => handleDelete(e, setFieldValue, 6)} disabled={!values.photo_5 && !values.photo_6 ? true : undefined}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Photos