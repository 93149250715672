import { useState, useEffect } from "react"
import { Field } from 'formik'
import { supervisionService } from "../../../../services/supervisionService"
import { formatDate } from "../../../../helpers/utils"
import Checkbox from '@material-ui/core/Checkbox'
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import { IoMdClose } from "react-icons/io"

const CetsLogiciel = (props) => {

    const [modelesLog, setModelesLog] = useState([])
    const [selectedModele, setSelectedModele] = useState(null)
    const [file, setFile] = useState(null)
    const [loadingData, setLoadingData] = useState(true)

    useEffect(() => {

        let marque = props.infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_appareil
        let modele = props.infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.modele_appareil

        async function getModelesLog(marque, modele) {
            await supervisionService.getModelesLog(marque, modele)
                .then(response => setModelesLog(response))
        }

        async function getModeleLog(marque, modele, logiciel) {
            await supervisionService.getModeleLog(marque, modele, logiciel)
                .then(response => {
                    setSelectedModele(response)
                    supervisionService.getDocumentListeByNumeroDocument(response.numero_document_cet)
                        .then(response => { setFile(response); setLoadingData(false) })
                })
                .catch(response => process.env.NODE_ENV === "development" && console.log(console.error))
        }

        getModelesLog(marque, modele)
        getModeleLog(marque, modele, 1)

    }, [])

    const handleChange = (e, setFieldValue) => {
        var index = e.nativeEvent.target.selectedIndex
        setSelectedModele(modelesLog[index])
        setFieldValue("numero_cet", e.target.value)
        loadFile(modelesLog[index].numero_document_cet)
    }

    // Chargement du document de la signature
    const loadFile = async (numero) => {
        await supervisionService.getDocumentListeByNumeroDocument(numero)
            .then(response => setFile(response))
            .catch(error => setFile(null))
    }

    // Ouvre un document 
    const openDocument = (event, afile) => {

        var byteCharacters = atob(afile.fichier_contenu);
        var byteNumbers = new Array(byteCharacters.length)
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers)
        var file = new Blob([byteArray], { type: 'application/' + afile.extension_fichier.split[1] + ';base64' })
        var fileURL = URL.createObjectURL(file)

        var element = document.createElement('a')
        element.setAttribute('href', fileURL)
        element.setAttribute('download', afile.fichier_original)
        document.body.appendChild(element)
        element.click()
        setTimeout(function () {
            // Necessaire pour Firefox
            window.URL.revokeObjectURL(fileURL)
        }, 100)
        document.body.removeChild(element)

    }

    return (
        <div className="popup-cover">
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        {props.title}
                    </h3>
                    <div className="popup-close-button" onClick={props.close}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-body">
                    <div className="document-infos">
                        {
                            loadingData ?
                                <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={70} style={{ padding: 20 }} wrapperStyle={{ color: 'red', marginLeft: 'auto', marginRight: 'auto' }} /> : <>
                                    <div className="form-group">
                                        <div className="form-row">
                                            <div className="form-col">
                                                <label>CET</label>
                                                <Field name="numero_cet" as="select" className="form-control" autoComplete="off" onChange={e => handleChange(e, props.setFieldValue)}>
                                                    {modelesLog && modelesLog.map((m, i) =>
                                                        <option value={m.numero_document_cet} key={i}>{"Signature " + m.signature + " (" + formatDate(m.acceptation) + " N° : " + m.dam_cet + ")"}</option>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="form-col ml-5">
                                                <div className="button-document-container">
                                                    <div className="rapport-sup-button">
                                                        <button disabled={(file && file.nom_fichier) ? false : true} type="button" className="btn btn-document" onClick={e => openDocument(e, file)}>
                                                            <span>{file ? file.nom_fichier : "Non renseigné"}</span>
                                                            <i className="fas fa-eye"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-row">
                                            <div className="form-col">
                                                <label htmlFor="dam_cet">Numéro CET</label>
                                                <Field name="dam_cet" type="text" className="form-control" autoComplete="off" disabled value={selectedModele && selectedModele.dam_cet} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-row">
                                            <div className="form-col">
                                                <label htmlFor="version_logiciel">Version Logiciel</label>
                                                <Field name="version_logiciel" type="text" className="form-control" autoComplete="off" disabled value={selectedModele && selectedModele.version_logiciel} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-row">
                                            <div className="form-col">
                                                <label htmlFor="signature">Signature Logiciel</label>
                                                <Field name="signature" className="form-control" autoComplete="off" disabled value={selectedModele && selectedModele.signature} />
                                            </div>
                                            <div className="form-col">
                                                <Checkbox
                                                    checked={selectedModele && selectedModele.interdit}
                                                    name="interdit"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                                <label htmlFor="signature">Logiciel Interdit</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-row">
                                            <div className="form-col">
                                                <label htmlFor="acceptation">Date</label>
                                                <Field name="acceptation" className="form-control" autoComplete="off" disabled value={selectedModele && formatDate(selectedModele.acceptation)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-row">
                                            <div className="form-col">
                                                <label htmlFor="autres">Détail</label>
                                                <Field name="autres" as="textarea" className="form-control" autoComplete="off" disabled value={selectedModele && selectedModele.autres} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CetsLogiciel