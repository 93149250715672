import React, { useState } from "react"
import Intervention from "../components/Intervention/Intervention"
import PreIntervention from "../components/Intervention/PreIntervention"
import { SaisieContextProvider } from "../components/Intervention/Saisie/SaisieContext"
import SaisieIntervention from "../components/Intervention/Saisie/SaisieIntervention"
import SaisieInterventionTachy from "../components/Intervention/Saisie/Tachy/SaisieInterventionTachy"
import NavBar from "../components/NavBar/NavBar"

const InterventionPage = () => {

    const [interventions, setinterventions] = useState(null)
    const [child, setchild] = useState(null)

    const getInterventions = (value) => {
        setinterventions(value)
    }

    const triggerShowChild = (activite) => {
        if (activite === 1) {
            setchild("SHOW_SAISIE_TAXI")
        }
        else if (activite === 4) {
            setchild("SHOW_SAISIE_TACHY")
        }
    }

    return (
        <>
            <NavBar />
            <div className="container">
                <div className="intervention mt-section">
                    {child !== "SHOW_SAISIE_TAXI" && child !== "SHOW_SAISIE_TACHY" &&
                        <PreIntervention passValuesToParent={getInterventions} />
                    }
                    {child !== "SHOW_SAISIE_TAXI" && child !== "SHOW_SAISIE_TACHY" && interventions &&
                        <Intervention interventions={interventions} startSaisie={triggerShowChild} />
                    }
                    {child === "SHOW_SAISIE_TAXI" &&
                        <SaisieContextProvider>
                            <SaisieIntervention />
                        </SaisieContextProvider>
                    }
                    {child === "SHOW_SAISIE_TACHY" &&
                        <SaisieInterventionTachy />
                    }
                </div>
            </div>
        </>
    )

}

export default InterventionPage
