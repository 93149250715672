import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { IoMdCloseCircle } from "react-icons/io"
import { CSSTransition } from 'react-transition-group'
import { Formik, Form, useFormikContext } from "formik"
import Compress from "react-image-file-resizer"

const Photo = (props) => {

    const [photo, setPhoto] = useState({ open: false })
    const [photos, setPhotos] = useState({ photo_1: null, photo_2: null })

    useEffect(() => {
        if (localStorage.getItem('general')) {
            setPhotos({
                photo_1: JSON.parse(localStorage.getItem('general')).photo_1 ? JSON.parse(localStorage.getItem('general')).photo_1 : null,
                photo_2: JSON.parse(localStorage.getItem('general')).photo_2 ? JSON.parse(localStorage.getItem('general')).photo_2 : null
            })
        }
    }, [])

    const openImage = (event, index) => {
        setPhoto({
            open: true,
            image: photos["photo_" + index]
        })
    }

    const closeImage = () => {
        setPhoto({
            open: false
        })
    }

    const compressImage = (file, setFieldValue, index) => {
        Compress.imageFileResizer(
            file, // fichier file de l'input
            200, // width
            200, // height
            "JPEG", // compress format WEBP, JPEG, PNG
            80, // qualité
            0, // rotation
            (uri) => {
                setPhotos({
                    ...photos,
                    ["photo_" + index]: uri.split(',')[1]
                })
                setFieldValue("photo_" + index, uri.split(',')[1])
            },
            "base64" // blob ou base64 par défaut base64
        )
    }

    const uploadPhoto = (event, setFieldValue, index) => {
        let reader = new FileReader()
        const file = event.currentTarget.files[0]
        reader.readAsDataURL(event.currentTarget.files[0])
        reader.onloadend = (e) => {
            // Recupération de la partie binaire
            if (reader.result !== null) {
                compressImage(file, setFieldValue, index)
            }
        }
    }

    const handleDelete = (event, index) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer la photo_' + index + ' ?')) {
            setPhotos({
                ...photos,
                ["photo_" + index]: null
            })
        }
    }

    // Style de la table
    const useStyles = makeStyles({
        table: {
            width: "100%",
        },
    })

    const classes = useStyles()

    return (
        <div>
            <CSSTransition
                in={photo.open}
                timeout={300}
                onExit={closeImage}
                classNames="exemple"
                unmountOnExit
            >
                <div className={"show-image" + (photo.open === true ? " show" : "")}>
                    <button className="btn" onClick={closeImage}>
                        <IoMdCloseCircle />
                    </button>
                    <div className="photo-supervision">
                        <input type="checkbox" className="zoomCheck" id="zoomCheck1" />
                        <label htmlFor="zoomCheck1">
                            <img src={photo && photo.image ? "data:image/jpeg;base64," + photo.image : ""} alt="Pas de photo1 pour cette supervision" />
                        </label>
                    </div>
                </div>
            </CSSTransition>
            <div className="supervision-photo">
                <div className="table-container">
                    <Formik
                        enableReinitialize
                        initialValues={photos}
                        validateOnMount={true}
                        validateOnChange={false}
                        validateOnBlur={true}
                        validate={(values) => {
                            const errors = {}
                            props.passFormToParent(values, errors)
                        }}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Photos</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <div className="button-document-container">
                                                        {values && values.photo_1 ?
                                                            <button id="photo1" type="button" className="btn btn-document" onClick={e => openImage(e, 1)}>
                                                                Photo 1
                                                                <i className="fas fa-eye"></i>
                                                            </button>
                                                            :
                                                            "Pas de photo1 pour cette supervision"
                                                        }
                                                    </div>
                                                    <div className="action-button-container">
                                                        <input
                                                            type="file"
                                                            name="photo_1"
                                                            id="photo_1"
                                                            className="photo-input"
                                                            accept="image/x-png,image/gif,image/jpeg"
                                                            onChange={e => uploadPhoto(e, setFieldValue, 1)}
                                                            key={Date.now() + 1}
                                                        />
                                                        <label htmlFor="photo_1">Ajouter ou modifier</label>
                                                        <button id="del1" className="photo-action-button" type="button" onClick={e => handleDelete(e, 1)}>
                                                            <i className="fas fa-trash-alt"></i>
                                                        </button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <div className="button-document-container">
                                                        {values && values.photo_2 ?
                                                            <button id="photo2" type="button" className="btn btn-document" onClick={(e) => openImage(e, 2)}>
                                                                Photo 2
                                                                <i className="fas fa-eye"></i>
                                                            </button>
                                                            :
                                                            "Pas de photo2 pour cette supervision"
                                                        }
                                                    </div>
                                                    <div className="action-button-container">
                                                        <input
                                                            type="file"
                                                            name="photo_2"
                                                            id="photo_2"
                                                            className="photo-input"
                                                            accept="image/x-png,image/gif,image/jpeg"
                                                            onChange={e => { uploadPhoto(e, setFieldValue, 2) }}
                                                            key={Date.now() + 1}
                                                        />
                                                        <label htmlFor="photo_2">Ajouter ou modifier</label>
                                                        <button id="del2" className="photo-action-button" type="button" onClick={e => handleDelete(e, 2)}>
                                                            <i className="fas fa-trash-alt"></i>
                                                        </button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <AutoSubmitPhoto />
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

const AutoSubmitPhoto = () => {
    const { values, validateForm } = useFormikContext()

    useEffect(() => {
        if (values) {
            validateForm()
        }
    }, [])

    return null
}

export default Photo