import history from "../helpers/history"
import player from "./../res/player.svg"

export const Profil = (props) => {

    const { user } = props

    const handleLogout = (e) => {
        e.preventDefault()
        history.push('/logout')
    }

    return (
        <div className={"profil" + (props.open === true ? " profil-expand" : "")}>
            <div className="profil-container">
                <div className="profil-header">
                    <div className="profil-header-title">
                        <h2 className="bf">
                            Connecté :
                        </h2>
                        <h2 className="user-name">
                            {user && user.given_name + " " + user.family_name}
                        </h2>
                    </div>
                </div>
                <div className="profil-body">
                    <div className="profil-user-infos">
                        <div className="profil-user-image">
                            <img className="profil-user-img" src={(props.avatar) ? "data:image/jpeg;base64," + props.avatar : player} alt="Profil" />
                        </div>
                        <div className="profil-user-details">
                            <label>Email : </label>
                            <h2 className="user-email">
                                {user && (user.email === null || user.email === "") ?
                                    <span className="text-danger">Non renseigné</span> :
                                    user.email
                                }
                            </h2>
                            <label>Login : </label>
                            <h2 className="user-login">
                                {user && user.preferred_username}
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="profil-user-actions">
                    <form onSubmit={handleLogout}>
                        <div className="logout">
                            <button className="logout-button disableoutline">Se déconnecter</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}