import { Field, Form, Formik } from "formik"
import { Table } from "react-bootstrap"

const Simulation = () => {

    return (
        <div className="receptionp-section">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue, setValues, handleChange }) => (
                    <Form autoComplete="off">
                        <div className="affichage-form-container etiquette">
                            <div className="form-row">
                                <div className="form-cell-input">
                                    <div className="form-input">
                                        <button type="button" className="cbtn cbtn-success mt-0 mb-2">
                                            30 km = Idéales
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Rappel */}
                            <div className="form-row w-100">
                                <div className="form-row w-100">
                                    <div className="form-cell-input m-w-100 w-100">
                                        <div className="form-input">
                                            <Field name="anomalies_initiales" as="textarea" className="form-control" disabled
                                                value={'Rappel EMT simulation : \nDistance : +- 1 % \nVitesse Affichée : +- 1 km/h'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Valeur de 'k' */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">Valeur de 'k'</div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        <Field name="util_mano" className="form-control" />
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <div className="form-input">
                                        <button type="button" className="cbtn cbtn-success mt-0 mb-2">
                                            = étalonnage
                                        </button>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <div className="form-input">
                                        <button type="button" className="cbtn cbtn-success mt-0 mb-2">
                                            = Plaque
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Variateur */}
                            <div className="form-row">
                                <div className="form-col">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Variateur</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-col m-w-100">
                                    <div className="form-row">
                                        <div className="form-col m-w-100">
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="util_mano" className="form-control" as="select">
                                                        <option value="0">Autre</option>
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <div className="form-input">
                                        <button type="button" className="cbtn cbtn-success mt-0 mb-2">
                                            = 18 min
                                        </button>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <div className="form-input">
                                        <button type="button" className="cbtn cbtn-success mt-0 mb-2">
                                            70 s
                                        </button>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <div className="form-input">
                                        <button type="button" className="cbtn cbtn-success mt-0 mb-2">
                                            4 min
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* 12 km à 180 km/h */}
                            <div className="section-one">
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">12 km à 180 km/h</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    {/* Compteur Départ */}
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Compteur Départ</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control input-small" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">km</div>
                                        </div>
                                    </div>
                                    {/* Valeur Variateur */}
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Valeur Variateur</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control input-small" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">m</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    {/* Compteur Arrivée */}
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Compteur Arrivée</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control input-small" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">km</div>
                                        </div>
                                    </div>
                                    {/* Erreur Distance */}
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Erreur Distance</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control input-small" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    {/* Compteur Arrivée */}
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Distance UEV</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control input-small" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">m</div>
                                        </div>
                                    </div>
                                    {/* Erreur Distance */}
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">EMT Distance</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control input-small" />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    {/* Résultat */}
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Résultat</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control input-small" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Vitesse */}
                            <div className="form-row">
                                <div className="form-col">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Vitesse : 3 paliers ci-dessous sur 18 minutes</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>20 km/h</th>
                                            <th>100 km/h</th>
                                            <th>180 km/h</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Valeur donnée moyen contrôle</td>
                                            <td><Field name="util_mano" className="form-control input-small" />km/h</td>
                                            <td><Field name="util_mano" className="form-control input-small" />km/h</td>
                                            <td><Field name="util_mano" className="form-control input-small" />km/h</td>
                                        </tr>
                                        <tr>
                                            <td>Valeur la plus éloignée lue</td>
                                            <td><Field name="util_mano" className="form-control input-small" />km/h</td>
                                            <td><Field name="util_mano" className="form-control input-small" />km/h</td>
                                            <td><Field name="util_mano" className="form-control input-small" />km/h</td>
                                        </tr>
                                        <tr>
                                            <td>Erreur Maxi</td>
                                            <td><Field name="util_mano" className="form-control input-small" />km/h</td>
                                            <td><Field name="util_mano" className="form-control input-small" />km/h</td>
                                            <td><Field name="util_mano" className="form-control input-small" />km/h</td>
                                        </tr>
                                        <tr>
                                            <td>EMT (avec résolution)</td>
                                            <td><Field name="util_mano" className="form-control input-small" />km/h</td>
                                            <td><Field name="util_mano" className="form-control input-small" />km/h</td>
                                            <td><Field name="util_mano" className="form-control input-small" />km/h</td>
                                        </tr>
                                        <tr>
                                            <td>Résultat</td>
                                            <td><Field name="util_mano" className="form-control input-small" /></td>
                                            <td><Field name="util_mano" className="form-control input-small" /></td>
                                            <td><Field name="util_mano" className="form-control input-small" /></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div >
    )
}

export default Simulation