import React, { useState, useEffect, useRef } from 'react'
import { Formik, Form, ErrorMessage, FastField, useFormikContext } from 'formik'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { Accordion, Card, Button } from 'react-bootstrap'
import Switch from '@material-ui/core/Switch'
import { useLovelySwitchStyles } from '@mui-treasury/styles/switch/lovely'
import Contexte from '../../constants/contexte'
import { useAuth } from '../../providers/authProvider'
import moment from 'moment'
import { fr } from "date-fns/locale"

const General = (props) => {
    // Style de switch
    const lovelyStyles = useLovelySwitchStyles()
    // Collapse 
    const [isOpen, setIsOpen] = useState(true)
    // Chargement de Général
    const [general, setGeneral] = useState(JSON.parse(localStorage.getItem("general")))
    // supervision actuelle si nouveau
    const sup = JSON.parse(localStorage.getItem('presaisie'))
    // Utilisateur actuelle
    let auth = useAuth()
    const user = auth.getLocalUser()
    // Verificateur contexte
    const regexContexte = /^[0-5]$/
    // Verificateur action apres
    const regexActionApres = /^[0-7]$/
    // Verificateur intervalle
    const regexIntervalle = /^(2[0-4]|1[0-9]|[1-9])$/
    // Reference formulaire
    const formikRef = useRef()

    useEffect(() => {
        if (general && (general.date_supervision === null || general.date_supervision === undefined)) {
            general.date_supervision = moment().format()
        }
    }, [])

    // Changement de valeur des checkbox
    const handleCB = (values, setFieldValue) => {
        setGeneral(() => {
            // Si true => 1
            if (values.resultat === 1) {
                // changer en false => 0
                setFieldValue('resultat', parseInt(0))
            } else {
                // changer en vrai => 1
                setFieldValue('resultat', parseInt(1))
            }
        })
    }

    const handleChangeCollegiale = (e) => {
        if (formikRef.current) {
            formikRef.current.setFieldValue("supervision_collegiale", !formikRef.current.values.supervision_collegiale)
        }
    }

    return (
        <div>
            <Formik
                innerRef={formikRef}
                initialValues={general ? general : {
                    numero_adherent: sup.adherent,
                    numero_intervenant: sup.intervenant,
                    numero_activite: sup.activite,
                    numero_supervision: 0,
                    // A changer plutard si application sera utilisée pluatrd par les autres adherents
                    adh_superviseur: 0,
                    int_superviseur: user.user_id,
                    contexte: 0,
                    resultat: 0,
                    mise_a_disposition: false,
                    sur_4x4: false,
                    supervision_collegiale: false,
                    type_supervision: sup.type_supervision,
                    type: 1,
                    numero_oi_sup: '',
                    supervision_visio: false,
                    solde_actions_rq: false,
                    date_supervision: moment().format(),
                    points_forts: '',
                    points_faibles: '',
                    action_apres: 0,
                    detail_action_apres: '',
                    commentaires: '',
                    intervalle_preconise: 1,
                    commentaires_solde_rq: '',
                    pointControles: []
                }}
                validateOnBlur={true}
                validateOnChange={false}
                validate={(values) => {
                    const errors = {}
                    const texte = "Ce champ est invalide"
                    // Validation de la date de supervision
                    if (values.date_supervision == null || values.date_supervision === "") {
                        errors.date_supervision = texte
                    }
                    else if (new Date(values.date_supervision) > new Date(moment().format())) {
                        errors.date_supervision = texte
                    }
                    // Validation du contexte
                    if (!regexContexte.test(values.contexte)) {
                        errors.contexte = texte
                    }
                    // Validation du type de supervision
                    if (values.type_supervision !== false && values.type_supervision !== true) {
                        errors.type_supervision = texte
                    }
                    // Validation de action 4x4
                    if (values.sur_4x4 !== false && values.sur_4x4 !== true) {
                        errors.sur_4x4 = texte
                    }
                    // Validation de la manière de supervision
                    if (values.supervision_visio !== false && values.supervision_visio !== true) {
                        errors.supervision_visio = texte
                    }
                    // Validation de la mise à disposition
                    if (values.mise_a_disposition !== false && values.mise_a_disposition !== true) {
                        errors.mise_a_disposition = texte
                    }
                    // Validation de la validation
                    if (values.resultat !== 0 && values.resultat !== 1) {
                        errors.resultat = texte
                    }
                    // Validation de soldes rq
                    if (values.solde_actions_rq !== false && values.solde_actions_rq !== true) {
                        errors.solde_actions_rq = texte
                    }
                    // Validation de la date de supervision
                    if (!values.intervalle_preconise) {
                        errors.intervalle_preconise = texte
                    } else if (!regexIntervalle.test(values.intervalle_preconise)) {
                        errors.intervalle_preconise = texte
                    }
                    // Validation de action apres
                    if (!regexActionApres.test(values.action_apres)) {
                        errors.action_apres = texte
                    } else if (values.action_apres && (values.action_apres !== 0 && values.action_apres !== -1) && values.detail_action_apres.trim() === "") {
                        errors.detail_action_apres = texte
                    }
                    props.passFormToParent(values, errors)
                    localStorage.setItem("general", JSON.stringify(values))
                    return errors
                }}
            >
                {
                    ({ isSubmitting, setFieldValue, errors, values, handleChange, handleBlur, validateField }) => (
                        <>
                            <Form>
                                <div className="form-group">
                                    <div className="form-group">
                                        <div className="row date-align">
                                            <div className="col">
                                                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        id="date-picker-dialog"
                                                        label="Date supervision"
                                                        inputVariant="outlined"
                                                        format="dd/MM/yyyy"
                                                        value={values.date_supervision}
                                                        onChange={value => setFieldValue("date_supervision", value)}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "Date de la supervision"
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <ErrorMessage name="date_supervision" component="div" className="text-danger" />
                                            </div>
                                            {/* Contexte */}
                                            <div className="col">
                                                <label htmlFor="contexte">Contexte</label>
                                                <FastField as="select" name="contexte" className="form-control" onChange={handleChange}>
                                                    <option value="-1"></option>
                                                    {Contexte && Contexte.map((item, key) =>
                                                        <option key={key} value={key}> {item} </option>
                                                    )}
                                                </FastField>
                                                <ErrorMessage name="contexte" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Options de la supervision : Collapse */}
                                    <div className="form-group">
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} onClick={() => setIsOpen(!isOpen)} className="comet-collapse supervision-collapse" eventKey="0">
                                                    <label>Check-list</label>
                                                    <Button
                                                        onClick={() => setIsOpen(!isOpen)}
                                                        aria-controls="example-collapse-text"
                                                        aria-expanded={isOpen}
                                                    >
                                                        {isOpen === false ?
                                                            <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                            <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                        }
                                                    </Button>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <div className="form-group checkbox-group">
                                                            <label className="options-label">Observation / Supervision {values.type === 0 ? <span className="details-item option-yes">Supervision</span> : <span className="details-item">Observation</span>} </label>
                                                            <Switch
                                                                name="type"
                                                                classes={lovelyStyles}
                                                                checked={values.type === 0 ? true : false}
                                                                onChange={e => values.type === 0 ? setFieldValue("type", 1) : setFieldValue("type", 0)}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        {/* Collegiale */}
                                                        <div className="form-group checkbox-group">
                                                            <label className="options-label">Collégiale {values.supervision_collegiale === true ? <span className="details-item option-yes">Oui</span> : <span className="details-item">Non</span>} </label>
                                                            <Switch
                                                                name="supervision_collegiale"
                                                                classes={lovelyStyles}
                                                                checked={values.supervision_collegiale}
                                                                onChange={handleChangeCollegiale}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <div className="form-group checkbox-group">
                                                            <label className="options-label">Fait sur 4x4 {values.sur_4x4 === true ? <span className="details-item option-yes">Oui</span> : <span className="details-item">Non</span>} </label>
                                                            <Switch
                                                                name="sur_4x4"
                                                                classes={lovelyStyles}
                                                                checked={values.sur_4x4}
                                                                onChange={() => setFieldValue("sur_4x4", !values.sur_4x4)}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <div className="form-group checkbox-group">
                                                            <label className="options-label">En visio {values.supervision_visio === true ? <span className="details-item option-yes">Oui</span> : <span className="details-item">Non</span>} </label>
                                                            <Switch
                                                                name="supervision_visio"
                                                                classes={lovelyStyles}
                                                                checked={values.supervision_visio}
                                                                onChange={() => setFieldValue("supervision_visio", !values.supervision_visio)}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <div className="form-group checkbox-group">
                                                            <label className="options-label">Mise à disposition {values.mise_a_disposition === true ? <span className="details-item option-yes">Oui</span> : <span className="details-item">Non</span>} </label>
                                                            <Switch
                                                                name="mise_a_disposition"
                                                                classes={lovelyStyles}
                                                                checked={values.mise_a_disposition}
                                                                onChange={() => setFieldValue("mise_a_disposition", !values.mise_a_disposition)}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <div className="form-group checkbox-group">
                                                            <label className="options-label">Validation {values.resultat === 1 ? <span className="details-item option-yes">Oui</span> : <span className="details-item">Non</span>} </label>
                                                            <Switch
                                                                name="resultat"
                                                                classes={lovelyStyles}
                                                                checked={values.resultat === 1 ? true : false}
                                                                onChange={() => handleCB(values, setFieldValue)}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                        <div className="form-group checkbox-group">
                                                            <label className="options-label">Soldes actions {values.solde_actions_rq === true ? <span className="details-item option-yes">Oui</span> : <span className="details-item">Non</span>} </label>
                                                            <Switch
                                                                name="solde_actions_rq"
                                                                classes={lovelyStyles}
                                                                checked={values.solde_actions_rq}
                                                                disabled
                                                                onChange={() => setFieldValue("solde_actions_rq", !values.solde_actions_rq)}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </div>

                                    {/* ------------ Points forts et points faibles ---------- */}
                                    <div className="form-group">
                                        <div className="row">
                                            {/* ------------ Points forts ---------- */}
                                            <div className="col">
                                                <label htmlFor="points_forts">Points forts</label>
                                                <FastField name="points_forts" as="textarea" className="form-control c-line-break" />
                                                <ErrorMessage name="points_forts" component="div" className="text-danger" />
                                            </div>
                                            {/* ------------ Points faibles ---------- */}
                                            <div className="col">
                                                <label htmlFor="points_faibles">Axes d'amélioration</label>
                                                <FastField name="points_faibles" as="textarea" className="form-control" />
                                                <ErrorMessage name="points_faibles" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>

                                    {/* ------------ Commentaires ---------- */}
                                    <div className="form-group">
                                        <label htmlFor="commentaires">Commentaires et conclusion</label>
                                        <FastField name="commentaires" as="textarea" className="form-control commentaires" />
                                        <ErrorMessage name="commentaires" component="div" className="text-danger" />
                                    </div>

                                    {/* ------------Periodicté ---------- */}
                                    <div className="form-group">
                                        <label htmlFor="intervalle_preconise">Périodicité</label>
                                        <FastField name="intervalle_preconise" as="select" className="form-control">
                                            <option value="-1"></option>
                                            <option value="1">1 mois</option>
                                            <option value="2">2 mois</option>
                                            <option value="3">3 mois</option>
                                            <option value="4">4 mois</option>
                                            <option value="5">5 mois</option>
                                            <option value="6">6 mois</option>
                                            <option value="7">7 mois</option>
                                            <option value="9">9 mois</option>
                                            <option value="9">9 mois</option>
                                            <option value="10">10 mois</option>
                                            <option value="11">11 mois</option>
                                            <option value="12">12 mois</option>
                                            <option value="13">13 mois</option>
                                            <option value="14">14 mois</option>
                                            <option value="15">15 mois</option>
                                            <option value="16">16 mois</option>
                                            <option value="17">17 mois</option>
                                            <option value="18">18 mois</option>
                                            <option value="19">19 mois</option>
                                            <option value="20">20 mois</option>
                                            <option value="21">21 mois</option>
                                            <option value="22">22 mois</option>
                                            <option value="23">23 mois</option>
                                            <option value="24">24 mois</option>
                                        </FastField>
                                        <ErrorMessage name="intervalle_preconise" component="div" className="text-danger" />
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col">
                                                <label htmlFor="action_apres">Action</label>
                                                <FastField name="action_apres" as="select" className="form-control" onChange={(event) => { setFieldValue('action_apres', parseInt(event.target.value)) }}>
                                                    <option value="-1"></option>
                                                    <option value="0">Sans Objet</option>
                                                    <option value="2">QCM Complémentaire (préciser)</option>
                                                    <option value="3">Création d'une Non Qualité (indiquer N°)</option>
                                                    <option value="4">Reformation (indiquer date)</option>
                                                    <option value="5">Rappel Oral</option>
                                                    <option value="6">Avertissement</option>
                                                    <option value="7">Création NC Audit interne (indiquer N°)</option>
                                                </FastField>
                                                <ErrorMessage name="action_apres" component="div" className="text-danger" />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="detail_action_apres">Détail</label>
                                                <FastField name="detail_action_apres" as="textarea" className="form-control" />
                                                <ErrorMessage name="detail_action_apres" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>

                                    {/* ------------ Commentaires soldes rq ---------- */}
                                    <div className="form-group">
                                        <label htmlFor="commentaires_solde_rq">Soldes par RQ</label>
                                        <FastField disabled name="commentaires_solde_rq" as="textarea" className="form-control" />
                                        <ErrorMessage name="commentaires_solde_rq" component="div" className="text-danger" />
                                    </div>
                                </div>
                                <AutoSubmitGeneral />
                            </Form>
                        </>
                    )
                }
            </Formik>
        </div>
    )
}

const AutoSubmitGeneral = () => {
    const { values, validateForm } = useFormikContext()

    useEffect(() => {
        if (values) {
            validateForm()
        }
    }, [])

    return null
}

export default General
