import React, { useState, useEffect } from 'react'
import NavBar from '../../components/NavBar/NavBar'
import history from '../../helpers/history'
import { supervisionService } from '../../services/supervisionService'
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import Checkbox from '@material-ui/core/Checkbox'
import Skeleton from 'react-loading-skeleton'
import { NavLink } from 'react-router-dom'

const ChooseAdherentPage = () => {

    // Tous les adherents
    const [adherents, setAdherents] = useState([])
    // Element recherché
    const [search, setSearch] = useState("")
    // Etat de chargement d'un adherent
    const [onloading, setOnLoading] = useState(false)
    // Adherent selectionné
    const [selectedAdherent, setSelectedAdherent] = useState(0)
    // Etat d'ouverture d'un filtre
    const [filterOpened, setFilterOpened] = useState(false)
    // Filtre (Actif pour le moment)
    const [actif, setActif] = useState(true)
    // Chargement de la liste des adherents
    const [loadingscreen, setLoadingscreen] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            getAllAdherent(search, actif)
            setLoadingscreen(false)
        }, 1000)
    }, [search, actif])

    const getAllAdherent = async (search, actif) => {
        await supervisionService.getAllAdherent(search, actif)
            .then(
                response => setAdherents(response)
            )
    }

    const toggleFiltre = () => {
        setFilterOpened(!filterOpened)
    }

    const handleCheck = () => {
        setActif(!actif)
    }

    const handleChange = (event) => {
        const inputValue = event.target.value
        setSearch(inputValue)
    }

    const handleClick = async (event) => {
        // Demarrage du chargement de l'adherent
        setOnLoading(true)
        // Récupéreration de l'identifiant de l'adherent pour 
        // afficher les données dans la page suivante
        let id = event.currentTarget.id
        // Récupération de l'attribut data pour cibler l'item selectionne
        // afin de charger une icone de chargement
        let adh = parseInt(event.currentTarget.attributes.getNamedItem('data').value)
        setSelectedAdherent(adh)
        // Chargement de l'adherent
        await supervisionService.getAdherent(id)
            .then(
                response => {
                    localStorage.setItem('monAdherent', JSON.stringify(response))
                }
            )
        // Chargement des intervenants
        await supervisionService.getAllIntervenant(id)
            .then(
                response => {
                    localStorage.setItem('mesIntervenants', JSON.stringify(response))
                    // Chargement terminé
                    setOnLoading(false)
                    // Redirection
                    history.push('/adherent')
                }
            )
            .catch(
                error => {
                    /**
                     * To Do: Afficher l'erreur
                     * Informer l'utilisateur
                     */
                    // Arrêter chargement
                    setOnLoading(false)
                }
            )
    }

    return (
        <>
            <NavBar />
            <div className="jumbotron">
                <div className="container">
                    <div className="mes-adherents c-mt-6">
                        <h1 className="c-mt-2 c-mb-3">Mes adherents</h1>
                        <div className="search-section c-mb-3">
                            <div className="searchbar">
                                <i className="fas fa-search"></i>
                                <input className="search" name="search" type="search" placeholder="Recherche..." onChange={event => { handleChange(event) }} autoComplete="off" />
                            </div>
                            <span className="filtre">
                                <NavLink className="link" to="#" onClick={toggleFiltre} >
                                    <span>
                                        {actif ? "Actif" : "Tous"}
                                    </span>
                                    <i className={"fas fa-angle-up " + (filterOpened ? "reverse-icon" : "")}></i>
                                </NavLink>
                                <div className={"c-dropdown-list " + (filterOpened ? "c-show" : "")}>
                                    <ul>
                                        <li>
                                            <Checkbox
                                                value={4}
                                                checked={actif}
                                                onChange={handleCheck}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox'
                                                }} />
                                            Actif
                                        </li>
                                    </ul>
                                </div>
                            </span>
                        </div>
                        <div className="adherents-liste">
                            {(!loadingscreen && adherents && adherents.map((adherent, i) =>
                                <NavLink id={adherent.numero_adherent} data={i} key={i} className="adherent" to={"/adherents/" + adherent.identification_adherent} /*onClick={handleClick}*/>
                                    <span>
                                        {`${adherent.identification_adherent} (${adherent.numero_adherent})`}
                                    </span>
                                    <p>
                                        {adherent.nom_adherent}
                                    </p>
                                    <div className="go-icon">
                                        {
                                            onloading === false || (onloading === true && (i !== selectedAdherent)) ?
                                                <i className="fas fa-chevron-right"></i> : ''
                                        }
                                        {
                                            onloading === true && (i === selectedAdherent) &&
                                            <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex' }} />
                                        }
                                    </div>
                                </NavLink>
                            )) ||
                                <>
                                    <div className="adherent">
                                        <Skeleton height={20.8} />
                                        <Skeleton height={17.6} />
                                    </div>
                                    <div className="adherent">
                                        <Skeleton height={20.8} />
                                        <Skeleton height={17.6} />
                                    </div>
                                    <div className="adherent">
                                        <Skeleton height={20.8} />
                                        <Skeleton height={17.6} />
                                    </div>
                                    <div className="adherent">
                                        <Skeleton height={20.8} />
                                        <Skeleton height={17.6} />
                                    </div>
                                    <div className="adherent">
                                        <Skeleton height={20.8} />
                                        <Skeleton height={17.6} />
                                    </div>
                                    <div className="adherent">
                                        <Skeleton height={20.8} />
                                        <Skeleton height={17.6} />
                                    </div>
                                    <div className="adherent">
                                        <Skeleton height={20.8} />
                                        <Skeleton height={17.6} />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default ChooseAdherentPage