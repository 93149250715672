export default class TreeNode {

    constructor(props) {
        this.root = props.root
        this.id = props.id
        this.name = props.name
        this.img = props.img
        this.subid = props.subid
        this.ok = props.ok
        this.children = props.children || []
    }

    parcours = (callback) => {
        const goThrough = (node) => {
            callback(node)
            node.children.forEach((child) => {
                goThrough(child)
            })
        }
        if (this.root) {
            goThrough(this.root)
        }
        else {
            goThrough(this)
        }
    }

    addNode = (value, parentId) => {
        if (this.search(value.id) !== null) {
            return this
        }

        const newNode = new TreeNode({
            ...value,
            root: this.root || this
        })

        if (this.root === null) {
            this.root = newNode
            return this
        }

        this.parcours((node) => {
            if (node.id === parentId) {
                node.children.push(newNode)
            }
        })

        return this
    }

    search = (id) => {
        let returnNode = null
        this.parcours((node) => {
            if (node.id === id) {
                returnNode = node
            }
        })
        return returnNode
    }

    addChildren = (values, parentId) => {
        if (Array.isArray(values)) {
            values.map((value) => this.addNode(value, parentId))
        }
        else if (typeof values === 'object') {
            this.addNode(values, parentId)
        }
        return this
    }

    removeNode = (value) => {
        this.parcours((node) => {
            node.children.forEach((childNode, index) => {
                if (childNode.id === value.id) {
                    node.children.splice(index, 1)
                }
            })
        })
        return this
    }

    searchByName = (name) => {
        let returnNode = null
        this.parcours((node) => {
            if (node.name.includes(name)) {
                returnNode = node
            }
        })
        return returnNode
    }

    displayLeafs = (parent) => {
        const parentNode =
            typeof parent === "string" ? this.search(parent) : parent
        if (!parentNode) return []

        const leafsRet = []

        if (parentNode.children && !parentNode.children.length) {
            return [parentNode]
        }

        parentNode.children.forEach((child) => leafsRet.push(child))

        return leafsRet.flat()
    }
}