import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { ReactComponent as OK } from './../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../res/erreur.svg'
import { ReactComponent as Intervention } from './../../../../res/alerte.svg'
import C from "./../../../../res/C_taximetre.png"
import E from "./../../../../res/E_taximetre.png"
import M from "./../../../../res/M_taximetre.png"
import Checkbox from '@material-ui/core/Checkbox'

const Marquage = (props) => {

    const [marquage] = useState(JSON.parse(localStorage.getItem("taximetre")))
    const [marquage_intervention] = useState(JSON.parse(localStorage.getItem("intervention")))
    const [infos] = useState(JSON.parse(localStorage.getItem("infos")))

    const limitValueLength = (e, setFieldValue) => {
        let value = e.target.value
        let name = e.target.name
        if (value.length <= 2) {
            setFieldValue(name, value)
        } else {
            return
        }
    }

    console.log(infos)

    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    c_marque_reparateur: marquage.c_marque_reparateur,
                    c_scelles: marquage.c_scelles,
                    millesime_mid: marquage_intervention.millesime_mid,
                    millesime_mid_afficheur: marquage_intervention.millesime_mid_afficheur,
                    c_marque_ce: marquage.c_marque_ce,
                    pas_marquage_mid_afficheur: marquage_intervention.pas_marquage_mid_afficheur,
                    c_ANO_double_marquage_CE: marquage.c_ANO_double_marquage_CE,
                    c_REF_double_marquage_CE: marquage.c_REF_double_marquage_CE,
                }}
                validate={(values) => {
                    const errors = {}
                    let taximetre = JSON.parse(localStorage.taximetre)
                    let intervention = JSON.parse(localStorage.intervention)
                    // Mise à jour des interventions
                    intervention.millesime_mid = parseInt(values.millesime_mid)
                    intervention.millesime_mid_afficheur = parseInt(values.millesime_mid_afficheur)
                    intervention.pas_marquage_mid_afficheur = values.pas_marquage_mid_afficheur
                    // Mise à jour des taximetres
                    taximetre.c_marque_reparateur = values.c_marque_reparateur
                    taximetre.c_scelles = values.c_scelles
                    taximetre.c_marque_ce = values.c_marque_ce
                    taximetre.c_ANO_double_marquage_CE = values.c_ANO_double_marquage_CE
                    taximetre.c_REF_double_marquage_CE = values.c_REF_double_marquage_CE
                    // Partage des données
                    props.passFormToParent(taximetre, intervention, errors)
                    // Mise à jour de localStorage
                    localStorage.setItem("taximetre", JSON.stringify(taximetre))
                    localStorage.setItem("intervention", JSON.stringify(intervention))
                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500))
                    alert(JSON.stringify(values, null, 2))
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container etiquette">
                            {/* Proximité Plaque */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title font-weight-bold">
                                            Réparation
                                        </div>
                                        <div className="indication-title">
                                            Proximité plaque (idem carnet)
                                            <i className="fas fa-question-circle ctooltip">
                                                <span className="tooltiptext tooltiptext-bottom">
                                                    00 : Année d'apposition du marquage
                                                </span>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_marque_reparateur"
                                                    id="c_marque_reparateur-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_marque_reparateur', 1)}
                                                    checked={values.c_marque_reparateur === 1 ? true : false} />
                                                <label htmlFor="c_marque_reparateur-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_marque_reparateur"
                                                    id="c_marque_reparateur-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_marque_reparateur', 2)}
                                                    checked={values.c_marque_reparateur === 2 ? true : false} />
                                                <label htmlFor="c_marque_reparateur-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R05
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_marque_reparateur"
                                                    id="c_marque_reparateur-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_marque_reparateur', 0)}
                                                    checked={(values.c_marque_reparateur === 0 || values.c_marque_reparateur === 4) ? true : false} />
                                                <label htmlFor="c_marque_reparateur-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Scellements */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Scellements fabricant/reparateur
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_scelles"
                                                    id="c_scelles-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_scelles', 1)}
                                                    checked={values.c_scelles === 1 ? true : false} />
                                                <label htmlFor="c_scelles-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_scelles"
                                                    id="c_scelles-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_scelles', 2)}
                                                    checked={values.c_scelles === 2 ? true : false} />
                                                <label htmlFor="c_scelles-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R05
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_scelles"
                                                    id="c_scelles-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_scelles', 0)}
                                                    checked={(values.c_scelles === 0 || values.c_scelles === 4) ? true : false} />
                                                <label htmlFor="c_scelles-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Infos Scellements */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Infos scellements Fabricant / Réparateur
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        <Field name="marque_fabricant_1" className="form-control form-infos" disabled as="textarea" value={infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_fabricant_1} />
                                    </div>
                                </div>
                            </div>
                            {/* Taximètre / Centrale */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Taximètre / Centrale
                                            <i className="fas fa-question-circle ctooltip">
                                                <span className="tooltiptext tooltiptext-bottom">
                                                    XXXX : Organisme qui approuve le Système Qualité
                                                    00 : Année d'apposition du marquage
                                                </span>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                                <div className="marqueur">
                                    <div className="marqueur-container">
                                        <div className="tax-img-container">
                                            <img src={C} alt="C" />
                                        </div>
                                        <div className="tax-img-container">
                                            <img src={E} alt="E" />
                                        </div>
                                        <div className="tax-img-container">
                                            <img src={M} alt="M" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <div className="form-input tax-form-input">
                                        <Field className="form-control input-extra-small tax-input" type="text" name="millesime_mid" placeholder="00" onChange={e => limitValueLength(e, setFieldValue)} />
                                    </div>
                                </div>
                                <div className="tax-organisme">
                                    <span>XXXX</span>
                                </div>
                            </div>
                            {/* Afficheur */}
                            {infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marquage_ce_afficheur === true &&
                                <>
                                    <div className="section-one">
                                        <div div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Afficheur
                                                        <i className="fas fa-question-circle ctooltip">
                                                            <span className="tooltiptext tooltiptext-bottom">
                                                                XXXX : Organisme qui approuve le Système Qualité
                                                            </span>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="marqueur">
                                                <div className="marqueur-container">
                                                    <div className="tax-img-container">
                                                        <img src={C} alt="C" />
                                                    </div>
                                                    <div className="tax-img-container">
                                                        <img src={E} alt="E" />
                                                    </div>
                                                    <div className="tax-img-container">
                                                        <img src={M} alt="M" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <div className="form-input tax-form-input">
                                                    <Field
                                                        className="form-control input-extra-small tax-input"
                                                        disabled={infos && (infos.stationnements[0].vehiculeTaximetres[0].taximetre.numero_serie_afficheur !== "" && infos.stationnements[0].vehiculeTaximetres[0].taximetre.numero_serie_afficheur !== null) ? false : true}
                                                        type="text"
                                                        name="millesime_mid_afficheur"
                                                        placeholder="00"
                                                        onChange={e => limitValueLength(e, setFieldValue)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tax-organisme">
                                                <span>XXXX</span>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-cell-input checkbox-container">
                                                <div className="form-input input-anomalie">
                                                    <Checkbox
                                                        checked={values.pas_marquage_mid_afficheur}
                                                        name="pas_marquage_mid_afficheur"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    /*onChange={(e) => {
                                                        setValues(prev => ({
                                                            ...prev,
                                                            detail_anomalie_logiciel: "",
                                                            anomalie_logiciel: !values.anomalie_logiciel
                                                        }))
                                                    }}*/
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Pas de marquage MID sur afficheur
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Avant 31/05/21 : marquage CE différent */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Avant 31/05/21 : marquage CE différent
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <ul>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_ANO_double_marquage_CE"
                                                                id="c_ANO_double_marquage_CE-1"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_ANO_double_marquage_CE', 1)}
                                                                checked={values.c_ANO_double_marquage_CE === 1 ? true : false} />
                                                            <label htmlFor="c_ANO_double_marquage_CE-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <OK />
                                                                Non
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_ANO_double_marquage_CE"
                                                                id="c_ANO_double_marquage_CE-2"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_ANO_double_marquage_CE', 2)}
                                                                checked={values.c_ANO_double_marquage_CE === 2 ? true : false} />
                                                            <label htmlFor="c_ANO_double_marquage_CE-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <Intervention />
                                                                A130
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* Après le 01/06/21 : marquage CE */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Après 01/06/21 : marquage CE
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <ul>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_REF_double_marquage_CE"
                                                                id="c_REF_double_marquage_CE-1"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_REF_double_marquage_CE', 1)}
                                                                checked={values.c_REF_double_marquage_CE === 1 ? true : false} />
                                                            <label htmlFor="c_REF_double_marquage_CE-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <OK />
                                                                Non
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_REF_double_marquage_CE"
                                                                id="c_REF_double_marquage_CE-2"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_REF_double_marquage_CE', 2)}
                                                                checked={values.c_REF_double_marquage_CE === 2 ? true : false} />
                                                            <label htmlFor="c_REF_double_marquage_CE-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <Erreur />
                                                                R130
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.photo_fabrication &&
                                <div className="form-row">
                                    <div className="form-img-container">
                                        <img src={`data:image/jpeg;base64,${infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.photo_fabrication}`} alt="Taximètre" />
                                    </div>
                                </div>
                            }
                            {/* Organisme notifie */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Organisme(s) notifié(s)
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <div className="form-input">
                                        <Field name="organisme_notifie" className="form-control form-infos" disabled as="textarea" value={infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.organisme_notifie} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_marque_ce"
                                                    id="c_marque_ce-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_marque_ce', 1)}
                                                    checked={values.c_marque_ce === 1 ? true : false} />
                                                <label htmlFor="c_marque_ce-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_marque_ce"
                                                    id="c_marque_ce-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_marque_ce', 2)}
                                                    checked={values.c_marque_ce === 2 ? true : false} />
                                                <label htmlFor="c_marque_ce-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R08
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_marque_ce"
                                                    id="c_marque_ce-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_marque_ce', 0)}
                                                    checked={values.c_marque_ce === 0 ? true : false} />
                                                <label htmlFor="c_marque_ce-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div >
    )

}

export default Marquage