import { Field, Form, Formik } from "formik"
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as SO } from './../../../../../res/erreur.svg'
import { ReactComponent as Refus } from './../../../../../res/erreur.svg'
import { ReactComponent as Intervention } from './../../../../../res/alerte.svg'
import { ReactComponent as Externe } from "./../../../../../assets/saisie-tachy-menu/satellite.svg"
import { Tabs } from 'antd'
import Reception from "./Reception"
import Liberation from "./Liberation"
const { TabPane } = Tabs

const Dsrc = () => {

    return (
        <div className="taximetre-section">
            <div className="taximetre-container">
                <Formik
                    enableReinitialize={true}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div className="affichage-form-container">
                                <div className="section-one">
                                    <div className="form-row">
                                        {/* Type de DSRC */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">Type de DSRC</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                        <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            Interne
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Externe />
                                                            Externe
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <SO />
                                                            Externe
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-cell-input">
                                                        <div className="form-input">
                                                            <Field name="marque_vignette" className="form-control input-small" type="text" value="Effectué entre 2 et 10" />
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        {/* Résultat */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">Résultat</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                        <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            OK
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Refus />
                                                            Refus
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Intervention />
                                                            Intervention
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <SO />
                                                            SO
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-cell-input">
                                                        <div className="form-input">
                                                            <Field name="marque_vignette" className="form-control input-small" type="text" />
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                <Tabs defaultActiveKey={1} tabPosition={'top'} style={{ height: 220, width: '100%' }} /*onChange={handleChangeTab}*/ >
                    <TabPane tab={<div className={"tab-title"}>DSRC à Réception</div>} key={1}>
                        <Reception />
                    </TabPane>
                    <TabPane tab={<div className={"tab-title"}>DSRC à libération</div>} key={2}>
                        <Liberation />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default Dsrc