import { Field, Form, Formik } from "formik"
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../../res/siren.svg'
import { ReactComponent as Intervention } from './../../../../../res/alerte.svg'
import { ReactComponent as SO } from './../../../../../res/erreur.svg'
import { Table } from "react-bootstrap"

const Liberation = () => {
    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container">
                            <div className="reception-header d-flex flex-wrap">
                                {/* Modèle */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Modèle</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control" as="select">
                                                <option value="0">Inconnu</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control" as="select">
                                                <option value="0">Inconnu</option>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                {/* N° Série */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">N° Série</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control input-small" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div className="container-horizontal">
                                    {/* Plaque : A minima */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Plaque : A minima Nom ou logo Fabricant + Numéro de série :</div>
                                            </div>
                                        </div>
                                        {/* GNSS identique */}
                                        <div className="button-similaire">
                                            <button type="button" className="cbtn cbtn-success">
                                                GNSS identique à réception
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-vertical">
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        Refus (A32)
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        Intervention (A32)
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-3"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 0)}
                                                        /*checked={values.usure_pneumatique === 0 ? true : false} */ />
                                                    <label htmlFor="usure_pneumatique-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        SO
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="marque_vignette" className="form-control input-small" type="text" />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="reception-body flex flex-column">
                                {/* Scellement apposé */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Scellement apposé</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        Non
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="marque_vignette" className="form-control input-small" type="text" />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* Identification */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">Identification</div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        <Field name="util_mano" className="form-control" as="select">
                                            <option value="0">Continental</option>
                                        </Field>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <div className="form-input">
                                        <Field name="marque_vignette" className="form-control input-small" type="text" />
                                    </div>
                                </div>
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">MQ</div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <div className="form-input">
                                        <button type="button" className="cbtn cbtn-success mt-0 mb-2">
                                            FG13
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="container-horizontal">
                                {/* Plaque : A minima */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Contrôle GNSSS</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    Externe
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="usure_pneumatique"
                                                    id="usure_pneumatique-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    Non conforme
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* Lieu */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Lieu</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="util_mano" className="form-control" as="select">
                                                <option value="0">Inconnu</option>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-vertical">
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Atelier</th>
                                            <th>GNSS</th>
                                            <th>Erreur (min)</th>
                                            <th>EMT (min)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Longitude</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Latitude</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Liberation