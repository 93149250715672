import { Formik, Field, Form, ErrorMessage } from 'formik'
import { IoMdClose } from "react-icons/io"
import { ReactComponent as OK } from './../../../res/checked.svg'
import { ReactComponent as Alerte } from './../../../res/alerte.svg'
import { ReactComponent as Adherent } from '../../../assets/tree-view/boss.svg'
import { ReactComponent as Clients } from '../../../assets/tree-view/clients.svg'
import { ReactComponent as Fabricant } from '../../../assets/tree-view/factory.svg'
import noImage from '../../../assets/tree-view/no-image.png'
import { useRef, useState } from 'react'
import { useEffect } from 'react'
import { supervisionService } from '../../../services/supervisionService'
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import { toast } from 'react-toastify'
import Checkbox from '@material-ui/core/Checkbox'

import * as React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import { Tabs } from 'antd'
import useConfirm from '../../../hooks/useConfirm'
const { TabPane } = Tabs

const TaximetrePopup = ({ newTaximetre, taximetre, adherent, clients, client, ...props }) => {

    const [saving, setSaving] = useState(false)
    const formikRef = useRef()
    const [selectedMarque, setSelectedMarque] = useState()
    const [marques, setMarques] = useState([])
    const [modeles, setModeles] = useState([])
    const [modele, setModele] = useState(null)
    const [fabricants, setFabricants] = useState([])
    const [selectedProprietaire, setSelectedProprietaire] = useState(0)
    // const { adherent, setAdherent } = useAdherentContext()
    const [activeAfficheur, setActiveAfficheur] = useState(false)
    const clientData = (clients).filter(client => client.numero_client === parseInt((props.selected).split('_')[1]))[0]
    const [currentModele, setCurrentModele] = useState(null)
    const [modelesAfficheur, setModelesAfficheur] = useState(null)
    const [selectedClient, setSelectedClient] = useState(0)
    const { isConfirmedDeblocage } = useConfirm()

    useEffect(() => {
        // Type 1 => Taximètre
        supervisionService.getMarqueAppareil(1)
            .then(response => { setMarques(response) })
            .catch(error => { toast.error(error, { containerId: 'App' }); console.log(error) })

        if (!newTaximetre) {
            setSelectedMarque(taximetre.taximetre.marque_taximetre)
            if (taximetre.taximetre.modeleTousAppareils.afficheur && taximetre.taximetre.modeleTousAppareils.afficheur === true) {

                supervisionService.getModeleAppareil(10, taximetre.taximetre.marque_taximetre)
                    .then(response => setModelesAfficheur(response))
                    .catch(error => { toast.error(error, { containerId: 'App' }) })

                setActiveAfficheur(true)
            }
            else {
                setActiveAfficheur(false)
                formikRef.current.setFieldValue("modele_afficheur", -1)
            }

            setModele(taximetre.taximetre.modeleTousAppareils)

            supervisionService.getModeleAppareil(1, taximetre.taximetre.marque_taximetre)
                .then(response => setModeles(response))
                .catch(error => { toast.error(error, { containerId: 'App' }); formikRef.current.setFieldValue("modele_taximetre", -1) })

            if (taximetre.taximetre.taximetreInterventions[0].prop_client === 0 && taximetre.taximetre.taximetreInterventions[0].prop_fab === 0) {
                //prop_num = 0
                formikRef.current.setFieldValue("proprio", adherent.nom_adherent)
                formikRef.current.setFieldValue("adresse1_proprio", adherent.adresse1_adherent ? adherent.adresse1_adherent : "")
                formikRef.current.setFieldValue("adresse2_proprio", adherent.adresse2_adherent ? adherent.adresse2_adherent : "")
                formikRef.current.setFieldValue("cp_proprio", adherent.cP_adherent)
                formikRef.current.setFieldValue("ville_proprio", adherent.ville_adherent)
            }
            else if (taximetre.taximetre.taximetreInterventions[0].prop_client !== 0 && taximetre.taximetre.taximetreInterventions[0].prop_fab === 0) {
                //prop_num = prop_client
                setSelectedProprietaire(1)
                let _client = null
                if ((formikRef.current.values.numero_taximetre === 0 || formikRef.current.values.numero_taximetre === 0)) {
                    _client = clientData
                }
                else {
                    _client = clients.find(c => c.numero_client === taximetre.taximetre.taximetreInterventions[0].prop_client)
                }
                setSelectedClient(_client.numero_client)
                formikRef.current.setFieldValue("recherche_proprio", _client.nom_client)
                formikRef.current.setFieldValue("proprio", _client.nom_client + " - " + _client.cp_client + " " + _client.ville_client)
                formikRef.current.setFieldValue("cp_proprio", _client.cp_client)
                formikRef.current.setFieldValue("adresse1_proprio", _client.adresse1_client)
                formikRef.current.setFieldValue("adresse2_proprio", _client.adresse2_client)
                formikRef.current.setFieldValue("ville_proprio", _client.ville_client)
            }
            else if (taximetre.taximetre.taximetreInterventions[0].prop_client === 0 && taximetre.taximetre.taximetreInterventions[0].prop_fab !== 0) {
                // prop_num = prop_fab
                setSelectedProprietaire(2)
                if (formikRef.current.values.marque_taximetre !== -1) {
                    supervisionService.getAllFabricant()
                        .then(response => {
                            setFabricants(response)
                            let fabricant = response.filter(fabricant => fabricant.numero_ftaxi === taximetre.taximetre.taximetreInterventions[0].prop_fab)[0]
                            formikRef.current.setFieldValue("proprio", taximetre.taximetre.taximetreInterventions[0].prop_fab)
                            formikRef.current.setFieldValue("recherche_proprio", "")
                            formikRef.current.setFieldValue("cp_proprio", fabricant.cp_ftaxi)
                            formikRef.current.setFieldValue("adresse1_proprio", fabricant.adresse1_ftaxi)
                            formikRef.current.setFieldValue("adresse2_proprio", fabricant.adresse2_ftaxi)
                            formikRef.current.setFieldValue("ville_proprio", fabricant.ville_ftaxi)
                        })
                        .catch(error => { toast.error(error, { containerId: 'App' }); console.log(error) })
                }
            }
        }
    }, [])

    const handleChangeRechercheProprio = (e) => {
        const value = e.target.value
        formikRef.current.setFieldValue("recherche_proprio", value)
        // Recherche du propriétaire
        if (e.target.value && e.target.value.length >= 3) {
            let _clients = clients
            let newClients = _clients.filter(c => (c.nom_client.toLowerCase()).indexOf((e.target.value).toLowerCase()) !== -1)
            let _client = newClients[0]
            setSelectedClient(_client.numero_client)
            formikRef.current.setFieldValue("proprio", _client.nom_client + " - " + _client.cp_client + " " + _client.ville_client)
            formikRef.current.setFieldValue("cp_proprio", _client.cp_client)
            formikRef.current.setFieldValue("adresse1_proprio", _client.adresse1_client)
            formikRef.current.setFieldValue("adresse2_proprio", _client.adresse2_client)
            formikRef.current.setFieldValue("ville_proprio", _client.ville_client)
        }
    }

    const haveErrors = async (data) => {
        let message = ""
        let error = true
        if (!newTaximetre && activeAfficheur && data.numero_serie_afficheur !== "") {
            if (taximetre.taximetre.numero_serie_afficheur !== data.numero_serie_afficheur) {
                toast.error("Tentative de modification du numéro de série Afficheur", { autoClose: 5000, containerId: "App" })
                let show_modal_deblocage = true
                while (show_modal_deblocage) {
                    try {
                        let confirmCodeDeblocage = await isConfirmedDeblocage("Code de déblocage", { message: "Changement numéro de série afficheur Taxi de " + taximetre.taximetre.numero_serie_afficheur + " à " + data.numero_serie_afficheur, option: 2, activite: 1 })

                        if (confirmCodeDeblocage === "code_bon") {
                            show_modal_deblocage = false
                            error = false
                        }
                        else if (confirmCodeDeblocage === "code_incorrect") {
                            toast.error("Code de déblocage erroné.", { autoClose: 5000, containerId: "App" })
                        }
                        else {
                            toast.error("Arrêt...", { autoClose: 5000, containerId: "App" })
                            message = "Arrêt..."
                            show_modal_deblocage = false
                            return true
                        }
                    } catch (err) {
                        toast.error("Erreur de déblocage.", { autoClose: 5000, containerId: "App" });
                        show_modal_deblocage = false;
                    }
                }
            }
            else {
                error = false
            }
        }
        else if (!newTaximetre && data.numero_serie_taximetre !== taximetre.taximetre.numero_serie_taximetre) {
            // requete
            try {
                const request = {
                    numero_adherent: data.numero_adherent,
                    numero_taximetre: taximetre.taximetre.numero_taximetre
                }
                let deja_intervention_existante = false
                const count = await supervisionService.getInterventionExistante(request)
                if (count > 1) {
                    deja_intervention_existante = true
                }

                if (deja_intervention_existante) {
                    toast.error("Vous ne pouvez pas changer le numéro de série du Taximètre car il y a plus d'une intervention dessus, si changement de Taximètre il faut le recréer.", { autoClose: 5000, containerId: "App" })
                    let show_modal_deblocage = true
                    while (show_modal_deblocage) {
                        try {
                            let confirmCodeDeblocage = await isConfirmedDeblocage("Code de déblocage", { message: "Changement numéro de série Taxi de " + taximetre.taximetre.numero_serie_taximetre + " à " + data.numero_serie_taximetre, option: 2, activite: 1 })

                            if (confirmCodeDeblocage === "code_bon") {
                                show_modal_deblocage = false
                                error = false
                            }
                            else if (confirmCodeDeblocage === "code_incorrect") {
                                toast.error("Code de déblocage erroné.", { autoClose: 5000, containerId: "App" })
                            }
                            else {
                                toast.error("Arrêt...", { autoClose: 5000, containerId: "App" })
                                message = "Arrêt..."
                                show_modal_deblocage = false
                                return true
                            }
                        } catch (err) {
                            toast.error("Erreur de déblocage.", { autoClose: 5000, containerId: "App" });
                            show_modal_deblocage = false;
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        else {
            error = false
        }

        return { error: error, message: message }
    }

    const handleChangeMarque = (e) => {
        const value = parseInt(e.target.value)
        setSelectedMarque(value)
        formikRef.current.setFieldValue(e.target.name, value)
        if (value !== -1) {
            supervisionService.getModeleAppareil(1, value)
                .then(response => setModeles(response))
                .catch(error => { toast.error(error, { containerId: 'App' }); formikRef.current.setFieldValue("modele_taximetre", -1) })
        }
        else {
            formikRef.current.setFieldValue("modele_taximetre", -1)
        }
    }

    const handleChangeModele = (e) => {
        const value = parseInt(e.target.value)
        formikRef.current.setFieldValue(e.target.name, value)
        setCurrentModele(modeles.find(m => m.modele_appareil === value))
        if (value !== -1) {
            supervisionService.getModeleTousAppareils(selectedMarque, value)
                .then(response => {
                    setModele(response);
                    formikRef.current.setFieldValue('numero_cet_initial', response.ref_dam)
                    if (response.afficheur && response.afficheur === true) {

                        supervisionService.getModeleAppareil(10, formikRef.current.values.marque_taximetre)
                            .then(response => setModelesAfficheur(response))
                            .catch(error => { toast.error(error, { containerId: 'App' }) })

                        setActiveAfficheur(true)
                    }
                    else {
                        setActiveAfficheur(false)
                        formikRef.current.setFieldValue("modele_afficheur", -1)
                    }
                })
                .catch(error => { toast.error(error, { containerId: 'App' }); formikRef.current.setFieldValue(e.target.name, -1) })
        }
        else {
            formikRef.current.setFieldValue(e.target.name, -1)
            formikRef.current.setFieldValue('numero_cet_initial', "")
        }
    }

    const handleChangeModeleAfficheur = (e) => {
        const value = parseInt(e.target.value)
        formikRef.current.setFieldValue(e.target.name, value)
    }

    const handleChangeProprietaire = (e) => {
        const value = parseInt(e.target.value)
        const name = e.target.name
        formikRef.current.setFieldValue(name, value)
        if (value === 0) {
            formikRef.current.setFieldValue("proprio", adherent.nom_adherent)
            formikRef.current.setFieldValue("adresse1_proprio", adherent.adresse1_adherent ? adherent.adresse1_adherent : "")
            formikRef.current.setFieldValue("adresse2_proprio", adherent.adresse2_adherent ? adherent.adresse2_adherent : "")
            formikRef.current.setFieldValue("cp_proprio", adherent.cP_adherent)
            formikRef.current.setFieldValue("ville_proprio", adherent.ville_adherent)
        }
        if (value === 1) {
            if (!newTaximetre) {
                let _client = null
                if ((formikRef.current.values.numero_taximetre === 0 || formikRef.current.values.numero_taximetre === 0)) {
                    _client = clientData
                    setSelectedClient(_client ? _client.numero_client : 0)
                }
                else {
                    _client = clients.find(c => c.numero_client === taximetre.taximetre.taximetreInterventions[0].prop_client)
                    setSelectedClient(_client ? _client.numero_client : 0)
                }
                formikRef.current.setFieldValue("recherche_proprio", _client ? _client.nom_client : "")
                formikRef.current.setFieldValue("proprio", _client ? _client.nom_client + " - " + _client.cp_client + " " + _client.ville_client : "")
                formikRef.current.setFieldValue("cp_proprio", _client ? _client.cp_client : "")
                formikRef.current.setFieldValue("adresse1_proprio", _client ? _client.adresse1_client : "")
                formikRef.current.setFieldValue("adresse2_proprio", _client ? _client.adresse2_client : "")
                formikRef.current.setFieldValue("ville_proprio", _client ? _client.ville_client : "")
            }
            else {
                formikRef.current.setFieldValue("recherche_proprio", clientData.nom_client)
                formikRef.current.setFieldValue("proprio", clientData.nom_client + " - " + clientData.cp_client + " " + clientData.ville_client)
                formikRef.current.setFieldValue("adresse1_proprio", clientData.adresse1_client ? clientData.adresse1_client : "")
                formikRef.current.setFieldValue("adresse2_proprio", clientData.adresse2_client ? clientData.adresse2_client : "")
                formikRef.current.setFieldValue("cp_proprio", clientData.cp_client)
                formikRef.current.setFieldValue("ville_proprio", clientData.ville_client)
            }
        }
        else if (value === 2) {
            if (formikRef.current.values.marque_taximetre !== -1) {
                supervisionService.getAllFabricant()
                    .then(response => {
                        setFabricants(response)
                        let prop_fab = taximetre.taximetre.taximetreInterventions[0].prop_fab
                        let prop_num = 0
                        let fabricant = null
                        if (prop_fab !== 0) {
                            prop_num = prop_fab
                            fabricant = response.filter(fabricant => fabricant.numero_ftaxi === prop_num)[0]
                        }
                        else {
                            prop_num = taximetre.taximetre.marque_taximetre
                            fabricant = response.filter(fabricant => fabricant.numero_ftaxi === prop_num)[0]
                        }
                        if (fabricant) {
                            formikRef.current.setFieldValue("proprio", prop_num)
                            formikRef.current.setFieldValue("recherche_proprio", "")
                            formikRef.current.setFieldValue("cp_proprio", fabricant.cp_ftaxi)
                            formikRef.current.setFieldValue("adresse1_proprio", fabricant.adresse1_ftaxi)
                            formikRef.current.setFieldValue("adresse2_proprio", fabricant.adresse2_ftaxi)
                            formikRef.current.setFieldValue("ville_proprio", fabricant.ville_ftaxi)
                        } else {
                            formikRef.current.setFieldValue("recherche_proprio", "")
                            formikRef.current.setFieldValue("proprio", "")
                            formikRef.current.setFieldValue("adresse1_proprio", "")
                            formikRef.current.setFieldValue("adresse2_proprio", "")
                            formikRef.current.setFieldValue("cp_proprio", "")
                            formikRef.current.setFieldValue("ville_proprio", "")
                        }
                    })
                    .catch(error => { toast.error(error, { containerId: 'App' }); console.log(error) })
            }
            else {
                formikRef.current.setFieldValue("recherche_proprio", "")
                formikRef.current.setFieldValue("proprio", "")
                formikRef.current.setFieldValue("adresse1_proprio", "")
                formikRef.current.setFieldValue("adresse2_proprio", "")
                formikRef.current.setFieldValue("cp_proprio", "")
                formikRef.current.setFieldValue("ville_proprio", "")
            }
        }
        setSelectedProprietaire(value)
    }

    const handleChangeFabricant = (e) => {
        const value = parseInt(e.target.value)
        formikRef.current.setFieldValue(e.target.name, value)
        if (value !== -1) {
            let fabricant = fabricants.filter(fabricant => fabricant.numero_ftaxi === value)[0]
            formikRef.current.setFieldValue("cp_proprio", fabricant.cp_ftaxi)
            formikRef.current.setFieldValue("adresse1_proprio", fabricant.adresse1_ftaxi)
            formikRef.current.setFieldValue("adresse2_proprio", fabricant.adresse2_ftaxi)
            formikRef.current.setFieldValue("ville_proprio", fabricant.ville_ftaxi)
        }
        else {
            formikRef.current.setFieldValue("cp_proprio", "")
            formikRef.current.setFieldValue("adresse1_proprio", "")
            formikRef.current.setFieldValue("adresse2_proprio", "")
            formikRef.current.setFieldValue("ville_proprio", "")
        }
    }

    const handleSaveTaximetre = (e) => {
        if (formikRef.current) {
            formikRef.current.handleSubmit()
        }
    }

    const saveTaximetre = async (request) => {
        const taximetre = await supervisionService.saveTaximetre(request)
        setSaving(false)
        props.close(taximetre)
    }

    const updateTaximetre = async (request) => {
        const taximetre = await supervisionService.updateTaximetre(request)
        setSaving(false)
        props.close(taximetre)
    }

    return (
        <div className="popup-cover">
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        {props.title}
                    </h3>
                    <div className="popup-close-button" onClick={e => props.close(null)}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-body pt-0 popup-liaison-body">
                    <div className="form-saisie-container">
                        <Formik
                            innerRef={formikRef}
                            enableReinitialize
                            initialValues={{
                                actif_taximetre: !newTaximetre ? taximetre.taximetre.actif_taximetre : false,
                                marque_taximetre: !newTaximetre ? taximetre.taximetre.marque_taximetre : -1,
                                modele_taximetre: !newTaximetre ? taximetre.taximetre.modele_taximetre : -1,
                                numero_serie_taximetre: !newTaximetre ? taximetre.taximetre.numero_serie_taximetre : "",
                                numero_serie_afficheur: !newTaximetre ? taximetre.taximetre.numero_serie_afficheur : "",
                                modele_afficheur: !newTaximetre ? taximetre.taximetre.modele_afficheur : -1,
                                numero_cet_initial: !newTaximetre ? taximetre.taximetre.modeleTousAppareils.ref_dam : "",
                                observations: !newTaximetre ? taximetre.taximetre.observations : "",
                                actif_proprio: !newTaximetre ?
                                    (taximetre.taximetre.taximetreInterventions[0].prop_client === 0 && taximetre.taximetre.taximetreInterventions[0].prop_fab === 0 ? 0 :
                                        taximetre.taximetre.taximetreInterventions[0].prop_client !== 0 && taximetre.taximetre.taximetreInterventions[0].prop_fab === 0 ? 1 :
                                            taximetre.taximetre.taximetreInterventions[0].prop_client === 0 && taximetre.taximetre.taximetreInterventions[0].prop_fab !== 0 ? 2 : 0
                                    ) : 0,
                                recherche_proprio: "",
                                proprio: "",
                                adresse1_proprio: "",
                                adresse2_proprio: "",
                                cp_proprio: "",
                                ville_proprio: "",
                                relais: !newTaximetre ? taximetre.taximetre.relais : false,
                                location: !newTaximetre ? taximetre.taximetre.location : false,
                            }}
                            validate={values => {
                                const errors = {}

                                if (values.marque_vehicule === -1) {
                                    errors.marque_vehicule = "Vous devez renseigner la marque du taximètre."
                                }
                                if (values.modele_vehicule === -1) {
                                    errors.modele_vehicule = "Vous devez renseigner le modèle du taximètre."
                                }
                                if (values.numero_serie_taximetre === "") {
                                    errors.numero_serie_taximetre = "Vous devez renseigner le numéro de série du taximètre."
                                }
                                if (values.numero_serie_afficheur === "" && activeAfficheur === true) {
                                    errors.numero_serie_afficheur = "Ce taximètre a un afficheur et son numéro de série doit être renseigné."
                                }
                                if (values.proprio === -1) {
                                    errors.proprio = "Vous devez identifier le propriétaire."
                                }

                                return errors
                            }}
                            onSubmit={async (values, errors) => {
                                setSaving(true)
                                let data = values
                                alert("Dans le cas d'un taximètre MID neuf vous devez vous assurer de la possession d'un Certificat de Conformité UE (à fournir avec le carnet)")
                                setSaving(false)
                                let selectedKeys = props.selected.split("_")
                                data.numero_adherent = adherent.numero_adherent
                                data.numero_client = parseInt(selectedKeys[1])
                                data.clef_stationnement = parseInt(selectedKeys[2])
                                data.numero_vehicule = parseInt(selectedKeys[3])
                                data.clef_lien = !newTaximetre ? taximetre.clef_lien : client.subid
                                data.proprio_fab = selectedProprietaire === 2 ? formikRef.current.values.proprio : 0
                                data.proprio_client = selectedProprietaire === 1 ? selectedClient : 0
                                /*data.numero_adherent = userGroup
                                data.immatriculation_vehicule = data.immatriculation_vehicule.replace(" ", "")
                                data.numero_serie = data.numero_serie.replace(" ", "")
                                data.marque_vehicule = parseInt(data.marque_vehicule)
                                data.modele_vehicule = parseInt(data.modele_vehicule)
                                setSaving(false)
                                if (haveErrors(data).error) {
                                    toast.error(haveErrors(data).message, { containerId: 'App' })
                                    setSaving(false)
                                    return
                                }
                                data.clef_stationnement = parseInt(props.selected.split("_")[2])*/
                                //saveVehicule(data)
                                const isError = await haveErrors(data)
                                if (isError.error) {
                                    toast.error(isError.message, { containerId: 'App' })
                                    setSaving(false)
                                    return
                                }
                                else {
                                    if (!newTaximetre) {
                                        data.numero_taximetre = taximetre.taximetre.numero_taximetre
                                        data.isActiveAfficheur = activeAfficheur
                                        updateTaximetre(data)
                                    }
                                    else {
                                        saveTaximetre(data)
                                    }
                                }
                            }}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <Tabs defaultActiveKey={1} tabPosition={'top'} style={{ height: 220, width: '100%' }} /*onChange={handleChangeTab}*/ >
                                        {/* Infos Taximètre */}
                                        <TabPane tab={<div className={"tab-title"}>Info Taxi</div>} key={1}>
                                            {/* Actif */}
                                            <div className="form-group">
                                                <div className="form-cell-input">
                                                    <ul>
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="actif_taximetre"
                                                                    id="actif_taximetre-1"
                                                                    className="sr-only"
                                                                    onChange={() => setFieldValue('actif_taximetre', true)}
                                                                    checked={values.actif_taximetre === true ? true : false} />
                                                                <label htmlFor="actif_taximetre-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <OK />
                                                                    Actif
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="actif_taximetre"
                                                                    id="actif_taximetre-2"
                                                                    className="sr-only"
                                                                    onChange={() => setFieldValue('actif_taximetre', false)}
                                                                    checked={values.actif_taximetre === false ? true : false} />
                                                                <label htmlFor="actif_taximetre-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <Alerte />
                                                                    Inactif
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <Card sx={{ display: 'flex', marginBottom: '15px' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '20px' }}>
                                                    {/* Constructeur */}
                                                    <div className="form-group">
                                                        <label>Constructeur</label>
                                                        <Field name="marque_taximetre" className="form-control" as="select" onChange={e => { handleChangeMarque(e) }}>
                                                            <option value="-1"></option>
                                                            {marques && marques.map((marque, index) =>
                                                                <option key={index} value={marque.marque_appareil}>{marque.marque_nom_appareil + " (" + marque.ville_taxi + ")"}</option>
                                                            )}
                                                        </Field>
                                                        <ErrorMessage name="marque_taximetre" component="div" className="text-danger" />
                                                    </div>
                                                    {/* Modèle */}
                                                    <div className="form-group">
                                                        <label htmlFor="modele_taximetre">Modèle</label>
                                                        <Field name="modele_taximetre" className="form-control" as="select" onChange={handleChangeModele}>
                                                            <option value="-1"></option>
                                                            {modeles && modeles.map((modele, index) =>
                                                                <option key={index} value={modele.modele_appareil}>{modele.marque_nom_appareil + " " + modele.modele_nom_appareil}</option>
                                                            )}
                                                        </Field>
                                                        <ErrorMessage name="modele_taximetre" component="div" className="text-danger" />
                                                    </div>
                                                </Box>
                                                <CardMedia
                                                    className='card-media'
                                                    component="img"
                                                    sx={{ width: 361, height: 219 }}
                                                    image={modele && modele.photo_1 ? `data:image/jpeg;base64,${modele.photo_1}` : noImage}
                                                    alt="Taximètre modèle"
                                                />
                                            </Card>
                                            {/* N° Série Centrale */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="numero_serie_taximetre">N° Série Centrale</label>
                                                        <Field className="form-control" type="text" name="numero_serie_taximetre" />
                                                        <ErrorMessage name="numero_serie_taximetre" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Modèles Afficheur */}
                                            {activeAfficheur &&
                                                <div className="form-row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label htmlFor="modele_afficheur">Modèle Afficheur</label>
                                                            <Field name="modele_afficheur" className="form-control" as="select" onChange={handleChangeModeleAfficheur}>
                                                                <option value="-1"></option>
                                                                {modelesAfficheur && modelesAfficheur.map((modele, index) =>
                                                                    <option key={index} value={modele.modele_appareil}>{modele.marque_nom_appareil + " " + modele.modele_nom_appareil}</option>
                                                                )}
                                                            </Field>
                                                            <ErrorMessage name="modele_afficheur" component="div" className="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {/* N° Série Afficheur */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="numero_serie_afficheur">N° Série Afficheur</label>
                                                        <Field className="form-control" type="text" name="numero_serie_afficheur" disabled={!activeAfficheur} />
                                                        <ErrorMessage name="numero_serie_afficheur" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* N° CET initial */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="numero_cet_initial">N° CET initial</label>
                                                        <Field className="form-control" type="text" name="numero_cet_initial" disabled value={values.numero_cet_initial} />
                                                        <ErrorMessage name="numero_cet_initial" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Observations */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="observations">Observations</label>
                                                        <Field className="form-control" as="textarea" name="observations" />
                                                        <ErrorMessage name="observations" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row m-m-0">
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            name="location"
                                                            // value={values.c_r20}
                                                            // checked={values.c_r20}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Location
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            name="relais"
                                                            // value={values.c_r20}
                                                            // checked={values.c_r20}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Relais
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        {/* Propriétaire */}
                                        <TabPane tab={<div className={"tab-title"}>Propriétaire</div>} key={2}>
                                            <div className="form-group">
                                                <div className="form-cell-input">
                                                    <ul>
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="actif_proprio"
                                                                    id="actif_proprio-1"
                                                                    className="sr-only"
                                                                    onChange={handleChangeProprietaire}
                                                                    value={0}
                                                                    checked={values.actif_proprio === 0 ? true : false} />
                                                                <label htmlFor="actif_proprio-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <Adherent />
                                                                    Adherent
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="actif_proprio"
                                                                    id="actif_proprio-2"
                                                                    className="sr-only"
                                                                    onChange={handleChangeProprietaire}
                                                                    value={1}
                                                                    checked={values.actif_proprio === 1 ? true : false} />
                                                                <label htmlFor="actif_proprio-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <Clients />
                                                                    Client
                                                                </label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="actif_proprio"
                                                                    id="actif_proprio-3"
                                                                    className="sr-only"
                                                                    value={2}
                                                                    onChange={handleChangeProprietaire}
                                                                    checked={values.actif_proprio === 2 ? true : false} />
                                                                <label htmlFor="actif_proprio-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <Fabricant />
                                                                    Fabricant
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* Recherche */}
                                            {selectedProprietaire === 1 &&
                                                <div className="form-group">
                                                    <Field className="form-control" type="text" name="recherche_proprio" onChange={handleChangeRechercheProprio} />
                                                    <ErrorMessage name="recherche_proprio" component="div" className="text-danger" />
                                                </div>
                                            }
                                            {/* Proprio */}
                                            {selectedProprietaire === 2 ?
                                                <div className="form-group">
                                                    <Field name="proprio" className="form-control" as="select" onChange={handleChangeFabricant}>
                                                        <option value="-1"></option>
                                                        {fabricants && fabricants.map((fabricant, index) =>
                                                            <option key={index} value={fabricant.numero_ftaxi}>{fabricant.nom_ftaxi + " - " + fabricant.cp_ftaxi}</option>
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name="proprio" component="div" className="text-danger" />
                                                </div> :
                                                <div className="form-group">
                                                    <Field name="proprio" className="form-control" type="text" disabled={selectedProprietaire !== 3 ? true : false} />
                                                    <ErrorMessage name="proprio" component="div" className="text-danger" />
                                                </div>
                                            }
                                            {/* Adresse */}
                                            <div className="form-group">
                                                {/* Adresse 1 */}
                                                <Field className="form-control" type="text" name="adresse1_proprio" disabled />
                                            </div>
                                            <div className="form-group">
                                                {/* Adresse 2 */}
                                                <Field className="form-control" type="text" name="adresse2_proprio" disabled />
                                            </div>
                                            {/* CP / Ville */}
                                            <div className="form-row">
                                                <div className="col">
                                                    <div className="form-group">
                                                        <Field className="form-control" type="text" name="cp_proprio" disabled />
                                                        <ErrorMessage name="cp_proprio" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <Field className="form-control" type="text" name="ville_proprio" disabled />
                                                        <ErrorMessage name="ville_proprio" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div >
                <div className="popup-actions popup-actions-liaisons">
                    <div className="popup-action-open">
                        <button disabled={saving} type="button" className={"upload-file-btn" + (saving ? " disabled" : "")} onClick={handleSaveTaximetre}>
                            <span> {saving ? <UseAnimations strokeColor={'var(--comet-white)'} animation={loading} size={24} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : "Enregistrer"}</span>
                        </button>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default TaximetrePopup