import { createContext, useState } from "react"

export const ConfirmContext = createContext()

const ConfirmContextProvider = ({ children }) => {
    const [confirm, setConfirm] = useState({
        title: "",
        opts: {
            message: "",
            option: 1
        },
        isOpen: false,
        proceed: null,
        cancel: null
    })

    return (
        <ConfirmContext.Provider value={[confirm, setConfirm]}>
            {children}
        </ConfirmContext.Provider>
    )
}

export default ConfirmContextProvider