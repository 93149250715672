import React, { useContext, useState } from 'react'
import { createContext } from 'react'

export const SaisieContext = createContext()

export const SaisieContextProvider = props => {

    const [formVerif, setFormVerif] = useState(null)
    const [formIntervention, setFormIntervention] = useState(JSON.parse(localStorage.intervention))
    const [formPhotos, setFormPhotos] = useState(null)
    const [formMateriel, setFormMateriel] = useState(null)
    const [formVignette, setFormVignette] = useState(null)
    const [isReelle, setIsReelle] = useState(true)
    const [isUniv, setIsUniv] = useState(false)
    const [isBanc, setIsBanc] = useState(false)
    const [okList, setOkList] = useState([])
    const [vpVisible, setVpVisible] = useState(false)
    const [vaiVisible, setVaiVisible] = useState(false)
    const [nbChutes, setNbChutes] = useState("")
    const [emtMoins, setEmtMoins] = useState(0)
    const [emtPlus, setEmtPlus] = useState(0)
    const [stopAll, setStopAll] = useState(false)
    const [historiqueAfficheur, setHistoriqueAfficheur] = useState([])
    const [currentSaisieTitle, setCurrentSaisieTitle] = useState("Infos")
    const [calculK, setCalculK] = useState(null)
    const [blockSaveMetrologie, setBlockSaveMetrologie] = useState(false)
    const [scellementTarifTaximetre, setScellementTarifTaximetre] = useState("")
    const [scellementTarifAfficheur, setScellementTarifAfficheur] = useState("")
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [activeKeyTab, setActiveKeyTab] = useState("1")

    const handleChangeTab = (active) => {
        setActiveKeyTab(active)
    }

    const value = {
        formVerif, setFormVerif,
        formIntervention, setFormIntervention,
        formPhotos, setFormPhotos,
        formMateriel, setFormMateriel,
        formVignette, setFormVignette,
        okList, setOkList,
        isReelle, setIsReelle,
        isUniv, setIsUniv,
        isBanc, setIsBanc,
        vpVisible, setVpVisible,
        vaiVisible, setVaiVisible,
        nbChutes, setNbChutes,
        emtMoins, setEmtMoins,
        emtPlus, setEmtPlus,
        historiqueAfficheur, setHistoriqueAfficheur,
        currentSaisieTitle, setCurrentSaisieTitle,
        stopAll, setStopAll,
        calculK, setCalculK,
        blockSaveMetrologie, setBlockSaveMetrologie,
        scellementTarifTaximetre, setScellementTarifTaximetre,
        scellementTarifAfficheur, setScellementTarifAfficheur,
        startDate, setStartDate, endDate, setEndDate, activeKeyTab, setActiveKeyTab, handleChangeTab
    }

    return (
        <SaisieContext.Provider value={value}>
            {props.children}
        </SaisieContext.Provider>
    )

}

export const useSaisieContext = () => useContext(SaisieContext)