
const isChronoGood = (lue_secondes, emt_plus, emt_moins, colorSetter) => {
    if (lue_secondes === 0) {
        // Neutre
        colorSetter(-1)
    }
    else if (lue_secondes <= emt_plus && lue_secondes >= emt_moins) {
        // Vert / Bon
        colorSetter(1)
    }
    else {
        // Rouge / Pas bon
        colorSetter(0)
    }
}

export {
    isChronoGood
}