import { createContext, useContext } from "react"
import { authService } from "../services/authService"

export const AuthContext = createContext({
    login: (pathname) => ({}),
    signinRedirectCallback: () => ({}),
    isAuthenticated: () => ({}),
    isLoggedIn: () => ({}),
    logout: () => ({}),
    logoutRedirectCallback: () => ({}),
    getUser: () => ({}),
    getLocalUser: () => ({}),
    accessToken: () => ({}),
    userRole: () => ({}),
    userGroup: () => ({}),
    userId: () => ({}),
    signInSilentCallback: () => ({}).then(() => console.log("Salut")).catch(error => console.log("Erreur")),
})

export const AuthConsumer = AuthContext.Consumer

export const useAuth = () => {
    return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
    return <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
}