import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { ReactComponent as OK } from './../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../res/erreur.svg'
import { ReactComponent as Intervention } from './../../../../res/alerte.svg'

const Etiquette = (props) => {

    const [etiquette] = useState(JSON.parse(localStorage.getItem("taximetre")))
    const [infos] = useState(JSON.parse(localStorage.getItem("infos")))

    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    etiquettek: etiquette.etiquettek,
                    c_etiquettek1: etiquette.c_etiquettek1,
                    c_etiquettek2: etiquette.c_etiquettek2,
                    c_etiquettek3: etiquette.c_etiquettek3,
                }}
                validate={(values) => {
                    const errors = {}
                    let taximetre = JSON.parse(localStorage.taximetre)
                    // Mise à jour des taximetres
                    taximetre.etiquettek = values.etiquettek
                    taximetre.c_etiquettek1 = values.c_etiquettek1
                    taximetre.c_etiquettek2 = values.c_etiquettek2
                    taximetre.c_etiquettek3 = values.c_etiquettek3
                    // Partage des données
                    props.passFormToParent(taximetre, errors)
                    // Mise à jour de localStorage
                    localStorage.setItem("taximetre", JSON.stringify(taximetre))
                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500))
                    alert(JSON.stringify(values, null, 2))
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container etiquette">
                            {/* Valeur 'k' lue dans taximetre */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Valeur 'k' lue dans Taximètre
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <div className="form-input">
                                        <Field className="form-control" type="number" name="etiquettek" placeholder="Valeur" />
                                    </div>
                                </div>
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        imp/km
                                    </div>
                                </div>
                            </div>
                            {/* Conformité */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Conformité
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_etiquettek1"
                                                    id="c_etiquettek1-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_etiquettek1', 1)}
                                                    checked={values.c_etiquettek1 === 1 ? true : false} />
                                                <label htmlFor="c_etiquettek1-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_etiquettek1"
                                                    id="c_etiquettek1-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_etiquettek1', 2)}
                                                    checked={values.c_etiquettek1 === 2 ? true : false} />
                                                <label htmlFor="c_etiquettek1-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R14
                                                </label>
                                            </div>
                                        </li>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_etiquettek1"
                                                        id="c_etiquettek1-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_etiquettek1', 3)}
                                                        checked={values.c_etiquettek1 === 3 ? true : false} />
                                                    <label htmlFor="c_etiquettek1-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I14
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_etiquettek1"
                                                    id="c_etiquettek1-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_etiquettek1', 0)}
                                                    checked={(values.c_etiquettek1 === 0 || values.c_etiquettek1 === 4) ? true : false} />
                                                <label htmlFor="c_etiquettek1-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Controle */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Contrôle
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        <Field className="form-control form-infos" disabled as="textarea" name="manip_k" value={infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.manip_k} />
                                    </div>
                                </div>
                            </div>
                            {/* Zone d'exercice */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Zone d'exercice (dpt)
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <div className="form-input">
                                        <Field className="form-control input-extra-small" disabled type="text" name="clef_dpt" value={infos && infos.stationnements[0].clef_dpt} />
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_etiquettek2"
                                                    id="c_etiquettek2-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_etiquettek2', 1)}
                                                    checked={values.c_etiquettek2 === 1 ? true : false} />
                                                <label htmlFor="c_etiquettek2-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_etiquettek2"
                                                    id="c_etiquettek2-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_etiquettek2', 2)}
                                                    checked={values.c_etiquettek2 === 2 ? true : false} />
                                                <label htmlFor="c_etiquettek2-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R14
                                                </label>
                                            </div>
                                        </li>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_etiquettek2"
                                                        id="c_etiquettek2-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_etiquettek2', 3)}
                                                        checked={values.c_etiquettek2 === 3 ? true : false} />
                                                    <label htmlFor="c_etiquettek2-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I14
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_etiquettek2"
                                                    id="c_etiquettek2-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_etiquettek2', 0)}
                                                    checked={(values.c_etiquettek2 === 0 || values.c_etiquettek2 === 4) ? true : false} />
                                                <label htmlFor="c_etiquettek2-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Taille lettre */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Taille lettre >= 10 mm
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_etiquettek3"
                                                    id="c_etiquettek3-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_etiquettek3', 1)}
                                                    checked={values.c_etiquettek3 === 1 ? true : false} />
                                                <label htmlFor="c_etiquettek3-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_etiquettek3"
                                                    id="c_etiquettek3-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_etiquettek3', 2)}
                                                    checked={values.c_etiquettek3 === 2 ? true : false} />
                                                <label htmlFor="c_etiquettek3-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R14
                                                </label>
                                            </div>
                                        </li>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_etiquettek3"
                                                        id="c_etiquettek3-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_etiquettek3', 3)}
                                                        checked={values.c_etiquettek3 === 3 ? true : false} />
                                                    <label htmlFor="c_etiquettek3-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I14
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_etiquettek3"
                                                    id="c_etiquettek3-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_etiquettek3', 0)}
                                                    checked={(values.c_etiquettek3 === 0 || values.c_etiquettek3 === 4) ? true : false} />
                                                <label htmlFor="c_etiquettek3-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Etiquette