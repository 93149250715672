import { ReactComponent as Vehicule } from "./../../../../assets/saisie-tachy-menu/vehicule.svg"
import { ReactComponent as Pneu } from "./../../../../assets/saisie-menu/wheel.svg"
import { ReactComponent as LSol } from "./../../../../assets/saisie-tachy-menu/lettre-l.svg"
import { ReactComponent as Plaque } from "./../../../../assets/saisie-tachy-menu/plaque-dimmatriculation.svg"
import { ReactComponent as Capteur } from "./../../../../assets/saisie-tachy-menu/capteur.svg"
import { ReactComponent as Adaptateur } from "./../../../../assets/saisie-tachy-menu/adaptateur.svg"
import { ReactComponent as Photos } from "./../../../../assets/saisie-tachy-menu/appareil-photo.svg"
import { ReactComponent as GNSS } from "./../../../../assets/saisie-tachy-menu/satellite.svg"
import { ReactComponent as Scellement } from "./../../../../assets/saisie-tachy-menu/serrer.svg"
import { ReactComponent as CalculW } from "./../../../../assets/saisie-tachy-menu/lettre-w.svg"
import { ReactComponent as LBanc } from "./../../../../assets/saisie-tachy-menu/ascenseur.svg"
import { ReactComponent as Parcours } from "./../../../../assets/saisie-tachy-menu/itineraire.svg"
import { ReactComponent as Simulation } from "./../../../../assets/saisie-tachy-menu/volant.svg"
import { ReactComponent as DSRC } from "./../../../../assets/saisie-tachy-menu/voiture-autonome.svg"
import { ReactComponent as Impression } from "./../../../../assets/saisie-tachy-menu/pile-de-papier.svg"
import { ReactComponent as Telechargement } from "./../../../../assets/saisie-tachy-menu/bar.svg"
import { ReactComponent as Sanction } from "./../../../../assets/saisie-tachy-menu/verification.svg"
import { ReactComponent as Vu } from "./../../../../assets/general/vu.svg"
import { ReactComponent as Infos } from "./../../../../res/info.svg"

const TachyNavigation = [
    {
        group: "Réception",
        items: [
            {
                name: 'Réception Véhicule',
                image: <Vehicule />,
                link: '/intervention/reception-vehicule',
            },
            {
                name: 'Réception Pneus',
                image: <Pneu />,
                link: '/intervention/reception-pneumatique',
            },
        ]
    },
    {
        group: "Vérification",
        items: [
            {
                name: 'L au Sol',
                image: <LSol />,
                link: '/intervention/l-au-sol',
            },
            {
                name: 'V.U.',
                image: <Vu />,
                link: '/intervention/vu',
            },
            {
                name: 'Plaque',
                image: <Plaque />,
                link: '/intervention/plaque',
            },
            {
                name: 'Capteur',
                image: <Capteur />,
                link: '/intervention/capteur',
            },
            {
                name: 'Adaptateur',
                image: <Adaptateur />,
                link: '/intervention/adaptateur',
            },
            {
                name: 'Photos',
                image: <Photos />,
                link: '/intervention/photos',
            },
            {
                name: 'GNSS',
                image: <GNSS />,
                link: '/intervention/gnss',
            },
            {
                name: 'Scellement Gen 2',
                image: <Scellement />,
                link: '/intervention/scellement-gen-2',
            },
            {
                name: 'Calcul W',
                image: <CalculW />,
                link: '/intervention/calcul-w',
            },
            {
                name: 'L au Banc',
                image: <LBanc />,
                link: '/intervention/l-au-banc',
            },
            {
                name: 'Parcours',
                image: <Parcours />,
                link: '/intervention/parcours',
            },
            {
                name: 'Simulation',
                image: <Simulation />,
                link: '/intervention/simulation',
            },
            {
                name: 'DSRC',
                image: <DSRC />,
                link: '/intervention/dsrc',
            },
            {
                name: 'Impression',
                image: <Impression />,
                link: '/intervention/impression',
            },
            {
                name: 'Téléchargement',
                image: <Telechargement />,
                link: '/intervention/telechargement',
            },
            {
                name: 'Sanction Vérification',
                image: <Sanction />,
                link: '/intervention/sanction',
            },
        ]
    },
    {
        group: "Aides",
        items: [
            {
                name: 'Infos',
                image: <Infos />,
                link: '/intervention/infos',
            },
        ]
    },
]

export {
    TachyNavigation
}