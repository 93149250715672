import React, { useEffect, useState } from "react"
import { Formik, Form, Field } from 'formik'
import { Accordion, Card } from 'react-bootstrap'
import { NavLink } from "react-router-dom"
import LiaisonPopup from "../../../Popup/LiaisonPopup"
import { extractNumberFromString, getListImageMateriel, getNomLiaisonTax, lockBody, unlockBody } from "../../../../helpers/utils"
import Switch from '@material-ui/core/Switch'
import { useLovelySwitchStyles } from '@mui-treasury/styles/switch/lovely'
import { Tabs } from 'antd'
import Checkbox from '@material-ui/core/Checkbox'
import { ReactComponent as OK } from './../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../res/erreur.svg'
import { useSaisieContext } from "../SaisieContext"
import { supervisionService } from './../../../../services/supervisionService.jsx'
import { toast } from "react-toastify"
import { ReactComponent as Intervention } from './../../../../res/alerte.svg'
import { useDataContext } from "../../../../providers/DataContextProvider"

const { TabPane } = Tabs

const Liaisons = (props) => {

    const [liaisons, setLiaisons] = useState(null)
    const [isOpened, setIsOpened] = useState(false)
    const [liaisonsTaximetre] = useState(JSON.parse(localStorage.taximetre))
    const [liaisons_intervention] = useState(JSON.parse(localStorage.intervention))
    const [appareils, setAppareils] = useState([])
    const [materiels] = useState(JSON.parse(localStorage.materiel))
    // Style de switch
    const lovelyStyles = useLovelySwitchStyles()
    const { setFormVerif } = useSaisieContext()
    const [errors, setErrors] = useState(null)
    const { interventionInfoGeneral } = useDataContext()
    const data = {
        numero_adherent: liaisons_intervention.numero_adherent,
        numero_client: liaisons_intervention.numero_client,
        clef_stationnement: liaisons_intervention.clef_stationnement,
        clef_lien: liaisons_intervention.clef_lien,
        numero_intervention: liaisons_intervention.numero_intervention,
        date_intervention: liaisons_intervention.date_intervention,
    }

    useEffect(() => {
        // localStorage.liaisons est un array et pas un Map
        if (localStorage.liaisons) {
            let set = new Map(JSON.parse(localStorage.liaisons))
            if (set.size > 1 && set.get(2)) {
                setLiaisons(set)
                let newAppareils = appareils
                set.forEach((value, key, map) => {
                    // on exclut les matériels dans Autres qui
                    // ne sont pas représentés dans la partie Liaisons
                    if (key !== 4 && key !== 14 && key !== 12 && key !== 11 && key !== 9) {
                        newAppareils.push(key)
                    }
                })
                // On ajoute aussi les capteur/fil et la batterie
                // Batterie
                newAppareils.push(20)
                // Capteur/fil
                newAppareils.push(21)
                setAppareils(newAppareils)
            }
        }
        else if (materiels && !localStorage.liaisons) {
            let materielsMap = new Map()
            for (let i = 0; i < materiels.length; i++) {
                materielsMap.set(materiels[i].numero_type_interne, materiels[i])
            }
            localStorage.setItem("liaisons", JSON.stringify(Array.from(materielsMap.entries())))
            if (materielsMap.size > 1 && materielsMap.get(2)) {
                setLiaisons(materielsMap)
                let newAppareils = appareils
                materielsMap.forEach((value, key, map) => {
                    // on exclut les matériels dans Autres qui
                    // ne sont pas représentés dans la partie Liaisons
                    if (key !== 4 && key !== 14 && key !== 12 && key !== 11 && key !== 9) {
                        newAppareils.push(key)
                    }
                })
                // On ajoute aussi les capteur/fil et la batterie
                // Batterie
                newAppareils.push(20)
                // Capteur/fil
                newAppareils.push(21)
                setAppareils(newAppareils)
            }
        }
    }, [])

    const togglePopup = () => {
        setIsOpened(!isOpened)
        isOpened ? unlockBody() : lockBody()
    }

    const handleChangeRst = (e, setFieldValue) => {
        let name = e.target.name
        let value = parseInt(e.target.value)
        setFieldValue(name, value)
        // Mise à jour de l'etat global
        // Dans name on liaisons."le nom"
        let taximetre = JSON.parse(localStorage.taximetre)
        taximetre[name.split(".")[1]] = value
        setFormVerif(taximetre)
        localStorage.setItem("taximetre", JSON.stringify(taximetre))
    }

    const handleChangeBlinde = (e, setFieldValue) => {
        let name = e.target.name
        let value = parseInt(e.target.value) === 1 ? 2 : 1
        setFieldValue(name, value)
        // Mise à jour de l'etat global
        let taximetre = JSON.parse(localStorage.taximetre)
        // Dans name on liaisons."le nom"
        taximetre[name.split(".")[1]] = value
        setFormVerif(taximetre)
        localStorage.setItem("taximetre", JSON.stringify(taximetre))
    }

    const handleChangeCB = (e, values, setFieldValue) => {
        let name = e.target.name
        let textName = name.split("c_")[1]
        let value = !values[name]
        setFieldValue(name, value)
        setFieldValue(textName, "")
        // Mise à jour de l'etat global
        let taximetre = JSON.parse(localStorage.taximetre)
        // Dans name on liaisons."le nom"
        taximetre[name] = value
        taximetre[textName] = ""
        setFormVerif(taximetre)
        localStorage.setItem("taximetre", JSON.stringify(taximetre))
    }

    const deleteLiaison = (values, c_liaisons, setFieldValue) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cette liaison ?")) {
            let liaisonName = "liaisons." + c_liaisons
            let blindeName = "blindes.c_blinde" + extractNumberFromString(c_liaisons)
            setFieldValue(liaisonName, 0)
            setFieldValue(blindeName, 0)
            setTimeout(() => {
                // Mise à jour de l'etat global
                let taximetre = JSON.parse(localStorage.taximetre)
                taximetre[c_liaisons] = 0
                taximetre["c_blinde" + extractNumberFromString(c_liaisons)] = 0
                setFormVerif(taximetre)
                localStorage.setItem("taximetre", JSON.stringify(taximetre))
            }, 100)

        }
    }

    const handleEgalPrecedentTax = (e, setValues) => {
        supervisionService.egalePrecedentTax(data)
            .then(response => {
                let taximetre = JSON.parse(localStorage.taximetre)
                let liaisons = new Map(JSON.parse(localStorage.liaisons))

                let c_liaisons1 = liaisons.get(1) ? response.c_liaisons1 : 0
                let c_liaisons2 = liaisons.get(3) ? response.c_liaisons2 : 0
                let c_liaisons3 = liaisons.get(2) && liaisons.get(6) && liaisons.get(3) ? response.c_liaisons3 : 0
                let c_liaisons4 = liaisons.get(1) && liaisons.get(3) ? response.c_liaisons4 : 0
                let c_liaisons5 = liaisons.get(3) ? response.c_liaisons5 : 0
                let c_liaisons6 = liaisons.get(2) && liaisons.get(3) ? response.c_liaisons6 : 0
                let c_liaisons7 = liaisons.get(2) && liaisons.get(1) ? response.c_liaisons7 : 0
                let c_liaisons8 = response.c_liaisons8
                let c_liaisons9 = liaisons.get(2) && liaisons.get(6) && liaisons.get(1) ? response.c_liaisons9 : 0
                let c_liaisons10 = liaisons.get(10) && liaisons.get(1) ? response.c_liaisons10 : 0
                let c_liaisons11 = liaisons.get(7) && liaisons.get(1) ? response.c_liaisons11 : 0
                let c_liaisons12 = liaisons.get(2) && liaisons.get(13) ? response.c_liaisons12 : 0
                let c_liaisons13 = liaisons.get(1) ? response.c_liaisons13 : 0
                let c_liaisons14 = liaisons.get(13) && liaisons.get(2) ? response.c_liaisons14 : 0
                let c_liaisons15 = liaisons.get(13) && liaisons.get(1) ? response.c_liaisons15 : 0
                let c_liaisons16 = liaisons.get(13) && liaisons.get(3) ? response.c_liaisons16 : 0
                let c_liaisons17 = liaisons.get(7) && liaisons.get(3) ? response.c_liaisons17 : 0
                let c_liaisons18 = liaisons.get(7) ? response.c_liaisons18 : 0
                let c_liaisons19 = liaisons.get(13) ? (response.c_liaisons19 === null ? 0 : response.c_liaisons19) : 0
                let c_liaisons20 = liaisons.get(13) && liaisons.get(6) && liaisons.get(2) ? (response.c_liaisons20 === null ? 0 : response.c_liaisons20) : 0

                let c_blinde1 = liaisons.get(1) ? response.c_blinde1 : 0
                let c_blinde2 = liaisons.get(3) ? response.c_blinde2 : 0
                let c_blinde3 = liaisons.get(2) && liaisons.get(6) && liaisons.get(3) ? response.c_blinde3 : 0
                let c_blinde4 = liaisons.get(1) && liaisons.get(3) ? response.c_blinde4 : 0
                let c_blinde5 = liaisons.get(3) ? response.c_blinde5 : 0
                let c_blinde6 = liaisons.get(2) && liaisons.get(3) ? response.c_blinde6 : 0
                let c_blinde7 = liaisons.get(2) && liaisons.get(1) ? response.c_blinde7 : 0
                let c_blinde8 = response.c_blinde8
                let c_blinde9 = liaisons.get(2) && liaisons.get(6) && liaisons.get(1) ? response.c_blinde9 : 0
                let c_blinde10 = liaisons.get(10) && liaisons.get(1) ? response.c_blinde10 : 0
                let c_blinde11 = liaisons.get(7) && liaisons.get(1) ? response.c_blinde11 : 0
                let c_blinde12 = liaisons.get(2) && liaisons.get(13) ? response.c_blinde12 : 0
                let c_blinde13 = liaisons.get(1) ? response.c_blinde13 : 0
                let c_blinde14 = liaisons.get(13) && liaisons.get(2) ? response.c_blinde14 : 0
                let c_blinde15 = liaisons.get(13) && liaisons.get(1) ? response.c_blinde15 : 0
                let c_blinde16 = liaisons.get(13) && liaisons.get(3) ? response.c_blinde16 : 0
                let c_blinde17 = liaisons.get(7) && liaisons.get(3) ? response.c_blinde17 : 0
                let c_blinde18 = liaisons.get(7) ? response.c_blinde18 : 0
                let c_blinde19 = liaisons.get(13) ? (response.c_blinde19 === null ? 0 : response.c_blinde19) : 0
                let c_blinde20 = liaisons.get(13) && liaisons.get(6) && liaisons.get(2) ? (response.c_blinde20 === null ? 0 : response.c_blinde20) : 0

                setValues(prev => ({
                    ...prev,
                    liaisons: {
                        c_liaisons1: c_liaisons1,
                        c_liaisons2: c_liaisons2,
                        c_liaisons3: c_liaisons3,
                        c_liaisons4: c_liaisons4,
                        c_liaisons5: c_liaisons5,
                        c_liaisons6: c_liaisons6,
                        c_liaisons7: c_liaisons7,
                        c_liaisons8: c_liaisons8,
                        c_liaisons9: c_liaisons9,
                        c_liaisons10: c_liaisons10,
                        c_liaisons11: c_liaisons11,
                        c_liaisons12: c_liaisons12,
                        c_liaisons13: c_liaisons13,
                        c_liaisons14: c_liaisons14,
                        c_liaisons15: c_liaisons15,
                        c_liaisons16: c_liaisons16,
                        c_liaisons17: c_liaisons17,
                        c_liaisons18: c_liaisons18,
                        c_liaisons19: c_liaisons19,
                        c_liaisons20: c_liaisons20,
                    },
                    blindes: {
                        c_blinde1: c_blinde1,
                        c_blinde2: c_blinde2,
                        c_blinde3: c_blinde3,
                        c_blinde4: c_blinde4,
                        c_blinde5: c_blinde5,
                        c_blinde6: c_blinde6,
                        c_blinde7: c_blinde7,
                        c_blinde8: c_blinde8,
                        c_blinde9: c_blinde9,
                        c_blinde10: c_blinde10,
                        c_blinde11: c_blinde11,
                        c_blinde12: c_blinde12,
                        c_blinde13: c_blinde13,
                        c_blinde14: c_blinde14,
                        c_blinde15: c_blinde15,
                        c_blinde16: c_blinde16,
                        c_blinde17: c_blinde17,
                        c_blinde18: c_blinde18,
                        c_blinde19: c_blinde19,
                        c_blinde20: c_blinde20,
                    },
                    liaison8: response.liaison8,
                    c_r20: response.c_r20,
                    c_r21: response.c_r21,
                    c_r22: response.c_r22,
                    r20: response.r20,
                    r21: response.r21,
                    r22: response.r22,
                }))

                taximetre.c_liaisons1 = c_liaisons1
                taximetre.c_liaisons2 = c_liaisons2
                taximetre.c_liaisons3 = c_liaisons3
                taximetre.c_liaisons4 = c_liaisons4
                taximetre.c_liaisons5 = c_liaisons5
                taximetre.c_liaisons6 = c_liaisons6
                taximetre.c_liaisons7 = c_liaisons7
                taximetre.c_liaisons8 = c_liaisons8
                taximetre.c_liaisons9 = c_liaisons9
                taximetre.c_liaisons10 = c_liaisons10
                taximetre.c_liaisons11 = c_liaisons11
                taximetre.c_liaisons12 = c_liaisons12
                taximetre.c_liaisons13 = c_liaisons13
                taximetre.c_liaisons14 = c_liaisons14
                taximetre.c_liaisons15 = c_liaisons15
                taximetre.c_liaisons16 = c_liaisons16
                taximetre.c_liaisons17 = c_liaisons17
                taximetre.c_liaisons18 = c_liaisons18
                taximetre.c_liaisons19 = c_liaisons19
                taximetre.c_liaisons20 = c_liaisons20
                taximetre.c_blinde1 = c_blinde1
                taximetre.c_blinde2 = c_blinde2
                taximetre.c_blinde3 = c_blinde3
                taximetre.c_blinde4 = c_blinde4
                taximetre.c_blinde5 = c_blinde5
                taximetre.c_blinde6 = c_blinde6
                taximetre.c_blinde7 = c_blinde7
                taximetre.c_blinde8 = c_blinde8
                taximetre.c_blinde9 = c_blinde9
                taximetre.c_blinde10 = c_blinde10
                taximetre.c_blinde11 = c_blinde11
                taximetre.c_blinde12 = c_blinde12
                taximetre.c_blinde13 = c_blinde13
                taximetre.c_blinde14 = c_blinde14
                taximetre.c_blinde15 = c_blinde15
                taximetre.c_blinde16 = c_blinde16
                taximetre.c_blinde17 = c_blinde17
                taximetre.c_blinde18 = c_blinde18
                taximetre.c_blinde19 = c_blinde19
                taximetre.c_blinde20 = c_blinde20
                taximetre.liaison8 = response.liaison8
                taximetre.c_r20 = response.c_r20
                taximetre.c_r21 = response.c_r21
                taximetre.c_r22 = response.c_r22
                taximetre.r20 = response.r20
                taximetre.r21 = response.r21
                taximetre.r22 = response.r22

                setFormVerif(taximetre)
                localStorage.setItem("taximetre", JSON.stringify(taximetre))
                toast.success("Dernière liaison trouvée. Modification effectuée.", { containerId: 'PRE' })
            })
            .catch(error => toast.info(error, { containerId: 'PRE' }))
    }

    return (
        <div className="liaisons-section">
            <div className="liaisons-container">
                <h2 className="mb-3">
                    Liaisons
                </h2>

                <Formik
                    enableReinitialize={true}
                    validateOnMount={true}
                    initialValues={{
                        liaisons: {
                            c_liaisons1: liaisonsTaximetre.c_liaisons1,
                            c_liaisons2: liaisonsTaximetre.c_liaisons2,
                            c_liaisons3: liaisonsTaximetre.c_liaisons3,
                            c_liaisons4: liaisonsTaximetre.c_liaisons4,
                            c_liaisons5: liaisonsTaximetre.c_liaisons5,
                            c_liaisons6: liaisonsTaximetre.c_liaisons6,
                            c_liaisons7: liaisonsTaximetre.c_liaisons7,
                            c_liaisons8: liaisonsTaximetre.c_liaisons8,
                            c_liaisons9: liaisonsTaximetre.c_liaisons9,
                            c_liaisons10: liaisonsTaximetre.c_liaisons10,
                            c_liaisons11: liaisonsTaximetre.c_liaisons11,
                            c_liaisons12: liaisonsTaximetre.c_liaisons12,
                            c_liaisons13: liaisonsTaximetre.c_liaisons13,
                            c_liaisons14: liaisonsTaximetre.c_liaisons14,
                            c_liaisons15: liaisonsTaximetre.c_liaisons15,
                            c_liaisons16: liaisonsTaximetre.c_liaisons16,
                            c_liaisons17: liaisonsTaximetre.c_liaisons17,
                            c_liaisons18: liaisonsTaximetre.c_liaisons18,
                            c_liaisons19: liaisonsTaximetre.c_liaisons19,
                            c_liaisons20: liaisonsTaximetre.c_liaisons20,
                        },
                        blindes: {
                            c_blinde1: liaisonsTaximetre.c_blinde1,
                            c_blinde2: liaisonsTaximetre.c_blinde2,
                            c_blinde3: liaisonsTaximetre.c_blinde3,
                            c_blinde4: liaisonsTaximetre.c_blinde4,
                            c_blinde5: liaisonsTaximetre.c_blinde5,
                            c_blinde6: liaisonsTaximetre.c_blinde6,
                            c_blinde7: liaisonsTaximetre.c_blinde7,
                            c_blinde8: liaisonsTaximetre.c_blinde8,
                            c_blinde9: liaisonsTaximetre.c_blinde9,
                            c_blinde10: liaisonsTaximetre.c_blinde10,
                            c_blinde11: liaisonsTaximetre.c_blinde11,
                            c_blinde12: liaisonsTaximetre.c_blinde12,
                            c_blinde13: liaisonsTaximetre.c_blinde13,
                            c_blinde14: liaisonsTaximetre.c_blinde14,
                            c_blinde15: liaisonsTaximetre.c_blinde12,
                            c_blinde16: liaisonsTaximetre.c_blinde16,
                            c_blinde17: liaisonsTaximetre.c_blinde17,
                            c_blinde18: liaisonsTaximetre.c_blinde18,
                            c_blinde19: liaisonsTaximetre.c_blinde19,
                            c_blinde20: liaisonsTaximetre.c_blinde20,
                        },
                        liaison8: liaisonsTaximetre.liaison8,
                        c_r20: liaisonsTaximetre.c_r20,
                        c_r21: liaisonsTaximetre.c_r21,
                        c_r22: liaisonsTaximetre.c_r22,
                        r20: liaisonsTaximetre.r20,
                        r21: liaisonsTaximetre.r21,
                        r22: liaisonsTaximetre.r22,
                    }}
                    validate={(values) => {
                        // Validation pour voir les changements sur le formulaire
                        // et activer ou non les boutons d'enregistrement
                        // Utilise pour la partie Autres
                        const errors = {}
                        let taximetre = JSON.parse(localStorage.taximetre)
                        // Mise à jour des taximetres
                        taximetre.liaison8 = values.liaison8
                        taximetre.c_blinde8 = values.blindes.c_blinde8
                        taximetre.c_liaisons8 = values.liaisons.c_liaisons8
                        taximetre.c_r20 = values.c_r20
                        taximetre.r20 = values.r20
                        taximetre.c_r21 = values.c_r21
                        taximetre.r21 = values.r21
                        taximetre.c_r22 = values.c_r22
                        taximetre.r22 = values.r22
                        // Partage des données
                        // Mise à jour de l'etat global
                        setFormVerif(taximetre)
                        setErrors({ ...errors })
                        // Mise à jour de localStorage
                        localStorage.setItem("taximetre", JSON.stringify(taximetre))
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            setSubmitting(false)
                        }, 400)
                    }}
                >
                    {({ values, setFieldValue, setValues }) => (
                        <Form>
                            {liaisons ?
                                <>
                                    <Tabs>
                                        <TabPane tab="Liaisons" key="1">

                                            <div className="liaisons-button-container">
                                                <div className="section-precedent">
                                                    <button type="button" className="cbtn cbtn-success mt-0  mr-4" onClick={e => handleEgalPrecedentTax(e, setValues)}>Egal Base</button>
                                                </div>
                                                <button type="button" className="comet-btn comet-btn-primary" onClick={togglePopup}>
                                                    Ajouter liaison
                                                </button>
                                            </div>
                                            <Accordion>
                                                {/* c_liaisons retourne c_liaisons7 ou c_liaisons10 etc */}
                                                {values && Object.keys(values.liaisons).filter(c_liaisons => values.liaisons[c_liaisons] !== 0 && values.liaisons[c_liaisons] !== undefined && c_liaisons !== "c_liaisons8").map((c_liaisons, n) =>
                                                    <Card key={n} className="lienrapport-card">
                                                        <Accordion.Toggle as={Card.Header} className="lienrapport-card-header liaisons-card-header" eventKey={"" + n + ""}>
                                                            <label className="liaisons-card-header-text">
                                                                {getNomLiaisonTax(extractNumberFromString(c_liaisons))}
                                                            </label>
                                                            <div className="liaisons-images">
                                                                {c_liaisons !== "c_liaisons8" && getListImageMateriel(extractNumberFromString(c_liaisons)).map((item, n) =>
                                                                    <img key={n} src={item} alt="test" />
                                                                )}
                                                            </div>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey={"" + n + ""}>
                                                            <Card.Body>
                                                                <div className="form-group rst">
                                                                    <label htmlFor={"liaisons." + c_liaisons}>Rst</label>
                                                                    <div className="rst-inputs">
                                                                        <div className="inputGroup">
                                                                            <Field
                                                                                id={"liaisons" + (n + 1) + "-1"}
                                                                                className="checkbox-rst checkbox-rst-1"
                                                                                name={"liaisons." + c_liaisons}
                                                                                type="radio"
                                                                                checked={values.liaisons[c_liaisons] === 1 ? true : false}
                                                                                value={1}
                                                                                onChange={e => handleChangeRst(e, setFieldValue)}
                                                                            />
                                                                            <label htmlFor={"liaisons" + (n + 1) + "-1"}>OK</label>
                                                                        </div>
                                                                        <div className="inputGroup">
                                                                            <Field
                                                                                id={"liaisons" + (n + 1) + "-2"}
                                                                                className="checkbox-rst checkbox-rst-2"
                                                                                name={"liaisons." + c_liaisons}
                                                                                type="radio"
                                                                                checked={values.liaisons[c_liaisons] === 3 ? true : false}
                                                                                value={3}
                                                                                onChange={e => handleChangeRst(e, setFieldValue)}
                                                                            />
                                                                            <label htmlFor={"liaisons" + (n + 1) + "-2"}>Interv</label>
                                                                        </div>
                                                                        <div className="inputGroup">
                                                                            <Field
                                                                                id={"liaisons" + (n + 1) + "-3"}
                                                                                className="checkbox-rst checkbox-rst-3"
                                                                                name={"liaisons." + c_liaisons}
                                                                                type="radio"
                                                                                checked={values.liaisons[c_liaisons] === 2 ? true : false}
                                                                                value={2}
                                                                                onChange={e => handleChangeRst(e, setFieldValue)}
                                                                            />
                                                                            <label htmlFor={"liaisons" + (n + 1) + "-3"}>Refus</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group checkbox-group">
                                                                    <label className="options-label">Blindé {values.blindes["c_blinde" + extractNumberFromString(c_liaisons)] === 1 ? <span className="details-item option-yes">Oui</span> : <span className="details-item">Non</span>} </label>
                                                                    <Switch
                                                                        name={"blindes.c_blinde" + extractNumberFromString(c_liaisons)}
                                                                        classes={lovelyStyles}
                                                                        checked={values.blindes["c_blinde" + extractNumberFromString(c_liaisons)] === 1 ? true : false}
                                                                        value={values.blindes["c_blinde" + extractNumberFromString(c_liaisons)]}
                                                                        onChange={e => handleChangeBlinde(e, setFieldValue)}
                                                                    />
                                                                </div>
                                                                <div className="action-button-container">
                                                                    <button id="del6" className="photo-action-button" type="button" onClick={e => deleteLiaison(values, c_liaisons, setFieldValue)}>
                                                                        <i className="fas fa-trash-alt"></i>
                                                                    </button>
                                                                </div>
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                )}
                                            </Accordion>
                                        </TabPane>
                                        <TabPane tab="Autres" key="2">
                                            <div className="form-group">
                                                {/* Autre */}
                                                <div className="form-row">
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <div className="indication-title">
                                                                Autre :
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-cell-input">
                                                        <div className="form-input">
                                                            <Field type="text" name="liaison8" className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="affichage-item-input-container m-w-100 mb-4">
                                                        <div id="filter1" className="input-items-container">
                                                            <div className="input-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="blindes.c_blinde8"
                                                                    id="filter1-1"
                                                                    className="sr-only"
                                                                    onChange={() => setFieldValue('blindes.c_blinde8', 1)}
                                                                    checked={values.blindes.c_blinde8 === 1 ? true : false} />
                                                                <label htmlFor="filter1-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    Blindé
                                                                </label>
                                                            </div>
                                                            <div className="input-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="blindes.c_blinde8"
                                                                    id="filter1-2"
                                                                    className="sr-only"
                                                                    onChange={() => setFieldValue('blindes.c_blinde8', 2)}
                                                                    checked={values.blindes.c_blinde8 === 2 ? true : false} />
                                                                <label htmlFor="filter1-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    Non blindé
                                                                </label>
                                                            </div>
                                                            <div className="input-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="blindes.c_blinde8"
                                                                    id="filter1-3"
                                                                    className="sr-only"
                                                                    onChange={() => setFieldValue('blindes.c_blinde8', 0)}
                                                                    checked={values.blindes.c_blinde8 === 0 ? true : false} />
                                                                <label htmlFor="filter1-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <SO />
                                                                    SO
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="affichage-item-input-container liaisons-input-container mb-4">
                                                    <div id="filter2" className="input-items-container">
                                                        <div className="input-item">
                                                            <Field
                                                                type="radio"
                                                                name="liaisons.c_liaisons8"
                                                                id="filter2-1"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('liaisons.c_liaisons8', 1)}
                                                                checked={values.liaisons.c_liaisons8 === 1 ? true : false} />
                                                            <label htmlFor="filter2-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <OK />
                                                                OK
                                                            </label>
                                                        </div>
                                                        <div className="input-item">
                                                            <Field
                                                                type="radio"
                                                                name="liaisons.c_liaisons8"
                                                                id="filter2-2"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('liaisons.c_liaisons8', 2)}
                                                                checked={values.liaisons.c_liaisons8 === 2 ? true : false} />
                                                            <label htmlFor="filter2-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <Erreur />
                                                                R13
                                                            </label>
                                                        </div>
                                                        {interventionInfoGeneral.que_vp === false &&
                                                            <div className="input-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="liaisons.c_liaisons8"
                                                                    id="filter2-4"
                                                                    className="sr-only"
                                                                    onChange={() => setFieldValue('liaisons.c_liaisons8', 3)}
                                                                    checked={values.liaisons.c_liaisons8 === 3 ? true : false} />
                                                                <label htmlFor="filter2-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <Intervention />
                                                                    I23
                                                                </label>
                                                            </div>
                                                        }
                                                        <div className="input-item">
                                                            <Field
                                                                type="radio"
                                                                name="liaisons.c_liaisons8"
                                                                id="filter2-3"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('liaisons.c_liaisons8', 0)}
                                                                checked={values.liaisons.c_liaisons8 === 0 ? true : false} />
                                                            <label htmlFor="filter2-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <SO />
                                                                SO
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* R20 */}
                                                <div className="form-row m-m-0">
                                                    <div className="form-cell-input checkbox-container">
                                                        <div className="form-input">
                                                            <Checkbox
                                                                name="c_r20"
                                                                value={values.c_r20}
                                                                checked={values.c_r20}
                                                                onChange={e => { handleChangeCB(e, values, setFieldValue) }}
                                                                inputProps={{
                                                                    'aria-label': 'primary checkbox'
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <div className="indication-title">
                                                                R20 - Autre motif de Refus :
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-cell-input m-w-100">
                                                        <div className="form-input">
                                                            <Field type="text" name="r20" className="form-control" disabled={!values.c_r20 ? true : undefined} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* R21 */}
                                                <div className="form-row m-m-0">
                                                    <div className="form-cell-input checkbox-container">
                                                        <div className="form-input">
                                                            <Checkbox
                                                                name="c_r21"
                                                                value={values.c_r21}
                                                                checked={values.c_r21}
                                                                onChange={e => { handleChangeCB(e, values, setFieldValue) }}
                                                                inputProps={{
                                                                    'aria-label': 'primary checkbox'
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <div className="indication-title">
                                                                R21 - Conformité Montage :
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-cell-input m-w-100">
                                                        <div className="form-input">
                                                            <Field type="text" name="r21" className="form-control" disabled={!values.c_r21 ? true : undefined} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* R22 */}
                                                <div className="form-row m-m-0">
                                                    <div className="form-cell-input checkbox-container">
                                                        <div className="form-input">
                                                            <Checkbox
                                                                name="c_r22"
                                                                value={values.c_r22}
                                                                checked={values.c_r22}
                                                                onChange={e => { handleChangeCB(e, values, setFieldValue) }}
                                                                inputProps={{
                                                                    'aria-label': 'primary checkbox'
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <div className="indication-title">
                                                                R22 - Intégrité des liaisons :
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-cell-input m-w-100">
                                                        <div className="form-input">
                                                            <Field type="text" name="r22" className="form-control" disabled={!values.c_r22 ? true : undefined} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </> :
                                <div className="no-liaisons">
                                    <span className="details-item">Il faut identifier la présence d'un lumineux dans</span>
                                    <span className="details-item">
                                        <NavLink to="/intervention/autres">Autres</NavLink>
                                    </span>
                                </div>
                            }
                            {isOpened &&
                                <LiaisonPopup title="Sélectionner les appareils à lier" close={togglePopup} data={appareils} setFieldValue={setFieldValue} values={values} />
                            }
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )

}

export default Liaisons