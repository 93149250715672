import { useState, useEffect, useRef } from "react"
import Timer from "./Timer"
import ControlButtons from "./ControlButtons"
import { supervisionService } from "../../../../services/supervisionService"
import { toast } from "react-toastify"
import { isNullOrUndefined, logger, loggerDev } from "../../../../helpers/utils"
import { useSaisieContext } from "../SaisieContext"

const StopWatch = (props) => {
    const [time, setTime] = useState(0)
    const [isRunning, setIsRunning] = useState(false)
    const intervalRef = useRef(null)
    // Ajouté
    const [elapsedTime, setElapsedTime] = useState(0)
    const { setBlockSaveMetrologie } = useSaisieContext()

    useEffect(() => {
        return () => clearInterval(intervalRef.current)
    }, [])


    const start = () => {
        if (!isRunning) {
            setIsRunning(true)

            // Ajouté
            supervisionService.stopwatchStart()
                .then(response => {
                    const startTime = Date.now() - time

                    intervalRef.current = setInterval(() => {
                        let _time = Date.now() - startTime
                        // Ajouté
                        setTime(_time)
                    }, 10)

                })
                .catch(error => {
                    toast.error(error, { containerId: 'App' });
                    console.log(error)
                })
        }
    }

    const stop = () => {
        if (isRunning) {
            clearInterval(intervalRef.current)
            setIsRunning(false)

            // Ajouté
            const startTime = performance.now() // Début de la mesure du temps
            supervisionService.stopwatchStop()
                .then(response => {
                    // Ajouté
                    const endTime = performance.now(); // Fin de la mesure du temps
                    const requestDuration = endTime - startTime; // Durée de la requête en millisecondes

                    // Ajouté
                    supervisionService.stopwatchElapsed()
                        .then(response => {
                            setElapsedTime(convertElapsedTimeToSeconds(response.elapsedTime))
                            let value = (convertElapsedTimeToSeconds(response.elapsedTime) - requestDuration / 1000)
                            controleServeur(value)
                        })
                        .catch(error => {
                            toast.error(error, { containerId: 'App' });
                            console.log(error)
                        })
                })
                .catch(error => {
                    toast.error(error, { containerId: 'App' });
                    console.log(error)
                })
        }
        // Ajouté
        props.parentCallback(time / 1000)
    }

    const reset = () => {
        clearInterval(intervalRef.current)
        setIsRunning(false)
        setTime(0)
    }

    const convertElapsedTimeToSeconds = (elapsedTimeString) => {
        // Extraire les différentes parties du temps
        const timeParts = elapsedTimeString.split(':')

        // Convertir les parties en heures, minutes, secondes et millisecondes
        const hours = parseInt(timeParts[0], 10)
        const minutes = parseInt(timeParts[1], 10)
        const secondsAndMilliseconds = timeParts[2].split('.')
        const seconds = parseInt(secondsAndMilliseconds[0], 10)
        const milliseconds = parseInt(secondsAndMilliseconds[1], 10)

        // Convertir tout en secondes
        const totalSeconds = (hours * 3600) + (minutes * 60) + seconds + (milliseconds / 1000)

        return totalSeconds
    }

    const isValidEssai = (value) => {
        if (!isNullOrUndefined(value)) {
            let _time = time
            if (time === 0 && localStorage.time) {
                _time = parseInt(JSON.parse(localStorage.getItem("time")))
            }
            let result = Math.round((value - (_time / 1000)) * 1000) / 1000

            if (result < 0.2) {
                toast.success("Essai valable au niveau du serveur : " + Math.round(value * 100) / 100 + " avec " + result + " secondes de différence. (Tolérance : 200ms)")
                return true
            }
            else {
                //toast.info("Essai au niveau du serveur supérieur à 200ms. Enregistrement impossible.")
                toast.info("Essai au niveau du serveur supérieur à 200ms. Enregistrement impossible : " + value + " avec " + result + " secondes de différence. (Tolérance : 200ms)")
                return false
            }
        }
    }

    const controleServeur = (elsapedTime) => {
        if (!isValidEssai(elsapedTime)) {
            setBlockSaveMetrologie(true)
        }
        else {
            setBlockSaveMetrologie(false)
        }
    }

    return (
        <div className="stop-watch c-center">
            <Timer time={time} />
            <div className="Control-Buttons">
                {!isRunning &&
                    <div className="btn btn-one btn-start comet-btn-primary"
                        onClick={start}>
                        Démarrer
                    </div>
                }
                {isRunning &&
                    <div className="btn btn-one comet-btn-danger"
                        onClick={stop}>
                        Arrêter
                    </div>
                }
                <div className="btn btn-two btn-info"
                    onClick={reset}>
                    Effacer
                </div>
            </div>
        </div>
    )
}

export default StopWatch