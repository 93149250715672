import { Field, Form, Formik } from "formik"
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../../res/erreur.svg'
import { ReactComponent as Alerte } from './../../../../../res/alerte.svg'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'

const Plaque = () => {

    // Style de la table
    const useStyles = makeStyles({
        table: {
            width: "100%",
        },
    })
    const classes = useStyles()

    return (
        <div className="plaque-section">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container etiquette">
                            <div className="reception-accordion">
                                {/* Chassis */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Chassis</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="kilometrage" className="form-control input-small" type="text" />
                                        </div>
                                    </div>
                                </div>
                                {/* Présence */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">Présence Plaque</div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only" />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        Existante
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-2"
                                                        className="sr-only" />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Alerte />
                                                        Absente
                                                    </label>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                {/* Marque */}
                                <div className="form-row">
                                    <div className="form-col">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Marque</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control input-small" type="text" /*disabled={values.pas_vignette_presente}*/ />
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <button type="button" className="cbtn cbtn-success mt-0 mb-2" /*disabled={values.pas_vignette_presente} onClick={e => setFieldValue("marque_vignette", "FG13")}*/>
                                                FG13
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* Mesure */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Mesure
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    id="date-picker-dialog"
                                                    label="Mesure"
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    //value={values.date_effectuee}
                                                    //onChange={value => setFieldValue("date_effectuee", value)}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "Date"
                                                    }}
                                                    maxDate={new Date()}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-row flex-column">
                                        {/* w */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">w</div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="kilometrage" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">imp/km</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* k */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">k</div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="kilometrage" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">imp/km</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* l */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">l</div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field name="kilometrage" className="form-control input-small" type="text" />
                                                </div>
                                            </div>
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">mm</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Bouton k=w */}
                                    <div className="form-row justify-content-center align-items-center ml-2">
                                        <div className="button-container">
                                            <button type="button" className="btn cbtn-success" >
                                                k = w
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* Ville atelier */}
                                <div className="form-row">
                                    <div className="form-col">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Ville atelier</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-col m-w-100">
                                        <div className="form-row">
                                            <div className="form-col m-w-100">
                                                <div className="form-cell-input m-w-100">
                                                    <div className="form-input">
                                                        <Field name="util_mano" className="form-control" as="select">
                                                            <option value="0">Aix-en-Provence</option>
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Plaquette */}
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Photo plaquette</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <div className="button-document-container">
                                                        {/*values && values.photo_1 ?
                                                                    <button id="photo1" type="button" className="btn btn-document" onClick={e => openImage(e, 1)}>
                                                                        Photo 1
                                                                        <i className="fas fa-eye"></i>
                                                                    </button>
                                                                    :
                                                                */}
                                                        Pas de photo
                                                    </div>
                                                    <div className="action-button-container">
                                                        <input
                                                            type="file"
                                                            name="photo_1"
                                                            id="photo_1"
                                                            className="photo-input"
                                                            accept="image/x-png,image/gif,image/jpeg"
                                                            /*onChange={e => uploadPhoto(e, setFieldValue, 1)}*/
                                                            key={Date.now() + 1}
                                                        />
                                                        <label htmlFor="photo_1">Ajouter ou modifier</label>
                                                        <button id="del1" className="photo-action-button" type="button" /*onClick={e => handleDelete(e, setFieldValue, 1)}*/>
                                                            <i className="fas fa-trash-alt"></i>
                                                        </button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* VU */}
                                <div className="card mt-2">
                                    <div className="form-row mb-2">
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">VU</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <button className="btn cbtn-success mr-2" > = Usine </button>
                                        <button className="btn cbtn-success" > = Plaque </button>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-row flex-column">
                                            {/* w */}
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">w</div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="kilometrage" className="form-control input-small" type="text" />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">imp/km</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* k */}
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">k</div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="kilometrage" className="form-control input-small" type="text" />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">imp/km</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* l */}
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">l</div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="kilometrage" className="form-control input-small" type="text" />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">mm</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row justify-content-center align-items-center ml-2">
                                            <div className="button-container">
                                                <button type="button" className="btn cbtn-success" >
                                                    k = w
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Que block (1) */}
                                    <div className="form-row m-m-0">
                                        <div className="form-cell-input checkbox-container">
                                            <div className="form-input">
                                                <Checkbox
                                                    //checked={values.c_r99}
                                                    /*onChange={() => {
                                                        setValues(prev => ({
                                                            ...prev,
                                                            r99: "",
                                                            c_r99: !values.c_r99
                                                        }))
                                                    }}*/
                                                    name="c_r99"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    Que block (1) présent
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Egal precedent */}
                                <div className="section-precedent mt-2">
                                    <button type="button" className="cbtn cbtn-success mt-0 mb-2">Egal précédent</button>
                                </div>
                                {/* Plaque d'installation */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Plaque d'installation
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_ANO_afficheur_depareille"
                                                        id="c_ANO_afficheur_depareille-1"
                                                        className="sr-only"
                                                    //onChange={() => setFieldValue('c_ANO_afficheur_depareille', 1)}
                                                    //checked={values.c_ANO_afficheur_depareille === 1 ? true : false}
                                                    />
                                                    <label htmlFor="c_ANO_afficheur_depareille-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_ANO_afficheur_depareille"
                                                        id="c_ANO_afficheur_depareille-2"
                                                        className="sr-only"
                                                            /*onChange={() => setFieldValue('c_ANO_afficheur_depareille', 4)}
                                                            checked={values.c_ANO_afficheur_depareille === 4 ? true : false}*/ />
                                                    <label htmlFor="c_ANO_afficheur_depareille-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Alerte />
                                                        AXX
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_ANO_afficheur_depareille"
                                                        id="c_ANO_afficheur_depareille-3"
                                                        className="sr-only"
                                                            /*onChange={() => setFieldValue('c_ANO_afficheur_depareille', 2)}
                                                            checked={values.c_ANO_afficheur_depareille === 2 ? true : false}*/ />
                                                    <label htmlFor="c_ANO_afficheur_depareille-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        SO
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_ANO_afficheur_depareille"
                                                        id="c_ANO_afficheur_depareille-4"
                                                        className="sr-only"
                                                            /*onChange={() => setFieldValue('c_ANO_afficheur_depareille', 2)}
                                                            checked={values.c_ANO_afficheur_depareille === 2 ? true : false}*/ />
                                                    <label htmlFor="c_ANO_afficheur_depareille-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        Retirée sur l'intervention précédente (refus ou dépose: véhicule resté atelier)
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* A10 : Absence de plaque */}
                                <div className="form-row m-m-0">
                                    <div className="form-cell-input checkbox-container">
                                        <div className="form-input">
                                            <Checkbox
                                                //checked={values.c_r99}
                                                /*onChange={() => {
                                                    setValues(prev => ({
                                                        ...prev,
                                                        r99: "",
                                                        c_r99: !values.c_r99
                                                    }))
                                                }}*/
                                                name="c_r99"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                A10 : Absence de plaque d'installation (ancienne A10)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* A10 : Plaque d'installation endommagé (ancienne A11) */}
                                <div className="form-row m-m-0">
                                    <div className="form-cell-input checkbox-container">
                                        <div className="form-input">
                                            <Checkbox
                                                //checked={values.c_r99}
                                                /*onChange={() => {
                                                    setValues(prev => ({
                                                        ...prev,
                                                        r99: "",
                                                        c_r99: !values.c_r99
                                                    }))
                                                }}*/
                                                name="c_r99"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                A10 : Plaque d'installation endommagé (ancienne A11)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* A10 : Renseignements incomplets sur la plaque d'installation (ancienne A12) */}
                                <div className="form-row m-m-0">
                                    <div className="form-cell-input checkbox-container">
                                        <div className="form-input">
                                            <Checkbox
                                                //checked={values.c_r99}
                                                /*onChange={() => {
                                                    setValues(prev => ({
                                                        ...prev,
                                                        r99: "",
                                                        c_r99: !values.c_r99
                                                    }))
                                                }}*/
                                                name="c_r99"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                A10 : Renseignements incomplets sur la plaque d'installation (ancienne A12)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* A10 : Informations relatives aux scellemens de l'installation affectées */}
                                <div className="form-row m-m-0">
                                    <div className="form-cell-input checkbox-container">
                                        <div className="form-input">
                                            <Checkbox
                                                //checked={values.c_r99}
                                                /*onChange={() => {
                                                    setValues(prev => ({
                                                        ...prev,
                                                        r99: "",
                                                        c_r99: !values.c_r99
                                                    }))
                                                }}*/
                                                name="c_r99"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                A10 : Informations relatives aux scellemens de l'installation affectées
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* A10 */}
                                <div className="form-row m-m-0">
                                    <div className="form-cell-input checkbox-container">
                                        <div className="form-input">
                                            <Checkbox
                                                //checked={values.c_r99}
                                                /*onChange={() => {
                                                    setValues(prev => ({
                                                        ...prev,
                                                        r99: "",
                                                        c_r99: !values.c_r99
                                                    }))
                                                }}*/
                                                name="c_r99"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                A00 : Mesure des pneumatiques ou w, k, l supérieure à 2 ans (ancienne A13)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* A50 : Non concordance Taille des pneus */}
                                <div className="form-row m-m-0">
                                    <div className="form-cell-input checkbox-container">
                                        <div className="form-input">
                                            <Checkbox
                                                //checked={values.c_r99}
                                                /*onChange={() => {
                                                    setValues(prev => ({
                                                        ...prev,
                                                        r99: "",
                                                        c_r99: !values.c_r99
                                                    }))
                                                }}*/
                                                name="c_r99"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                A50 : Non concordance Taille des pneus
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* A50 : Non concordance Circonférence pneus */}
                                <div className="form-row m-m-0">
                                    <div className="form-cell-input checkbox-container">
                                        <div className="form-input">
                                            <Checkbox
                                                //checked={values.c_r99}
                                                /*onChange={() => {
                                                    setValues(prev => ({
                                                        ...prev,
                                                        r99: "",
                                                        c_r99: !values.c_r99
                                                    }))
                                                }}*/
                                                name="c_r99"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                A50 : Non concordance Circonférence pneus
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Oter la plaque présente */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Oter la plaque présente
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_ANO_afficheur_depareilles"
                                                        id="c_ANO_afficheur_depareille-7"
                                                        className="sr-only"
                                                    //onChange={() => setFieldValue('c_ANO_afficheur_depareille', 1)}
                                                    //checked={values.c_ANO_afficheur_depareille === 1 ? true : false}
                                                    />
                                                    <label htmlFor="c_ANO_afficheur_depareille-7" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OUI
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_ANO_afficheur_depareilles"
                                                        id="c_ANO_afficheur_depareille-6"
                                                        className="sr-only"
                                                            /*onChange={() => setFieldValue('c_ANO_afficheur_depareille', 2)}
                                                            checked={values.c_ANO_afficheur_depareille === 2 ? true : false}*/ />
                                                    <label htmlFor="c_ANO_afficheur_depareille-6" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        Pas de plaque : SO
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default Plaque