import React from 'react'
import ReactDOM from 'react-dom'
//------------------------
import { App } from './App'
//------------------------
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './index.css'
import './style/style.css'
import './style/responsive.css'
import './components/Intervention/intervention.css'
import './pages/Texte/texte.css'
import './style/all.scss'
import { jwtInterceptor } from './rxjsTest/jwt.interceptor'
import { errorInterceptor } from './rxjsTest/error.interceptor'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

jwtInterceptor()
errorInterceptor()

Sentry.init({
  dsn: "https://7f37f11d098b46eda11b6ad814a67b3e@o1343943.ingest.sentry.io/6619398",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <App name="Mouss" />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()