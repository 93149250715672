import { styled } from "@mui/material"
import TreeItem from '@mui/lab/TreeItem'

export const StyledTreeItem = styled(TreeItem)`
  & > .MuiTreeItem-content .MuiTreeItem-label {
    font-weight: bold;
    font-family: 'Segoe UI', sans-serif;
    font-size: 15px;
  }
  & > .MuiTreeItem-content {
    padding: 5px;
    border-radius: 10px;
  }
  & > .MuiTreeItem-content.Mui-selected {
    background-color: var(--comet-primary);
    color: var(--comet-white);
  }
`;