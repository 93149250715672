import apilink from '../constants/apilink'
import jwtDecode from "jwt-decode"
import history from "../helpers/history"
import axios from "axios"
import { oidclink } from '../utils/oidclink'

export const userService = {
    login,
    getAvatar,
    loginIntervenantAdherent,
    register,
    logout,
    getAll,
    update,
    getUnlockCode,
    getUnlockCodeAncien,
    switchDatabase,
    delete: _delete
}

// Prend un objet JSON avec des champs nom utilisateur et mot de passe
function login(login, passe_net) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ login, passe_net })
    }

    // JSON parse car specialement pour le login un texte est retourne
    return fetch(apilink + "/user/login/siege", requestOptions)
        .then(handleResponse)
        .then(user => {
            // Enregistre les données de l'utilisateur ainsi que son token afin d'etre authentifier a chaque chargement
            localStorage.setItem("user", user)
            return JSON.parse(user)
        })

}

// Prend un objet JSON avec des champs nom utilisateur et mot de passe
function loginIntervenantAdherent(login, nom_adherent, passe_net) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ login, nom_adherent, passe_net })
    }

    // JSON parse car specialement pour le login un texte est retourne
    return fetch(apilink + "/user/login/adherent", requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem("user", user)
            return JSON.parse(user)
        })

}

// Prend un objet JSON user puis effectue la requete
function register(user) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    }
    return fetch(apilink + "/user/register", requestOptions).then(handleResponse)
}

function logout() {
    localStorage.clear()
}

function getAvatar(adherent, user) {
    return axios.get(apilink + `/user/avatar?adherent=${adherent}&user=${user}`)
        .then(response => response.data)
}

function getAll() {
    return axios.get(apilink + "/user")
        .then(response => response.data)
}

function getUnlockCode(code) {
    const oidcStorage = JSON.parse(sessionStorage.getItem(oidclink))
    const user = jwtDecode(oidcStorage.access_token)

    return axios.post(apilink + "/user/" + user.user_id + "/code", code)
        .then(response => response.data)
}

function getUnlockCodeAncien() {
    return axios.get(apilink + "/user/code/ancien")
        .then(response => response.data)
}

function update(user) {
    return axios.put(apilink + "/user/" + user.id, user)
        .then(response => response.data)
}

function _delete(id) {
    return axios.delete(apilink + "/user/" + id)
        .then(response => response.data)
}

function switchDatabase(selection) {
    return axios.get(apilink + "/user/switch/" + selection)
        .then(response => response.data)
}

// Renvoie une reponse sous forme de texte
// Puis prendre le texte cad la reponse et convertit en jsoon
function handleResponse(response) {
    return response.text().then(
        text => {
            const data = text;
            if (!response.ok) {
                if (response.status === 401 || response.status === 403) {
                    history.push('/logout')
                    // eslint-disable-next-line no-restricted-globals
                    location.reload(true)
                }
                const error = JSON.parse(text).message//(data && data.message)
                return Promise.reject(error)
            }
            return data
        }
    )
}