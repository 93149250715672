import { Field, Form, Formik } from "formik"
import { ReactComponent as OK } from './../../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../../res/siren.svg'
import { ReactComponent as Intervention } from './../../../../../res/alerte.svg'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Table } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const MouleInaccessible = () => {

    // Style de la table
    const useStyles = makeStyles({
        table: {
            width: "100%",
        },
    })

    const classes = useStyles()

    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container">
                            <div className="container-horizontal">
                                {/* Detail 1 */}
                                <div className="form-row m-m-0">
                                    <div className="form-cell-input  w-100">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control" type="text" disabled />
                                        </div>
                                    </div>
                                </div>
                                {/* Detail 2 */}
                                <div className="form-row m-m-0">
                                    <div className="form-cell-input  w-100">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control" type="text" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 1)} />
                                                    <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        Numéro de série lisible
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        Illisible : Refus : R42
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="usure_pneumatique"
                                                        id="usure_pneumatique-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('usure_pneumatique', 2)} />
                                                    <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        Illisible : Remplacement : I42
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="form-row m-m-0">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Détail :
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control" type="text" disabled />
                                        </div>
                                    </div>
                                </div>
                                {/* Detail 3*/}
                                <div className="form-row m-m-0">
                                    <div className="form-cell-input  w-100">
                                        <div className="form-input">
                                            <Field name="marque_vignette" className="form-control" type="text" disabled />
                                        </div>
                                    </div>
                                </div>
                                {/*---------- Photo ---------- */}
                                <TableContainer component={Paper} className="mb-5">
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Photo Ticket</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <div className="button-document-container">
                                                        {values && values.photo_20 ?
                                                            <button id="photo1" type="button" className="btn btn-document">
                                                                Photo 1
                                                                <i className="fas fa-eye"></i>
                                                            </button>
                                                            :
                                                            "Pas encore de photo de ticket"
                                                        }
                                                    </div>
                                                    <div className="action-button-container">
                                                        <input
                                                            type="file"
                                                            name="photo_20"
                                                            id="photo_20"
                                                            className="photo-input"
                                                            accept="image/x-png,image/gif,image/jpeg"
                                                            key={Date.now() + 1}
                                                        />
                                                        <label htmlFor="photo_20">Ajouter ou modifier</label>
                                                        <button id="del1" className="photo-action-button" type="button" >
                                                            <i className="fas fa-trash-alt"></i>
                                                        </button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default MouleInaccessible